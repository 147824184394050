import React, { useState, useEffect } from "react";
import AdminGeneralInfo from "../GeneralInfo";
import GetAdminData from "../../../../graphql/CustomAdminQueries/GetAdminData";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import { Form, Alert } from "react-bootstrap";
import { Auth } from "aws-amplify";
import AdminDisableUser from "../../../../graphql/CustomAdminQueries/AdminDisableUser";
import AdminUserGlobalSignOut from "../../../../graphql/CustomAdminQueries/AdminUserGlobalSignOut";
import AdminEnableUser from "../../../../graphql/CustomAdminQueries/AdminEnableUser";
import { toast } from "react-toastify";

const AdminBlock = (props) => {
  const [adminData, setAdminData] = useState(null);
  const [adminCurrentEnableState, setAdminCurrentEnableState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [
    blockUnBlockButtonDisabledStatus,
    setBlockUnBlockButtonDisabledStatus,
  ] = useState(true);

  useEffect(() => {
    GetAdminData(props.match.params.adminId, setAdminData, setIsLoading);
  }, []);

  const checkIfAdminTryingToBlockHimself = async (toBeDeletedAdminId) => {
    await Auth.currentUserInfo().then((user) => {
      if (user.attributes.sub !== toBeDeletedAdminId)
        setBlockUnBlockButtonDisabledStatus(false);
    });
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (adminData) {
      setIsLoading(false);

      // check if admin is trying to delete himself
      const userId = adminData.data.getAdmin.UserAttributes.find(
        (attr) => attr.Name === "sub"
      ).Value;

      // check from amplify
      checkIfAdminTryingToBlockHimself(userId);

      // define the current state
      setAdminCurrentEnableState(adminData.data.getAdmin.Enabled);
    }
  }, [adminData]);

  const handleReturn = () => {
    props.history.push(`/admins`);
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setFormSubmitInProgress(true);

    let opperationResultMessage = "";

    if (adminCurrentEnableState) {
      // sign user out
      let signUserOutResult = await AdminUserGlobalSignOut(
        adminData.data.getAdmin.Username,
        setGeneralError
      );

      // check success
      if (!signUserOutResult.result) {
        toast.error(signUserOutResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      // disable user
      let disableUserResult = await AdminDisableUser(
        adminData.data.getAdmin.Username,
        setGeneralError
      );

      // check success
      if (!disableUserResult.result) {
        toast.error(disableUserResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = disableUserResult.message;
    } else {
      // enable user
      let enableUserResult = await AdminEnableUser(
        adminData.data.getAdmin.Username,
        setGeneralError
      );

      // check success
      if (!enableUserResult.result) {
        toast.error(enableUserResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = enableUserResult.message;
    }

    // go back to list
    setTimeout(function () {
      toast.success(opperationResultMessage);
      handleReturn();
    }, GlobalSubmitUIDelay.DefaultDelay);
  };

  return (
    <>
      <PagePreLoad isLoading={isLoading}>
        <Form onSubmit={handleFormSubmission} noValidate>
          <CardContainer
            header={`${adminCurrentEnableState ? "Block" : "UnBlock"} Admin`}
            submitButton={
              <ButtonInput
                label={adminCurrentEnableState ? "Block" : "UnBlock"}
                disabled={blockUnBlockButtonDisabledStatus}
                variant={
                  adminCurrentEnableState
                    ? BootstrapVariants.Danger
                    : BootstrapVariants.Success
                }
                formSubmitInProgress={formSubmitInProgress}
                formSubmittingText={"In Progress..."}
              />
            }
            backButton={
              <ButtonInput
                label="Back to List"
                type="button"
                variant={BootstrapVariants.OutlineSecondary}
                onClick={() => handleReturn()}
              />
            }
          >
            {generalError && (
              <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
            )}
            <span>
              By clicking on{" "}
              <b>{adminCurrentEnableState ? "Block" : "UnBlock"}</b>, Admin will
              be {adminCurrentEnableState ? "blocked" : "unblocked"}, do you
              want to proceed?
            </span>
          </CardContainer>
        </Form>
        <CardContainer header={"Admin Details"}>
          <AdminGeneralInfo
            providedModelData={adminData}
            adminId={props.match.params.adminId}
          />
        </CardContainer>
      </PagePreLoad>
    </>
  );
};

export default AdminBlock;
