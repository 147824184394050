/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const testPushNotification = /* GraphQL */ `
  query TestPushNotification($recipientUserId: String) {
    testPushNotification(recipientUserId: $recipientUserId) {
      results {
        Address
        DeliveryStatus
        StatusCode
        StatusMessage
      }
    }
  }
`;
export const chatRoomExistanceCheck = /* GraphQL */ `
  query ChatRoomExistanceCheck($chatRoomParticipantsList: [String]) {
    chatRoomExistanceCheck(
      chatRoomParticipantsList: $chatRoomParticipantsList
    ) {
      id
      isError
      errorMessage
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      userName
      firstName
      lastName
      major
      graduationYear
      values
      interests
      hero
      bio
      notificationsEmailEnabled
      notificationsLikeEnabled
      notificationsCommentEnabled
      notificationsNewFollowerEnabled
      notificationsFriendRequestEnabled
      privacyPublicAccount
      privacyAllowComments
      privacyHideOffensiveContent
      createdAt
      updatedAt
      deletedAt
      isBlocked
      isProfane
      schoolId
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      userCollegeId
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      userDegreeId
      degree {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherDegreeType
      userGenderId
      gender {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherGenderType
      skills {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      userProfileImageId
      profileImage {
        id
        s3ObjectId
        owner
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      journeys {
        items {
          id
          owner
          title
          institution
          location
          isCurrent
          startDate
          endDate
          description
          journeyTypeId
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          owner
          message
          isRead
          feedbackCategoryId
          feedbackCategoryName
          userId
          userName
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      clazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      pastClazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfPosts
      posts {
        items {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      followings {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowings
      followers {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowers
      friendRequestsReceived {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      friendRequestsSent {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFriends
      friends {
        items {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        nextToken
      }
      blokedUsers {
        items {
          id
          owner
          userId
          blockedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      mutedUsers {
        items {
          id
          owner
          userId
          mutedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      willingToHelps {
        items {
          id
          owner
          content
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      subjectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      objectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfStarPoints
      appBadgeCount
      referralCodeId
      numberOfTimesReferralCodeUsed
      userSignedUpWithReferralCodeId
      searchableClazzes
      searchablePastClazzes
      referralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
      signedUpWithReferralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserFollower = /* GraphQL */ `
  query GetUserFollower($id: ID!) {
    getUserFollower(id: $id) {
      id
      owner
      followerId
      followingId
      follower {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      following {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserFollowers = /* GraphQL */ `
  query ListUserFollowers(
    $filter: ModelUserFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFollowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendRequest = /* GraphQL */ `
  query GetFriendRequest($id: ID!) {
    getFriendRequest(id: $id) {
      id
      userFromId
      userToId
      isAccepted
      userFrom {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userTo {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFriendRequests = /* GraphQL */ `
  query ListFriendRequests(
    $filter: ModelFriendRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriendRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userFromId
        userToId
        isAccepted
        userFrom {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userTo {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserFriend = /* GraphQL */ `
  query GetUserFriend($id: ID!) {
    getUserFriend(id: $id) {
      id
      owner
      userId
      friendId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      friend {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserFriends = /* GraphQL */ `
  query ListUserFriends(
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlock = /* GraphQL */ `
  query GetUserBlock($id: ID!) {
    getUserBlock(id: $id) {
      id
      owner
      userId
      blockedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      blockedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserBlocks = /* GraphQL */ `
  query ListUserBlocks(
    $filter: ModelUserBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userId
        blockedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        blockedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMute = /* GraphQL */ `
  query GetUserMute($id: ID!) {
    getUserMute(id: $id) {
      id
      owner
      userId
      mutedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      mutedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserMutes = /* GraphQL */ `
  query ListUserMutes(
    $filter: ModelUserMuteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userId
        mutedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        mutedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getModelAggregations = /* GraphQL */ `
  query GetModelAggregations($modelType: modelTypes!) {
    getModelAggregations(modelType: $modelType) {
      modelType
      totalActiveCount
      createdAt
      updatedAt
    }
  }
`;
export const listModelAggregationss = /* GraphQL */ `
  query ListModelAggregationss(
    $modelType: modelTypes
    $filter: ModelModelAggregationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModelAggregationss(
      modelType: $modelType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        modelType
        totalActiveCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      createdAt
      deletedAt
      colleges {
        items {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        nextToken
      }
      numberOfColleges
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      totalSchoolUsersCount
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchoolCollege = /* GraphQL */ `
  query GetSchoolCollege($id: ID!) {
    getSchoolCollege(id: $id) {
      id
      schoolId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      updatedAt
    }
  }
`;
export const listSchoolColleges = /* GraphQL */ `
  query ListSchoolColleges(
    $filter: ModelSchoolCollegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolColleges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDomain = /* GraphQL */ `
  query GetDomain($id: ID!) {
    getDomain(id: $id) {
      id
      schoolId
      domainCollegeId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listDomains = /* GraphQL */ `
  query ListDomains(
    $filter: ModelDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        domainCollegeId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClazz = /* GraphQL */ `
  query GetClazz($id: ID!) {
    getClazz(id: $id) {
      id
      type
      schoolId
      clazzCollegeId
      name
      order
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      users {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      usersPastClazz {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listClazzs = /* GraphQL */ `
  query ListClazzs(
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClazzs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDegree = /* GraphQL */ `
  query GetDegree($id: ID!) {
    getDegree(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listDegrees = /* GraphQL */ `
  query ListDegrees(
    $filter: ModelDegreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDegrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGender = /* GraphQL */ `
  query GetGender($id: ID!) {
    getGender(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listGenders = /* GraphQL */ `
  query ListGenders(
    $filter: ModelGenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      users {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileImage = /* GraphQL */ `
  query GetProfileImage($id: ID!) {
    getProfileImage(id: $id) {
      id
      s3ObjectId
      owner
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listProfileImages = /* GraphQL */ `
  query ListProfileImages(
    $filter: ModelProfileImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        s3ObjectId
        owner
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getS3Object = /* GraphQL */ `
  query GetS3Object($id: ID!) {
    getS3Object(id: $id) {
      id
      owner
      bucket
      key
      region
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const listS3Objects = /* GraphQL */ `
  query ListS3Objects(
    $filter: ModelS3ObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Objects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJourneyType = /* GraphQL */ `
  query GetJourneyType($id: ID!) {
    getJourneyType(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listJourneyTypes = /* GraphQL */ `
  query ListJourneyTypes(
    $filter: ModelJourneyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneyTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJourney = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      owner
      title
      institution
      location
      isCurrent
      startDate
      endDate
      description
      journeyTypeId
      journeyType {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listJourneys = /* GraphQL */ `
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        institution
        location
        isCurrent
        startDate
        endDate
        description
        journeyTypeId
        journeyType {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getFeedbackCategory = /* GraphQL */ `
  query GetFeedbackCategory($id: ID!) {
    getFeedbackCategory(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listFeedbackCategorys = /* GraphQL */ `
  query ListFeedbackCategorys(
    $filter: ModelFeedbackCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      owner
      message
      isRead
      feedbackCategoryId
      feedbackCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      feedbackCategoryName
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        message
        isRead
        feedbackCategoryId
        feedbackCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        feedbackCategoryName
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostCategory = /* GraphQL */ `
  query GetPostCategory($id: ID!) {
    getPostCategory(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listPostCategorys = /* GraphQL */ `
  query ListPostCategorys(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportCategory = /* GraphQL */ `
  query GetReportCategory($id: ID!) {
    getReportCategory(id: $id) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listReportCategorys = /* GraphQL */ `
  query ListReportCategorys(
    $filter: ModelReportCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      owner
      message
      isAcknowledged
      reportCategoryId
      reportCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      reportByUserId
      reportByUserName
      reportByUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportUserId
      reportUserName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportPostId
      reportPostUserName
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      reportCommentId
      reportCommentUserName
      reportCommentContentAtTimeOfReport
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        message
        isAcknowledged
        reportCategoryId
        reportCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        reportByUserId
        reportByUserName
        reportByUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportUserId
        reportUserName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportPostId
        reportPostUserName
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        reportCommentId
        reportCommentUserName
        reportCommentContentAtTimeOfReport
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      postType
      content
      owner
      isProfane
      isFriendsOnly
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postPostCategoryId
      postPostCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfLikes
      likes {
        items {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfComments
      comments {
        items {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      postAssets {
        items {
          id
          owner
          order
          postId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      hashTags {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostAsset = /* GraphQL */ `
  query GetPostAsset($id: ID!) {
    getPostAsset(id: $id) {
      id
      owner
      order
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listPostAssets = /* GraphQL */ `
  query ListPostAssets(
    $filter: ModelPostAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        order
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        s3ObjectId
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getHashTag = /* GraphQL */ `
  query GetHashTag($id: ID!) {
    getHashTag(id: $id) {
      id
      title
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfPosts
      posts {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listHashTags = /* GraphQL */ `
  query ListHashTags(
    $filter: ModelHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHashTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      owner
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      owner
      content
      isProfane
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      isReply
      replies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      parentReplies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getCommentReply = /* GraphQL */ `
  query GetCommentReply($id: ID!) {
    getCommentReply(id: $id) {
      id
      commentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      repliedToId
      repliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      parrentRepliedToId
      parrentRepliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCommentReplys = /* GraphQL */ `
  query ListCommentReplys(
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentReplys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        repliedToId
        repliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        parrentRepliedToId
        parrentRepliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWillingToHelp = /* GraphQL */ `
  query GetWillingToHelp($id: ID!) {
    getWillingToHelp(id: $id) {
      id
      owner
      content
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listWillingToHelps = /* GraphQL */ `
  query ListWillingToHelps(
    $filter: ModelWillingToHelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWillingToHelps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        content
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getUserActivity = /* GraphQL */ `
  query GetUserActivity($id: ID!) {
    getUserActivity(id: $id) {
      id
      activityType
      isRead
      subjectUserId
      objectUserId
      subjectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      objectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userActivityLikeId
      like {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      userActivityCommentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      userActivityUserFollowerId
      userFollower {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      userActivityUserFriendId
      userFriend {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserActivitys = /* GraphQL */ `
  query ListUserActivitys(
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPointSystem = /* GraphQL */ `
  query GetPointSystem($id: ID!) {
    getPointSystem(id: $id) {
      id
      type
      pointType
      order
      point
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const listPointSystems = /* GraphQL */ `
  query ListPointSystems(
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        pointType
        order
        point
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      members
      chatRoomParticipants {
        items {
          id
          pinnedStatus
          userId
          chatRoomId
          chatRoomParticipantLastReadMessageId
          numberOfUnReadMessages
          createdAt
          updatedAt
          lastChatRoomMessageCreatedAt
        }
        nextToken
      }
      chatRoomMessages {
        items {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomLastMessageId
      lastMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        chatRoomParticipants {
          nextToken
        }
        chatRoomMessages {
          nextToken
        }
        chatRoomLastMessageId
        lastMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomParticipant = /* GraphQL */ `
  query GetChatRoomParticipant($id: ID!) {
    getChatRoomParticipant(id: $id) {
      id
      pinnedStatus
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoom {
        id
        members
        chatRoomParticipants {
          nextToken
        }
        chatRoomMessages {
          nextToken
        }
        chatRoomLastMessageId
        lastMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      chatRoomParticipantLastReadMessageId
      lastReadMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      numberOfUnReadMessages
      createdAt
      updatedAt
      lastChatRoomMessageCreatedAt
    }
  }
`;
export const listChatRoomParticipants = /* GraphQL */ `
  query ListChatRoomParticipants(
    $filter: ModelChatRoomParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pinnedStatus
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoom {
          id
          members
          chatRoomLastMessageId
          createdAt
          updatedAt
        }
        chatRoomParticipantLastReadMessageId
        lastReadMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        numberOfUnReadMessages
        createdAt
        updatedAt
        lastChatRoomMessageCreatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomMessage = /* GraphQL */ `
  query GetChatRoomMessage($id: ID!) {
    getChatRoomMessage(id: $id) {
      id
      owner
      chatMessageType
      body
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoomMessageAssets {
        items {
          id
          owner
          order
          chatRoomMessageId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomMessageSharedPostId
      sharedPost {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listChatRoomMessages = /* GraphQL */ `
  query ListChatRoomMessages(
    $filter: ModelChatRoomMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomMessageAsset = /* GraphQL */ `
  query GetChatRoomMessageAsset($id: ID!) {
    getChatRoomMessageAsset(id: $id) {
      id
      owner
      order
      chatRoomMessageId
      chatRoomMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listChatRoomMessageAssets = /* GraphQL */ `
  query ListChatRoomMessageAssets(
    $filter: ModelChatRoomMessageAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomMessageAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        order
        chatRoomMessageId
        chatRoomMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        s3ObjectId
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const userBySchool = /* GraphQL */ `
  query UserBySchool(
    $schoolId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySchool(
      schoolId: $schoolId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserFollowingsByUserId = /* GraphQL */ `
  query GetUserFollowingsByUserId(
    $followerId: ID
    $followingId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserFollowingsByUserId(
      followerId: $followerId
      followingId: $followingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserFollowersByUserId = /* GraphQL */ `
  query GetUserFollowersByUserId(
    $followingId: ID
    $followerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserFollowersByUserId(
      followingId: $followingId
      followerId: $followerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowingsByFollowerId = /* GraphQL */ `
  query GetFollowingsByFollowerId(
    $followerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFollowingsByFollowerId(
      followerId: $followerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowersByFollowingId = /* GraphQL */ `
  query GetFollowersByFollowingId(
    $followingId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFollowersByFollowingId(
      followingId: $followingId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendRequestByUserFromId = /* GraphQL */ `
  query GetFriendRequestByUserFromId(
    $userFromId: ID
    $userToId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendRequestByUserFromId(
      userFromId: $userFromId
      userToId: $userToId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFromId
        userToId
        isAccepted
        userFrom {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userTo {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendRequestByUserToId = /* GraphQL */ `
  query GetFriendRequestByUserToId(
    $userToId: ID
    $userFromId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendRequestByUserToId(
      userToId: $userToId
      userFromId: $userFromId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFromId
        userToId
        isAccepted
        userFrom {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userTo {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendRequestsByUserFromId = /* GraphQL */ `
  query GetFriendRequestsByUserFromId(
    $userFromId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendRequestsByUserFromId(
      userFromId: $userFromId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFromId
        userToId
        isAccepted
        userFrom {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userTo {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendRequestsByUserToId = /* GraphQL */ `
  query GetFriendRequestsByUserToId(
    $userToId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFriendRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendRequestsByUserToId(
      userToId: $userToId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFromId
        userToId
        isAccepted
        userFrom {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userTo {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendsByUserId = /* GraphQL */ `
  query GetFriendsByUserId(
    $userId: ID
    $friendId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendsByUserId(
      userId: $userId
      friendId: $friendId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriendsByFriendId = /* GraphQL */ `
  query GetFriendsByFriendId(
    $friendId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFriendsByFriendId(
      friendId: $friendId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserFriendsByUserId = /* GraphQL */ `
  query GetUserFriendsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserFriendsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserFriendsbyFriendId = /* GraphQL */ `
  query GetUserFriendsbyFriendId(
    $friendId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserFriendsbyFriendId(
      friendId: $friendId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlockByUserId = /* GraphQL */ `
  query GetUserBlockByUserId(
    $userId: ID
    $blockedUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserBlockByUserId(
      userId: $userId
      blockedUserId: $blockedUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        blockedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        blockedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlockByBlockedUserId = /* GraphQL */ `
  query GetUserBlockByBlockedUserId(
    $blockedUserId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserBlockByBlockedUserId(
      blockedUserId: $blockedUserId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        blockedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        blockedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlocksByUserId = /* GraphQL */ `
  query GetUserBlocksByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserBlocksByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        blockedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        blockedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlocksByBlockedUserId = /* GraphQL */ `
  query GetUserBlocksByBlockedUserId(
    $blockedUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserBlocksByBlockedUserId(
      blockedUserId: $blockedUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        blockedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        blockedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMuteByUserId = /* GraphQL */ `
  query GetUserMuteByUserId(
    $userId: ID
    $mutedUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMuteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMuteByUserId(
      userId: $userId
      mutedUserId: $mutedUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        mutedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        mutedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMuteByMutedUserId = /* GraphQL */ `
  query GetUserMuteByMutedUserId(
    $mutedUserId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMuteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMuteByMutedUserId(
      mutedUserId: $mutedUserId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        mutedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        mutedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMutesByUserId = /* GraphQL */ `
  query GetUserMutesByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMuteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMutesByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        mutedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        mutedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMutesByMutedUserId = /* GraphQL */ `
  query GetUserMutesByMutedUserId(
    $mutedUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMuteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserMutesByMutedUserId(
      mutedUserId: $mutedUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        mutedUserId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        mutedUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const domainByName = /* GraphQL */ `
  query DomainByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDomainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    domainByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        schoolId
        domainCollegeId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const clazzByName = /* GraphQL */ `
  query ClazzByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clazzByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClazzesBySchool = /* GraphQL */ `
  query GetClazzesBySchool(
    $schoolId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClazzesBySchool(
      schoolId: $schoolId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClazzesBySchoolIdOrdered = /* GraphQL */ `
  query GetClazzesBySchoolIdOrdered(
    $schoolId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClazzesBySchoolIdOrdered(
      schoolId: $schoolId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClazzesByCollege = /* GraphQL */ `
  query GetClazzesByCollege(
    $clazzCollegeId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClazzesByCollege(
      clazzCollegeId: $clazzCollegeId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClazzesByCollegeIdOrdered = /* GraphQL */ `
  query GetClazzesByCollegeIdOrdered(
    $clazzCollegeId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClazzesByCollegeIdOrdered(
      clazzCollegeId: $clazzCollegeId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listClazzByOrder = /* GraphQL */ `
  query ListClazzByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListClazzByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserClazzByUserId = /* GraphQL */ `
  query GetUserClazzByUserId(
    $userId: ID
    $clazzId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserClazzByUserId(
      userId: $userId
      clazzId: $clazzId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        clazzId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        clazz {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPastClazzByUserId = /* GraphQL */ `
  query GetUserPastClazzByUserId(
    $userId: ID
    $clazzId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPastClazzFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserPastClazzByUserId(
      userId: $userId
      clazzId: $clazzId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        clazzId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        clazz {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDegreeByName = /* GraphQL */ `
  query GetDegreeByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelDegreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDegreeByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDegreeByOrder = /* GraphQL */ `
  query ListDegreeByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDegreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListDegreeByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGenderByName = /* GraphQL */ `
  query GetGenderByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelGenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGenderByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGenderByOrder = /* GraphQL */ `
  query ListGenderByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListGenderByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkillByName = /* GraphQL */ `
  query GetSkillByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSkillByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSkillByOrder = /* GraphQL */ `
  query ListSkillByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListSkillByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSkillByUserId = /* GraphQL */ `
  query GetUserSkillByUserId(
    $userId: ID
    $skillId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserSkillByUserId(
      userId: $userId
      skillId: $skillId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        skillId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        skill {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJourneyTypeByName = /* GraphQL */ `
  query GetJourneyTypeByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJourneyTypeByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listJourneyTypeByOrder = /* GraphQL */ `
  query ListJourneyTypeByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListJourneyTypeByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJourneyByOwner = /* GraphQL */ `
  query GetJourneyByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJourneyByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        institution
        location
        isCurrent
        startDate
        endDate
        description
        journeyTypeId
        journeyType {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getJourneysByUserId = /* GraphQL */ `
  query GetJourneysByUserId(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJourneysByUserId(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        institution
        location
        isCurrent
        startDate
        endDate
        description
        journeyTypeId
        journeyType {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getFeedbackCategoryByName = /* GraphQL */ `
  query GetFeedbackCategoryByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFeedbackCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbackCategoryByOrder = /* GraphQL */ `
  query ListFeedbackCategoryByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListFeedbackCategoryByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedbackByUser = /* GraphQL */ `
  query FeedbackByUser(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByUser(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        isRead
        feedbackCategoryId
        feedbackCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        feedbackCategoryName
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const feedbacksByCategoryId = /* GraphQL */ `
  query FeedbacksByCategoryId(
    $feedbackCategoryId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbacksByCategoryId(
      feedbackCategoryId: $feedbackCategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        isRead
        feedbackCategoryId
        feedbackCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        feedbackCategoryName
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostCategoryByName = /* GraphQL */ `
  query GetPostCategoryByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPostCategoryByOrder = /* GraphQL */ `
  query ListPostCategoryByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListPostCategoryByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportCategoryByName = /* GraphQL */ `
  query GetReportCategoryByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReportCategoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listReportCategoryByOrder = /* GraphQL */ `
  query ListReportCategoryByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListReportCategoryByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportByOwner = /* GraphQL */ `
  query GetReportByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReportByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        isAcknowledged
        reportCategoryId
        reportCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        reportByUserId
        reportByUserName
        reportByUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportUserId
        reportUserName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportPostId
        reportPostUserName
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        reportCommentId
        reportCommentUserName
        reportCommentContentAtTimeOfReport
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostByOwner = /* GraphQL */ `
  query GetPostByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostsByUserId = /* GraphQL */ `
  query GetPostsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostsBySchoolId = /* GraphQL */ `
  query GetPostsBySchoolId(
    $schoolId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostsBySchoolId(
      schoolId: $schoolId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostAssetsByPostId = /* GraphQL */ `
  query GetPostAssetsByPostId(
    $postId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostAssetsByPostId(
      postId: $postId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        order
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        s3ObjectId
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getPostHashTagsByPostId = /* GraphQL */ `
  query GetPostHashTagsByPostId(
    $postId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostHashTagsByPostId(
      postId: $postId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        hashTagId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        hashTag {
          id
          title
          schoolId
          schoolName
          numberOfPosts
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostHashTagsByHashTagId = /* GraphQL */ `
  query GetPostHashTagsByHashTagId(
    $hashTagId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPostHashTagsByHashTagId(
      hashTagId: $hashTagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        hashTagId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        hashTag {
          id
          title
          schoolId
          schoolName
          numberOfPosts
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const hashTagByTitle = /* GraphQL */ `
  query HashTagByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hashTagByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getHashTagsBySchoolIdAndTitle = /* GraphQL */ `
  query GetHashTagsBySchoolIdAndTitle(
    $schoolId: ID
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHashTagsBySchoolIdAndTitle(
      schoolId: $schoolId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getHashTagsBySchoolId = /* GraphQL */ `
  query GetHashTagsBySchoolId(
    $schoolId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHashTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHashTagsBySchoolId(
      schoolId: $schoolId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getLikeByOwner = /* GraphQL */ `
  query GetLikeByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikeByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikesByUserId = /* GraphQL */ `
  query GetLikesByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikesByUserIdAndPostId = /* GraphQL */ `
  query GetLikesByUserIdAndPostId(
    $userId: ID
    $postId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByUserIdAndPostId(
      userId: $userId
      postId: $postId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikesByPostId = /* GraphQL */ `
  query GetLikesByPostId(
    $postId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLikesByPostId(
      postId: $postId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommentByOwner = /* GraphQL */ `
  query GetCommentByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getCommentsByUserId = /* GraphQL */ `
  query GetCommentsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getCommentsByPostId = /* GraphQL */ `
  query GetCommentsByPostId(
    $postId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByPostId(
      postId: $postId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getCommentsByPostIdAndUserId = /* GraphQL */ `
  query GetCommentsByPostIdAndUserId(
    $postId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentsByPostIdAndUserId(
      postId: $postId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getRepliedToCommentByCommentId = /* GraphQL */ `
  query GetRepliedToCommentByCommentId(
    $commentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRepliedToCommentByCommentId(
      commentId: $commentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        repliedToId
        repliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        parrentRepliedToId
        parrentRepliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommentRepliesByRepliedToId = /* GraphQL */ `
  query GetCommentRepliesByRepliedToId(
    $repliedToId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentRepliesByRepliedToId(
      repliedToId: $repliedToId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        repliedToId
        repliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        parrentRepliedToId
        parrentRepliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommentRepliesByParrentRepliedToId = /* GraphQL */ `
  query GetCommentRepliesByParrentRepliedToId(
    $parrentRepliedToId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCommentRepliesByParrentRepliedToId(
      parrentRepliedToId: $parrentRepliedToId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        repliedToId
        repliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        parrentRepliedToId
        parrentRepliedTo {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWillingToHelpByOwner = /* GraphQL */ `
  query GetWillingToHelpByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelWillingToHelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWillingToHelpByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getWillingToHelpByUserId = /* GraphQL */ `
  query GetWillingToHelpByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWillingToHelpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWillingToHelpByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getActivitysBySubjectUser = /* GraphQL */ `
  query GetActivitysBySubjectUser(
    $subjectUserId: ID
    $objectUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivitysBySubjectUser(
      subjectUserId: $subjectUserId
      objectUserId: $objectUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivitysByObjectUser = /* GraphQL */ `
  query GetActivitysByObjectUser(
    $objectUserId: ID
    $subjectUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getActivitysByObjectUser(
      objectUserId: $objectUserId
      subjectUserId: $subjectUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserActivitysBySubjectUserId = /* GraphQL */ `
  query GetUserActivitysBySubjectUserId(
    $subjectUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivitysBySubjectUserId(
      subjectUserId: $subjectUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserActivitysByObjectUserId = /* GraphQL */ `
  query GetUserActivitysByObjectUserId(
    $objectUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivitysByObjectUserId(
      objectUserId: $objectUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserActivitysByLikeId = /* GraphQL */ `
  query GetUserActivitysByLikeId(
    $userActivityLikeId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivitysByLikeId(
      userActivityLikeId: $userActivityLikeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityType
        isRead
        subjectUserId
        objectUserId
        subjectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        objectUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        userActivityLikeId
        like {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        userActivityCommentId
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        userActivityUserFollowerId
        userFollower {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        userActivityUserFriendId
        userFriend {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPointSystemByPointType = /* GraphQL */ `
  query GetPointSystemByPointType(
    $pointType: PointSystemType
    $sortDirection: ModelSortDirection
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPointSystemByPointType(
      pointType: $pointType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        pointType
        order
        point
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPointSystemByOrder = /* GraphQL */ `
  query ListPointSystemByOrder(
    $type: modelTypes
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListPointSystemByOrder(
      type: $type
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        pointType
        order
        point
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomParticipantsByChatRoomId = /* GraphQL */ `
  query GetChatRoomParticipantsByChatRoomId(
    $chatRoomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChatRoomParticipantsByChatRoomId(
      chatRoomId: $chatRoomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pinnedStatus
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoom {
          id
          members
          chatRoomLastMessageId
          createdAt
          updatedAt
        }
        chatRoomParticipantLastReadMessageId
        lastReadMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        numberOfUnReadMessages
        createdAt
        updatedAt
        lastChatRoomMessageCreatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomParticipantsByUserId = /* GraphQL */ `
  query GetChatRoomParticipantsByUserId(
    $userId: ID
    $pinnedStatusCreatedAt: ModelChatRoomParticipantByUserIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChatRoomParticipantsByUserId(
      userId: $userId
      pinnedStatusCreatedAt: $pinnedStatusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pinnedStatus
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoom {
          id
          members
          chatRoomLastMessageId
          createdAt
          updatedAt
        }
        chatRoomParticipantLastReadMessageId
        lastReadMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        numberOfUnReadMessages
        createdAt
        updatedAt
        lastChatRoomMessageCreatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomParticipantsByUserIdOrdered = /* GraphQL */ `
  query GetChatRoomParticipantsByUserIdOrdered(
    $userId: ID
    $pinnedStatusLastChatRoomMessageCreatedAt: ModelChatRoomParticipantByUserIdOrderedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChatRoomParticipantsByUserIdOrdered(
      userId: $userId
      pinnedStatusLastChatRoomMessageCreatedAt: $pinnedStatusLastChatRoomMessageCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pinnedStatus
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoom {
          id
          members
          chatRoomLastMessageId
          createdAt
          updatedAt
        }
        chatRoomParticipantLastReadMessageId
        lastReadMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        numberOfUnReadMessages
        createdAt
        updatedAt
        lastChatRoomMessageCreatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomMessagesByChatRoomId = /* GraphQL */ `
  query GetChatRoomMessagesByChatRoomId(
    $chatRoomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChatRoomMessagesByChatRoomId(
      chatRoomId: $chatRoomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomMessageAssetsByChatRoomMessageId = /* GraphQL */ `
  query GetChatRoomMessageAssetsByChatRoomMessageId(
    $chatRoomMessageId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomMessageAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getChatRoomMessageAssetsByChatRoomMessageId(
      chatRoomMessageId: $chatRoomMessageId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        order
        chatRoomMessageId
        chatRoomMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        s3ObjectId
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchSchools = /* GraphQL */ `
  query SearchSchools(
    $filter: SearchableSchoolFilterInput
    $sort: SearchableSchoolSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSchools(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchSchoolColleges = /* GraphQL */ `
  query SearchSchoolColleges(
    $filter: SearchableSchoolCollegeFilterInput
    $sort: SearchableSchoolCollegeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSchoolColleges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchDomains = /* GraphQL */ `
  query SearchDomains(
    $filter: SearchableDomainFilterInput
    $sort: SearchableDomainSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDomains(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        schoolId
        domainCollegeId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchClazzs = /* GraphQL */ `
  query SearchClazzs(
    $filter: SearchableClazzFilterInput
    $sort: SearchableClazzSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClazzs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchDegrees = /* GraphQL */ `
  query SearchDegrees(
    $filter: SearchableDegreeFilterInput
    $sort: SearchableDegreeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDegrees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchGenders = /* GraphQL */ `
  query SearchGenders(
    $filter: SearchableGenderFilterInput
    $sort: SearchableGenderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchGenders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchSkills = /* GraphQL */ `
  query SearchSkills(
    $filter: SearchableSkillFilterInput
    $sort: SearchableSkillSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSkills(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchJourneyTypes = /* GraphQL */ `
  query SearchJourneyTypes(
    $filter: SearchableJourneyTypeFilterInput
    $sort: SearchableJourneyTypeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchJourneyTypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchFeedbackCategorys = /* GraphQL */ `
  query SearchFeedbackCategorys(
    $filter: SearchableFeedbackCategoryFilterInput
    $sort: SearchableFeedbackCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFeedbackCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchFeedbacks = /* GraphQL */ `
  query SearchFeedbacks(
    $filter: SearchableFeedbackFilterInput
    $sort: SearchableFeedbackSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFeedbacks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        message
        isRead
        feedbackCategoryId
        feedbackCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        feedbackCategoryName
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPostCategorys = /* GraphQL */ `
  query SearchPostCategorys(
    $filter: SearchablePostCategoryFilterInput
    $sort: SearchablePostCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPostCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchReportCategorys = /* GraphQL */ `
  query SearchReportCategorys(
    $filter: SearchableReportCategoryFilterInput
    $sort: SearchableReportCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReportCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchReports = /* GraphQL */ `
  query SearchReports(
    $filter: SearchableReportFilterInput
    $sort: SearchableReportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        message
        isAcknowledged
        reportCategoryId
        reportCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        reportByUserId
        reportByUserName
        reportByUser {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportUserId
        reportUserName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        reportPostId
        reportPostUserName
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        reportCommentId
        reportCommentUserName
        reportCommentContentAtTimeOfReport
        comment {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const searchHashTags = /* GraphQL */ `
  query SearchHashTags(
    $filter: SearchableHashTagFilterInput
    $sort: SearchableHashTagSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchHashTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const searchLikes = /* GraphQL */ `
  query SearchLikes(
    $filter: SearchableLikeFilterInput
    $sort: SearchableLikeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLikes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchComments = /* GraphQL */ `
  query SearchComments(
    $filter: SearchableCommentFilterInput
    $sort: SearchableCommentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchComments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPointSystems = /* GraphQL */ `
  query SearchPointSystems(
    $filter: SearchablePointSystemFilterInput
    $sort: SearchablePointSystemSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPointSystems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        pointType
        order
        point
        createdAt
        deletedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchChatRoomMessages = /* GraphQL */ `
  query SearchChatRoomMessages(
    $filter: SearchableChatRoomMessageFilterInput
    $sort: SearchableChatRoomMessageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchChatRoomMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
      total
    }
  }
`;
export const listReferralCodes = /* GraphQL */ `
  query ListReferralCodes(
    $filter: ModelReferralCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferralCode = /* GraphQL */ `
  query GetReferralCode($id: ID!) {
    getReferralCode(id: $id) {
      id
      code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const getReferralCodeByUserId = /* GraphQL */ `
  query GetReferralCodeByUserId(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralCodeByUserId(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferralCodeBycode = /* GraphQL */ `
  query GetReferralCodeBycode(
    $code: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralCodeBycode(
      code: $code
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReferralCodeBycodeValue = /* GraphQL */ `
  query GetReferralCodeBycodeValue(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelReferralCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralCodeBycodeValue(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemSettings = /* GraphQL */ `
  query GetSystemSettings($id: ID!) {
    getSystemSettings(id: $id) {
      id
      settingType
      content
      createdAt
      updatedAt
    }
  }
`;
export const listSystemSettingss = /* GraphQL */ `
  query ListSystemSettingss(
    $filter: ModelSystemSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        settingType
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemSettingsByType = /* GraphQL */ `
  query GetSystemSettingsByType(
    $settingType: SystemSettingsType
    $sortDirection: ModelSortDirection
    $filter: ModelSystemSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSystemSettingsByType(
      settingType: $settingType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        settingType
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const domainExistanceCheck = /* GraphQL */ `
  query DomainExistanceCheck($domainName: String) {
    domainExistanceCheck(domainName: $domainName) {
      exists
      isError
      errorMessage
    }
  }
`;
