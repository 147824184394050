import React from "react";
import { searchComments } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const Comments = (props) => {
  return (
    <DataGrid
      {...props}
      header="Comments"
      gridObjectDataModel={[
        {
          name: "userName",
          headerText: "Commented By",
          type: DataTypes.String,
        },
        {
          name: "content",
          headerText: "Content",
          type: DataTypes.String,
        },
        {
          name: "isProfane",
          headerText: "Is Profane",
          type: DataTypes.Boolean,
        },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.Comment}
      queryFunction={searchComments}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchComments"}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      hasProfane={true}
      baseRoute={"comments"}
    />
  );
};

export default Comments;
