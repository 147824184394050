import React from "react";
import BatchCreation from "../../../Common/Crud/BatchCreation";
import { listSchools } from "../../../../graphql/queries";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const SchoolBatchCreate = (props) => {
  return (
    <BatchCreation
      listQuery={listSchools}
      listQueryName="listSchools"
      //   listQueryAttributes={}
      listQueryFilters={{
        deletedAt: {
          eq: null,
        },
      }}
      modelName="School"
      schemaModelName={ModelTypes.School}
      //   parentModelName={}
      //   parentModelId={}
      // ancestorModelId={}
      //   parentModelQueryFunction={}
      //   parentModelQueryFunctionName={}
      returnMethod={() => {
        props.history.push(`/schools`);
      }}
    />
  );
};

export default SchoolBatchCreate;
