import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../../../../graphql/queries";
import { updateUser } from "../../../../graphql/mutations";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import UserGeneralInfo from "../GeneralInfo";
import getCurrentUserStarPoints from "../../../Common/PointSystem/handlers/getCurrentUserStarPoints";
import getPointSystemByTypeAsync from "../../../Common/PointSystem/handlers/getPointSystemByTypeAsync";
import updateUserStarPointsByUserId from "../../../Common/PointSystem/handlers/updateUserStarPointsByUserId";

const UserProfane = (props) => {
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userCurrentProfaneState, setUserCurrentProfaneState] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [generalError, setGeneralError] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [
    formSubmitDemeritInProgress,
    setFormSubmitDemeritInProgress,
  ] = useState(false);
  const [demeritPoint, setDemeritPoint] = useState(null);

  useEffect(() => {
    getPointSystemForFlagesContent();
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getUser, { id: props.match.params.userId })
      );
      const {
        id,
        userName,
        isProfane,
        signedUpWithReferralCode,
      } = result.data.getUser;

      // check if we need to fetch the referralCode owner used at signup
      if (signedUpWithReferralCode) {
        const referringUserResult = await API.graphql(
          graphqlOperation(getUser, {
            id: signedUpWithReferralCode.userId,
          })
        );
        // attach the username to the userData model
        result.data.getUser.signedUpWithReferralCode = {
          ...result.data.getUser.signedUpWithReferralCode,
          user: referringUserResult.data.getUser,
        };
      }

      // keep state
      setUserData(result);
      setUserId(id);
      setUserCurrentProfaneState(isProfane);
      setUserName(userName);
    } catch (error) {
      toast.error(`Error while fetching user data ${JSON.stringify(error)}`);
    }
  };

  // get the profane flagging demerit point from the current data at db
  const getPointSystemForFlagesContent = async () => {
    let pointSystemResult = await getPointSystemByTypeAsync(
      "FLAGGED_REMOVED_CONTENT"
    );

    if (pointSystemResult.result) {
      setDemeritPoint(pointSystemResult.point);
    } else {
      toast.error(`${pointSystemResult.message}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (userName && demeritPoint) {
      setIsLoading(false);
    }
  }, [userName, demeritPoint]);

  const handleReturn = () => {
    // check how we got here ( from school or from college )
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Users`
      );
    } else if (props.match.params.schoolId) {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/${props.match.params.tabName}`
      );
    } else {
      props.history.push(
        `/userReports/details/${props.match.params.userReportId}`
      );
    }
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    await markAsProfaneFunction();
  };

  // reusable function to mark a user as profane
  const markAsProfaneFunction = async () => {
    setFormSubmitInProgress(true);

    let opperationResultMessage = "";

    if (!userCurrentProfaneState) {
      // update user data at dynamo db
      let updateProfaneStatusToTrueResult = await updateUserProfaneStatus(
        userId,
        true
      );
      if (!updateProfaneStatusToTrueResult.result) {
        toast.error(updateProfaneStatusToTrueResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = updateProfaneStatusToTrueResult.message;
    } else {
      // update user data at dynamo db
      let updateProfaneStatusToFalseResult = await updateUserProfaneStatus(
        userId,
        false
      );
      if (!updateProfaneStatusToFalseResult.result) {
        toast.success(updateProfaneStatusToFalseResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = updateProfaneStatusToFalseResult.message;
    }

    // go back to list
    setTimeout(function () {
      toast.success(opperationResultMessage);
      handleReturn();
    }, GlobalSubmitUIDelay.DefaultDelay);
  };

  const updateUserProfaneStatus = async (userId, newStatus) => {
    let formSubmissionResult = {
      result: true,
      message: "",
    };
    try {
      let input = {
        id: userId,
        isProfane: newStatus,
      };
      let userUpdateResult = await API.graphql(
        graphqlOperation(updateUser, { input })
      );

      // check result
      if (userUpdateResult) {
        formSubmissionResult.message = `User ${
          newStatus ? "Marked As Profane" : "UnMarked from Profane"
        } successfully`;
      }
    } catch (userUpdateError) {
      formSubmissionResult.result = false;
      formSubmissionResult.message = `Error while updating user ${JSON.stringify(
        userUpdateError,
        null,
        2
      )}`;
    }

    return formSubmissionResult;
  };

  // functionality to apply demerit points to user
  const applyDemeritPoints = async (positiveNeggative) => {
    setFormSubmitDemeritInProgress(true);

    // fetch current start points of the user
    let userCurrentPointsResult = await getCurrentUserStarPoints(userId);

    if (userCurrentPointsResult.result) {
      let updateUserPointsResult = await updateUserStarPointsByUserId(
        userId,
        userCurrentPointsResult.currentPoints + positiveNeggative * demeritPoint
      );

      // check the result
      if (!updateUserPointsResult.result) {
        toast.error(`${updateUserPointsResult.message}`);
        setFormSubmitInProgress(false);
        return;
      } else {
        // mark the post as profane
        // which will have the return handler inside
        await markAsProfaneFunction();
      }
    } else {
      toast.error(`${userCurrentPointsResult.message}`);
      setFormSubmitInProgress(false);
      return;
    }
  };

  return (
    <>
      <PagePreLoad isLoading={isLoading}>
        <Form onSubmit={handleFormSubmission} noValidate>
          <CardContainer
            header={`${
              userCurrentProfaneState
                ? "UnMark user from Profanity flag "
                : "Mark User as Profane"
            } `}
            submitButton={
              <ButtonInput
                label={userCurrentProfaneState ? "UnMark" : "Mark"}
                variant={
                  userCurrentProfaneState
                    ? BootstrapVariants.Success
                    : BootstrapVariants.Danger
                }
                formSubmitInProgress={formSubmitInProgress}
                formSubmittingText={"In Progress..."}
                disabled={formSubmitDemeritInProgress}
              />
            }
            extraButtons={
              !userCurrentProfaneState && (
                <ButtonInput
                  label={"Mark and Apply Demerit Points "}
                  type="button"
                  variant={BootstrapVariants.Danger}
                  formSubmitInProgress={formSubmitDemeritInProgress}
                  formSubmittingText={"In Progress..."}
                  onClick={() => applyDemeritPoints(+1)}
                  disabled={formSubmitInProgress}
                />
              )
            }
            backButton={
              <ButtonInput
                label="Back to List"
                type="button"
                variant={BootstrapVariants.OutlineSecondary}
                onClick={() => handleReturn()}
              />
            }
          >
            {generalError && (
              <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
            )}
            <span>
              By clicking on{" "}
              <b>{userCurrentProfaneState ? "UnMark" : "Mark"}</b>, User will be{" "}
              {userCurrentProfaneState
                ? "unmarked from profane account"
                : "marked as a profane account"}
              , do you want to proceed?
            </span>
          </CardContainer>
        </Form>
        <UserGeneralInfo
          providedModelData={userData}
          userId={props.match.params.userId}
        />
      </PagePreLoad>
    </>
  );
};

export default UserProfane;
