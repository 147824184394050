import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { API, graphqlOperation } from "aws-amplify";
import { getModelAggregationsByType } from "../../../graphql/CustomQueries/ModelAggregations/getModelAggregationsByType";
import { toast } from "react-toastify";
import CardContainer from "../../../components/Common/CardContainer";
import template from "./template";
import PropTypes from "prop-types";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";
import dateTimeConvertProvidedData from "./handlers/dateTimeConvertProvidedData";
import commandClickHandler from "./handlers/commandClickHandler";
import toolbarOptionsGenerator from "./handlers/toolbarOptionsGenerator";
import commandOptionsGenerator from "./handlers/commandOptionsGenerator";
import columnsGenerator from "./handlers/columnsGenerator";
import uriDecodeProvidedData from "./handlers/uriDecodeProvidedData";

const DataGrid = (props) => {
  //#region Destructuring props
  const {
    gridObjectDataModel,
    customTotalCountFunction,
    modelType,
    queryFunction,
    queryFunctionType,
    queryName,
    queryFilters,
    hasCreate,
    hasDetails,
    detailsActiveTab,
    hasEdit,
    hasDelete,
    hasBlock,
    hasProfane,
    baseRoute,
    softDeleteAbleModel,
  } = props;
  //#endregion

  //#region Defining the initial States
  const [__Truli__Grid_SearchTerm, SET__Truli__Grid_SearchTerm] = useState(
    null
  );
  const [
    __Truli__Grid__NextButton__Hidden__Status,
    SET__Truli__Grid__NextButton__Hidden__Status,
  ] = useState(true);
  const [__Truli__Grid__Sort, SET__Truli__Grid__Sort] = useState({
    field: "createdAt",
    direction: "desc",
  });
  const [__Truli__Grid__PageSize, SET__Truli__Grid__PageSize] = useState(10);
  const [__Truli__Grid__NextToken, SET__Truli__Grid__NextToken] = useState(
    null
  );
  const [__Truli__Grid__TotalCount, SET__Truli__Grid__TotalCount] = useState(
    -1
  );
  const [__Truli__Grid__CurrentPage, SET__Truli__Grid__CurrentPage] = useState(
    -1
  );
  const [__Truli__Grid__PageDatas, SET__Truli__Grid__PageDatas] = useState([
    [],
  ]);
  const [
    __Truli__Grid__TotalCount__Backup,
    SET__Truli__Grid__TotalCount__Backup,
  ] = useState(-1);

  // grid data
  const [dataGridData, setDataGridData] = useState([]);
  //#endregion Defining the initial States

  //#region refresh the settings
  const refreshGridSettings = () => {
    SET__Truli__Grid__NextToken(null);
    SET__Truli__Grid__CurrentPage(-1);
    SET__Truli__Grid__PageDatas([[]]);
  };
  //#endregion

  //#region component mount hooks
  useEffect(() => {
    // __Trulli__Refresh__Settings();
    refreshGridSettings();

    return () => {
      // __Trulli__Refresh__Settings();
      refreshGridSettings();
    };
  }, []);
  //#endregion

  //#region Getting total count from aggrigation
  const getTotalCountAsync = async () => {
    // if we have a custom total counsFunction
    if (customTotalCountFunction) {
      return await customTotalCountFunction();
    } else {
      // get the total count of the model ( school )
      const result = await getModelAggregationsByType(modelType);
      if (result.errors) {
        toast.error(`Error Fetching total count`);
      }
      if (result.data && result.data.getModelAggregations) {
        return result.data.getModelAggregations.totalActiveCount;
      }
    }
  };
  //#endregion

  //#region Execute Data Getter method
  const execute = async (state, grid) => {
    return getData(state, grid);
  };
  //#endregion

  //#region Get Data logic
  const getData = async (state, grid) => {
    // new sort setting if defined
    let newSortSetting = null;

    // defining the serahc term if defined
    let newSearchTerm = null;
    let returnFromSearchToNormalGrid = false;

    // defining the new next token
    let newNextToken = __Truli__Grid__NextToken;

    // defining the current page
    let newCurrentPage = __Truli__Grid__CurrentPage;

    // defining the grid page data
    let newGridPageData = { ...__Truli__Grid__PageDatas };

    // check if we already pulled the total count
    let newTotalCount = -1;
    if (__Truli__Grid__TotalCount === -1) {
      newTotalCount = await getTotalCountAsync();

      SET__Truli__Grid__TotalCount__Backup(newTotalCount);
      SET__Truli__Grid__TotalCount(newTotalCount);
    } else {
      newTotalCount = __Truli__Grid__TotalCount;
    }

    // defining if the page size has been changed, in this case we have to re-starts
    if (
      state.action &&
      state.action.requestType &&
      state.action.requestType === "paging" &&
      state.take !== __Truli__Grid__PageSize
    ) {
      // keep required data, and refresh others
      let totalCountTemp = __Truli__Grid__TotalCount;
      let searchTermTemp = __Truli__Grid_SearchTerm;
      // __Trulli__Refresh__Settings();
      refreshGridSettings();
      newNextToken = null;
      newCurrentPage = -1;
      newGridPageData = [[]];
      // __Truli__Grid__TotalCount = totalCountTemp;
      SET__Truli__Grid__TotalCount(totalCountTemp);
      SET__Truli__Grid_SearchTerm(searchTermTemp);

      // assing new page size
      SET__Truli__Grid__PageSize(state.take);
    }

    // defining if the sort options are changed, in this case we have to re-start
    // check the sorting
    if (
      state.action &&
      state.action.requestType &&
      state.action.requestType === "sorting"
    ) {
      // keep required data, and refresh others
      let totalCountTemp = __Truli__Grid__TotalCount;
      let searchTermTemp = __Truli__Grid_SearchTerm;
      // __Trulli__Refresh__Settings();
      refreshGridSettings();
      newNextToken = null;
      newCurrentPage = -1;
      newGridPageData = [[]];
      // __Truli__Grid__TotalCount = totalCountTemp;
      SET__Truli__Grid__TotalCount(totalCountTemp);
      SET__Truli__Grid_SearchTerm(searchTermTemp);

      // assign new soprting options
      newSortSetting = {
        field: state.action.columnName,
        direction: state.action.direction === "Ascending" ? "asc" : "desc",
      };
      SET__Truli__Grid__Sort(newSortSetting);
    }

    // defining if the search is happening, in this case we have to re-start
    if (
      state.action &&
      state.action.requestType &&
      state.action.requestType === "searching"
    ) {
      // __Trulli__Refresh__Settings();
      refreshGridSettings();
      newNextToken = null;
      newCurrentPage = -1;
      newGridPageData = [[]];
      newSearchTerm =
        state.action.searchString.length !== 0
          ? state.action.searchString
          : null;

      if (newSearchTerm !== __Truli__Grid_SearchTerm) {
        // this is a new search, we should start with page 0
        SET__Truli__Grid_SearchTerm(newSearchTerm);

        // override skip to go back to page 0
        state.skip = 0;

        // force to go to page 1
        grid.current.pageSettings.currentPage = 1;
      }

      // if the new provided searchTerm is empty string,
      // that means we are back in normal search
      // we need to bring back the total count
      if (state.action.searchString.length === 0) {
        // __Truli__Grid__TotalCount = __Truli__Grid__TotalCount__Backup;
        SET__Truli__Grid__TotalCount(__Truli__Grid__TotalCount__Backup);
        returnFromSearchToNormalGrid = true;
      }
    }

    if (
      state.action &&
      state.action.requestType &&
      state.action.requestType === "forceRefresh" // custom action
    ) {
      newNextToken = null;
      newCurrentPage = -1;
      newGridPageData = [[]];
      // force to go to page 1
      grid.current.pageSettings.currentPage = 1;
    }

    return await generatePageData(
      state,
      newSortSetting ?? __Truli__Grid__Sort,
      returnFromSearchToNormalGrid
        ? null
        : newSearchTerm ?? __Truli__Grid_SearchTerm,
      newNextToken,
      newTotalCount,
      newCurrentPage,
      newGridPageData,
      grid
    );
  };
  //#endregion

  //#region Defining the status of the next button on pagination
  const changeNextButtonHiddenStatus = (newStatus, grid) => {
    if (
      grid.current.pagerModule.element.childNodes.item(0) &&
      grid.current.pagerModule.element.childNodes.item(0).childNodes.item(0) &&
      grid.current.pagerModule.element.childNodes
        .item(0)
        .childNodes.item(0)
        .childNodes.item(0) &&
      grid.current.pagerModule.element.childNodes
        .item(0)
        .childNodes.item(0)
        .childNodes.item(0)
        .childNodes.item(1)
    ) {
      // apply the status to the element it self
      grid.current.pagerModule.element.childNodes
        .item(0)
        .childNodes.item(0)
        .childNodes.item(0)
        .childNodes.item(1).hidden = newStatus;

      // define trhe new status for rerenders
      // __Truli__Grid__NextButton__Hidden__Status = newStatus;
      SET__Truli__Grid__NextButton__Hidden__Status(newStatus);
      template.prototype = {
        gridRefrence: grid,
        searchTerm: __Truli__Grid_SearchTerm,
        nextButtonHiddenStatus: newStatus,
      };
    }
  };
  //#endregion

  //#region Generate the the specific page data
  const generatePageData = async (
    state,
    sort,
    searchTerm,
    nextToken,
    newTotalCount,
    newCurrentPage,
    newGridPageData,
    grid
  ) => {
    // since user can go directly to each page, we need to see how many times we have to fetch pages to provide the requested page
    let requestedPage = state.skip / state.take;
    let numberOfRequiredRequests = 1;
    if (requestedPage > newCurrentPage) {
      numberOfRequiredRequests = requestedPage - newCurrentPage;
    }

    // check if we have page data already
    // if we do not have it we will call the graphql in order to get it
    if (
      !newGridPageData[requestedPage] ||
      newGridPageData[requestedPage].length === 0
    ) {
      let params = {
        sort: sort,
        limit: state.take,
        from: state.skip, // non-unique sort + search fix
      };

      // Applying deleted at filter based on the query function type
      if (queryFunctionType === QueryTypes.Search) {
        // managing soft delete able model
        let customFilters = {
          ...queryFilters,
        };
        if (softDeleteAbleModel) {
          customFilters = {
            ...customFilters,
            deletedAt: { exists: false },
          };
        }

        // defining the search parameters
        params = {
          ...params,
          filter: {
            ...customFilters,
          },
        };
      } else {
        params = {
          ...params,
          // filter: {
          //   ...queryFilters,
          // },
        };
      }

      if (searchTerm) {
        let searchOrArray = [];

        // some special characters will have specific meanings at the type of default analysis on ElasticSearch created by amplify
        // @ + space .
        let searchTermSplit = searchTerm.split(/[\s,+@.]+/);

        for (let objectModelItem of gridObjectDataModel) {
          if (objectModelItem.type === DataTypes.String) {
            let searchOrItem = {};
            if (searchTermSplit.length > 1) {
              searchOrItem.and = [];
            }
            for (let index = 0; index < searchTermSplit.length; ++index) {
              if (index == 0) {
                searchOrItem[objectModelItem.name] = {
                  regexp: `.*${searchTermSplit[index].toLowerCase()}.*`,
                };
              } else {
                searchOrItem.and.push({
                  [objectModelItem.name]: {
                    regexp: `.*${searchTermSplit[index].toLowerCase()}.*`,
                  },
                });
              }
            }
            searchOrArray.push(searchOrItem);
            // for (let splitItem of searchTermSplit) {
            //   // we need to generate the search wuery based on the provided model
            //   let searchOrItem = {
            //     [objectModelItem.name]: {
            //       regexp: `.*${splitItem.toLowerCase()}.*`,
            //     },
            //   };
            //   searchOrArray.push(searchOrItem);
            // }
          }
        }

        let searchParams = {
          and: [
            {
              // or: [
              //   {
              //     name: { regexp: `.*${searchTerm}.*` },
              //   },
              // ],
              or: searchOrArray,
            },
          ],
        };

        // managing soft delete able model
        let customFilters = {
          ...queryFilters,
          ...searchParams,
        };
        if (softDeleteAbleModel) {
          customFilters = {
            ...customFilters,
            deletedAt: { exists: false },
          };
        }

        // defining the search parameters

        params = {
          ...params,
          filter: {
            ...customFilters,
          },
        };
      }

      // for (let index = 0; index < numberOfRequiredRequests; ++index) {
      //   // the row data that we are dealing with at this moment
      //   let operationRow = newCurrentPage + index + 1;

      //   if (state.skip !== 0) {
      //     // that means we need to get not the initial page
      //     // there fore we should have the next token

      //     //
      //     // // non-unique sort + search fix
      //     //
      //     // params = { ...params, nextToken: nextToken };
      //   }

      //   // check if we passed the required page in the past
      //   if (
      //     !newGridPageData[operationRow] ||
      //     newGridPageData[operationRow].length === 0
      //   ) {
      //     const result = await API.graphql(
      //       graphqlOperation(queryFunction, params)
      //     );
      //     // get the next token
      //     nextToken = result.data[queryName].nextToken;
      //     SET__Truli__Grid__NextToken(nextToken);

      //     let dateTimeConvertedResult = dateTimeConvertProvidedData(
      //       result.data[queryName].items,
      //       gridObjectDataModel
      //     );

      //     let uriConvertedResult = uriDecodeProvidedData(
      //       dateTimeConvertedResult,
      //       gridObjectDataModel
      //     );
      //     // let dateTimeConvertedResult = result.data[queryName].items.map(
      //     //   (resultItem) => {
      //     //     // define what entries are dateTime type twhich need to be converted
      //     //     let objectModelEntries = gridObjectDataModel.filter(
      //     //       (item) => item.type === DataTypes.DateTime
      //     //     );

      //     //     // converting string values to DateTime in order to have formatting and local convert enabled
      //     //     for (let dateTimeTypeItem of objectModelEntries) {
      //     //       resultItem[dateTimeTypeItem.name] = resultItem[
      //     //         dateTimeTypeItem.name
      //     //       ]
      //     //         ? new Date(resultItem[dateTimeTypeItem.name])
      //     //         : resultItem[dateTimeTypeItem.name];
      //     //     }
      //     //     return resultItem;
      //     //   }
      //     // );

      //     newGridPageData[operationRow] = uriConvertedResult;

      //     SET__Truli__Grid__PageDatas(newGridPageData);
      //   }
      // }

      //
      //
      // non-unique search sort fix
      //
      //
      let operationRow = requestedPage;
      const result = await API.graphql(graphqlOperation(queryFunction, params));
      // get the next token
      nextToken = result.data[queryName].nextToken;
      SET__Truli__Grid__NextToken(nextToken);

      let dateTimeConvertedResult = dateTimeConvertProvidedData(
        result.data[queryName].items,
        gridObjectDataModel
      );

      let uriConvertedResult = uriDecodeProvidedData(
        dateTimeConvertedResult,
        gridObjectDataModel
      );

      newGridPageData[operationRow] = uriConvertedResult;

      SET__Truli__Grid__PageDatas(newGridPageData);
      //
      //
      //
      //
      //
      //

      //
      // non-unique search fix
      //
      // check if we have found any result to show
      if (newGridPageData[0] === null || newGridPageData[0].length === 0) {
        newTotalCount = 0;
      }
    }

    // update page indicatores
    SET__Truli__Grid__CurrentPage(state.skip / state.take);

    if (searchTerm) {
      // define the next button disabled status
      // it won't be disabled when the length of data at the portion we are looking at is equal to page size
      // check if we have reached to the end of results e.g 10 items, page size 5, exactly 2 pages
      if (
        newGridPageData[requestedPage].length !== __Truli__Grid__PageSize ||
        (requestedPage + 1) * __Truli__Grid__PageSize ===
          __Truli__Grid__TotalCount
      ) {
        // trying to reach out to the next button by tracing the nodelists
        changeNextButtonHiddenStatus(true, grid);
      } else {
        changeNextButtonHiddenStatus(false, grid);
      }
    }

    // return page result
    return {
      count: newTotalCount !== -1 ? newTotalCount : 0, // when searching since we do not have the total count anymore and we have refreshed the setting values, we pass zero instead
      result: newGridPageData[requestedPage],
    };
  };
  //#endregion

  //#region refrence to the grids ( being used by both grid components )
  const grid = useRef();
  //#endregion

  //#region tool bar options

  // toolbaroptions
  const toolBarOptions = toolbarOptionsGenerator(hasCreate, true);

  //#endregion

  //#region command column setting
  const commands = commandOptionsGenerator(
    hasDetails,
    hasEdit,
    hasBlock,
    hasProfane,
    hasDelete
  );
  //#endregion

  //#region Render complete lifecycle method
  const renderComplete = () => {
    if (
      grid &&
      grid.current.dataSource &&
      !grid.current.dataSource.length &&
      grid.current.dataSource.result === undefined
    ) {
      const state = { skip: 0, take: __Truli__Grid__PageSize };
      dataStateChange(state);
    }
  };
  //#endregion

  //#region Data State Change life cycle method
  const dataStateChange = (state) => {
    execute(state, grid).then((gridData) => {
      if (grid) {
        // grid.current.dataSource = gridData;
        setDataGridData(gridData);
      }
    });
  };
  //#endregion

  //#region Tool bar actions
  const toolbarClickHandler = (args) => {
    if (grid) {
      switch (args.item.id) {
        case "create":
          props.history.push(`/${baseRoute}/create`);
          break;
        case "refresh":
          const state = {
            skip: 0,
            take: __Truli__Grid__PageSize,
            action: { requestType: "forceRefresh" },
          };
          dataStateChange(state);
          break;
        default:
          break;
      }
    }
  };
  //#endregion

  //#region command clicks handlers
  const gridCommandClickHandler = (args) => {
    commandClickHandler(args, props, grid, baseRoute, detailsActiveTab);
  };
  //#endregion

  //#region default grid page settings
  let pageSettings = {
    pageSize: __Truli__Grid__PageSize,
    pageSizes: true,
  };
  //#endregion

  //#region assingning data to template prototype while we are searching
  template.prototype = {
    gridRefrence: grid,
    searchTerm: __Truli__Grid_SearchTerm,
    nextButtonHiddenStatus: __Truli__Grid__NextButton__Hidden__Status,
  };
  //#endregion

  //#region page settings for the search based grid component
  let searchPageSettings = {
    pageSize: __Truli__Grid__PageSize,
    pageSizes: true,
    template: template,
  };
  //#endregion

  //#region defininf the columns
  const definedColumns = columnsGenerator(gridObjectDataModel);
  //#endregion

  return (
    <>
      <CardContainer header={props.header}>
        {__Truli__Grid_SearchTerm ? (
          <>
            <GridComponent
              dataSource={dataGridData}
              allowPaging={true}
              pageSettings={searchPageSettings}
              allowSorting={true}
              // sortSettings={sortSettings}
              dataBound={renderComplete}
              dataStateChange={dataStateChange}
              ref={grid}
              toolbar={toolBarOptions}
              toolbarClick={toolbarClickHandler}
              commandClick={gridCommandClickHandler}
            >
              <ColumnsDirective>
                {definedColumns}

                <ColumnDirective
                  headerText="Commands"
                  width="120"
                  commands={commands}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Group, Toolbar, CommandColumn]}
              />
            </GridComponent>
          </>
        ) : (
          <GridComponent
            dataSource={dataGridData}
            allowPaging={true}
            pageSettings={pageSettings}
            allowSorting={true}
            // sortSettings={sortSettings}
            dataBound={renderComplete}
            dataStateChange={dataStateChange}
            ref={grid}
            toolbar={toolBarOptions}
            toolbarClick={toolbarClickHandler}
            commandClick={gridCommandClickHandler}
          >
            <ColumnsDirective>
              {definedColumns}

              <ColumnDirective
                headerText="Commands"
                width="120"
                commands={commands}
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Sort, Filter, Group, Toolbar, CommandColumn]}
            />
          </GridComponent>
        )}
      </CardContainer>
    </>
  );
};

// proptypes definition
DataGrid.propTypes = {
  header: PropTypes.string.isRequired,
  gridObjectDataModel: PropTypes.array.isRequired,
  customTotalCountFunction: PropTypes.func,
  modelType: PropTypes.string,
  queryFunction: PropTypes.string.isRequired,
  queryFunctionType: PropTypes.string.isRequired,
  queryName: PropTypes.string.isRequired,
  queryFilters: PropTypes.object,
  hasCreate: PropTypes.bool.isRequired,
  hasDetails: PropTypes.bool.isRequired,
  detailsActiveTab: PropTypes.string,
  hasEdit: PropTypes.bool.isRequired,
  hasDelete: PropTypes.bool.isRequired,
  hasBlock: PropTypes.bool.isRequired,
  hasProfane: PropTypes.bool.isRequired,
  baseRoute: PropTypes.string.isRequired,
  softDeleteAbleModel: PropTypes.bool.isRequired,
};

// default props if not provided
DataGrid.defaultProps = {
  header: "Header",
  customTotalCountFunction: null,
  queryFilters: null,
  hasCreate: false,
  hasDetails: false,
  detailsActiveTab: null,
  hasEdit: false,
  hasDelete: false,
  hasBlock: false,
  hasProfane: false,
  softDeleteAbleModel: true,
};

export default DataGrid;
