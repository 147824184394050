import { API, graphqlOperation } from "aws-amplify";
import { createModelAggregations } from "../../mutations";

export const createModelAggregationsByType = async (type, initialCount) => {
  const input = {
    modelType: type,
    totalActiveCount: initialCount
  };
  try {
    return await API.graphql(graphqlOperation(createModelAggregations, { input }));
  } catch (error) {
    return error;
  }
};
