import React from "react";
import { getComment } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const CommentGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.commentId}
      modelName={"Comment"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "content",
          attributeTitle: "Content",
          description: "Comment Content",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "userName",
          attributeTitle: "User Name",
          description: "Comment Owner UserName",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "isProfane",
          attributeTitle: "Is Profane",
          description: "Is Comment Content Profane?",
          dataType: DataTypes.Boolean,
        },
        {
          key: 4,
          attributeName: "isReply",
          attributeTitle: "Is a Reply",
          description: "Is Comment a Reply to Another Comment?",
          dataType: DataTypes.Boolean,
        },
        {
          key: 5,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Comment Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 6,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Comment Updated At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 7,
          attributeName: "deletedAt",
          attributeTitle: "Deleted At",
          description: "Comment Deleted At",
          dataType: DataTypes.DateTime,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getComment}
      queryFunctionName={"getComment"}
    />
  );
};

CommentGeneralInfo.propTypes = {
  commentId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

CommentGeneralInfo.defaultProps = {
  providedModelData: null,
};

export default CommentGeneralInfo;
