import React from "react";
import { searchFeedbacks } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const Feedbacks = (props) => {
    return (
        <DataGrid
          {...props}
          header="Feedback"
          gridObjectDataModel={[
            { name: `userName`, headerText: "Submitted By", type: DataTypes.String },
            { name: `feedbackCategoryName`, headerText: "Category", type: DataTypes.String },
            { name: "isRead", headerText: "isRead", type: DataTypes.Boolean },
            {
              name: "createdAt",
              headerText: "Created At",
              type: DataTypes.DateTime,
            },
          ]}
          modelType={ModelTypes.Feedback}
          queryFunction={searchFeedbacks}
          queryFunctionType={QueryTypes.Search}
          queryName={"searchFeedbacks"}
          hasCreate={false}
          hasDetails={true}
          hasEdit={false}
          hasDelete={true}
          baseRoute={"feedbacks"}
        />
      );
};

export default Feedbacks;
