import React from "react";
import PropTypes from "prop-types";
import { FaCheck, FaTimes } from "react-icons/fa";

const IsChecked = (props) => {
  const checkedClass = { color: "green" };
  const notCheckedClass = { color: "red" };
  return props.status ? (
    <FaCheck style={checkedClass} />
  ) : (
    <FaTimes style={notCheckedClass} />
  );
};

IsChecked.propTypes = {
  status: PropTypes.bool.isRequired,
};

IsChecked.defaultProps = {
    status: false,
};

export default IsChecked;
