import React from "react";
import { getDomain } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const DomainGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.domainId}
      modelName={"Domain"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "Domain Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Domain Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 3,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Domain Updated At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeTitle: "School Name",
          description: "School which domain belongs to",
          dataType: DataTypes.String,
          attributeFetchFunction: (data) => {
            return data.school.name;
          },
        },
        {
          key: 5,
          attributeTitle: "College Name",
          description: "College which domain belongs to",
          dataType: DataTypes.String,
          attributeFetchFunction: (data) => {
            if (data.college) {
              return data.college.name;
            } else {
              return "---";
            }
          },
        },
      ]}
      queryFunction={getDomain}
      queryFunctionName={"getDomain"}
    />
  );
};

DomainGeneralInfo.propTypes = {
  domainId: PropTypes.string.isRequired,
};

export default DomainGeneralInfo;
