import React from "react";
import { searchJourneyTypes } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const JourneyTypes = (props) => {
  return (
    <DataGrid
      {...props}
      header="Journey Types"
      gridObjectDataModel={[
        { name: "name", headerText: "Name", type: DataTypes.String },
        { name: "order", headerText: "Order", type: DataTypes.Number },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.JourneyType}
      queryFunction={searchJourneyTypes}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchJourneyTypes"}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={false}
      hasProfane={false}
      baseRoute={"journeyTypes"}
    />
  );
};

export default JourneyTypes;
