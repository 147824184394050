const { API, graphqlOperation } = require("aws-amplify");
const { getUser } = require("../../../../graphql/queries");

const getCurrentUserStarPoints = async (userId) => {
  let fetchResult = { result: false, message: "", currentPoints: 0 };
  try {
    // get points from user
    const result = await API.graphql(graphqlOperation(getUser, { id: userId }));

    const { numberOfStarPoints } = result.data.getUser;

    // apply the result
    fetchResult.result = true;
    fetchResult.currentPoints = numberOfStarPoints;
  } catch (error) {
    fetchResult.message = `Error while fetching user data ${JSON.stringify(
      error
    )}`;
  }

  return fetchResult;
};

export default getCurrentUserStarPoints;
