import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

const SideBarAccordionToggle = (props) => {
  return (
    <li className="sidebar-item">
      <Row>
        <Col xs={2}>{props.icon}</Col>
        <Col xs={10} style={{ paddingTop: "2px" }}>
          <span className="e-text" title="home">
            {props.label}
          </span>
        </Col>
      </Row>
    </li>
  );
};

SideBarAccordionToggle.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SideBarAccordionToggle.defaultProps = {
  style: {},
};

export default SideBarAccordionToggle;
