import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { searchComments } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";
import DataGrid from "../../../Common/DataGrid";
import * as QueryTypes from "../../../../graphql/Constants/QueryTypes";

const UserPostComments = (props) => {
  const { postId, postCommentsTotalCount } = props;

  useEffect(() => {}, []);

  const getPostCommentsTotalCount = async () => {
    return postCommentsTotalCount;
  };

  return (
    <DataGrid
      header="Comments"
      {...props}
      gridObjectDataModel={[
        {
          name: "userName",
          headerText: "Commented By UserName",
          type: DataTypes.String,
        },
        {
          name: "content",
          headerText: "Content",
          type: DataTypes.String,
        },
        {
          name: "isProfane",
          headerText: "is Profane",
          type: DataTypes.Boolean,
        },
        {
          name: "createdAt",
          headerText: "Commented At",
          type: DataTypes.DateTime,
        },
      ]}
      customTotalCountFunction={getPostCommentsTotalCount}
      queryFunction={searchComments}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchComments"}
      queryFilters={{
        postId: {
          eq: props.match.params.userPostId,
        },
      }}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      hasBlock={false}
      hasProfane={true}
      baseRoute={`userPosts/${props.match.params.userPostId}/comments`}
      detailsActiveTab={"Comments"}
    />
  );
};

UserPostComments.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default UserPostComments;
