import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { API, graphqlOperation } from "aws-amplify";
import { getWillingToHelpByUserId } from "../../../../graphql/queries";
import { toast } from "react-toastify";

const UserWillingToHelps = (props) => {
  const { userId } = props;
  const [userWillingToHelpList, setUserWillingToHelpList] = useState(null);

  const getUserWillingToHelpsList = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getWillingToHelpByUserId, { userId: userId })
      );

      // check the result
      if (result) {
        setUserWillingToHelpList(result.data.getWillingToHelpByUserId);
      } else {
      }
    } catch (error) {
      toast.error("Error while fetching user Willing TO Help List");
    }
  };

  useEffect(() => {
    getUserWillingToHelpsList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 8,
      attributeName: "content",
      attributeTitle: "Content",
      description: "Content",
      dataType: DataTypes.String,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "User Skill Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "User Skill Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of skills based of the list of properties we have defined
  return userWillingToHelpList &&
    userWillingToHelpList.items &&
    userWillingToHelpList.items.length !== 0 ? (
    userWillingToHelpList.items.map((willingTOHelpItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(willingTOHelpItem)
                  : willingTOHelpItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Willing To Help Id : ${willingTOHelpItem.id}`}
      />
    ))
  ) : (
    <>
      <span> User is not being helpful </span>
    </>
  );
};

UserWillingToHelps.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserWillingToHelps;
