import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import _ from "lodash";
import JSONViewer from "react-json-viewer";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import { API, graphqlOperation, Storage } from "aws-amplify";
import IsChecked from "../../../../components/Common/IsChecked";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { toast } from "react-toastify";
import { ProgressBar, Table } from "react-bootstrap";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";
import {
  createSchool,
  deleteSchool,
  createDomain,
  deleteDomain,
  createClazz,
  deleteClazz,
  createSchoolCollege,
  deleteSchoolCollege,
} from "../../../../graphql/mutations";
import CollegeNONE from "../../../../graphql/Constants/Colleges/None";
import DomainValidation from "../../../Pages/Domains/DomainValidation";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const BatchCreation = (props) => {
  const {
    schemaModelName,
    modelName,
    parentModelName,
    parentModelId,
    parentModelQueryFunction,
    parentModelQueryFunctionName,
    listQuery,
    listQueryName,
    listQueryFilters,
    listQueryAttributes,
    returnMethod,
    ancestorModelId,
  } = props;

  // S3 storage location
  const s3BatchCreationFileName = `initialdata/${schemaModelName}_${parentModelName}_${parentModelId}_BATCH_UPLOAD_LIST.xlsx`;

  //#region states
  const [
    parentModelPresentationalName,
    setParentModelPresentationalName,
  ] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // upload input disable state
  const [uploadInputDisable, setUploadInputDisable] = useState(false);
  const inputFileRef = useRef(null);

  // state indicating the process had been started
  const [procedureStarted, setProcedureStarted] = useState(false);

  // state indicating and showing the over all errors
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // states required to render the Structure check process
  const [structureCheckProgressShow, setStructureCheckProgressShow] = useState(
    false
  );
  const [
    structureCheckProgressState,
    setStructureCheckProgressState,
  ] = useState(0);
  const [
    structureCheckProgressVariant,
    setStructureCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [structureCheckIsCheck, setStructureCheckIsCheck] = useState(null);

  // states required to render the Logical check process
  const [logicalCheckProgressShow, setLogicalCheckProgressShow] = useState(
    false
  );
  const [logicalCheckProgressState, setLogicalCheckProgressState] = useState(0);
  const [
    logicalCheckProgressVariant,
    setLogicalCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [logicalCheckIsCheck, setLogicalCheckIsCheck] = useState(null);

  // states required to check database existence
  const [
    databaseExistsCheckProgressShow,
    setDatabaseExistsCheckProgressShow,
  ] = useState(false);
  const [
    databaseExistsCheckProgressState,
    setDatabaseExistsCheckProgressState,
  ] = useState(0);
  const [
    databaseExistsCheckProgressVariant,
    setDatabaseExistsCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [databaseExistsCheckIsCheck, setDatabaseExistsCheckIsCheck] = useState(
    null
  );

  // states required to render the Upload to S3 process
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState(0);
  const [uploadProgressStateVariant, setUploadProgressStateVariant] = useState(
    BootstrapVariants.Info
  );
  const [uploadProgressIsCheck, setUploadProgressIsCheck] = useState(null);

  // states required to create data at database
  const [
    databaseCreationCheckProgressShow,
    setDatabaseCreationCheckProgressShow,
  ] = useState(false);
  const [
    databaseCreationCheckProgressState,
    setDatabaseCreationCheckProgressState,
  ] = useState(0);
  const [
    databaseCreationCheckProgressVariant,
    setDatabaseCreationCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [
    databaseCreationCheckIsCheck,
    setDatabaseCreationCheckIsCheck,
  ] = useState(null);

  // showing the json result
  const [jsonResult, setJsonResult] = useState(null);

  //Build States
  const [isBuildCompleted, setIsBuildCompleted] = useState(false);

  // defining the default values
  let numberOfRequiredStructuralActions = 0;
  let numberOfAccomplishedStructuralActions = 0;
  let numberOfRequiredLogicalActions = 0;
  let numberOfAccomplishedLogicalActions = 0;
  let numberOfRequiredDatabaseExistsActions = 0;
  let numberOfAccomplishedDatabaseExistsActions = 0;
  let numberOfRequiredDatabaseCreationActions = 0;
  let numberOfAccomplishedDatabaseCreationActions = 0;
  let overallStatus = true;

  // defining the column Names for the excel file schema
  const EXCEL_COLUMN_NAME = "A";
  const EXCEL_COLUMN_NAME_ID = 0;

  //#endregion

  //#region preload effect
  useEffect(() => {
    if (parentModelId) {
      getParentModelData(parentModelId);
    } else {
      setIsLoading(false);
    }
  }, []);
  //#endregion

  //#region Get Parent Model Data
  const getParentModelData = async (modelId) => {
    try {
      const result = await API.graphql(
        graphqlOperation(parentModelQueryFunction, { id: parentModelId })
      );
      const { name } = result.data[parentModelQueryFunctionName];

      setParentModelPresentationalName(name);

      setIsLoading(false);
    } catch (error) {
      toast.error(
        `Error while fetching ${modelName} data ${JSON.stringify(error)}`
      );
    }
  };
  //#endregion

  //#region progress
  // define the number of actions required for structural checks
  const defineStructuralRequiredActions = (excelParsedData) => {
    // for number of the rows provided
    numberOfRequiredStructuralActions += excelParsedData.length;
  };
  // increase the structure progress completion
  const increaseStructureProgress = () => {
    ++numberOfAccomplishedStructuralActions;
    setStructureCheckProgressState(
      (
        (numberOfAccomplishedStructuralActions /
          numberOfRequiredStructuralActions) *
        100
      ).toFixed(2)
    );
  };

  // define the number of actions required for logical checks
  const defineLogicalRequiredActions = (structuredObjectArray) => {
    //checking the duplications at the provided excel converted excel file
    numberOfRequiredLogicalActions += structuredObjectArray.length;
  };

  // increase the Logical progress completion
  const increaseLogicalProgress = (numberToIncrease) => {
    numberOfAccomplishedLogicalActions += numberToIncrease;
    setLogicalCheckProgressState(
      (
        (numberOfAccomplishedLogicalActions / numberOfRequiredLogicalActions) *
        100
      ).toFixed(2)
    );
  };

  // define the number of actions required for DatabaseExists checks
  const defineDatabaseExistsRequiredActions = (structuredObjectArray) => {
    numberOfRequiredDatabaseExistsActions += structuredObjectArray.length;
  };

  // increase the DatabaseExists progress completion
  const increaseDatabaseExistsProgress = (numberToIncrease) => {
    numberOfAccomplishedDatabaseExistsActions += numberToIncrease;
    setDatabaseExistsCheckProgressState(
      (
        (numberOfAccomplishedDatabaseExistsActions /
          numberOfRequiredDatabaseExistsActions) *
        100
      ).toFixed(2)
    );
  };
  //#endregion

  //#region restart the process
  const handleRestart = () => {
    setUploadInputDisable(false);
    setProcedureStarted(false);

    // empty input file
    inputFileRef.current.value = "";

    // clean the json result
    setJsonResult(null);

    // clean the errors
    setIsError(false);
    setErrorMessage("");

    // start the process
    // clean up
    setStructureCheckProgressShow(false);
    setStructureCheckProgressState(0);
    setStructureCheckProgressVariant("info");
    setStructureCheckIsCheck(null);

    // clean up
    setLogicalCheckProgressShow(false);
    setLogicalCheckProgressState(0);
    setLogicalCheckProgressVariant("info");
    setLogicalCheckIsCheck(null);

    // clean up
    setDatabaseExistsCheckProgressShow(false);
    setDatabaseExistsCheckProgressState(0);
    setDatabaseExistsCheckProgressVariant("info");
    setDatabaseExistsCheckIsCheck(null);

    // upload
    setUploadProgressShow(false);
    setUploadProgressState(0);
    setUploadProgressStateVariant("info");
    setUploadProgressIsCheck(null);

    // clean up
    setDatabaseCreationCheckProgressShow(false);
    setDatabaseCreationCheckProgressState(0);
    setDatabaseCreationCheckProgressVariant("info");
    setDatabaseCreationCheckIsCheck(null);

    //
    setIsBuildCompleted(false);
  };
  //#endregion

  //#region  event to start the process after providing the file on file input
  const onChange = async (e) => {
    // disable the input file
    setUploadInputDisable(true);

    // start
    setProcedureStarted(true);
    const file = e.target.files[0];

    //batch creation following the rules
    await batchCreationProcedure(file);
  };
  //#endregion

  //#region batch creation procedure
  // rules at batch creation
  // the excel template will be one sheet and one column
  // 1.we need to check the duplications at provided list
  // 2.we need to check the duplications with existing database
  // 3.create one by one, revert if something goes wrong
  const batchCreationProcedure = async (providedFile) => {
    // sanitizing the data and checking the structure
    setStructureCheckProgressShow(true);
    setLogicalCheckProgressShow(true);
    setDatabaseExistsCheckProgressShow(true);
    setDatabaseCreationCheckProgressShow(true);

    let overallErrorMessage = "";

    try {
      var reader = new FileReader();
      reader.onload = async (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

        // defined the amount of actions we need to perform to validate the structure and generate the objects and etc ...
        defineStructuralRequiredActions(dataParse);

        let structuredObjectArray = [];
        try {
          convertExcelToStructuredObject(dataParse, structuredObjectArray);

          // if everything on structural check was ok so far
          setStructureCheckIsCheck(true);
          setStructureCheckProgressVariant("success");

          setJsonResult(structuredObjectArray);
        } catch (error) {
          overallStatus = false;
          setStructureCheckIsCheck(false);
          setStructureCheckProgressVariant("danger");
          setIsError(true);
          setErrorMessage(error ? error.toString() : "Unknown Error");
        }

        if (overallStatus) {
          // define the amount of actions needed for logical checks
          defineLogicalRequiredActions(structuredObjectArray);

          // logical check
          try {
            checkForDuplicationsAtConvertedData(structuredObjectArray);

            // if everything on structural check was ok so far
            setLogicalCheckIsCheck(true);
            setLogicalCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setLogicalCheckIsCheck(false);
            setLogicalCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(error ? error.toString() : "Unknown Error");
          }
        }

        if (overallStatus) {
          // define required actions
          defineDatabaseExistsRequiredActions(structuredObjectArray);

          // Database check for duplications
          try {
            await checkDatabaseDuplicates(structuredObjectArray);

            // if everything on structural check was ok so far
            setDatabaseExistsCheckIsCheck(true);
            setDatabaseExistsCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setDatabaseExistsCheckIsCheck(false);
            setDatabaseExistsCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(
              error ? error.toString() : `Error at database check`
            );
          }
        }

        // upload no matter what to have it as debugging reference if needed
        // upload to s3 for reference
        uploadExcelToS3(providedFile);

        // check all the overall status and at this point we need to start creating items at the database
        // creation process
        if (overallStatus) {
          // create at the database
          let generatedObjects = [];
          try {
            defineDatabaseCreationRequiredActions(structuredObjectArray);

            await generateStructuredObjectAtDatabase(
              structuredObjectArray,
              generatedObjects
            );

            setIsBuildCompleted(true);

            // if everything on structural check was ok so far
            setDatabaseCreationCheckIsCheck(true);
            setDatabaseCreationCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setDatabaseCreationCheckIsCheck(false);
            setDatabaseCreationCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(
              error
                ? error.toString()
                : `Error at generating items at the database`
            );

            // revert ( delete ) the generated items
            await revertGeneratedObjectOnCreationFailure(generatedObjects);
          }
        }
      };
      reader.readAsBinaryString(providedFile);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error);
    }
  };
  //#endregion

  // reads through the json object and coverts it ro an object based on our models
  const convertExcelToStructuredObject = (dataParse, structuredObjectArray) => {
    for (let index = 0; index < dataParse.length; ++index) {
      if (dataParse[index][EXCEL_COLUMN_NAME_ID]) {
        let newItem = {
          name: dataParse[index][EXCEL_COLUMN_NAME_ID].trim(),
        };
        if (schemaModelName === ModelTypes.Clazz) {
          // if the model type is class we need to add order as well
          newItem = {
            ...newItem,
            order: index,
          };
        }
        structuredObjectArray.push(newItem);
      }

      increaseStructureProgress();
    }
  };

  // checks if the converted data doesn't have duplicated values in it
  const checkForDuplicationsAtConvertedData = (structuredObjectArray) => {
    // convert to array and toLowerCase
    let tempCheckArray = [];
    for (let index = 0; index < structuredObjectArray.length; ++index) {
      tempCheckArray.push(structuredObjectArray[index].name.toLowerCase());
    }
    increaseLogicalProgress(structuredObjectArray.length);
    // let tempCheckArray = structuredObjectArray.map((item) => {
    //   increaseLogicalProgress();
    //   return item.name.toLowerCase();
    // });

    // check the domain validation
    if (schemaModelName === ModelTypes.Domain) {
      let errorMessage = "";
      for (
        let domainIndex = 0;
        domainIndex < tempCheckArray.length;
        ++domainIndex
      ) {
        let validationResult = DomainValidation(tempCheckArray[domainIndex]);
        if (!validationResult.result) {
          errorMessage += `Domain validation error at ${tempCheckArray[domainIndex]} at row ${domainIndex}`;
        }
      }

      if (errorMessage !== "") {
        throw errorMessage;
      }
    }

    // check the duplications
    let duplicatesResults = findDuplicates(tempCheckArray);

    if (duplicatesResults && duplicatesResults.length > 0) {
      // create the error message
      let errorMessage = "";
      for (
        let valueItemIndex = 0;
        valueItemIndex < duplicatesResults.length;
        ++valueItemIndex
      ) {
        errorMessage += `We have a duplicated value: ${duplicatesResults[valueItemIndex]}`;
        if (valueItemIndex !== duplicatesResults.length - 1) {
          errorMessage += " --- ";
        }
      }
      throw errorMessage;
    }
  };

  const convertStructuredObjectToArray = (structuredObjectArray) => {
    return structuredObjectArray.map((item) => {
      //   increaseLogicalProgress();
      return item.name.toLowerCase();
    });
  };

  // logic to find duplicates in an array
  const findDuplicates = (providedArray) => {
    let grouped = _.groupBy(providedArray, function (n) {
      return n;
    });
    let result = _.uniq(
      _.flatten(
        _.filter(grouped, function (n) {
          return n.length > 1;
        })
      )
    );
    return result;
  };

  // finding the duplicates at db
  const checkDatabaseDuplicates = async (structuredObjectArray) => {
    // convert to array and toLowerCase
    let listItemToCheck = convertStructuredObjectToArray(structuredObjectArray);
    let modelListFetchResult = [];
    try {
      let result = await GetFullListResult(listQuery, listQueryName, {
        ...listQueryAttributes,
        filter: {
          ...listQueryFilters,
        },
        // limit: 100,
      });

      // check the result
      if (result.success) {
        modelListFetchResult = result.resultList;
      } else {
        let errorMessage = `Error while fetching data from db at existence check, ${result.errorMessage}`;
        throw errorMessage;
      }
    } catch (error) {
      throw "Error while fetching data from db at existence check";
    }

    // let modelListFetchResult = await API.graphql(
    //   graphqlOperation(listQuery, {
    //     ...listQueryAttributes,
    //     filter: {
    //       ...listQueryFilters,
    //     },
    //     limit: 10000,
    //   })
    // );
    if (modelListFetchResult.length > 0) {
      for (let item of listItemToCheck) {
        if (
          modelListFetchResult.find(
            (dbItem) => dbItem.name.toLowerCase() === item.toLowerCase()
          )
        ) {
          throw `There is a ${modelName} name duplication, ${modelName} name: ${item} exists at database`;
        }
      }
    }
    increaseDatabaseExistsProgress(listItemToCheck.length);
  };

  // upload excel file to s3
  const uploadExcelToS3 = (providedFile) => {
    setUploadProgressShow(true);
    Storage.put(`${s3BatchCreationFileName}`, providedFile, {
      contentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        setUploadProgressState((progress.loaded / progress.total) * 100);
      },
    })
      .then((result) => {
        console.log(result);
        setUploadProgressStateVariant("success");
        setUploadProgressIsCheck(true);
        // setIsUploaded(true);
        // getObjectFromS3(result.key);
      })
      .catch((err) => {
        console.log(err);
        setUploadProgressStateVariant("danger");
        setUploadProgressIsCheck(false);
      });
  };

  // define the number of actions required for Database creation
  const defineDatabaseCreationRequiredActions = (structuredObjectArray) => {
    // number of items needed to be generated at daTABASE
    numberOfRequiredDatabaseCreationActions = structuredObjectArray.length;
  };

  // increase the Database Creation progress completion
  const increaseDatabaseCreationProgress = () => {
    ++numberOfAccomplishedDatabaseCreationActions;
    setDatabaseCreationCheckProgressState(
      (
        (numberOfAccomplishedDatabaseCreationActions /
          numberOfRequiredDatabaseCreationActions) *
        100
      ).toFixed(2)
    );
  };

  // generating the structured
  const generateStructuredObjectAtDatabase = async (
    structuredObject,
    generatedObjects
  ) => {
    // loop through the object and create each item one by one and at the same time track what is being generated
    for (let itemToBeGenerated of structuredObject) {
      let generatedItem = {
        name: itemToBeGenerated.name,
      };
      switch (schemaModelName) {
        case ModelTypes.School:
          // generate the school
          let schoolCreationResult = await handleCreateSchool(
            itemToBeGenerated.name
          );

          if (schoolCreationResult.result) {
            generatedItem = {
              ...generatedItem,
              id: schoolCreationResult.id,
            };
          } else {
            throw `Error at creating school: ${itemToBeGenerated.name}`;
          }
          break;
        case ModelTypes.College:
          // create college
          let collegeCreationResult = await handleCollegeCreation(
            itemToBeGenerated.name,
            parentModelId // at college creation parent model Id is schoolId
          );
          if (collegeCreationResult.result) {
            generatedItem = {
              ...generatedItem,
              id: collegeCreationResult.id,
            };
          } else {
            throw `Error at creating college: ${itemToBeGenerated.name}`;
          }
          break;
        case ModelTypes.Clazz:
          // create class
          let classCreationResult = await handleClassCreation(
            itemToBeGenerated.name,
            itemToBeGenerated.order,
            parentModelName === ModelTypes.School
              ? parentModelId
              : ancestorModelId,
            parentModelName === ModelTypes.School ? null : parentModelId
          );
          if (classCreationResult.result) {
            generatedItem = {
              ...generatedItem,
              id: classCreationResult.id,
            };
          } else {
            throw `Error at creating class: ${itemToBeGenerated.name}`;
          }
          break;
        case ModelTypes.Domain:
          // create each domain
          let domainCreationResult = await handleDomainCreation(
            itemToBeGenerated.name,
            parentModelName === ModelTypes.School
              ? parentModelId
              : ancestorModelId,
            parentModelName === ModelTypes.School
              ? CollegeNONE.id
              : parentModelId
          );

          // check the creation result
          if (domainCreationResult.result) {
            generatedItem = {
              ...generatedItem,
              id: domainCreationResult.id,
            };
          } else {
            throw `Error at creating domain ${itemToBeGenerated.name}`;
          }
          break;
      }

      // add generated item to the list
      generatedObjects.push(generatedItem);
    }
  };

  // revert the generated objects when creation goes wrong
  const revertGeneratedObjectOnCreationFailure = async (generatedObjects) => {
    for (let generatedItemToBeDeleted of generatedObjects) {
      switch (schemaModelName) {
        case ModelTypes.School:
          await deleteItemById(generatedItemToBeDeleted.id, deleteSchool);
          break;
        case ModelTypes.College:
          await deleteItemById(
            generatedItemToBeDeleted.id,
            deleteSchoolCollege
          );
          break;
        case ModelTypes.Clazz:
          await deleteItemById(generatedItemToBeDeleted.id, deleteClazz);
          break;
        case ModelTypes.Domain:
          await deleteItemById(generatedItemToBeDeleted.id, deleteDomain);
          break;
      }
    }
  };

  // school creation
  const handleCreateSchool = async (schoolName) => {
    try {
      const input = {
        name: schoolName,
        numberOfColleges: 0,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        totalSchoolUsersCount: 0,
        deletedAt: null,
      };
      let creationResult = await API.graphql(
        graphqlOperation(createSchool, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createSchool.id,
        message: `School created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating school, ${error}`,
      };
    }
  };

  // domain creation
  const handleDomainCreation = async (domainName, schoolId, collegeId) => {
    try {
      // initial required domain creation data
      let input = {
        name: domainName.toLowerCase(),
        deletedAt: null,
      };

      // check if we are building the domain for college or for school
      if (collegeId) {
        // this if for school college
        input = {
          ...input,
          schoolId: schoolId,
          domainCollegeId: collegeId,
        };
      } else {
        // this if for school
        // and we need to define the none college for collegeID
        input = {
          ...input,
          schoolId: schoolId,
          domainCollegeId: CollegeNONE.id,
        };
      }

      let creationResult = await API.graphql(
        graphqlOperation(createDomain, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createDomain.id,
        message: `Domain created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating domain, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  // class creation
  const handleClassCreation = async (className, order, schoolId, collegeId) => {
    try {
      // initial required class creation data
      let input = {
        name: className,
        type: ModelTypes.Clazz,
        order: order,
        deletedAt: null,
      };

      // check if we are building the class for college or for school
      if (collegeId) {
        // this if for school college
        input = {
          ...input,
          schoolId: schoolId,
          clazzCollegeId: collegeId,
        };
      } else {
        // this if for school
        input = { ...input, schoolId: schoolId };
      }

      let creationResult = await API.graphql(
        graphqlOperation(createClazz, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createClazz.id,
        message: `Class created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating class, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  // college creation
  const handleCollegeCreation = async (collegeName, schoolId) => {
    try {
      const input = {
        name: collegeName,
        schoolId: schoolId,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        deletedAt: null,
      };
      let collegeCreationResult = await API.graphql(
        graphqlOperation(createSchoolCollege, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: collegeCreationResult.data.createSchoolCollege.id,
        message: `College created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating college, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  // delete items
  const deleteItemById = async (itemToBeDeletedId, deleteMutation) => {
    let deletionResult = await API.graphql(
      graphqlOperation(deleteMutation, {
        input: {
          id: itemToBeDeletedId,
        },
      })
    );
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <CardContainer
        header={`Batch ${modelName} Creation`}
        title={`Create ${modelName} in batch ${
          parentModelName
            ? `for ${parentModelName}: ${parentModelPresentationalName}`
            : ""
        } `}
        text="Provide the Excel file based on the provided template."
        submitButton={
          <ButtonInput label="Restart" onClick={handleRestart}></ButtonInput>
        }
        backButton={
          <ButtonInput
            label="back"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => returnMethod()}
          />
        }
      >
        <>
          <ul>
            {schemaModelName === ModelTypes.Domain && (
              <>
                <li>
                  Provide domains in the format of : google.com or uofc.ca
                </li>
                <li>Do not include @</li>
                <li>
                  Domains should be unique in whole system due to business
                  logic.
                </li>
              </>
            )}
          </ul>
          <CardContainer header="Upload file" style={{ marginBottom: "20px" }}>
            <input
              type="file"
              accept=".xlsx"
              onChange={(evt) => onChange(evt)}
              disabled={uploadInputDisable}
              ref={inputFileRef}
            />
          </CardContainer>
          {isError && (
            <CardContainer
              header="Error!"
              title="Some errors have happened!"
              text={errorMessage}
              style={{
                marginBottom: "20px",
                backgroundColor: "red",
                color: "white",
              }}
            ></CardContainer>
          )}

          {isBuildCompleted && (
            <CardContainer
              header="Build Completed"
              title="Populating data at Database is completed"
              text="Provided data is generated"
              style={{
                marginBottom: "20px",
                backgroundColor: "green",
                color: "white",
              }}
            ></CardContainer>
          )}
          {procedureStarted && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Process</th>
                  <th style={{ width: "70%" }}>Progress</th>
                  <th style={{ width: "10%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Structure Check</td>
                  <td>
                    {structureCheckProgressShow && (
                      <ProgressBar
                        now={structureCheckProgressState}
                        label={`${structureCheckProgressState}%`}
                        variant={structureCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {structureCheckIsCheck !== null && (
                      <IsChecked status={structureCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Logical Check</td>
                  <td>
                    {logicalCheckProgressShow && (
                      <ProgressBar
                        now={logicalCheckProgressState}
                        label={`${logicalCheckProgressState}%`}
                        variant={logicalCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {logicalCheckIsCheck !== null && (
                      <IsChecked status={logicalCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Database Existence Check</td>
                  <td>
                    {databaseExistsCheckProgressShow && (
                      <ProgressBar
                        now={databaseExistsCheckProgressState}
                        label={`${databaseExistsCheckProgressState}%`}
                        variant={databaseExistsCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {databaseExistsCheckIsCheck !== null && (
                      <IsChecked status={databaseExistsCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Upload to File Storage</td>
                  <td>
                    {uploadProgressShow && (
                      <ProgressBar
                        now={uploadProgressState}
                        label={`${uploadProgressState}%`}
                        variant={uploadProgressStateVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {uploadProgressIsCheck && (
                      <IsChecked status={uploadProgressIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Database Creation</td>
                  <td>
                    {databaseCreationCheckProgressShow && (
                      <ProgressBar
                        now={databaseCreationCheckProgressState}
                        label={`${databaseCreationCheckProgressState}%`}
                        variant={databaseCreationCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {databaseCreationCheckIsCheck && (
                      <IsChecked status={databaseCreationCheckIsCheck} />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {jsonResult && <JSONViewer json={jsonResult} />}
        </>
      </CardContainer>
    </PagePreLoad>
  );
};

BatchCreation.propTypes = {
  schemaModelName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  parentModelName: PropTypes.string,
  parentModelId: PropTypes.string,
  parentModelQueryFunction: PropTypes.object,
  parentModelQueryFunctionName: PropTypes.string,
  ancestorModelId: PropTypes.string.isRequired,
  listQuery: PropTypes.object.isRequired,
  listQueryName: PropTypes.string.isRequired,
  listQueryFilters: PropTypes.object,
  listQueryAttributes: PropTypes.object,
  returnMethod: PropTypes.func,
};

BatchCreation.defaultProps = {
  listQueryFilters: {},
  listQueryAttributes: {},
  parentModelId: null,
  parentModelName: null,
  ancestorModelId: null,
};

export default BatchCreation;
