import React from "react";
import { getJourneyType } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const JourneyTypeGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.journeyTypeId}
      modelName={"JourneyType"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "Journey Type Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "order",
          attributeTitle: "Order",
          description: "Journey Type Appearance Order",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Journey Type Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Journey Type Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getJourneyType}
      queryFunctionName={"getJourneyType"}
    />
  );
};

JourneyTypeGeneralInfo.propTypes = {
  journeyTypeId: PropTypes.string.isRequired,
};

export default JourneyTypeGeneralInfo;
