import React, { useState } from "react";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import GenderGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateGender } from "../../../../graphql/mutations";
import { getGender } from "../../../../graphql/queries";
import * as GendersConstants from "../../../../graphql/Constants/Genders/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const GenderDelete = (props) => {

  const deleteOperationalValidation = async (genderId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired gender
      let tobeDeleted = await API.graphql(
        graphqlOperation(getGender, { id: genderId })
      );

      if (tobeDeleted.data) {
        if (tobeDeleted.data.getGender.id === GendersConstants.Other.id) {
          // we need to prevent deletion
          return false;
        }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching gender data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Gender"
        deleteDescription="gender will be deleted. Deleting a
        gender will have presentational affect on existing relationships."
        returnRoute="/genders"
        generalInfoComponent={
          <GenderGeneralInfo genderId={props.match.params.genderId} />
        }
        mutationMethod={updateGender}
        mutationInput={{
          id: props.match.params.genderId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.genderId)
        }
      />
    </>
  );
};

export default GenderDelete;
