import React from "react";
import { getSchool } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const SchoolGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.schoolId}
      modelName={"School"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "School Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "School Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 3,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "School Updated At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "numberOfUsers",
          attributeTitle: "Number Of Users",
          description: "Number Of Users who signed up with school domains",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "totalSchoolUsersCount",
          attributeTitle: "Total Number Of Users",
          description: "Number Of Users who signed up with school/school Colleges domains",
          dataType: DataTypes.String,
        },
      ]}
      queryFunction={getSchool}
      queryFunctionName={"getSchool"}
    />
  );
};

SchoolGeneralInfo.propTypes = {
  schoolId: PropTypes.string.isRequired,
};

export default SchoolGeneralInfo;
