import isValidDomain from "is-valid-domain";

const DomainValidation = (domainName) => {
  let result = { result: true, message: "" };
  if (!domainName || domainName.length === 0) {
    result.result = false;
    result.message = "Domain name is required";
  } else {
    if (!isValidDomain(domainName)) result.result = false;
    result.message =
      "Domain name is not in valid format, acceptable formats are: example.com, foo.example.com, bar.foo.example.com";
  }
  return result;
};

export default DomainValidation;
