import React from "react";
import ReportCategoryGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const ReportCategoryDetails = (props) => {
  const handleReturn = () => {
    props.history.push(`/reportCategories`);
  };
  return (
    <CardContainer
      header={"Report Category Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <ReportCategoryGeneralInfo
        reportCategoryId={props.match.params.reportCategoryId}
      />
    </CardContainer>
  );
};

export default ReportCategoryDetails;
