import userNameVerification from "./userNameVerification";

const userNameChangeHandler = (
  event,
  modelName,
  setNameState,
  setActionButtonDisableStatus,
  setErrorsState,
  currentErrors
) => {
  const _errors = { ...currentErrors };

  // empty existing username erros
  if (_errors.userName) _errors.userName = "";

  let providedName = event.target.value;
  setNameState(providedName);
  // check the name validation
  let validationResult = userNameVerification(providedName, modelName);
  // check the result
  if (!validationResult.result) {
    _errors.userName = validationResult.message;
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(true);
  } else {
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);
};

export default userNameChangeHandler;
