import passwordConfirmationValidation from "./passwordConfirmationValidation";

const passwordConfirmationChangeHandler = (
  event,
  passwordValue,
  setPasswordConfirmationState,
  setActionButtonDisableStatus,
  setErrorsState,
  currentErrors
) => {
  const _errors = { ...currentErrors };

  // empty existing password errors
  if (_errors.passwordConfirmation) _errors.passwordConfirmation = "";

  let providedPasswordConfirmation = event.target.value;
  setPasswordConfirmationState(providedPasswordConfirmation);
  // check the name validation
  let validationResult = passwordConfirmationValidation(
    providedPasswordConfirmation,
    passwordValue
  );
  // check the result
  if (!validationResult.result) {
    _errors.passwordConfirmation = validationResult.message;
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(true);
  } else {
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);
};

export default passwordConfirmationChangeHandler;
