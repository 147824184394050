import React from "react";
import { getUser } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const UserPrivacySettings = (props) => {
  return (
    <GeneralInfo
      modelId={props.userId}
      modelName={"User"}
      listOfProperties={[
        {
          key: 15,
          attributeName: "privacyPublicAccount",
          attributeTitle: "Public Account",
          description: "Is Public Account",
          dataType: DataTypes.Boolean,
        },
        {
          key: 16,
          attributeName: "privacyAllowComments",
          attributeTitle: "Allow Comments",
          description: "Allow Comments",
          dataType: DataTypes.Boolean,
        },
        {
          key: 17,
          attributeName: "privacyHideOffensiveContent",
          attributeTitle: "Hide Offensive Content",
          description: "Hide Offensive Content",
          dataType: DataTypes.Boolean,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getUser}
      queryFunctionName={"getUser"}
    />
  );
};

UserPrivacySettings.propTypes = {
  userId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

UserPrivacySettings.defaultProps = {
  providedModelData: null,
};

export default UserPrivacySettings;
