export const NONE = {
  id: "3a9f9c5b-52e9-47f8-a412-0fb8dc35a31c",
  name: "NONE",
  order: 0,
};
export const TheCommons = {
  id: "f614a5c1-086c-4b4c-beef-c8d3d56adae2",
  name: "The Commons",
  order: 10,
};
export const Help = {
  id: "3afa9e51-fac0-451b-9312-f83f07b7f754",
  name: "Help",
  order: 20,
};
export const Thoughts = {
  id: "9b9ce6d4-67a9-4b6e-8f01-a754d2ea47b7",
  name: "Thoughts?",
  order: 30,
};
export const Happenings = {
  id: "e01ba1ed-60ab-4231-a9c9-9b6b965b7692",
  name: "Happenings",
  order: 40,
};
export const YardSale = {
  id: "31b39153-223b-4c9c-8501-5d916b6201d8",
  name: "Yard Sale",
  order: 50,
};

export const AllTypes = [NONE, TheCommons, Help, Thoughts, Happenings, YardSale];
