import React from "react";
import { getFeedbackCategory } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const FeedbackCategoryGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.feedbackCategoryId}
      modelName={"FeedbackCategory"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "Feedback Category Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "order",
          attributeTitle: "Order",
          description: "Feedback Category Appearance Order",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Feedback Category Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Feedback Category Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getFeedbackCategory}
      queryFunctionName={"getFeedbackCategory"}
    />
  );
};

FeedbackCategoryGeneralInfo.propTypes = {
  feedbackCategoryId: PropTypes.string.isRequired,
};

export default FeedbackCategoryGeneralInfo;
