import React from "react";
import CollegeGeneralInfo from "../GeneralInfo";
import { updateSchoolCollege } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const CollegeDelete = (props) => {
  return (
    <>
      <SoftDelete
        {...props}
        modelName="College"
        deleteDescription="college will be deleted.As a result,
        users who belong to this college will have corrupted data. Are you
        willing to proceed?"
        returnRoute={`/schools/details/${props.match.params.schoolId}/tab/Colleges`}
        generalInfoComponent={
          <CollegeGeneralInfo collegeId={props.match.params.collegeId} />
        }
        mutationMethod={updateSchoolCollege}
        mutationInput={{
          id: props.match.params.collegeId,
        }}
      />
    </>
  );
};

export default CollegeDelete;
