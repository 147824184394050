import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { API, graphqlOperation } from "aws-amplify";
import { getUserSkillByUserId } from "../../../../graphql/queries";
import { toast } from "react-toastify";

const UserSkills = (props) => {
  const { userId } = props;
  const [userSkillsList, setUserJourneysList] = useState(null);

  const getUserSkillsList = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getUserSkillByUserId, { userId: userId })
      );

      // check the result
      if (result) {
        setUserJourneysList(result.data.getUserSkillByUserId);
      } else {
      }
    } catch (error) {
      toast.error("Error while fetching user Skills");
    }
  };

  useEffect(() => {
    getUserSkillsList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 8,
      attributeTitle: "Skill Type",
        attributeFetchFunction: (data) => {
          return data.skill.name;
        },
      description: "Skill Type",
      dataType: DataTypes.String,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "User Skill Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "User Skill Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of skills based of the list of properties we have defined
  return userSkillsList &&
    userSkillsList.items &&
    userSkillsList.items.length !== 0 ? (
    userSkillsList.items.map((journeyItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(journeyItem)
                  : journeyItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Skill Id : ${journeyItem.id}`}
      />
    ))
  ) : (
    <>
      <span> User Does not have any skills</span>
    </>
  );
};

UserSkills.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserSkills;
