import React from "react";
import SchoolGeneralInfo from "../GeneralInfo";
import { updateSchool } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const SchoolDelete = (props) => {
  return (
    <>
      <SoftDelete
        {...props}
        modelName="School"
        deleteDescription="school will be deleted.As a result,
        users who belong to this school will have corrupted data. Are you
        willing to proceed?"
        returnRoute="/schools"
        generalInfoComponent={
          <SchoolGeneralInfo schoolId={props.match.params.schoolId} />
        }
        mutationMethod={updateSchool}
        mutationInput={{
          id: props.match.params.schoolId,
        }}
      />
    </>
  );
};

export default SchoolDelete;
