import React from "react";
import ReportCategoryGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateReportCategory } from "../../../../graphql/mutations";
import { getReportCategory } from "../../../../graphql/queries";
import * as ReportCategoriesConstants from "../../../../graphql/Constants/ReportCategories/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const ReportCategoryDelete = (props) => {
  const deleteOperationalValidation = async (reportCategoriesId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired ReportCategory
      let tobeDeleted = await API.graphql(
        graphqlOperation(getReportCategory, { id: reportCategoriesId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching ReportCategory data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="ReportCategory"
        deleteDescription="ReportCategory will be deleted. Deleting a ReportCategory will have presentational affect on existing relationships."
        returnRoute="/reportCategories"
        generalInfoComponent={
          <ReportCategoryGeneralInfo
            reportCategoryId={props.match.params.reportCategoryId}
          />
        }
        mutationMethod={updateReportCategory}
        mutationInput={{
          id: props.match.params.reportCategoryId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(
            props.match.params.reportCategoryId
          )
        }
      />
    </>
  );
};

export default ReportCategoryDelete;
