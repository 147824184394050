import React from "react";
import { Greetings } from "aws-amplify-react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaPowerOff, FaUserPlus, FaKey } from "react-icons/fa";
import { Auth } from "aws-amplify";
import PreLoad from "../../../components/Common/PreLoad";
import { Link } from "react-router-dom";

class GreetingsCustom extends Greetings {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn"];
    this.handelSignOut = this.handelSignOut.bind(this);
    this.state = {
      username: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillReceiveProps() {
    const authState = this.props.authState || this.state.authState;
    const signedIn = authState === "signedIn";
    if (signedIn) {
      try {
        Auth.currentAuthenticatedUser()
          .then((user) =>
            this.setState({
              username: user.attributes.email,
              isLoading: false,
            })
          )
          .catch((err) => console.log(err));
      } catch (e) {
        console.error(e);
      }
    }
  }

  async handelSignOut(event) {
    await Auth.signOut();
    this.props.onStateChange("signedOut");
  }

  userGreetings(theme) {
    const iconStyle = { marginRight: "15px" };
    return (
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
        <Navbar.Brand>Truli</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <PreLoad isLoading={this.state.isLoading}>
              <NavDropdown
                title={this.state.username}
                id="collasible-nav-dropdown"
                drop={"left"}
              >
                {/* <NavDropdown.Item>
                  <Link to="/profile/myprofile" exact>
                    <FaUserPlus style={iconStyle} /> My Profile
                  </Link>
                </NavDropdown.Item> */}
                <NavDropdown.Item>
                  <Link to="/profile/changepassword" exact>
                    <FaKey style={iconStyle} /> Change Password
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.handelSignOut}>
                  <FaPowerOff style={iconStyle} /> Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </PreLoad>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  render() {
    const authState = this.props.authState || this.state.authState;
    const signedIn = authState === "signedIn";
    return signedIn ? this.userGreetings(this.props.theme) : null;
  }
}

export default GreetingsCustom;
