import React from "react";
import FeedbackCategoryGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const FeedbackCategoryDetails = (props) => {
  const handleReturn = () => {
    props.history.push(`/feedbackCategories`);
  };
  return (
    <CardContainer
      header={"Feedback Category Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <FeedbackCategoryGeneralInfo
        feedbackCategoryId={props.match.params.feedbackCategoryId}
      />
    </CardContainer>
  );
};

export default FeedbackCategoryDetails;
