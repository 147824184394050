import React from "react";
import { searchSchools } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";
import SchoolNONE from "../../../graphql/Constants/Schools/None";
import CardContainer from "../../../components/Common/CardContainer";
import ButtonInput from "../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../constants/Bootstrap/Variants";

const Schools = (props) => {
  const handleBatchClassCreation = () => {
    props.history.push(`/schools/batchCreate`);
  };

  return (
    <>
      <CardContainer
        header="Batch Creation"
        text="Create batch of schools by excel file upload."
        submitButton={
          <ButtonInput
            label="Batch Creation"
            type="button"
            variant={BootstrapVariants.Info}
            onClick={() => handleBatchClassCreation()}
          />
        }
      >
        <></>
      </CardContainer>
      <DataGrid
        {...props}
        header="Schools"
        gridObjectDataModel={[
          { name: "name", headerText: "Name", type: DataTypes.String },
          {
            name: "createdAt",
            headerText: "Created At",
            type: DataTypes.DateTime,
          },
        ]}
        modelType={ModelTypes.School}
        queryFunction={searchSchools}
        queryFunctionType={QueryTypes.Search}
        queryFilters={{
          id: {
            ne: SchoolNONE.id,
          },
        }}
        queryName={"searchSchools"}
        hasCreate={true}
        hasDetails={true}
        detailsActiveTab="GeneralInfo"
        hasEdit={true}
        hasDelete={true}
        baseRoute={"schools"}
      />
    </>
  );
};

export default Schools;
