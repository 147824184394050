import React, { useState, useEffect } from "react";
import AdminGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import GetAdminData from "../../../../graphql/CustomAdminQueries/GetAdminData"

const AdminDetails = (props) => {
  const [adminData, setAdminData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetAdminData(props.match.params.adminId, setAdminData, setIsLoading);
  }, []);

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (adminData) setIsLoading(false);
  }, [adminData]);

  // const getAdminData = async () => {
  //   let apiName = "AdminQueries";
  //   let path = "/getUser";
  //   let myInit = {
  //     queryStringParameters: {
  //       username: props.match.params.adminId,
  //     },
  //   };
  //   try {
  //     let result = await ExecuteAdminQuery(apiName, path, myInit);
  //     // user data comes back inside thge Attributes
  //     if (result && result.success) {
  //       let adminDataResult = {
  //           data : { getAdmin : result.resultItem } 
  //       }
  //       setAdminData(adminDataResult);
  //     } else {
  //       toast.error(`Error while fetching admins, ${result.errorMessage}`);
  //     }
  //   } catch (error) {
  //     toast.error(
  //       `Error while fetching admins, ${JSON.stringify(error, null, 2)}`
  //     );
  //   }
  //   setIsLoading(false);
  // };

  const handleReturn = () => {
    props.history.push(`/admins`);
  };
  return (
    <PagePreLoad isLoading={isLoading}>
      <CardContainer
        header={"Admin Details"}
        backButton={
          <ButtonInput
            label="Back to List"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => handleReturn()}
          />
        }
      >
        <AdminGeneralInfo
          providedModelData={adminData}
          adminId={props.match.params.adminId}
        />
      </CardContainer>
    </PagePreLoad>
  );
};

export default AdminDetails;
