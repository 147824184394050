import React from "react";
import { Row, Col } from "react-bootstrap";

const MyProfile = () => {
  return (
    <>
      <Row>
          <Col>
            this is my profile
          </Col>
      </Row>
    </>
  );
};

export default MyProfile;
