import React from "react";
import { getPostCategory } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const PostCategoryGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.postCategoryId}
      modelName={"PostCategory"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "Post Category Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "order",
          attributeTitle: "Order",
          description: "Post Category Appearance Order",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Post Category Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Post Category Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getPostCategory}
      queryFunctionName={"getPostCategory"}
    />
  );
};

PostCategoryGeneralInfo.propTypes = {
  postCategoryId: PropTypes.string.isRequired,
};

export default PostCategoryGeneralInfo;
