import React from "react";
import { searchDegrees } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const Degrees = (props) => {
    return (
        <DataGrid
          {...props}
          header="Degrees"
          gridObjectDataModel={[
            { name: "name", headerText: "Name", type: DataTypes.String },
            { name: "order", headerText: "Order", type: DataTypes.Number },
            {
              name: "createdAt",
              headerText: "Created At",
              type: DataTypes.DateTime,
            },
          ]}
          modelType={ModelTypes.Degree}
          queryFunction={searchDegrees}
          queryFunctionType={QueryTypes.Search}
          queryName={"searchDegrees"}
          hasCreate={true}
          hasDetails={true}
          hasEdit={true}
          hasDelete={true}
          baseRoute={"degrees"}
        />
      );
};

export default Degrees;
