export const ItsASpam = {
  id: "5096e1f8-18ce-43c2-8679-f50f7b77883d",
  name: "It's a spam",
  order: 10,
};
export const ItsInappropriate = {
  id: "031ce96f-0f9e-4733-b1b5-172be45c3d70",
  name: "It's inappropriate",
  order: 20,
};

export const AllTypes = [ItsASpam, ItsInappropriate];
