import React from "react";
import BatchCreation from "../../../Common/Crud/BatchCreation";
import {
  getSchool,
  getSchoolCollege,
  getClazzesByCollegeIdOrdered,
  getClazzesBySchoolIdOrdered,
} from "../../../../graphql/queries";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const ClassBatchCreate = (props) => {
  return (
    <BatchCreation
      listQuery={
        props.match.params.collegeId
          ? getClazzesByCollegeIdOrdered
          : getClazzesBySchoolIdOrdered
      }
      listQueryName={
        props.match.params.collegeId
          ? "getClazzesByCollegeIdOrdered"
          : "getClazzesBySchoolIdOrdered"
      }
      listQueryAttributes={
        props.match.params.collegeId
          ? {
              clazzCollegeId: props.match.params.collegeId,
            }
          : { schoolId: props.match.params.schoolId }
      }
      listQueryFilters={
        props.match.params.collegeId
          ? {
              deletedAt: {
                eq: null,
              }
            }
          : {
              deletedAt: {
                eq: null,
              },
              clazzCollegeId: {
                attributeExists: false,
              },
            }
      }
      modelName={
        props.match.params.collegeId ? "College Classes" : "School Classes"
      }
      schemaModelName={ModelTypes.Clazz}
      parentModelName={
        props.match.params.collegeId ? ModelTypes.College : ModelTypes.School
      }
      parentModelId={
        props.match.params.collegeId
          ? props.match.params.collegeId
          : props.match.params.schoolId
      }
      ancestorModelId={
        props.match.params.collegeId ? props.match.params.schoolId : null
      }
      parentModelQueryFunction={
        props.match.params.collegeId ? getSchoolCollege : getSchool
      }
      parentModelQueryFunctionName={
        props.match.params.collegeId ? "getSchoolCollege" : "getSchool"
      }
      returnMethod={() => {
        // since we can create a domain for college as well we need to check how we got here and go back there
        if (props.match.params.collegeId) {
          props.history.push(
            `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
          );
        } else {
          props.history.push(
            `/schools/details/${props.match.params.schoolId}/tab/Classes`
          );
        }
      }}
    />
  );
};

export default ClassBatchCreate;
