import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import {
  getUserClazzByUserId,
  getCommentRepliesByRepliedToId,
} from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const CommentReplies = (props) => {
  const { commentId } = props;
  const [commentRepliesList, setCommentRepliesList] = useState(null);

  const getCommentRepliesList = async () => {
    try {
      let result = await GetFullListResult(
        getCommentRepliesByRepliedToId,
        "getCommentRepliesByRepliedToId",
        {
          repliedToId: commentId,
        }
      );

      // check the result
      if (result.success) {
        setCommentRepliesList(result.resultList);
      } else {
        toast.error(
          `Error while fetching comment replies, ${result.errorMessage}`
        );
      }
    } catch (error) {
      toast.error("Error while fetching comment replies");
    }
  };

  useEffect(() => {
    getCommentRepliesList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 1,
      attributeTitle: "Content",
      attributeFetchFunction: (data) => {
        return data.comment.content;
      },
      description: "Comment Content",
      dataType: DataTypes.String,
    },
    {
      key: 2,
      attributeTitle: "User Name",
      attributeFetchFunction: (data) => {
        return data.comment.userName;
      },
      description: "Comment Owner UserName",
      dataType: DataTypes.String,
    },
    {
      key: 3,
      attributeName: "isProfane",
      attributeTitle: "Is Profane",
      description: "Is Comment Content Profane?",
      dataType: DataTypes.Boolean,
    },
    {
      key: 4,
      attributeName: "isReply",
      attributeTitle: "Is a Reply",
      description: "Is Comment a Reply to Another Comment?",
      dataType: DataTypes.Boolean,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "Class Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "Class Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of journeys based of the list of properties we have defined
  return commentRepliesList && commentRepliesList.length !== 0 ? (
    commentRepliesList.map((journeyItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(journeyItem)
                  : journeyItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Comment Id : ${journeyItem.id}`}
      />
    ))
  ) : (
    <>
      <span> Comment does not have any replies</span>
    </>
  );
};

CommentReplies.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default CommentReplies;
