import React from "react";
import PostCategoryGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updatePostCategory } from "../../../../graphql/mutations";
import { getPostCategory } from "../../../../graphql/queries";
import * as PostCategoriesConstants from "../../../../graphql/Constants/PostCategories/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const PostCategoryDelete = (props) => {
  const deleteOperationalValidation = async (postCategoriesId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired PostCategory
      let tobeDeleted = await API.graphql(
        graphqlOperation(getPostCategory, { id: postCategoriesId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching PostCategory data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="PostCategory"
        deleteDescription="PostCategory will be deleted. Deleting a PostCategory will have presentational affect on existing relationships."
        returnRoute="/postCategories"
        generalInfoComponent={
          <PostCategoryGeneralInfo
            postCategoryId={props.match.params.postCategoryId}
          />
        }
        mutationMethod={updatePostCategory}
        mutationInput={{
          id: props.match.params.postCategoryId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(
            props.match.params.postCategoryId
          )
        }
      />
    </>
  );
};

export default PostCategoryDelete;
