import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createSchoolCollege } from "../../../../graphql/mutations";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";

const CollegeCreate = (props) => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [name, setName] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);

  const isFormValid = () => {
    const _errors = {};

    if (!name) _errors.name = "College name is required";

    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    // create school at this point
    let result = await handleCreateCollege();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        props.history.push(
          `/schools/details/${props.match.params.schoolId}/tab/Colleges`
        );
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };

  const handleCreateCollege = async () => {
    try {
      const input = {
        name: name,
        schoolId: props.match.params.schoolId,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        deletedAt: null,
      };
      await API.graphql(graphqlOperation(createSchoolCollege, { input }));
      return { result: true, message: `College created successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error creating college, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  const handleReturn = () => {
    props.history.push(
      `/schools/details/${props.match.params.schoolId}/tab/Colleges`
    );
  };

  return (
    <Form onSubmit={handleFormSubmission} noValidate>
      <CardContainer
        header="Create a College"
        submitButton={
          <ButtonInput
            label="Create"
            variant={BootstrapVariants.Success}
            formSubmitInProgress={formSubmitInProgress}
            formSubmittingText={"In Progress..."}
          />
        }
        backButton={
          <ButtonInput
            label="back"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => handleReturn()}
          />
        }
      >
        {generalError && (
          <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
        )}

        <TextInput
          type="name"
          id="name"
          label="College Name"
          placeholder="Enter a name for College"
          value={name}
          onChange={(e) => setName(e.target.value)}
          isRequired={true}
          error={errors.name}
        />
      </CardContainer>
    </Form>
  );
};

export default CollegeCreate;
