import React from "react";
import { Card, Accordion, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const PageAccordion = (props) => {
  return (
    <Accordion defaultActiveKey="0" style={{ marginBottom: "15px" }}>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          style={{ cursor: "pointer" }}
        >
          {props.title}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Row>{props.children}</Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

PageAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
};

PageAccordion.defaultProps = {
  title: "Title",
};

export default PageAccordion;
