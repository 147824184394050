import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { getPostAssetsByPostId } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const UserPostAssets = (props) => {
  const { postId } = props;
  const [postAssetsList, setPostAssetsList] = useState(null);

  const getUserPostAssetsList = async () => {
    try {
      let result = await GetFullListResult(
        getPostAssetsByPostId,
        "getPostAssetsByPostId",
        {
          postId: postId,
        }
      );

      // check the result
      if (result.success) {
        setPostAssetsList(result.resultList);
      } else {
        toast.error(`Error while fetching Post Assets, ${result.errorMessage}`);
      }
    } catch (error) {
      toast.error("Error while fetching Post Assets");
    }
  };

  useEffect(() => {
    getUserPostAssetsList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    // {
    //   key: 1,
    //   attributeTitle: "Past Class Name",
    //   attributeFetchFunction: (data) => {
    //     return data.clazz.name;
    //   },
    //   description: "Past Class Name",
    //   dataType: DataTypes.String,
    // },
    {
      key: 9,
      attributeName: "order",
      attributeTitle: "Appearance Order",
      description: "Assets Appereance order",
      dataType: DataTypes.String,
    },
    {
      key: 1,
      attributeTitle: "Post Media Asset",
      attributeFetchFunction: (data) => {
        return data.s3Object.key;
      },
      description: "Post Media Asset Preview",
      dataType: DataTypes.ImageFromS3,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "Post Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "Post Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of journeys based of the list of properties we have defined
  return postAssetsList && postAssetsList.length !== 0 ? (
    postAssetsList.map((postAssetItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(postAssetItem)
                  : postAssetItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Post Asset Id : ${postAssetItem.id}`}
      />
    ))
  ) : (
    <>
      <span>Post does not have any assets</span>
    </>
  );
};

UserPostAssets.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default UserPostAssets;
