import React from "react";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import ImagePreview from "../../ImagePreview";
import getObjectFromS3 from "../../../../containers/Common/S3/Handlers/getObjectFromS3";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const DetailsPresentationItem = (props) => {
  const [imageFromS3Url, SetImageFromS3Url] = useState(null);

  useEffect(() => {
    if (props.dataType === DataTypes.ImageFromS3) {
      getImageFromS3();
    }
  }, []);

  const getImageFromS3 = async () => {
    let objectFromS3Result = await getObjectFromS3(props.value);
    if (objectFromS3Result.result) {
      SetImageFromS3Url(objectFromS3Result.url);
    } else {
      toast.error("Error while getting Image from S3");
    }
  };

  return (
    <tr>
      <td style={{ width: "25%" }}>
        <span className="text-info">{props.name}</span>
      </td>
      <td style={{ width: "45%" }}>
        <span className="font-weight-normal">
          {props.dataType === DataTypes.DateTime &&
            (props.value ? new Date(props.value).toLocaleString() : "---")}
          {props.dataType === DataTypes.Date &&
            (props.value
              ? new Date(props.value).toLocaleDateString("en-US")
              : "---")}
          {props.dataType === DataTypes.Boolean && (
            <span>{props.value === true || props.value === 'true' ? "Yes" : `No`}</span>
          )}
          {props.dataType === DataTypes.Image && (
            <ImagePreview url={props.value} />
          )}
          {props.dataType === DataTypes.ImageFromS3 && imageFromS3Url && (
            <ImagePreview url={imageFromS3Url} />
          )}
          {props.dataType === DataTypes.String && <span>{props.value}</span>}
        </span>
      </td>
      <td style={{ width: "30%" }}>
        <span className="text-muted">{props.description}</span>
      </td>
    </tr>
  );
};

DetailsPresentationItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string,
  dataType: PropTypes.string.isRequired,
};

DetailsPresentationItem.defaultProps = {
  name: "",
  value: "",
  description: "",
  dataType: DataTypes.String,
};

export default DetailsPresentationItem;
