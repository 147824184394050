const SchoolNONE = {
  id: "79dcbd9d-46eb-4261-80a4-dcf8f8b219bf", // we will use this ID at school trigger to filter out this school 
  name: "NONE",
  numberOfColleges: 0,
  numberOfDomains: 0,
  numberOfClazzes: 0,
  numberOfUsers: 0,
  totalSchoolUsersCount: 0,
  deletedAt: null,
};

export default SchoolNONE;
