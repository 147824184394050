const CollegeNONE = {
  id: "14110c04-84b2-43a3-adf5-abcab2a6cb2c", // we will use this ID at college trigger to filter out this school
  name: "NONE",
  schoolId: "79dcbd9d-46eb-4261-80a4-dcf8f8b219bf", // this is school NONE
  numberOfDomains: 0,
  numberOfClazzes: 0,
  numberOfUsers: 0,
  deletedAt: null,
};

export default CollegeNONE;
