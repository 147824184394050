import React, { useState, useEffect } from "react";
import TabsContainer from "../../../../components/Common/TabsContainer";
import CollegeGeneralInfo from "../GeneralInfo";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import DataGrid from "../../../Common/DataGrid";
import {
  getSchoolCollege,
  searchDomains,
  searchClazzs,
  searchUsers,
} from "../../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../../graphql/Constants/QueryTypes";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import CardContainer from "../../../../components/Common/CardContainer";

const CollegeDetails = (props) => {
  const [collegeName, setCollegeName] = useState("");
  const [collegeDomainsTotalCount, setCollegeDomainsTotalCount] = useState(
    null
  );
  const [collegeClassesTotalCount, setCollegeClassesTotalCount] = useState(0);
  const [collegeUsersTotalCount, setCollegeUsersTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabsActiveTab, setTabsActiveTab] = useState(
    props.match.params.tabName
  );

  useEffect(() => {
    getCollegeData();
  }, []);

  const getCollegeData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getSchoolCollege, { id: props.match.params.collegeId })
      );
      const {
        name,
        numberOfDomains,
        numberOfClazzes,
        numberOfUsers,
      } = result.data.getSchoolCollege;
      // reuse the same function being called by college data grid to assign the header
      setCollegeName(name);
      setCollegeClassesTotalCount(numberOfClazzes);
      setCollegeUsersTotalCount(numberOfUsers);
      setCollegeDomainsTotalCount(numberOfDomains);
    } catch (error) {
      toast.error(`Error while fetching college data ${JSON.stringify(error)}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (collegeDomainsTotalCount != null) setIsLoading(false);
  }, [collegeDomainsTotalCount]);

  const getCollegeDomainsTotalCount = async () => {
    return collegeDomainsTotalCount;
  };

  const getCollegeClassesTotalCount = async () => {
    return collegeClassesTotalCount;
  };

  const getCollegeUsersTotalCount = async () => {
    return collegeUsersTotalCount;
  };

  const handleReturn = () => {
    props.history.push(
      `/schools/details/${props.match.params.schoolId}/tab/Colleges`
    );
  };

  const handleBatchClassCreation = () => {
    props.history.push(
      `/schools/${props.match.params.schoolId}/colleges/${props.match.params.collegeId}/classes/batchCreate`
    );
  };

  const handleBatchDomainCreation = () => {
    props.history.push(
      `/schools/${props.match.params.schoolId}/colleges/${props.match.params.collegeId}/domains/batchCreate`
    );
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <TabsContainer
        header={`College Details : ${collegeName}`}
        defaultActiveKey={tabsActiveTab}
        backButton={
          <ButtonInput
            label="Back to List"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => handleReturn()}
          />
        }
        tabs={[
          {
            name: "General Info",
            eventKey: "GeneralInfo",
            content: (
              <CollegeGeneralInfo collegeId={props.match.params.collegeId} />
            ),
          },
          {
            name: "Domains",
            eventKey: "Domains",
            content: (
              <>
                <CardContainer
                  header="Batch Creation"
                  text="Create batch of domains by excel file upload."
                  submitButton={
                    <ButtonInput
                      label="Batch Creation"
                      type="button"
                      variant={BootstrapVariants.Info}
                      onClick={() => handleBatchDomainCreation()}
                    />
                  }
                />
                <DataGrid
                  header="Domains"
                  {...props}
                  gridObjectDataModel={[
                    {
                      name: "name",
                      headerText: "Name",
                      type: DataTypes.String,
                    },
                    {
                      name: "createdAt",
                      headerText: "Created At",
                      type: DataTypes.DateTime,
                    },
                  ]}
                  customTotalCountFunction={getCollegeDomainsTotalCount}
                  queryFunction={searchDomains}
                  queryFunctionType={QueryTypes.Search}
                  queryName={"searchDomains"}
                  queryFilters={{
                    domainCollegeId: {
                      eq: props.match.params.collegeId,
                    },
                  }}
                  hasCreate={true}
                  hasDetails={true}
                  hasEdit={true}
                  hasDelete={true}
                  baseRoute={`schools/${props.match.params.schoolId}/colleges/${props.match.params.collegeId}/domains`}
                />
              </>
            ),
          },
          {
            name: "Classes",
            eventKey: "Classes",
            content: (
              <>
                <CardContainer
                  header="Batch Creation"
                  text="Create batch of classes by excel file upload."
                  submitButton={
                    <ButtonInput
                      label="Batch Creation"
                      type="button"
                      variant={BootstrapVariants.Info}
                      onClick={() => handleBatchClassCreation()}
                    />
                  }
                />
                <DataGrid
                  header="Classes"
                  {...props}
                  gridObjectDataModel={[
                    {
                      name: "name",
                      headerText: "Name",
                      type: DataTypes.String,
                    },
                    {
                      name: "order",
                      headerText: "Order",
                      type: DataTypes.Number,
                    },
                    {
                      name: "createdAt",
                      headerText: "Created At",
                      type: DataTypes.DateTime,
                    },
                  ]}
                  customTotalCountFunction={getCollegeClassesTotalCount}
                  queryFunction={searchClazzs}
                  queryFunctionType={QueryTypes.Search}
                  queryName={"searchClazzs"}
                  queryFilters={{
                    clazzCollegeId: {
                      eq: props.match.params.collegeId,
                    },
                  }}
                  hasCreate={true}
                  hasDetails={true}
                  hasEdit={true}
                  hasDelete={true}
                  baseRoute={`schools/${props.match.params.schoolId}/colleges/${props.match.params.collegeId}/classes`}
                />
              </>
            ),
          },
          {
            name: "Users",
            eventKey: "Users",
            content: (
              <DataGrid
                header="Users"
                {...props}
                gridObjectDataModel={[
                  {
                    name: "userName",
                    headerText: "UserName",
                    type: DataTypes.String,
                  },
                  {
                    name: "firstName",
                    headerText: "First Name",
                    type: DataTypes.String,
                  },
                  {
                    name: "lastName",
                    headerText: "Last Name",
                    type: DataTypes.String,
                  },
                  {
                    name: "isBlocked",
                    headerText: "Is Blocked",
                    type: DataTypes.Boolean,
                  },
                  {
                    name: "isProfane",
                    headerText: "Is Marked as Profane",
                    type: DataTypes.Boolean,
                  },
                  {
                    name: "numberOfTimesReferralCodeUsed",
                    headerText: "Number of Referred Users",
                    type: DataTypes.Boolean,
                  },
                  {
                    name: "createdAt",
                    headerText: "Created At",
                    type: DataTypes.DateTime,
                  },
                ]}
                customTotalCountFunction={getCollegeUsersTotalCount}
                queryFunction={searchUsers}
                queryFunctionType={QueryTypes.Search}
                queryName={"searchUsers"}
                queryFilters={{
                  userCollegeId: {
                    eq: props.match.params.collegeId,
                  },
                }}
                hasCreate={false}
                hasDetails={true}
                hasEdit={false}
                hasDelete={true}
                hasBlock={true}
                hasProfane={true}
                baseRoute={`schools/${props.match.params.schoolId}/colleges/${props.match.params.collegeId}/users`}
              />
            ),
          },
        ]}
      />
    </PagePreLoad>
  );
};

export default CollegeDetails;
