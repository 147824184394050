import React from "react";
import { getSkill } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const SkillsGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.skillId}
      modelName={"Skill"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "Skill Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "order",
          attributeTitle: "Order",
          description: "Skill Appearance order",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Skill Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Skill Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getSkill}
      queryFunctionName={"getSkill"}
    />
  );
};

SkillsGeneralInfo.propTypes = {
  skillId: PropTypes.string.isRequired,
};

export default SkillsGeneralInfo;
