const { API, graphqlOperation } = require("aws-amplify");
const { getPointSystemByPointType } = require("../../../../graphql/queries");

const getPointSystemByTypeAsync = async (pointSystemType) => {
  let fetchResult = { result: false, message: "", point: 0 };
  try {
    const result = await API.graphql(
      graphqlOperation(getPointSystemByPointType, {
        pointType: pointSystemType,
      })
    );
    const { point } = result.data.getPointSystemByPointType.items[0];

    // apply the result
    fetchResult.result = true;
    fetchResult.point = point;
  } catch (error) {
    fetchResult.message = `Error while fetching user data ${JSON.stringify(
      error
    )}`;
  }

  return fetchResult;
};

export default getPointSystemByTypeAsync;
