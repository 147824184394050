export const MAKE_A_FRIEND = {
  id: "95847ad7-0aeb-43ac-ad5c-5355f0d0bfdb",
  pointType: "MAKE_A_FRIEND",
  order: 10,
  point: 5,
};
export const FOLLOW_A_USER = {
  id: "6be338a5-c870-4ec0-aaf5-4437eec812a0",
  pointType: "FOLLOW_A_USER",
  order: 20,
  point: 3,
};
export const LIKE_A_POST = {
  id: "be8194b8-c8eb-4762-9f03-4f416519662c",
  pointType: "LIKE_A_POST",
  order: 30,
  point: 1,
};
export const COMMENT_ON_POST = {
  id: "8ea42fd2-1d5c-405c-a684-9da1998167dc",
  pointType: "COMMENT_ON_POST",
  order: 40,
  point: 2,
};
export const POST = {
  id: "7e379357-c44a-46a4-b840-0736fe47c7a0",
  pointType: "POST",
  order: 50,
  point: 2,
};
export const PROFILE_UPDATE = {
  id: "181f0b30-8792-4b83-8266-1f1c544a99d9", // this is being used at userTrigger
  pointType: "PROFILE_UPDATE",
  order: 60,
  point: 2,
};
export const FLAGGED_REMOVED_CONTENT = {
  id: "ed652409-f6f4-4b0f-a7a4-e0abfdaf20c2",
  pointType: "FLAGGED_REMOVED_CONTENT",
  order: 70,
  point: -20,
};

export const AllTypes = [
  MAKE_A_FRIEND,
  FOLLOW_A_USER,
  LIKE_A_POST,
  COMMENT_ON_POST,
  POST,
  PROFILE_UPDATE,
  FLAGGED_REMOVED_CONTENT,
];
