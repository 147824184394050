import React, { useRef } from "react";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import logo from "../../Assets/Images/logo512.png";
import Authorization from "../../containers/Authorization";
import {
  FaCogs,
  FaCog,
  FaFileAlt,
  FaFileWord,
  FaTabletAlt,
  FaTachometerAlt,
  FaSchool,
  FaStarHalfAlt,
  FaVenusMars,
  FaSuperpowers,
  FaSuitcaseRolling,
  FaCommentAlt,
  FaComment,
  FaLayerGroup,
  FaAddressCard,
  FaFlag,
  FaUsersCog,
  FaHashtag,
  FaStar,
} from "react-icons/fa";
import SideBarItem from "./SideBarItem";
import SideBarAccordionToggle from "./SideBarAccordionToggle";
import SideBarAccordion from "./SideBarAccordion";
import { Row, Col } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import * as UserGroups from "../../graphql/Constants/UserGroups";

const Sidebar = () => {
  const sidebarObj = useRef(null);
  const onCreate = () => {
    sidebarObj.current.element.style.visibility = "";
  };
  const target = ".content";
  const width = "260px";
  const dockSize = "50px";
  const mediaQuery = window.matchMedia("(min-width: 768px)");
  const type = "Push";

  return (
    <>
      <div className="control-section">
        <div id="wrapper">
          <SidebarComponent
            enableGestures={false}
            id="dockSidebar"
            ref={sidebarObj}
            created={onCreate}
            style={{ visibility: "hidden" }}
            target={target}
            width={width}
            mediaQuery={mediaQuery}
            type={type}
            enableDock={true}
            dockSize={dockSize}
          >
            <div className="dock">
              <div>
                <img
                  alt=""
                  src={logo}
                  style={{ width: "100%" }}
                  className="d-inline-block align-top"
                />{" "}
              </div>
              <ul>
                <li
                  className="sidebar-item"
                  onClick={() => sidebarObj.current.toggle()}
                >
                  <Row>
                    <Col xs={2}>
                      <FaBars className="sidebar-icon" />
                    </Col>
                    <Col xs={10} style={{ paddingTop: "2px" }}>
                      <span className="e-text" title="Menu">
                        Menu
                      </span>
                    </Col>
                  </Row>
                </li>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/"
                    label="Dashboard"
                    icon={<FaTachometerAlt className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/admins"
                    exact={false}
                    label="Admin Management"
                    icon={<FaUsersCog className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/schools"
                    exact={false}
                    label="Schools Management"
                    icon={<FaSchool className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/degrees"
                    exact={false}
                    label="Degrees Management"
                    icon={<FaStarHalfAlt className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/skills"
                    exact={false}
                    label="Skills Management"
                    icon={<FaSuperpowers className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/genders"
                    exact={false}
                    label="Genders Management"
                    icon={<FaVenusMars className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/journeyTypes"
                    exact={false}
                    label="Journey Types Management"
                    icon={<FaSuitcaseRolling className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarAccordion
                    toggleItem={
                      <SideBarAccordionToggle
                        label="Posts"
                        icon={<FaAddressCard className="sidebar-icon" />}
                      />
                    }
                  >
                    <>
                      <SideBarItem
                        to="/postCategories"
                        label="Categories"
                        icon={<FaLayerGroup className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                      <SideBarItem
                        to="/userPosts"
                        label="User Posts"
                        icon={<FaCommentAlt className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                    </>
                  </SideBarAccordion>
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/hashTags"
                    exact={false}
                    label="HashTags"
                    icon={<FaHashtag className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/comments"
                    exact={false}
                    label="Comments"
                    icon={<FaComment className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarAccordion
                    toggleItem={
                      <SideBarAccordionToggle
                        label="Feedback"
                        icon={<FaCommentAlt className="sidebar-icon" />}
                      />
                    }
                  >
                    <>
                      <SideBarItem
                        to="/feedbackCategories"
                        label="Categories"
                        icon={<FaLayerGroup className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                      <SideBarItem
                        to="/feedbacks"
                        label="User Feedback"
                        icon={<FaCommentAlt className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                    </>
                  </SideBarAccordion>
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarAccordion
                    toggleItem={
                      <SideBarAccordionToggle
                        label="Reports"
                        icon={<FaFlag className="sidebar-icon" />}
                      />
                    }
                  >
                    <>
                      <SideBarItem
                        to="/reportCategories"
                        label="Categories"
                        icon={<FaLayerGroup className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                      <SideBarItem
                        to="/userReports"
                        label="User Reports"
                        icon={<FaFlag className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                      <SideBarItem
                        to="/postReports"
                        label="Post Reports"
                        icon={<FaFlag className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                      <SideBarItem
                        to="/commentReports"
                        label="Comment Reports"
                        icon={<FaFlag className="sidebar-icon" />}
                        style={{}}
                        exact={false}
                      />
                    </>
                  </SideBarAccordion>
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarAccordion
                    toggleItem={
                      <SideBarAccordionToggle
                        label="System Settings"
                        icon={<FaCog className="sidebar-icon" />}
                      />
                    }
                  >
                    <>
                      <SideBarItem
                        to="/systemsettings/termsofservice"
                        label="Terms Of Service"
                        icon={<FaFileAlt className="sidebar-icon" />}
                        style={{}}
                      />
                      <SideBarItem
                        to="/systemsettings/privacypolicy"
                        label="Privacy Policy"
                        icon={<FaFileWord className="sidebar-icon" />}
                        style={{}}
                      />
                      {/* <SideBarItem
                        to="/systemsettings/aboutapp"
                        label="About App"
                        icon={<FaTabletAlt className="sidebar-icon" />}
                        style={{}}
                      /> */}
                    </>
                  </SideBarAccordion>
                </Authorization>

                <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/pointSystems"
                    exact={false}
                    label="Point System"
                    icon={<FaStar className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization allowedUserGroups={[UserGroups.SystemAdmins]}>
                  <SideBarItem
                    to="/systeminitialsettings"
                    label="System Initial Settings"
                    icon={<FaCogs className="sidebar-icon" />}
                  />
                </Authorization>

                <Authorization allowedUserGroups={[UserGroups.SystemAdmins]}>
                  <SideBarItem
                    to="/systeminitialassets"
                    label="System Initial Assets"
                    icon={<FaCogs className="sidebar-icon" />}
                  />
                </Authorization>

                {/* <Authorization allowedUserGroups={[UserGroups.SystemAdmins]}>
                  <SideBarItem
                    to="/schoolsinitialsettings"
                    label="Schools/Colleges/Classes Initial"
                    icon={<FaCogs className="sidebar-icon" />}
                  />
                </Authorization> */}

                {/* <Authorization
                  allowedUserGroups={[
                    UserGroups.SystemAdmins,
                    UserGroups.SuperAdmins,
                  ]}
                >
                  <SideBarItem
                    to="/batchupload"
                    label="Batch Upload"
                    icon={<FaCogs className="sidebar-icon" />}
                  />
                </Authorization> */}
              </ul>
            </div>
          </SidebarComponent>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
