import React from "react";
import { getPost } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const UserPostGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.userPostId}
      modelName={"Post"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "userName",
          attributeTitle: "UserName",
          description: "Post By UserName",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "content",
          attributeTitle: "Content",
          description: "Post Text Content",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "numberOfLikes",
          attributeTitle: "Number Of Likes",
          description: "Number Of Likes",
          dataType: DataTypes.String,
        },
        {
          key: 4,
          attributeName: "numberOfComments",
          attributeTitle: "Number Of Comments",
          description: "Number Of Comments",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "schoolName",
          attributeTitle: "School Name",
          description: "School Name",
          dataType: DataTypes.String,
        },
        {
          key: 6,
          attributeName: "isProfane",
          attributeTitle: "IsProfane",
          description: "Is Profane Content",
          dataType: DataTypes.Boolean,
        },
        {
          key: 7,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Post Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 8,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Post Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getPost}
      queryFunctionName={"getPost"}
    />
  );
};

UserPostGeneralInfo.propTypes = {
  userReportId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

UserPostGeneralInfo.defaultProps = {
  providedModelData: null,
};

export default UserPostGeneralInfo;
