export const School = "SCHOOL";
export const College = "COLLEGE";
export const Domain = "DOMAIN";
export const User = "USER";
export const Clazz = "CLAZZ";
export const Degree = "DEGREE";
export const Gender = "GENDER";
export const Skill = "SKILL";
export const JourneyType = "JOURNEYTYPE";
export const FeedbackCategory = "FEEDBACKCATEGORY";
export const Feedback = "FEEDBACK";
export const PostCategory = "POSTCATEGORY";
export const ReportCategory = "REPORTCATEGORY";
export const UserReport = "USERREPORT";
export const PostReport = "POSTREPORT";
export const CommentReport = "COMMENTREPORT";
export const Post = "POST";
export const HashTag = "HASHTAG";
export const Comment = "COMMENT";
export const PointSystem = "POINTSYSTEM";





