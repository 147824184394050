import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { getUserPastClazzByUserId } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const UserClasses = (props) => {
  const { userId } = props;
  const [userClassesList, setUserClassesList] = useState(null);

  const getUserClassesList = async () => {
    try {
      let result = await GetFullListResult(
        getUserPastClazzByUserId,
        "getUserPastClazzByUserId",
        {
          userId: userId,
        }
      );

      // check the result
      if (result.success) {
        setUserClassesList(result.resultList);
      } else {
        toast.error(
          `Error while fetching user past classes, ${result.errorMessage}`
        );
      }
    } catch (error) {
      toast.error("Error while fetching user past classes");
    }
  };

  useEffect(() => {
    getUserClassesList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 8,
      attributeTitle: "Past Class Name",
      attributeFetchFunction: (data) => {
        return data.clazz.name;
      },
      description: "Past Class Name",
      dataType: DataTypes.String,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "Class Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "Class Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of journeys based of the list of properties we have defined
  return userClassesList && userClassesList.length !== 0 ? (
    userClassesList.map((journeyItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(journeyItem)
                  : journeyItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Journey Id : ${journeyItem.id}`}
      />
    ))
  ) : (
    <>
      <span> User does not have a past class</span>
    </>
  );
};

UserClasses.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserClasses;
