import React from "react";
import { getHashTag } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const HashTagInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.hashTagId}
      modelName={"HashTag"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "title",
          attributeTitle: "Title",
          description: "HashTag Title",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "schoolName",
          attributeTitle: "School Name",
          description: "HashTag Belongs to School",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "numberOfPosts",
          attributeTitle: "Number Of Posts",
          description: "Number OF Posts with this hashtag",
          dataType: DataTypes.String,
        },
        {
          key: 4,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "HashTag Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 5,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "HashTag Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getHashTag}
      queryFunctionName={"getHashTag"}
    />
  );
};

HashTagInfo.propTypes = {
  hashTagId: PropTypes.string.isRequired,
};

export default HashTagInfo;
