import React, { useState, useEffect } from "react";
import TabsContainer from "../../../../components/Common/TabsContainer";
import UserPostGeneralInfo from "../GeneralInfo";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import DataGrid from "../../../Common/DataGrid";
import { getPost } from "../../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../../graphql/Constants/QueryTypes";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import UserPostAssets from "../UserPostAssets/index";
import UserPostHashTags from "../UserPostHashTags/index";
import UserPostLikes from "../UserPostsLikes";
import UserPostComments from "../UserPostComments";

const UserPostDetails = (props) => {
  const [userPostData, setUserPostData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [postId, setPostUserId] = useState(null);
  const [userCollegeIdValue, setCollegeIdValue] = useState(null);
  const [userSchoolIdValue, setSchoolIdValue] = useState(null);
  // const [schoolClassesTotalCount, setSchoolClassesTotalCount] = useState(0);
  const [userJourneys, setUserJourneys] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tabsActiveTab, setTabsActiveTab] = useState(
    props.match.params.tabName
  );
  const [listOfTabs, setListOfTabs] = useState(null);

  useEffect(() => {
    getUserPostData();
  }, []);

  const getUserPostData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getPost, {
          id: props.userPostId ?? props.match.params.userPostId,
        })
      );
      const { id, userName, schoolId } = result.data.getPost;

      // keep state
      setUserPostData(result);
      setSchoolIdValue(schoolId);
      setPostUserId(id);
      setUserName(userName);
    } catch (error) {
      toast.error(`Error while post data ${JSON.stringify(error)}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (userName) {
      setIsLoading(false);
      setListOfTabs(generateListOfTabs());
    }
  }, [userName]);

  const handleReturn = () => {
    props.history.push(`/userPosts`);
  };

  // generate the list of tabs to be shown
  // under post report we are hiding the cooments and likes section
  const generateListOfTabs = () => {
    let baseList = [
      {
        name: "General Info",
        eventKey: "GeneralInfo",
        content: userPostData && (
          <UserPostGeneralInfo
            providedModelData={userPostData}
            userPostId={props.userPostId ?? props.match.params.userPostId}
          />
        ),
      },
      {
        name: "Assets",
        eventKey: "PostAssets",
        content: <UserPostAssets postId={postId} />,
      },
      {
        name: "HashTags",
        eventKey: "PostHashTag",
        content: <UserPostHashTags postId={postId} />,
      },
    ];

    if (!props.userPostId) {
      baseList.push({
        name: "Likes",
        eventKey: "PostLike",
        content: (
          <UserPostLikes
            {...props}
            postId={postId}
            postLikesTotalCount={userPostData.data.getPost.numberOfLikes}
          />
        ),
      });
      baseList.push({
        name: "Comments",
        eventKey: "Comments",
        content: userPostData && (
          <UserPostComments
            {...props}
            postId={postId}
            postCommentsTotalCount={userPostData.data.getPost.numberOfComments}
          />
        ),
      });
    }

    return baseList;
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <TabsContainer
        header={`Post Details : ${postId}`}
        defaultActiveKey={tabsActiveTab}
        backButton={
          props.match.params.userPostId && (
            <ButtonInput
              label="Back to List"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          )
        }
        tabs={listOfTabs}
      />
    </PagePreLoad>
  );
};

export default UserPostDetails;
