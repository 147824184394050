import React from "react";
import { searchReports } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const PostReports = (props) => {
  return (
    <DataGrid
      {...props}
      header="Post Reports"
      gridObjectDataModel={[
        {
          name: "reportPostUserName",
          headerText: "Reported Post Owner",
          type: DataTypes.String,
        },
        {
          name: "isAcknowledged",
          headerText: "Is Acknowledged",
          type: DataTypes.Boolean,
        },
        {
          name: "reportByUserName",
          headerText: "Reported By",
          type: DataTypes.String,
        },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.PostReport}
      queryFunction={searchReports}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchReports"}
      queryFilters={{
        reportPostId: {
          exists: true,
        },
      }}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      baseRoute={"postReports"}
    />
  );
};

export default PostReports;
