import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [currentPassoword, setCurrentPassoword] = useState("");
  const [newPassoword, setNewPassoword] = useState("");
  const [confirmNewPassoword, setConfirmNewPassoword] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);

  const isFormValid = () => {
    const _errors = {};

    if (!currentPassoword)
      _errors.currentPassoword = "Current Passoword is required";
    if (!newPassoword) _errors.newPassoword = "New Passoword is required";
    else if (newPassoword.length < 8)
      _errors.newPassoword =
        "New Passoword must have length greater than or equal to 8";
    if (!confirmNewPassoword)
      _errors.confirmNewPassoword = "Confirm New Passoword is required";
    else if (confirmNewPassoword.length < 8)
      _errors.confirmNewPassoword =
        "Confirm New Passoword must have length greater than or equal to 8";

    // confirmpassword must be equal to new password
    if (newPassoword !== confirmNewPassoword)
      _errors.confirmNewPassoword =
        "Confirm New Passoword must match with New Password";

    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    await handleChangePassword();

    setFormSubmitInProgress(false);
  };

  const handleChangePassword = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, currentPassoword, newPassoword)
        .then((data) => {
          toast.success("Password had been changed successfully!");
          setCurrentPassoword("");
          setNewPassoword("");
          setConfirmNewPassoword("");
        })
        .catch((err) => {
          let errorMessage = `Error while changing password, ${err.message}`;
          handleGeneralError(errorMessage);
          console.log(err);
        });
    } catch (error) {
      let errorMessage = `Error while changing password, ${error.message}`;
      handleGeneralError(errorMessage);
      console.log(error);
    }
  };

  const handleGeneralError = (errorMessage) => {
    toast.error(errorMessage);
    setGeneralError(errorMessage);
  };

  return (
    <>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header="Change Password"
          submitButton={
            <ButtonInput
              label="Change Password"
              variant="success"
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"Changing Password..."}
            />
          }
        >
          {generalError && <Alert variant={"danger"}>{generalError}</Alert>}

          <TextInput
            type="password"
            id="currentPassoword"
            label="Current Passoword"
            placeholder="Enter your current Passoword"
            value={currentPassoword}
            onChange={(e) => setCurrentPassoword(e.target.value)}
            isRequired={true}
            error={errors.currentPassoword}
          />

          <TextInput
            type="password"
            id="newPassoword"
            label="New Passoword"
            placeholder="Enter your new Passoword"
            value={newPassoword}
            onChange={(e) => setNewPassoword(e.target.value)}
            isRequired={true}
            error={errors.newPassoword}
          />

          <TextInput
            type="password"
            id="confirmNewPassoword"
            label="Confirm New Passoword"
            placeholder="Confirm your new Passoword"
            value={confirmNewPassoword}
            onChange={(e) => setConfirmNewPassoword(e.target.value)}
            isRequired={true}
            error={errors.confirmNewPassoword}
          />
        </CardContainer>
      </Form>
    </>
  );
};

export default ChangePassword;
