import React from "react";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const AdminGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.adminId}
      modelName={"Admin"}
      listOfProperties={[
        {
          key: 1,
          attributeTitle: "UserName",
          attributeFetchFunction: (data) => {
            return data.UserAttributes.find((attr) => attr.Name === "email")
              .Value;
          },
          description: "Admin UserName",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "Username",
          attributeTitle: "Id",
          description: "Admin Id",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "Enabled",
          attributeTitle: "Is Enable",
          description: "Admin Enable Status",
          dataType: DataTypes.Boolean,
        },
        {
          key: 4,
          attributeName: "UserCreateDate",
          attributeTitle: "Created At",
          description: "Admin Created At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunctionName={"getAdmin"}
      providedModelData={props.providedModelData}
    />
  );
};

AdminGeneralInfo.propTypes = {
  adminId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

AdminGeneralInfo.defaultProps = {
  providedModelData: null,
};

export default AdminGeneralInfo;
