import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import * as BootstrapVariants from "../../../constants/Bootstrap/Variants";

const ButtonInput = (props) => {
  return (
    <Button
      variant={props.variant}
      type={props.type}
      style={props.style}
      className={props.className}
      onClick={props.onClick}
      disabled={props.formSubmitInProgress || props.disabled}
    >
      {props.formSubmitInProgress ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          {props.formSubmittingText}
        </>
      ) : (
        props.label
      )}
    </Button>
  );
};

ButtonInput.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  formSubmitInProgress: PropTypes.bool,
  formSubmittingText: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ButtonInput.defaultProps = {
  variant: BootstrapVariants.Primary,
  type: "submit",
  style: { marginTop: "0px" },
  label: "Submit",
  formSubmitInProgress: false,
  formSubmittingText: `Submitting...`,
  disabled: false
};

export default ButtonInput;
