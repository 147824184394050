import React from "react";
import BatchCreation from "../../../Common/Crud/BatchCreation";
import {
  getSchool,
  getSchoolCollege,
  listDomains,
  getClazzesByCollegeIdOrdered,
  getClazzesBySchoolIdOrdered,
} from "../../../../graphql/queries";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const DomainBatchCreate = (props) => {
  return (
    <BatchCreation
      listQuery={listDomains}
      listQueryName={"listDomains"}
      //   listQueryAttributes={}
      listQueryFilters={{
        deletedAt: {
          eq: null,
        },
      }}
      modelName={
        props.match.params.collegeId ? "College Domains" : "School Domains"
      }
      schemaModelName={ModelTypes.Domain}
      parentModelName={
        props.match.params.collegeId ? ModelTypes.College : ModelTypes.School
      }
      parentModelId={
        props.match.params.collegeId
          ? props.match.params.collegeId
          : props.match.params.schoolId
      }
      ancestorModelId={
        props.match.params.collegeId ? props.match.params.schoolId : null
      }
      parentModelQueryFunction={
        props.match.params.collegeId ? getSchoolCollege : getSchool
      }
      parentModelQueryFunctionName={
        props.match.params.collegeId ? "getSchoolCollege" : "getSchool"
      }
      returnMethod={() => {
        // since we can create a domain for college as well we need to check how we got here and go back there
        if (props.match.params.collegeId) {
          props.history.push(
            `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Domains`
          );
        } else {
          props.history.push(
            `/schools/details/${props.match.params.schoolId}/tab/Domains`
          );
        }
      }}
    />
  );
};

export default DomainBatchCreate;
