import React from "react";
import logo from "../../../Assets/Images/logo512.png";
import { Row, Col, Card, Form, Container } from "react-bootstrap";
import { SignIn } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import TextInput from "../../../components/Common/TextInput";
import ButtonInput from "../../../components/Common/ButtonInput";

toast.configure();

class SignInCustom extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.signIn = this.signIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleSuccessfulSignIn = this.handleSuccessfulSignIn.bind(this);
    this.state = {
      username: "",
      password: "",
      errors: {},
      formValidated: false,
      formSubmitInProgress: false,
    };
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  isFormValid() {
    const _errors = {};

    if (!this.state.username) _errors.username = "Username is required";
    if (!this.state.password) _errors.password = "Password is required";

    this.setState({ errors: _errors });
    this.setState({ formValidated: true });

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  }

  handleFormSubmission(event) {
    event.preventDefault();
    this.setState({ error: {} });
    this.setState({ formSubmitInProgress: true });
    if (!this.isFormValid()) {
      this.setState({ formSubmitInProgress: false });
      return;
    }
    this.signIn();
  }

  handleSuccessfulSignIn() {
    this.props.onStateChange("signedIn", {});
    this.setState({
      username: "",
      password: "",
      errors: {},
      error: "",
      formValidated: false,
    });
  }

  async signIn() {
    const username = this.state.username;
    const password = this.state.password;
    try {
      var signInResult = await Auth.signIn(username, password);

      // check if password change is required
      if (
        signInResult.challengeName &&
        signInResult.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        this.props.onStateChange("requireNewPassword", signInResult);
        this.setState({
          username: "",
          password: "",
          errors: {},
          error: "",
          formValidated: false,
        });
      } else {
        // custom logic in order to have only admin to login
        // provided allowes user groups by props
        if (!this.props.allowedUserGroups) {
          this.handleSuccessfulSignIn();
        } else {
          var signedInUserGroups =
            signInResult.signInUserSession.accessToken.payload[
              "cognito:groups"
            ];

          let isGroupMember = false;
          this.props.allowedUserGroups.map((group) => {
            if (signedInUserGroups.includes(group)) isGroupMember = true;
          });

          if (isGroupMember) {
            this.handleSuccessfulSignIn();
          } else {
            // throw error and sign user out
            await Auth.signOut();
            this.setState({
              error: `Login failed. Username/Password does not exist!`,
            });
            this.setState({ formSubmitInProgress: false });
          }
        }
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        this.props.updateUsername(username);
        await Auth.resendSignUp(username);
        this.props.onStateChange("confirmSignUp", {});
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        this.setState({ error: `Login failed. ${err.message}` });
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        this.setState({ error: `Login failed. ${err.message}` });
      } else {
        this.setState({ error: `An error has occurred. ${err.message}` });
        console.error(err);
      }
    }

    // if there is any error throw a toast error message
    if (this.state.error) {
      toast.error(this.state.error, {
        autoClose: 5000,
      });
    }
    this.setState({ formSubmitInProgress: false });
  }

  showComponent(theme) {
    return (
      <>
        <Container fluid>
          <Row
            style={{
              marginTop: "50px",
            }}
          >
            <Col xl={4} lg={3} md={2} xs={1}></Col>
            <Col xl={4} lg={6} md={8} xs={10}>
              <Row>
                <Col xs={2}></Col>
                <Col xs={8}>
                  <Card>
                    <Card.Img variant="top" src={logo} />
                    <Card.Body>
                      <Card.Title>Sign in to your account</Card.Title>

                      <Form
                        onSubmit={this.handleFormSubmission}
                        noValidate
                        validated={this.state.formValidated}
                      >
                        <TextInput
                          type="email"
                          id="username"
                          label="Username"
                          placeholder="Enter your username"
                          value={this.state.username}
                          onChange={this.handleInputChange}
                          isRequired={true}
                          error={this.state.errors.username}
                        />

                        <TextInput
                          type="password"
                          id="password"
                          label="Password"
                          placeholder="Enter your password"
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          isRequired={true}
                          error={this.state.errors.password}
                        />

                        <Form.Text className="text-muted">
                          Forgot your password?{" "}
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.props.onStateChange("forgotPassword")
                            }
                          >
                            Reset password
                          </a>
                        </Form.Text>

                        <ButtonInput
                          className="btn-block"
                          label="SIGN IN"
                          style={{ marginTop: "25px" }}
                          formSubmitInProgress={this.state.formSubmitInProgress}
                          formSubmittingText={"SIGNING IN..."}
                        />
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={2}></Col>
              </Row>
            </Col>
            <Col xl={4} lg={3} md={2} xs={1}></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SignInCustom;
