import React, { useState, useRef } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import CardContainer from "../../../components/Common/CardContainer";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import { ProgressBar, Table } from "react-bootstrap";
import IsChecked from "../../../components/Common/IsChecked";
import * as XLSX from "xlsx";
import ButtonInput from "../../../components/Common/ButtonInput";
import _ from "lodash";
import getObjectFromS3 from "../../Common/S3/Handlers/getObjectFromS3";
import * as BootstrapVariants from "../../../constants/Bootstrap/Variants";
import { listDomains, listSchools } from "../../../graphql/queries";
import {
  createSchool,
  deleteSchool,
  createDomain,
  deleteDomain,
  createClazz,
  deleteClazz,
  createSchoolCollege,
  deleteSchoolCollege,
} from "../../../graphql/mutations";
import CollegeNONE from "../../../graphql/Constants/Colleges/None";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import JSONViewer from "react-json-viewer";

const BatchUpload = (props) => {
  //#region States
  // default defined excel file at s3
  const truliSchoolsListName = "initialdata/TRULI_BATCH_UPLOAD_LIST.xlsx";

  // page preload number of actions
  const requiredActionsBeforePageIsLoaded = 0;
  let finishedActionsCount = 0;
  const [finishedActions, setFinishedActions] = useState(0);

  // state indicating the process had been started
  const [procedureStarted, setProcedureStarted] = useState(false);

  // state indicating and showing the over all errors
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // upload input disable state
  const [uploadInputDisable, setUploadInputDisable] = useState(false);
  const inputFileRef = useRef(null);

  // states required to render the Structure check process
  const [structureCheckProgressShow, setStructureCheckProgressShow] = useState(
    false
  );
  const [
    structureCheckProgressState,
    setStructureCheckProgressState,
  ] = useState(0);
  const [
    structureCheckProgressVariant,
    setStructureCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [structureCheckIsCheck, setStructureCheckIsCheck] = useState(null);

  // states required to render the Logical check process
  const [logicalCheckProgressShow, setLogicalCheckProgressShow] = useState(
    false
  );
  const [logicalCheckProgressState, setLogicalCheckProgressState] = useState(0);
  const [
    logicalCheckProgressVariant,
    setLogicalCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [logicalCheckIsCheck, setLogicalCheckIsCheck] = useState(null);

  // states required to check database existanses
  const [
    databaseExistsCheckProgressShow,
    setDatabaseExistsCheckProgressShow,
  ] = useState(false);
  const [
    databaseExistsCheckProgressState,
    setDatabaseExistsCheckProgressState,
  ] = useState(0);
  const [
    databaseExistsCheckProgressVariant,
    setDatabaseExistsCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [databaseExistsCheckIsCheck, setDatabaseExistsCheckIsCheck] = useState(
    null
  );

  // states required to render the Upload to S3 process
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState(0);
  const [uploadProgressStateVariant, setuploadProgressStateVariant] = useState(
    BootstrapVariants.Info
  );
  const [uploadProgressIsCheck, setUploadProgressIsCheck] = useState(null);

  // states required to create data at database
  const [
    databaseCreationCheckProgressShow,
    setDatabaseCreationCheckProgressShow,
  ] = useState(false);
  const [
    databaseCreationCheckProgressState,
    setDatabaseCreationCheckProgressState,
  ] = useState(0);
  const [
    databaseCreationCheckProgressVariant,
    setDatabaseCreationCheckProgressVariant,
  ] = useState(BootstrapVariants.Info);
  const [
    databaseCreationCheckIsCheck,
    setDatabaseCreationCheckIsCheck,
  ] = useState(null);

  // showing the json result
  const [jsonResult, setJsonResult] = useState(null);

  // defining the default values
  let numberOfRequiredStructuralActions = 0;
  let numberOfAccomplishedStructuralActions = 0;
  let numberOfRequiredLogicalActions = 0;
  let numberOfAccomplishedLogicalActions = 0;
  let numberOfRequiredDatabaseExistsActions = 0;
  let numberOfAccomplishedDatabaseExistsActions = 0;
  let numberOfRequiredDatabaseCreationActions = 0;
  let numberOfAccomplishedDatabaseCreationActions = 0;
  let overallStatus = true;
  let domainNamesArray = [];
  let schoolNamesArray = [];

  // defining the column Names for the excel file schema
  const COLUMN_SCHOOL_NAME = "A";
  const COLUMN_SCHOOL_NAME_ID = 0;
  const COLUMN_COLLEGE_NAME = "B";
  const COLUMN_COLLEGE_ID = 1;
  const COLUMN_DOMAIN_NAME = "C";
  const COLUMN_DOMAIN_NAME_ID = 2;
  const COLUMN_CLASS_NAME = "D";
  const COLUMN_CLASS_NAME_ID = 3;
  //#endregion

  //#region Build States
  const [isBuildCompleted, setIsBuildCompleted] = useState(false);
  //#endregion

  // define the number of actions required for structural checks
  const defineStructuralRequiredActions = (excelParsedData) => {
    // for checkIfThereIsAnyRowsWithoutSchoolName
    // for checkIfThereIsAnyRowsWithSchoolNameOnly
    numberOfRequiredStructuralActions += 2 * excelParsedData.length;
    numberOfRequiredStructuralActions += excelParsedData.length - 1; // convert to convertExcelTostructedObject
  };

  // increase the structure progress completion
  const increaseStructureProgress = () => {
    ++numberOfAccomplishedStructuralActions;
    setStructureCheckProgressState(
      (
        (numberOfAccomplishedStructuralActions /
          numberOfRequiredStructuralActions) *
        100
      ).toFixed(2)
    );
  };

  // define the number of actions required for logical checks
  const defineLogicalRequiredActions = (structuredObjectArray) => {
    // eachSchoolNeedsToHaveOneDomainOrClassIfItDoesntHaveAnyColleges
    // eachCollegesNeedsToHaveAtLeastOneDomainName
    // eachSchoolNeedsToHaveOneDomainOrClassIfItDoesntHaveAnyColleges
    numberOfRequiredLogicalActions += 4 * structuredObjectArray.length;
  };

  // increase the Logical progress completion
  const increaseLogicalProgress = () => {
    ++numberOfAccomplishedLogicalActions;
    setLogicalCheckProgressState(
      (
        (numberOfAccomplishedLogicalActions / numberOfRequiredLogicalActions) *
        100
      ).toFixed(2)
    );
  };

  // define the number of actions required for DatabaseExists checks
  const defineDatabaseExistsRequiredActions = (structuredObjectArray) => {
    numberOfRequiredDatabaseExistsActions +=
      schoolNamesArray.length + domainNamesArray.length;
  };

  // increase the DatabaseExists progress completion
  const increaseDatabaseExistsProgress = () => {
    ++numberOfAccomplishedDatabaseExistsActions;
    setDatabaseExistsCheckProgressState(
      (
        (numberOfAccomplishedDatabaseExistsActions /
          numberOfRequiredDatabaseExistsActions) *
        100
      ).toFixed(2)
    );
  };

  // define the number of actions required for Database creation
  const defineDatabaseCreationRequiredActions = (structuredObjectArray) => {
    for (let schoolIndex of structuredObjectArray) {
      // increase for each school
      ++numberOfRequiredDatabaseCreationActions;
      if (schoolIndex.domains && schoolIndex.domains.length > 0) {
        numberOfRequiredDatabaseCreationActions += schoolIndex.domains.length;
      }
      if (schoolIndex.classes && schoolIndex.classes.length > 0) {
        numberOfRequiredDatabaseCreationActions += schoolIndex.classes.length;
      }
      if (schoolIndex.colleges && schoolIndex.colleges.length > 0) {
        for (let schoolCollege of schoolIndex.colleges) {
          ++numberOfRequiredDatabaseCreationActions;
          if (schoolCollege.domains && schoolCollege.domains.length > 0) {
            numberOfRequiredDatabaseCreationActions +=
              schoolCollege.domains.length;
          }
          if (schoolCollege.classes && schoolCollege.classes.length > 0) {
            numberOfRequiredDatabaseCreationActions +=
              schoolCollege.classes.length;
          }
        }
      }
    }
  };

  // increase the Database Creation progress completion
  const increaseDatabaseCreationProgress = () => {
    ++numberOfAccomplishedDatabaseCreationActions;
    setDatabaseCreationCheckProgressState(
      (
        (numberOfAccomplishedDatabaseCreationActions /
          numberOfRequiredDatabaseCreationActions) *
        100
      ).toFixed(2)
    );
  };

  // restart the process
  const handleRestart = () => {
    setUploadInputDisable(false);
    setProcedureStarted(false);

    // empty input file
    inputFileRef.current.value = "";

    // clean the json result
    setJsonResult(null);

    // start the process
    // clean up
    setIsError(false);
    setErrorMessage("");
    setStructureCheckProgressShow(false);
    setStructureCheckProgressState(0);
    setStructureCheckProgressVariant("info");
    setStructureCheckIsCheck(null);

    // clean up
    setIsError(false);
    setErrorMessage("");
    setLogicalCheckProgressShow(false);
    setLogicalCheckProgressState(0);
    setLogicalCheckProgressVariant("info");
    setLogicalCheckIsCheck(null);

    // clean up
    setIsError(false);
    setErrorMessage("");
    setDatabaseExistsCheckProgressShow(false);
    setDatabaseExistsCheckProgressState(0);
    setDatabaseExistsCheckProgressVariant("info");
    setDatabaseExistsCheckIsCheck(null);

    // clean up
    setIsError(false);
    setErrorMessage("");
    setDatabaseCreationCheckProgressShow(false);
    setDatabaseCreationCheckProgressState(0);
    setDatabaseCreationCheckProgressVariant("info");
    setDatabaseCreationCheckIsCheck(null);

    //
    setUploadProgressShow(false);
    setUploadProgressState(0);
    setuploadProgressStateVariant("info");
    setUploadProgressIsCheck(null);

    //
    setIsBuildCompleted(false);
  };

  // event to start the process after providing the file on file input
  const onChange = async (e) => {
    // disable the input file
    setUploadInputDisable(true);

    // start
    setProcedureStarted(true);
    const file = e.target.files[0];
    // schema structure check
    let schemaCheckResult = await schemaCheck(file);
  };

  // upload excel file to s3
  const uploadExcelToS3 = (providedFile) => {
    setUploadProgressShow(true);
    Storage.put(`${truliSchoolsListName}`, providedFile, {
      contentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        setUploadProgressState((progress.loaded / progress.total) * 100);
      },
    })
      .then((result) => {
        console.log(result);
        setuploadProgressStateVariant("success");
        setUploadProgressIsCheck(true);
        // setIsUploaded(true);
        // getObjectFromS3(result.key);
      })
      .catch((err) => {
        console.log(err);
        setuploadProgressStateVariant("danger");
        setUploadProgressIsCheck(false);
      });
  };

  // getting all the required list of keys by parsing the excel file
  const parseListOfKeys = (arrayOfKeys) => {
    return {
      schoolNames: cellKeysAtColumn(COLUMN_SCHOOL_NAME, arrayOfKeys), // school names
      collegeNames: cellKeysAtColumn(COLUMN_COLLEGE_NAME, arrayOfKeys), // college names
      domainNames: cellKeysAtColumn(COLUMN_DOMAIN_NAME, arrayOfKeys), // domain names
      classNames: cellKeysAtColumn(COLUMN_CLASS_NAME, arrayOfKeys), // class names
    };
  };

  // getting list of keys at a specific column
  const cellKeysAtColumn = (columnName, arrayOfKeys) => {
    const firstCell = columnName + "1";
    return arrayOfKeys.filter(
      (key) => key.startsWith(columnName) && key !== firstCell
    );
  };

  // if there is a row without schoolname this is an error
  const checkIfThereIsAnyRowsWithoutSchoolName = (dataParse) => {
    // dataparse is an array of objects
    for (let index = 0; index < dataParse.length; ++index) {
      if (
        dataParse[index][COLUMN_COLLEGE_ID] ||
        dataParse[index][COLUMN_DOMAIN_NAME_ID] ||
        dataParse[index][COLUMN_CLASS_NAME_ID]
      ) {
        // at least one of the other 3 columns needs to values
        if (!dataParse[index][COLUMN_SCHOOL_NAME_ID]) {
          throw `Row ${index + 1} is missing the school name`;
        }
      }
      increaseStructureProgress();
    }
  };

  //   3. if there is a row with just schoolname this is an error
  const checkIfThereIsAnyRowsWithSchoolNameOnly = (dataParse) => {
    // dataparse is an array of objects
    for (let index = 0; index < dataParse.length; ++index) {
      if (dataParse[index][COLUMN_SCHOOL_NAME_ID]) {
        // at least one of the other 3 columns needs to values
        if (
          !dataParse[index][COLUMN_COLLEGE_ID] &&
          !dataParse[index][COLUMN_DOMAIN_NAME_ID] &&
          !dataParse[index][COLUMN_CLASS_NAME_ID]
        ) {
          throw `Row ${index + 1} is missing other information for school`;
        }
      }
      increaseStructureProgress();
    }
  };

  const tryAddClassOrDomain = (
    schoolOrCollgeTobeAddedTo,
    excelCellToCkeck,
    DomainOrClassPropertyName
  ) => {
    let ifAdded = false;
    if (excelCellToCkeck) {
      // trim the the white spaces
      excelCellToCkeck = excelCellToCkeck.toString().trim();

      // if after trim the length is bigger than 0 move on
      if (excelCellToCkeck.length > 0) {
        // in order to reduce the amount to tolower function call
        let compareTempValue = excelCellToCkeck.toLowerCase();

        // if the value is domain value, we are only interested in lower cases
        if (DomainOrClassPropertyName == "domains") {
          excelCellToCkeck = excelCellToCkeck.toLowerCase();
        }

        // check if the domain exists on the provided object
        if (schoolOrCollgeTobeAddedTo[DomainOrClassPropertyName]) {
          // check if we already have this value, if not add it
          if (
            !schoolOrCollgeTobeAddedTo[DomainOrClassPropertyName].find(
              (item) => item.toLowerCase() === compareTempValue
            )
          ) {
            schoolOrCollgeTobeAddedTo[DomainOrClassPropertyName].push(
              excelCellToCkeck
            );

            // check for later evaluation
            ifAdded = true;
          }
        } else {
          schoolOrCollgeTobeAddedTo[DomainOrClassPropertyName] = [
            excelCellToCkeck,
          ];

          // check for later evaluation
          ifAdded = true;
        }
      }
    }

    // add to list of general domain for later evaluation
    if (ifAdded && DomainOrClassPropertyName == "domains") {
      domainNamesArray.push(excelCellToCkeck); // for later evaluation
    }
  };

  // reads through the json object and coverts it ro an object based on our models
  const convertExcelTostructedObject = (dataParse, structedObjectArray) => {
    for (let index = 1; index < dataParse.length; ++index) {
      if (dataParse[index][COLUMN_SCHOOL_NAME_ID]) {
        // school
        // check if already have this school
        let schoolExistanceResult = structedObjectArray.find(
          (item) =>
            item.schoolName.toLowerCase() ===
            dataParse[index][COLUMN_SCHOOL_NAME_ID].toLowerCase()
        );
        let newSchoolObject = schoolExistanceResult;
        if (!schoolExistanceResult) {
          // we need to add the school
          newSchoolObject = {
            schoolName: dataParse[index][COLUMN_SCHOOL_NAME_ID].trim(),
          };
        }

        // check if we have values for college column
        if (dataParse[index][COLUMN_COLLEGE_ID]) {
          // if so, domain and class will belong to college
          let collegeExistanceResult = null;
          if (newSchoolObject.colleges) {
            collegeExistanceResult = newSchoolObject.colleges.find(
              (item) =>
                item.collegeName.toLowerCase() ===
                dataParse[index][COLUMN_COLLEGE_ID].toLowerCase()
            );
          }
          let newCollegeObject = collegeExistanceResult;
          if (!collegeExistanceResult) {
            newCollegeObject = {
              collegeName: dataParse[index][COLUMN_COLLEGE_ID].trim(),
            };
          }
          tryAddClassOrDomain(
            newCollegeObject,
            dataParse[index][COLUMN_DOMAIN_NAME_ID],
            "domains"
          );

          tryAddClassOrDomain(
            newCollegeObject,
            dataParse[index][COLUMN_CLASS_NAME_ID],
            "classes"
          );

          // add or update college fpr school
          if (!newSchoolObject.colleges) {
            newSchoolObject.colleges = [newCollegeObject];
          } else {
            if (!collegeExistanceResult)
              newSchoolObject.colleges.push(newCollegeObject);
          }
        } else {
          // domain belong to school if exists
          tryAddClassOrDomain(
            newSchoolObject,
            dataParse[index][COLUMN_DOMAIN_NAME_ID],
            "domains"
          );

          // class belongs to school iuf exists
          tryAddClassOrDomain(
            newSchoolObject,
            dataParse[index][COLUMN_CLASS_NAME_ID],
            "classes"
          );
        }
        if (!schoolExistanceResult) {
          // we need to add the school
          structedObjectArray.push(newSchoolObject);

          // keep for later evaluations
          schoolNamesArray.push(newSchoolObject.schoolName);
        }
      }
      increaseStructureProgress();
    }
  };

  // 4. each school needs to have at least one domain if it doesn't have any colleges
  // 7. each school needs to have at least one class if it doesn't have any colleges
  const eachSchoolNeedsToHaveOneDomainOrClassIfItDoesntHaveAnyColleges = (
    structedObjectArray,
    typeToCheck
  ) => {
    for (let index = 0; index < structedObjectArray.length; ++index) {
      // check if the there is any school without a college that doesn't have any domains
      if (!structedObjectArray[index].colleges) {
        if (!structedObjectArray[index][typeToCheck])
          throw `School ${structedObjectArray[index].schoolName} doesn't have any ${typeToCheck}. It will need at least one.`;
      }
      increaseLogicalProgress();
    }
  };

  // 5. each college needs to have at least one domain other wise we can list proper college classes
  // 8.
  const eachCollegesNeedsToHaveAtLeastOneDomainOrClassName = (
    structedObjectArray,
    typeToCheck
  ) => {
    for (let index = 0; index < structedObjectArray.length; ++index) {
      if (structedObjectArray[index].colleges) {
        for (
          let collegeIndex = 0;
          collegeIndex < structedObjectArray[index].colleges.length;
          ++collegeIndex
        ) {
          if (!structedObjectArray[index].colleges[collegeIndex][typeToCheck])
            throw `College ${structedObjectArray[index].colleges[collegeIndex].collegeName} from School ${structedObjectArray[index].schoolName} doesn't have any ${typeToCheck}. It will need at least one`;
        }
      }
      increaseLogicalProgress();
    }
  };

  // 6. there shouldn't be any duplications in domains names at all ( all domain names should be unique)
  const domainNameDuplicationCheck = () => {
    let duplicatesResults = findDuplicates(domainNamesArray);

    if (duplicatesResults && duplicatesResults.length > 0) {
      // create the error message
      let errorMessage = "";
      for (
        let domainItemIndex = 0;
        domainItemIndex < duplicatesResults.length;
        ++domainItemIndex
      ) {
        errorMessage += `We have a duplicated domain: ${duplicatesResults[domainItemIndex]}`;
        if (domainItemIndex !== duplicatesResults.length - 1) {
          errorMessage += " --- ";
        }
      }
      throw errorMessage;
    }
  };

  // logic to find duplicates in an array
  const findDuplicates = (providedArray) => {
    let groupped = _.groupBy(providedArray, function (n) {
      return n;
    });
    let result = _.uniq(
      _.flatten(
        _.filter(groupped, function (n) {
          return n.length > 1;
        })
      )
    );
    return result;
  };

  // finding the duplicates at db
  const checkDatabaseDuplicates = async (modelName, query, queryName) => {
    let listItemToCheck =
      modelName === "school" ? schoolNamesArray : domainNamesArray;
    let modelListFetchResult = await API.graphql(
      graphqlOperation(query, { limit: 10000 })
    );
    if (
      modelListFetchResult &&
      modelListFetchResult.data &&
      modelListFetchResult.data[queryName] &&
      modelListFetchResult.data[queryName].items &&
      modelListFetchResult.data[queryName].items.length > 0
    ) {
      for (let item of listItemToCheck) {
        if (
          modelListFetchResult.data[queryName].items.find(
            (dbItem) => dbItem.name.toLowerCase() === item.toLowerCase()
          )
        ) {
          throw `There is a ${modelName} name duplication, ${modelName} name: ${item}`;
        }
        increaseDatabaseExistsProgress();
      }
    } else {
      throw `Error at fetching schools list from db`;
    }
  };

  // school creation
  const handleCreateSchool = async (schoolName) => {
    try {
      const input = {
        name: schoolName,
        numberOfColleges: 0,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        totalSchoolUsersCount: 0,
        deletedAt: null,
      };
      let creationResult = await API.graphql(
        graphqlOperation(createSchool, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createSchool.id,
        message: `School created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating school, ${error}`,
      };
    }
  };

  // domain creation
  const handleDomainCreation = async (domainName, schoolId, collegeId) => {
    try {
      // initial required domain creation data
      let input = {
        name: domainName,
        deletedAt: null,
      };

      // check if we are building the domain for college or for school
      if (collegeId) {
        // this if for school college
        input = {
          ...input,
          schoolId: schoolId,
          domainCollegeId: collegeId,
        };
      } else {
        // this if for school
        // and we need to define the none college for collegeID
        input = {
          ...input,
          schoolId: schoolId,
          domainCollegeId: CollegeNONE.id,
        };
      }

      let creationResult = await API.graphql(
        graphqlOperation(createDomain, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createDomain.id,
        message: `Domain created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating domain, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  // class creation
  const handleClassCreation = async (className, order, schoolId, collegeId) => {
    try {
      // initial required class creation data
      let input = {
        name: className,
        type: ModelTypes.Clazz,
        order: order,
        deletedAt: null,
      };

      // check if we are building the class for college or for school
      if (collegeId) {
        // this if for school college
        input = {
          ...input,
          schoolId: schoolId,
          clazzCollegeId: collegeId,
        };
      } else {
        // this if for school
        input = { ...input, schoolId: schoolId };
      }

      let creationResult = await API.graphql(
        graphqlOperation(createClazz, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: creationResult.data.createClazz.id,
        message: `Class created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating class, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  const handleCollegeCreation = async (collegeName, schoolId) => {
    try {
      const input = {
        name: collegeName,
        schoolId: schoolId,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        deletedAt: null,
      };
      let collegeCreationResult = await API.graphql(
        graphqlOperation(createSchoolCollege, { input })
      );

      // increase the creation completion number
      increaseDatabaseCreationProgress();

      return {
        result: true,
        id: collegeCreationResult.data.createSchoolCollege.id,
        message: `College created successfully`,
      };
    } catch (error) {
      return {
        result: false,
        id: null,
        message: `Error creating college, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };

  // delete items
  const deleteItemById = async (itemToBeDeletedId, deleteMutation) => {
    let deletionResult = await API.graphql(
      graphqlOperation(deleteMutation, {
        input: {
          id: itemToBeDeletedId,
        },
      })
    );
  };

  // generating the structured
  const generateStructuredObjectAtDatabase = async (
    structuredObject,
    generatedObjects
  ) => {
    // loop through the object and create each item one by one and at the same time track what is being generated
    for (let itemToBeGenerated of structuredObject) {
      let generatedSchool = {
        schoolName: itemToBeGenerated.schoolName,
        colleges: [],
        classes: [],
        domains: [],
      };
      // generate the school
      let schoolCreationResult = await handleCreateSchool(
        itemToBeGenerated.schoolName
      );

      if (schoolCreationResult.result) {
        generatedSchool = {
          ...generatedSchool,
          id: schoolCreationResult.id,
        };
      } else {
        throw `Error at creationg school ${itemToBeGenerated.schoolName}`;
      }

      // if there is domains for the school generate them here
      if (itemToBeGenerated.domains) {
        for (let domainItem of itemToBeGenerated.domains) {
          // create each domain for school
          let schoolDomainCreationResult = await handleDomainCreation(
            domainItem,
            generatedSchool.id
          );

          // check the creation result
          if (schoolDomainCreationResult.result) {
            let newGeneratedDomain = {
              name: domainItem,
              id: schoolDomainCreationResult.id,
            };

            // add to the list of domain
            generatedSchool.domains.push(newGeneratedDomain);
          } else {
            generatedObjects.push(generatedSchool);
            throw `Error at creationg domain ${domainItem}`;
          }
        }
      }

      // if there is classes for school generate here
      if (itemToBeGenerated.classes) {
        for (
          let classIndex = 0;
          classIndex < itemToBeGenerated.classes.length;
          ++classIndex
        ) {
          // create each class for school
          let schoolClassCreationResult = await handleClassCreation(
            itemToBeGenerated.classes[classIndex],
            classIndex,
            generatedSchool.id
          );

          if (schoolClassCreationResult.result) {
            let newGeneratedClass = {
              name: itemToBeGenerated.classes[classIndex],
              id: schoolClassCreationResult.id,
            };
            // add to the list of classes
            generatedSchool.classes.push(newGeneratedClass);
          } else {
            generatedObjects.push(generatedSchool);
            throw `Error at creationg class ${itemToBeGenerated.classes[classIndex]}`;
          }
        }
      }

      // loop through the college of the school
      if (itemToBeGenerated.colleges) {
        for (let collegeItem of itemToBeGenerated.colleges) {
          // create college
          let collegeCreationResult = await handleCollegeCreation(
            collegeItem.collegeName,
            generatedSchool.id
          );

          if (collegeCreationResult.result) {
            let generatedCollege = {
              collegeName: collegeItem.collegeName,
              id: collegeCreationResult.id,
              classes: [],
              domains: [],
            };

            // if there is any domains generate here
            if (collegeItem.domains) {
              for (let collegeDomainItem of collegeItem.domains) {
                // create each domain for college
                let collegeDomainCreationResult = await handleDomainCreation(
                  collegeDomainItem,
                  generatedSchool.id,
                  generatedCollege.id
                );

                // check the creation result
                if (collegeDomainCreationResult.result) {
                  let newGeneratedDomain = {
                    name: collegeDomainItem,
                    id: collegeDomainCreationResult.id,
                  };
                  // add to the list of generated domains for college
                  generatedCollege.domains.push(newGeneratedDomain);
                } else {
                  generatedSchool.colleges.push(generatedCollege);
                  generatedObjects.push(generatedSchool);
                  throw `Error at creating domain ${collegeDomainItem}`;
                }
              }
            }

            // if there is any classes generate for college
            if (collegeItem.classes) {
              for (
                let collegeClassIndex = 0;
                collegeClassIndex < collegeItem.classes.length;
                ++collegeClassIndex
              ) {
                // create each domain for school
                let collegeClassCreationResult = await handleClassCreation(
                  collegeItem.classes[collegeClassIndex],
                  collegeClassIndex,
                  generatedSchool.id,
                  generatedCollege.id
                );

                // check the creation result
                if (collegeClassCreationResult.result) {
                  let newGeneratedClass = {
                    name: collegeItem.classes[collegeClassIndex],
                    id: collegeClassCreationResult.id,
                  };
                  // add to the list of generated domains for college
                  generatedCollege.classes.push(newGeneratedClass);
                } else {
                  generatedSchool.colleges.push(generatedCollege);
                  generatedObjects.push(generatedSchool);
                  throw `Error at creating domain ${collegeItem.classes[collegeClassIndex]}`;
                }
              }
            }
            // add college
            generatedSchool.colleges.push(generatedCollege);
          } else {
            generatedObjects.push(generatedSchool);
            throw `Error at creationg college ${collegeItem.collegeName}`;
          }
        }
      }

      // add generated school
      generatedObjects.push(generatedSchool);
    }
  };

  // revert the generated objects when creation goes wrong
  const revertGeneratedObjectOnCreationFailure = async (generatedObjects) => {
    for (let generatedItemToBeDeleted of generatedObjects) {
      // if school has domains delete them
      if (
        generatedItemToBeDeleted.domains &&
        generatedItemToBeDeleted.domains.length > 0
      ) {
        for (let generatedSchoolDomainItem of generatedItemToBeDeleted.domains) {
          await deleteItemById(generatedSchoolDomainItem.id, deleteDomain);
        }
      }

      // if school has classes, delete them
      if (
        generatedItemToBeDeleted.classes &&
        generatedItemToBeDeleted.classes.length > 0
      ) {
        for (let generatedSchoolClassItem of generatedItemToBeDeleted.classes) {
          await deleteItemById(generatedSchoolClassItem.id, deleteClazz);
        }
      }

      // if school has colleges loop through them
      if (
        generatedItemToBeDeleted.colleges &&
        generatedItemToBeDeleted.colleges.length > 0
      ) {
        for (let generatedCollegeItem of generatedItemToBeDeleted.colleges) {
          // if college has classes delete them
          if (
            generatedCollegeItem.classes &&
            generatedCollegeItem.classes.length > 0
          ) {
            for (let generatedCollegeClassItem of generatedCollegeItem.classes) {
              await deleteItemById(generatedCollegeClassItem.id, deleteClazz);
            }
          }
          // if college has domain delete them
          if (
            generatedCollegeItem.domains &&
            generatedCollegeItem.domains.length > 0
          ) {
            for (let generatedCollegeDomainItem of generatedCollegeItem.domains) {
              await deleteItemById(generatedCollegeDomainItem.id, deleteDomain);
            }
          }

          // delete college
          await deleteItemById(generatedCollegeItem.id, deleteSchoolCollege);
        }
      }

      // delete school
      await deleteItemById(generatedItemToBeDeleted.id, deleteSchool);
    }
  };

  // schema structure check
  const schemaCheck = async (providedFile) => {
    // read excel file line by line and check if the structure is correct
    setStructureCheckProgressShow(true);
    setLogicalCheckProgressShow(true);
    setDatabaseExistsCheckProgressShow(true);
    setDatabaseCreationCheckProgressShow(true);

    let overallErrorMessage = "";

    try {
      var reader = new FileReader();
      reader.onload = async (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

        // rules,
        // 1. if there is a row with no value we can skip
        // 2. if there is a row without schoolname this is an error
        // 3. if there is a row with just schoolname this is an error
        // 4. each school needs to have at least one domain if it doesn't have any colleges
        // 5. each college needs to have at least one domain other wise we can not list proper college classes
        // 6. there shouldn't be any duplications in domains names at all ( all domain names should be unique)
        // 7. each school needs to have at least one class if it doesn't have any colleges
        // 8. each college needs to have at least one class
        // 9. check with the backend to see if we have existing duplicated school names
        // 10. check with the backend to see if we have existing duplicated domain names
        //

        // defined the amount of actions we need to perform to validate the structure and generate the objects and etc ...
        defineStructuralRequiredActions(dataParse);

        let structuredObjectArray = [];
        try {
          checkIfThereIsAnyRowsWithoutSchoolName(dataParse);
          checkIfThereIsAnyRowsWithSchoolNameOnly(dataParse);
          convertExcelTostructedObject(dataParse, structuredObjectArray);

          // if everything on structural check was ok so far
          setStructureCheckIsCheck(true);
          setStructureCheckProgressVariant("success");
          // setJsonResult(JSON.stringify(structuredObjectArray, null, 2));
          setJsonResult(structuredObjectArray);
        } catch (error) {
          overallStatus = false;
          setStructureCheckIsCheck(false);
          setStructureCheckProgressVariant("danger");
          setIsError(true);
          setErrorMessage(error ? error.toString() : "Unknown Error");
        }

        if (overallStatus) {
          // define the amoun of actions needed for logical checks
          defineLogicalRequiredActions(structuredObjectArray);

          // logical check
          try {
            eachSchoolNeedsToHaveOneDomainOrClassIfItDoesntHaveAnyColleges(
              structuredObjectArray,
              "domains"
            );
            eachCollegesNeedsToHaveAtLeastOneDomainOrClassName(
              structuredObjectArray,
              "domains"
            );
            domainNameDuplicationCheck();
            eachSchoolNeedsToHaveOneDomainOrClassIfItDoesntHaveAnyColleges(
              structuredObjectArray,
              "classes"
            );
            eachCollegesNeedsToHaveAtLeastOneDomainOrClassName(
              structuredObjectArray,
              "classes"
            );

            // if everything on structural check was ok so far
            setLogicalCheckIsCheck(true);
            setLogicalCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setLogicalCheckIsCheck(false);
            setLogicalCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(error ? error : "Unknown Error");
          }
        }

        if (overallStatus) {
          // define required actions
          defineDatabaseExistsRequiredActions();

          // Database check for duplications
          try {
            await checkDatabaseDuplicates("school", listSchools, "listSchools");
            await checkDatabaseDuplicates("domain", listDomains, "listDomains");

            // if everything on structural check was ok so far
            setDatabaseExistsCheckIsCheck(true);
            setDatabaseExistsCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setDatabaseExistsCheckIsCheck(false);
            setDatabaseExistsCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(
              error ? error.toString() : `Error at database check`
            );
          }
        }

        // upload no matter what to have it as debuigging refrence
        // upload to s3 for refrence
        uploadExcelToS3(providedFile);

        // check all the overall status and at this point we need to start creating items at the database
        // creation process
        if (overallStatus) {
          // create at the database
          let generatedObjects = [];
          try {
            defineDatabaseCreationRequiredActions(structuredObjectArray);

            await generateStructuredObjectAtDatabase(
              structuredObjectArray,
              generatedObjects
            );

            setIsBuildCompleted(true);

            // if everything on structural check was ok so far
            setDatabaseCreationCheckIsCheck(true);
            setDatabaseCreationCheckProgressVariant("success");
          } catch (error) {
            overallStatus = false;
            setDatabaseCreationCheckIsCheck(false);
            setDatabaseCreationCheckProgressVariant("danger");
            setIsError(true);
            setErrorMessage(
              error
                ? error.toString()
                : `Error at generating items at the database`
            );

            // revert ( delete ) the generated items
            await revertGeneratedObjectOnCreationFailure(generatedObjects);
          }
        }
      };
      reader.readAsBinaryString(providedFile);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error);
    }
  };

  const handleExampleDownload = async () => {
    let objectFromS3Result = await getObjectFromS3(truliSchoolsListName);
    if (objectFromS3Result.result) {
      let win = window.open(objectFromS3Result.url, "_blank");
      win.focus();
    } else {
      alert("Something went wrong");
    }
  };

  return (
    <PagePreLoad
      isLoading={finishedActions < requiredActionsBeforePageIsLoaded}
    >
      <CardContainer
        header="Schools/Colleges/Domains/Classes Initial"
        title="Populate Schools/Colleges/Domains/Classes"
        text="Provide the Excel file based on the provided template."
        submitButton={
          <ButtonInput label="Restart" onClick={handleRestart}></ButtonInput>
        }
        // extraButtons={
        //   <ButtonInput
        //     label="Example"
        //     variant={BootstrapVariants.Info}
        //     onClick={() => handleExampleDownload()}
        //   ></ButtonInput>
        // }
      >
        <>
          <p>
            <ul>
              <li>
                <b>
                  Batch Upload is a one time functionality only, it should be
                  used for creation of new items.
                </b>
              </li>
              <li>
                If there is a row with no value in any columns it will be
                skipped.
              </li>
              <li>
                If there is a row without schoolname this will result to an
                error.
              </li>
              <li>
                If there is a row with just schoolname this will result to an
                error.
              </li>
              <li>
                Each school needs to have at least one domain if it doesn't have
                any colleges.
              </li>
              <li>
                Each college needs to have at least one domain otherwise we can
                not list proper college classes.
              </li>
              <li>
                There shouldn't be any duplications in domain names at all (all
                domain names should be unique).
              </li>
              <li>
                Each school needs to have at least one class if it doesn't have
                any colleges.
              </li>
              <li>Each college needs to have at least one class.</li>
              <li>
                Existing school names at database will result to an error.
              </li>
              <li>
                Existing domain names at database will result to an error.
              </li>
            </ul>
          </p>

          <CardContainer header="Upload file" style={{ marginBottom: "20px" }}>
            <input
              type="file"
              accept=".xlsx"
              onChange={(evt) => onChange(evt)}
              disabled={uploadInputDisable}
              ref={inputFileRef}
            />
          </CardContainer>

          {isBuildCompleted && (
            <CardContainer
              header="Build Completed"
              title="Populating data at Database is completed"
              text="Provided data is generated"
              style={{
                marginBottom: "20px",
                backgroundColor: "green",
                color: "white",
              }}
            ></CardContainer>
          )}

          {isError && (
            <CardContainer
              header="Error!"
              title="Some errors have occurred!"
              text={errorMessage}
              style={{
                marginBottom: "20px",
                backgroundColor: "red",
                color: "white",
              }}
            ></CardContainer>
          )}

          {procedureStarted && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Process</th>
                  <th style={{ width: "70%" }}>Progress</th>
                  <th style={{ width: "10%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Structure Check</td>
                  <td>
                    {structureCheckProgressShow && (
                      <ProgressBar
                        now={structureCheckProgressState}
                        label={`${structureCheckProgressState}%`}
                        variant={structureCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {structureCheckIsCheck && (
                      <IsChecked status={structureCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Logical Check</td>
                  <td>
                    {logicalCheckProgressShow && (
                      <ProgressBar
                        now={logicalCheckProgressState}
                        label={`${logicalCheckProgressState}%`}
                        variant={logicalCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {logicalCheckIsCheck && (
                      <IsChecked status={logicalCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Database Existence Check</td>
                  <td>
                    {databaseExistsCheckProgressShow && (
                      <ProgressBar
                        now={databaseExistsCheckProgressState}
                        label={`${databaseExistsCheckProgressState}%`}
                        variant={databaseExistsCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {databaseExistsCheckIsCheck && (
                      <IsChecked status={databaseExistsCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Upload</td>
                  <td>
                    {uploadProgressShow && (
                      <ProgressBar
                        now={uploadProgressState}
                        label={`${uploadProgressState}%`}
                        variant={uploadProgressStateVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {uploadProgressIsCheck && (
                      <IsChecked status={uploadProgressIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Database Creation</td>
                  <td>
                    {databaseCreationCheckProgressShow && (
                      <ProgressBar
                        now={databaseCreationCheckProgressState}
                        label={`${databaseCreationCheckProgressState}%`}
                        variant={databaseCreationCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {databaseCreationCheckIsCheck && (
                      <IsChecked status={databaseCreationCheckIsCheck} />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}

          {/* {jsonResult && (
            <pre>
              <code>{jsonResult}</code>
            </pre>
          )} */}

          {jsonResult && <JSONViewer json={jsonResult} />}
        </>
      </CardContainer>
    </PagePreLoad>
  );
};

export default BatchUpload;
