import { API, graphqlOperation } from "aws-amplify";
import { getModelAggregations } from "../../queries";

export const getModelAggregationsByType = async (type) => {
  // const filter = {
  //   modelType: {
  //     eq: type,
  //   },
  // };
  try {
    return await API.graphql(graphqlOperation(getModelAggregations, { modelType: type  }));
  } catch (error) {
    return error;
  }
};
