import PasswordValidation from "./PasswordValidation";

const passwordChangeHandler = (
  event,
  setPasswordState,
  setActionButtonDisableStatus,
  setErrorsState,
  currentErrors
) => {
  const _errors = { ...currentErrors };

  // empty existing password errors
  if (_errors.password) _errors.password = "";

  let providedPassword = event.target.value;
  setPasswordState(providedPassword);
  // check the name validation
  let validationResult = PasswordValidation(providedPassword);
  // check the result
  if (!validationResult.result) {
    _errors.password = validationResult.message;
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(true);
  } else {
    if (setActionButtonDisableStatus) setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);
};

export default passwordChangeHandler;
