import orderVerification from "./orderVerification";

const orderChangeHandler = (
  event,
  modelName,
  setOrderState,
  setActionButtonDisableStatus,
  setErrorsState
) => {
  const _errors = {};
  let providedOrder = event.target.value;
  setOrderState(providedOrder);
  // check the Order validation
  let validationResult = orderVerification(providedOrder, modelName);
  // check the result
  if (!validationResult.result) {
    _errors.order = validationResult.message;
    setActionButtonDisableStatus(true);
  } else {
    setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);
};

export default orderChangeHandler;
