import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import CardContainer from "../CardContainer";
import PropTypes from "prop-types";

const TabsContainer = (props) => {
  return (
    <CardContainer header={props.header} backButton={props.backButton}>
      <Tabs
        defaultActiveKey={props.defaultActiveKey}
        id={props.id}
        style={{ borderBottom: "1px solid #dee2e6" }}
      >
        {props.tabs &&
          props.tabs.map((item) => (
            <Tab eventKey={item.eventKey} title={item.name} key={item.name}>
              <div style={{ paddingTop: "40px" }}>{item.content}</div>
            </Tab>
          ))}
      </Tabs>
    </CardContainer>
  );
};

TabsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  defaultActiveKey: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  backButton: PropTypes.object,
};

TabsContainer.defaultProps = {
  id: "uncontrolledTab",
  header: "Header",
};

export default TabsContainer;
