import React, { useState, useEffect } from "react";
import PostReportGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import UserDetails from "../../Users/Details";
import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { getReport } from "../../../../graphql/queries";
import { updateReport } from "../../../../graphql/mutations";
import { Form, Alert } from "react-bootstrap";
import UserPostDetails from "../../UserPosts/Details";

const PostReportDetails = (props) => {
  const [reportedPostId, setReportedPostId] = useState(null);
  const [postReportData, setPostReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [generalError, setGeneralError] = useState("");

  useEffect(() => {
    getPostReportData();
  }, []);

  const getPostReportData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getReport, { id: props.match.params.postReportId })
      );

      const { reportPostId } = result.data.getReport;

      // keep state
      setPostReportData(result);
      setReportedPostId(reportPostId);
    } catch (error) {
      toast.error(
        `Error while fetching user report data ${JSON.stringify(error)}`
      );
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (reportedPostId) setIsLoading(false);
  }, [reportedPostId]);

  const handleReturn = () => {
    props.history.push(`/postReports`);
  };

  const handleProfaneRedirect = () => {
    props.history.push(
      `/postReports/${props.match.params.postReportId}/userPosts/profane/${reportedPostId}`
    );
  };

  const handleDeleteRedirect = () => {
    props.history.push(
      `/postReports/${props.match.params.postReportId}/userPosts/delete/${reportedPostId}`
    );
  };

  const markAsAcknowledged = async (event) => {
    event.preventDefault();
    setFormSubmitInProgress(true);
    try {
      let input = {
        id: props.match.params.postReportId,
        isAcknowledged: true,
      };
      let marAsAcknowledgedResult = await API.graphql(
        graphqlOperation(updateReport, { input })
      );

      setTimeout(function () {
        toast.success(`Post Report marked as Acknowledged`);
        handleReturn();
      }, GlobalSubmitUIDelay.DefaultDelay);
    } catch (error) {
      let errorMessage = `Error while updateing post report, ${JSON.stringify(
        error,
        null,
        2
      )}`;
      toast.error(errorMessage);
      setGeneralError(errorMessage);
      setFormSubmitInProgress(false);
    }
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <Form onSubmit={markAsAcknowledged} noValidate>
        <CardContainer
          header={"Post Report Details"}
          backButton={
            <ButtonInput
              label="Back to List"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
          submitButton={
            <ButtonInput
              label="Mark as Acknowledge"
              variant={BootstrapVariants.Success}
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}
          <PostReportGeneralInfo
            providedModelData={postReportData}
            userReportId={props.match.params.postReportId}
          />
        </CardContainer>
      </Form>
      <CardContainer
        header={"Reported Post"}
        backButton={
          <ButtonInput
            label="Back to List"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => handleReturn()}
          />
        }
        extraButtons={
          <>
            <ButtonInput
              label="Mark As Profane"
              variant={BootstrapVariants.Dark}
              onClick={() => handleProfaneRedirect()}
            />
            <ButtonInput
              label="Delete Post"
              style={{ marginLeft: "5px" }}
              variant={BootstrapVariants.Danger}
              onClick={() => handleDeleteRedirect()}
            />
          </>
        }
      >
        <UserPostDetails {...props} userPostId={reportedPostId} />
      </CardContainer>
    </PagePreLoad>
  );
};

export default PostReportDetails;
