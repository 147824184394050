import { API, graphqlOperation } from "aws-amplify";
import { updateSystemSettings } from "../../mutations";

export const updateSystemSettingsById = async (id, content) => {
    const input = {
      id,
      content,
    };
    try {
      return await API.graphql(graphqlOperation(updateSystemSettings, { input }));
    } catch (error) {
      return error;
    }
  };