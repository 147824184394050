import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const TextInput = (props) => {
  let wrapperClass = "form-group";
  if (props.error.length > 0) {
    wrapperClass += " has-error";
  }
  return (
    <Form.Group className={wrapperClass}>
      <Form.Label>
        <b>
          {props.label} {props.isRequired && "*"}
        </b>
      </Form.Label>
      <Form.Control
        type={props.type}
        id={props.id}
        key={props.id}
        name={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        // required={props.isRequired ? false : props.isRequired}
        required
        isInvalid={props.error.length > 0}
        isValid={props.value.length !== 0 && props.error.length == 0}
        disabled={props.disabled}
      />
      {props.error ? (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {props.error}
        </Form.Control.Feedback>
      ) : (
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

TextInput.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  error: "",
  isRequired: false,
  disabled: false,
};

export default TextInput;
