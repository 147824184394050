import React from "react";
import JourneyTypeGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateJourneyType } from "../../../../graphql/mutations";
import { getJourneyType } from "../../../../graphql/queries";
import * as JourneyTypesConstants from "../../../../graphql/Constants/JourneyTypes/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const JourneyTypeDelete = (props) => {
  const deleteOperationalValidation = async (journeyTypeId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired journeyType
      let tobeDeleted = await API.graphql(
        graphqlOperation(getJourneyType, { id: journeyTypeId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching journeyType data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Journey Type"
        deleteDescription="journey type will be deleted. Deleting a journey Type will have presentational affect on existing relationships."
        returnRoute="/journeyTypes"
        generalInfoComponent={
          <JourneyTypeGeneralInfo
            journeyTypeId={props.match.params.journeyTypeId}
          />
        }
        mutationMethod={updateJourneyType}
        mutationInput={{
          id: props.match.params.journeyTypeId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.journeyTypeId)
        }
      />
    </>
  );
};

export default JourneyTypeDelete;
