export const AB = {
  id: "f015a593-529b-4446-85b4-751bd680294f",
  name: "AB",
  order: 10,
};
export const SB = {
  id: "90237bbe-1350-4f53-bea7-f15c63d4c692",
  name: "SB",
  order: 20,
};
export const BA = {
  id: "7575fd30-4639-4809-b536-ad3aea7fc16a",
  name: "BA",
  order: 30,
};
export const BS = {
  id: "ad690502-4866-4534-92ef-f74937a4800e",
  name: "BS",
  order: 40,
};
export const BBA = {
  id: "b7754855-e429-40d4-a6cf-978332710b75",
  name: "BBA",
  order: 50,
};
export const BFA = {
  id: "802138f8-6101-4325-8463-5b6ad9b2028e",
  name: "BFA",
  order: 60,
};
export const BM = {
  id: "c1c1bb7b-624f-4642-b9da-6d89fef9e1c2",
  name: "BM",
  order: 70,
};
export const AM = {
  id: "d9535fae-a8ba-4dd1-b72c-fa7b2abffd40",
  name: "AM",
  order: 80,
};
export const SM = {
  id: "c1adb8d8-8cfc-4d92-ad2c-3f187d33e31a",
  name: "SM",
  order: 90,
};
export const MA = {
  id: "3e4cc390-2807-4fa4-9760-6edc600feda0",
  name: "MA",
  order: 100,
};
export const MBA = {
  id: "7dc6fd79-52b9-4a90-85c2-861bc67deee8",
  name: "MBA",
  order: 110,
};
export const MDE = {
  id: "3dccb1c9-1de4-4490-8b5c-8c9acb9436ab",
  name: "MDE",
  order: 120,
};
export const ME = {
  id: "ac060abc-ed1b-44ec-838a-394bb6a1318d",
  name: "ME",
  order: 130,
};
export const MEng = {
  id: "3bd54f1c-5c4f-436c-b38c-28cb94aacd39",
  name: "MEng",
  order: 140,
};
export const MFA = {
  id: "723b592f-64f6-489d-8449-32d981d31dc5",
  name: "MFA",
  order: 150,
};
export const MM = {
  id: "20442f82-b136-48c1-a8ea-bb39416dac84",
  name: "MM",
  order: 160,
};
export const MS = {
  id: "9342780b-2747-4963-944b-dabae0960f34",
  name: "MS",
  order: 170,
};
export const DDS = {
  id: "13711ffe-6b8e-47fe-a212-78db029390a1",
  name: "DDS",
  order: 180,
};
export const DMA = {
  id: "76fe236a-e6c5-4964-a905-d0965f0529e1",
  name: "DMA",
  order: 190,
};
export const EdD = {
  id: "de840247-3e09-4d73-a772-8b3199837b43",
  name: "EdD",
  order: 200,
};
export const EdM = {
  id: "6e87eb31-fddc-4371-833c-aed6b919a8b7",
  name: "EdM",
  order: 210,
};
export const MD = {
  id: "59a78544-cc2d-4c7a-a873-5ceba2234cfd",
  name: "MD",
  order: 220,
};
export const PhD = {
  id: "aa31324f-d794-469a-a439-2f9ca792b057",
  name: "PhD",
  order: 230,
};
export const JD = {
  id: "f23c18f5-fafa-43fd-a779-954a12c4a84f",
  name: "JD",
  order: 240,
};
export const Other = {
  id: "02ce8e62-3cb0-497b-871f-d6c3780d93e1",
  name: "Other",
  order: 250,
};

// // test added value
// export const Fred = {
//   id: "2f66f47c-614f-4c77-9e83-e71d0a646b38",
//   name: "Fred",
// };

export const AllTypes = [
  AB,
  SB,
  BA,
  BS,
  BBA,
  BFA,
  BM,
  AM,
  SM,
  MA,
  MBA,
  MDE,
  ME,
  MEng,
  MFA,
  MM,
  MS,
  DDS,
  DMA,
  EdD,
  EdM,
  MD,
  PhD,
  JD,
  Other,
  // Fred,
];
