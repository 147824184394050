export const Female = {
  id: "f29ed8c1-aecb-44fb-bb80-e041ff458f24",
  name: "Female",
  order: 10,
};
export const Male = {
  id: "d63d42d0-9fe1-46b1-934f-8469e00a58ea",
  name: "Male",
  order: 20,
};
export const NonBinary = {
  id: "41bf5b93-64dd-486f-8184-ae726fd5057b",
  name: "Non-binary",
  order: 30,
};
export const Other = {
  id: "2c76388a-502a-4535-a0d1-7c71fc9f77a0",
  name: "Other",
  order: 40,
};
export const IPreferNotToAay = {
  id: "fba75d1c-476c-459f-a843-4919f19579a5",
  name: "I prefer not to say",
  order: 50,
};

export const AllTypes = [Female, Male, NonBinary, Other, IPreferNotToAay];
