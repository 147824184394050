import emailValidator from "email-validator";

const EmailValidation = (emailAddress) => {
  let result = { result: true, message: "" };
  if (!emailAddress || emailAddress.length === 0) {
    result.result = false;
    result.message = "Email is required";
  } else {
    if (!emailValidator.validate(emailAddress)) result.result = false;
    result.message =
      "Email is not in a valid format, acceptable format is: test@example.com";
  }
  return result;
};

export default EmailValidation;
