import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getSkill } from "../../../../graphql/queries";
import { updateSkill } from "../../../../graphql/mutations";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import * as SkillsConstants from "../../../../graphql/Constants/Skills/Types";
import nameChangeHandler from "../../../Common/Crud/formHandlers/nameChangeHandler";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
import nameOnlyFormsValidationHandler from "../../../Common/Crud/formHandlers/nameOnlyFormsValidationHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";

const SkillEdit = (props) => {
  // states
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [updateButtonDisableStatus, setUpdateButtonDisableStatus] = useState(
    false
  );

  //#region update Skill business logic
  const handleUpdateSkill = async () => {
    try {
      // initial required Skill update data
      let input = {
        id: props.match.params.skillId,
        name: name.trim(),
        order: order
      };

      await API.graphql(graphqlOperation(updateSkill, { input }));
      return { result: true, message: `Skill updated successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error updating skill, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };
  //#endregion

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Edit}
      returnHandler={() => props.history.push(`/skills`)}
      modelName="Skill"
      getModelMethod={async () => {
        let result = await API.graphql(
          graphqlOperation(getSkill, { id: props.match.params.skillId })
        );
        if (result) {
          setName(result.data.getSkill.name);
          setOrder(result.data.getSkill.order);
        }
        return result.data.getSkill;
      }}
      // createOrUpdateDisableMethod={(modelData) => {
      //   if (modelData.id === SkillsConstants.Other.id) return true;
      //   return false;
      // }}
      // createOrUpdateDisabledActions={() => {
      //   setNameInputTextDisable(true);
      // }}
      formSubmissionHandler={handleUpdateSkill}
      formValidationHandler={() =>
        nameOnlyFormsValidationHandler(
          name,
          "Skill",
          false, // we need to exclude the OTHER
          setUpdateButtonDisableStatus,
          setErrors
        )
      }
      setErrorsState={() => setErrors}
      actionButtonDisabledState={updateButtonDisableStatus}
    >
      <TextInput
        type="name"
        id="name"
        label="Skill Name"
        placeholder="Enter a Skill name"
        value={name}
        onChange={(e) =>
          nameChangeHandler(
            e,
            "Skill",
            false, // we need to exclude the OTHER,
            setName,
            setUpdateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.name}
        disabled={inputFieldsDisable}
      />
      <TextInput
        type="number"
        id="order"
        label="Skill Order"
        placeholder="Enter a Skill Order"
        value={order}
        onChange={(e) =>
          orderChangeHandler(
            e,
            "Skill",
            setOrder,
            setUpdateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.order}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default SkillEdit;
