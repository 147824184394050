const commandClickHandler = (
  args,
  props,
  grid,
  baseRoute,
  detailsActiveTab
) => {
  if (grid && args.commandColumn.type === "Details") {
    let baseDetailsRoute = `/${baseRoute}/details/${args.rowData.id}`;
    // if default active tab is defined
    if (detailsActiveTab) {
      baseDetailsRoute = `${baseDetailsRoute}/tab/${detailsActiveTab}`;
    }
    props.history.push(baseDetailsRoute);
  }
  if (grid && args.commandColumn.type === "Edit") {
    props.history.push(`/${baseRoute}/edit/${args.rowData.id}`);
  }
  if (grid && args.commandColumn.type === "Block") {
    let baseBlockRoute = `/${baseRoute}/block/${args.rowData.id}`;
    // if default active tab is defined
    if (detailsActiveTab) {
      baseBlockRoute = `${baseBlockRoute}/tab/${detailsActiveTab}`;
    }
    props.history.push(baseBlockRoute);
  }
  if (grid && args.commandColumn.type === "Profane") {
    let baseBlockRoute = `/${baseRoute}/profane/${args.rowData.id}`;
    // if default active tab is defined
    if (detailsActiveTab) {
      baseBlockRoute = `${baseBlockRoute}/tab/${detailsActiveTab}`;
    }
    props.history.push(baseBlockRoute);
  }
  if (grid && args.commandColumn.type === "Delete") {
    let baseDeleteRoute = `/${baseRoute}/delete/${args.rowData.id}`;
    // if default active tab is defined
    if (detailsActiveTab) {
      baseDeleteRoute = `${baseDeleteRoute}/tab/${detailsActiveTab}`;
    }
    props.history.push(baseDeleteRoute);
  }
};

export default commandClickHandler;
