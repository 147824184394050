import React from "react";
import { searchPointSystems } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const PointSystems = (props) => {
  return (
    <DataGrid
      {...props}
      header="Points"
      gridObjectDataModel={[
        { name: "pointType", headerText: "Point Type", type: DataTypes.String },
        { name: "point", headerText: "point", type: DataTypes.String },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.PointSystem}
      queryFunction={searchPointSystems}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchPointSystems"}
      hasCreate={false}
      hasDetails={true}
      hasEdit={true}
      hasDelete={false}
      baseRoute={"pointSystems"}
    />
  );
};

export default PointSystems;
