import nameVerification from "./nameVerification";

const nameChangeHandler = (
  event,
  modelName,
  excludeOtherType,
  setNameState,
  setActionButtonDisableStatus,
  setErrorsState
) => {
  const _errors = {};
  let providedName = event.target.value;
  setNameState(providedName);
  // check the name validation
  let validationResult = nameVerification(providedName, modelName, excludeOtherType);
  // check the result
  if (!validationResult.result) {
    _errors.name = validationResult.message;
    setActionButtonDisableStatus(true);
  } else {
    setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);
};

export default nameChangeHandler;
