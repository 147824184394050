import React, { useState } from "react";
import { Image, Card, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const ImagePreview = (props) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Image
        style={{ maxHeight: props.maxHeight, cursor: "pointer" }}
        src={props.url}
        thumbnail
        onClick={() => setModalShow(true)}
      ></Image>

      <VerticallyCenteredIamgeModal
        {...props}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

const VerticallyCenteredIamgeModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <Image src={props.url}></Image>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

ImagePreview.propTypes = {
  url: PropTypes.string.isRequired,
  maxHeight: PropTypes.string.isRequired,
};

ImagePreview.defaultProps = {
  maxHeight: "100px",
};

export default ImagePreview;
