const orderVerification = (providedOrder, modelName, excludeOtherType) => {
  // trim the provided value
  providedOrder = providedOrder.trim();

  // define the base result
  let result = { result: true, message: "" };

  // apply the logic
  if (!providedOrder || providedOrder.length === 0) {
    result.result = false;
    result.message = `${modelName} Order is required`;
  }

  // orders can not be negative
  if (providedOrder < 0) {
    result.result = false;
    result.message = `${modelName} Order can not be negative`;
  }

  // return the result of validation logic
  return result;
};

export default orderVerification;
