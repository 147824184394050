import React, { useState, useEffect } from "react";
import { getUser } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";
import ImagePreview from "../../../../components/Common/ImagePreview";
import getObjectFromS3 from "../../../Common/S3/Handlers/getObjectFromS3";
import { toast } from "react-toastify";
import CardContainer from "../../../../components/Common/CardContainer";

const UserProfile = (props) => {
  const [userProfileImageURL, setUserProfileImageURL] = useState("");
  const getUserProfileImage = async () => {
    let objectFromS3Result = await getObjectFromS3(
      props.providedModelData.data.getUser.profileImage.s3Object.key
    );
    if (objectFromS3Result.result) {
      setUserProfileImageURL(objectFromS3Result.url);
    } else {
      toast.error("Error while getting user profile Image");
    }
  };

  useEffect(() => {
    getUserProfileImage();
  }, []);

  return (
    <>
      {userProfileImageURL !== "" && (
        <CardContainer header="User Profile Image">
          <ImagePreview url={userProfileImageURL} />
        </CardContainer>
      )}

      <GeneralInfo
        modelId={props.userId}
        modelName={"User"}
        listOfProperties={[
          {
            key: 2,
            attributeName: "firstName",
            attributeTitle: "FirstName",
            description: "FirstName",
            dataType: DataTypes.String,
          },
          {
            key: 3,
            attributeName: "lastName",
            attributeTitle: "LastName",
            description: "LastName",
            dataType: DataTypes.String,
          },
          {
            key: 4,
            attributeTitle: "Gender",
            attributeFetchFunction: (data) => {
              if (data.gender) {
                return data.gender.name;
              } else {
                return "---";
              }
            },
            description: "Gender",
            dataType: DataTypes.String,
          },
          {
            key: 5,
            attributeName: "otherGenderType",
            attributeTitle: "Other Gender",
            description: "Other Gender",
            dataType: DataTypes.String,
          },
          {
            key: 4,
            attributeTitle: "Degree",
            attributeFetchFunction: (data) => {
              if (data.degree) {
                return data.degree.name;
              } else {
                return "---";
              }
            },
            description: "Degree",
            dataType: DataTypes.String,
          },
          {
            key: 5,
            attributeName: "otherDegreeType",
            attributeTitle: "Other Degree",
            description: "Other Degree",
            dataType: DataTypes.String,
          },
          {
            key: 4,
            attributeName: "major",
            attributeTitle: "Major",
            description: "Major",
            dataType: DataTypes.String,
          },
          {
            key: 5,
            attributeName: "graduationYear",
            attributeTitle: "Graduation Year",
            description: "Graduation Year",
            dataType: DataTypes.String,
          },
          {
            key: 6,
            attributeName: "values",
            attributeTitle: "Values",
            description: "Values",
            dataType: DataTypes.String,
          },
          {
            key: 7,
            attributeName: "interests",
            attributeTitle: "Interests",
            description: "Interests",
            dataType: DataTypes.String,
          },
          {
            key: 8,
            attributeName: "hero",
            attributeTitle: "Hero",
            description: "Hero",
            dataType: DataTypes.String,
          },
          {
            key: 9,
            attributeName: "bio",
            attributeTitle: "Bio",
            description: "Bio",
            dataType: DataTypes.String,
          },
        ]}
        providedModelData={props.providedModelData}
        queryFunction={getUser}
        queryFunctionName={"getUser"}
      />
    </>
  );
};

UserProfile.propTypes = {
  userId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

UserProfile.defaultProps = {
  providedModelData: null,
};

export default UserProfile;
