import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  SignIn,
  SignUp,
  ConfirmSignUp,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  withAuthenticator,
  Greetings,
} from "aws-amplify-react";
import AmplifyTruliTheme from "./theme/AmplifyTruliTheme";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./containers/Pages/Dashboard";
import Schools from "./containers/Pages/Schools";
import SchoolCreate from "./containers/Pages/Schools/Create";
import SchoolDelete from "./containers/Pages/Schools/Delete";
import SchoolEdit from "./containers/Pages/Schools/Edit";
import SchoolDetails from "./containers/Pages/Schools/Details";
import CollegeCreate from "./containers/Pages/Colleges/Create";
import CollegeEdit from "./containers/Pages/Colleges/Edit";
import CollegeDelete from "./containers/Pages/Colleges/Delete";
import CollegeDetails from "./containers/Pages/Colleges/Details";
import DomainCreate from "./containers/Pages/Domains/Create";
import DomainEdit from "./containers/Pages/Domains/Edit";
import DomainDetails from "./containers/Pages/Domains/Details";
import DomainDelete from "./containers/Pages/Domains/Delete";
import ClassCreate from "./containers/Pages/Classes/Create";
import ClassBatchCreate from "./containers/Pages/Classes/BatchCreate";
import ClassEdit from "./containers/Pages/Classes/Edit";
import ClassDetails from "./containers/Pages/Classes/Details";
import ClassDelete from "./containers/Pages/Classes/Delete";
import Degrees from "./containers/Pages/Degrees";
import DegreeCreate from "./containers/Pages/Degrees/Create";
import DegreeEdit from "./containers/Pages/Degrees/Edit";
import DegreeDetails from "./containers/Pages/Degrees/Details";
import DegreeDelete from "./containers/Pages/Degrees/Delete";
import Genders from "./containers/Pages/Genders";
import GenderCreate from "./containers/Pages/Genders/Create";
import GenderEdit from "./containers/Pages/Genders/Edit";
import GenderDetails from "./containers/Pages/Genders/Details";
import GenderDelete from "./containers/Pages/Genders/Delete";
import Skills from "./containers/Pages/Skills";
import SkillCreate from "./containers/Pages/Skills/Create";
import SkillEdit from "./containers/Pages/Skills/Edit";
import SkillDetails from "./containers/Pages/Skills/Details";
import SkillDelete from "./containers/Pages/Skills/Delete";
import JourneyTypes from "./containers/Pages/JourneyTypes";
import JourneyTypeCreate from "./containers/Pages/JourneyTypes/Create";
import JourneyTypeEdit from "./containers/Pages/JourneyTypes/Edit";
import JourneyTypeDetails from "./containers/Pages/JourneyTypes/Details";
import JourneyTypeDelete from "./containers/Pages/JourneyTypes/Delete";
import FeedbackCategories from "./containers/Pages/FeedbackCategories";
import FeedbackCategoryCreate from "./containers/Pages/FeedbackCategories/Create";
import FeedbackCategoryEdit from "./containers/Pages/FeedbackCategories/Edit";
import FeedbackCategoryDetails from "./containers/Pages/FeedbackCategories/Details";
import FeedbackCategoryDelete from "./containers/Pages/FeedbackCategories/Delete";
import Feedbacks from "./containers/Pages/Feedbacks";
import FeedbackDetails from "./containers/Pages/Feedbacks/Details";
import FeedbackDelete from "./containers/Pages/Feedbacks/Delete";
import PostCategories from "./containers/Pages/PostCategories";
import PostCategoryCreate from "./containers/Pages/PostCategories/Create";
import PostCategoryEdit from "./containers/Pages/PostCategories/Edit";
import PostCategoryDetails from "./containers/Pages/PostCategories/Details";
import PostCategoryDelete from "./containers/Pages/PostCategories/Delete";
import ReportCategories from "./containers/Pages/ReportCategories";
import ReportCategoryCreate from "./containers/Pages/ReportCategories/Create";
import ReportCategoryEdit from "./containers/Pages/ReportCategories/Edit";
import ReportCategoryDetails from "./containers/Pages/ReportCategories/Details";
import ReportCategoryDelete from "./containers/Pages/ReportCategories/Delete";
import UserReports from "./containers/Pages/UserReports";
import Admins from "./containers/Pages/Admins";
import AdminCreate from "./containers/Pages/Admins/Create";
import AdminDetails from "./containers/Pages/Admins/Details";
import AdminDelete from "./containers/Pages/Admins/Delete";
import AdminBlock from "./containers/Pages/Admins/Block";
import UserDetails from "./containers/Pages/Users/Details";
import UserBlock from "./containers/Pages/Users/Block";
import UserDelete from "./containers/Pages/Users/Delete";
import UserProfane from "./containers/Pages/Users/Profane";
import ChangePassword from "./containers/Pages/Profile/ChangePassword";
import MyProfile from "./containers/Pages/Profile/MyProfile";
import PageNotFound from "./components/PageNotFound";
import SystemInitialSettings from "./containers/Pages/SystemInitialSettings";
import SystemSettings from "./containers/Pages/SystemSettings";
import SchoolsInitialSettings from "./containers/Pages/SchoolsInitialSettings";
import BatchUpload from "./containers/Pages/BatchUpload";
import Layout from "./containers/Layout";
import SignInCustom from "./containers/Auth/SignInCustom";
import GreetingsCustom from "./containers/Auth/GreetingsCustom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as UserGroups from "./graphql/Constants/UserGroups";
import AlwaysOn from "./containers/Auth/AlwaysOn";
import UserReportDetails from "./containers/Pages/UserReports/Details";
import UserReportDelete from "./containers/Pages/UserReports/Delete";
import PostReports from "./containers/Pages/PostReports";
import CommentReports from "./containers/Pages/CommentReports";
import UserPosts from "./containers/Pages/UserPosts";
import SystemInitialAssets from "./containers/Pages/SystemInitialAssets";
import UserPostDetails from "./containers/Pages/UserPosts/Details";
import UserPostProfane from "./containers/Pages/UserPosts/Profane";
import UserPostDelete from "./containers/Pages/UserPosts/Delete";
import HashTags from "./containers/Pages/HashTags";
import HashTagDetails from "./containers/Pages/HashTags/Details";
import Comments from "./containers/Pages/Comments";
import CommentProfane from "./containers/Pages/Comments/Profane";
import PostReportDetails from "./containers/Pages/PostReports/Details";
import PostReportDelete from "./containers/Pages/PostReports/Delete";
import CommentReportDelete from "./containers/Pages/CommentReports/Delete";
import CommentReportDetails from "./containers/Pages/CommentReports/Details";
import CommentDetails from "./containers/Pages/Comments/Details";
import CommentDelete from "./containers/Pages/Comments/Delete";
import PointSystems from "./containers/Pages/PointSystems";
import PointSystemDetails from "./containers/Pages/PointSystems/Details";
import PointSystemEdit from "./containers/Pages/PointSystems/Edit";
import SchoolBatchCreate from "./containers/Pages/Schools/BatchCreate";
import DomainBatchCreate from "./containers/Pages/Domains/BatchCreate";
import CollegeBatchCreate from "./containers/Pages/Colleges/BatchCreate";

function App() {
  //#region  crud route path generator
  const routeGenerator = (action, baseRoute, modelName) => {
    if (action === "index") return `/${baseRoute}`;
    else if (action === "create") return `/${baseRoute}/create`;
    return `/${baseRoute}/${action}/:${modelName}Id`;
  };
  //#endregion

  //#region  convinient method to generate CRUD based routes
  const simpleCRUDrouteGenerator = (
    indexComponent,
    indexPath,
    createComponent,
    createPath,
    editComponent,
    editPath,
    detailsComponent,
    detailsPath,
    deleteComponent,
    deletePath,
    blockComponent,
    blockPath,
    baseRoute,
    modelName
  ) => {
    // initial routes array
    let routesArray = [];

    // if we have delete
    if (blockComponent)
      routesArray.push(
        <Route
          path={blockPath ?? routeGenerator("block", baseRoute, modelName)}
          component={blockComponent}
          exact
        />
      );

    // if we have delete
    if (deleteComponent)
      routesArray.push(
        <Route
          path={deletePath ?? routeGenerator("delete", baseRoute, modelName)}
          component={deleteComponent}
          exact
        />
      );

    // if we have delete
    if (detailsComponent)
      routesArray.push(
        <Route
          path={detailsPath ?? routeGenerator("details", baseRoute, modelName)}
          component={detailsComponent}
          exact
        />
      );

    // if we have edit
    if (editComponent)
      routesArray.push(
        <Route
          path={editPath ?? routeGenerator("edit", baseRoute, modelName)}
          component={editComponent}
          exact
        />
      );

    // if we have create
    if (createComponent)
      routesArray.push(
        <Route
          path={createPath ?? routeGenerator("create", baseRoute, modelName)}
          component={createComponent}
          exact
        />
      );

    // if we have index
    if (indexComponent)
      routesArray.push(
        <Route
          path={indexPath ?? routeGenerator("index", baseRoute, modelName)}
          component={indexComponent}
          exact
        />
      );

    return routesArray;
  };
  //#endregion

  return (
    <div className="App">
      <Layout />
      <ToastContainer autoClose={5000} hideProgressBar={false} />
      <div className="content container-fluid">
        <div style={{ marginTop: "20px" }}>
          <Switch>
            {/* users */}
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/users/details/:userId"
              component={UserDetails}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/users/details/:userId/tab/:tabName"
              component={UserDetails}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/users/delete/:userId"
              component={UserDelete}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/users/delete/:userId/tab/:tabName"
              component={UserDelete}
              exact
            />
            <Route
              exact
              path="/userReports/:userReportId/users/delete/:userId"
              component={UserDelete}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/users/block/:userId"
              component={UserBlock}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/users/block/:userId/tab/:tabName"
              component={UserBlock}
              exact
            />
            <Route
              exact
              path="/userReports/:userReportId/users/block/:userId"
              component={UserBlock}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/users/profane/:userId"
              component={UserProfane}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/users/profane/:userId/tab/:tabName"
              component={UserProfane}
              exact
            />
            <Route
              exact
              path="/userReports/:userReportId/users/profane/:userId"
              component={UserProfane}
              exact
            />

            {/* Classes more explicit */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              ClassCreate,
              "/schools/:schoolId/colleges/:collegeId/classes/create",
              ClassEdit,
              "/schools/:schoolId/colleges/:collegeId/classes/edit/:classId",
              ClassDetails,
              "/schools/:schoolId/colleges/:collegeId/classes/details/:classId",
              ClassDelete,
              "/schools/:schoolId/colleges/:collegeId/classes/delete/:classId",
              null,
              null,
              "classes",
              "class"
            )}
            {/* Classes simple */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              ClassCreate,
              "/schools/:schoolId/classes/create",
              ClassEdit,
              "/schools/:schoolId/classes/edit/:classId",
              ClassDetails,
              "/schools/:schoolId/classes/details/:classId",
              ClassDelete,
              "/schools/:schoolId/classes/delete/:classId",
              null,
              null,
              "classes",
              "class"
            )}
            {/* batch class creation */}
            <Route
              exact
              path="/schools/:schoolId/classes/batchCreate"
              component={ClassBatchCreate}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/classes/batchCreate"
              component={ClassBatchCreate}
              exact
            />

            {/* Domains more explicit */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              DomainCreate,
              "/schools/:schoolId/colleges/:collegeId/domains/create",
              DomainEdit,
              "/schools/:schoolId/colleges/:collegeId/domains/edit/:domainId",
              DomainDetails,
              "/schools/:schoolId/colleges/:collegeId/domains/details/:domainId",
              DomainDelete,
              "/schools/:schoolId/colleges/:collegeId/domains/delete/:domainId",
              null,
              null,
              "domains",
              "domain"
            )}
            {/* Domains simple */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              DomainCreate,
              "/schools/:schoolId/domains/create",
              DomainEdit,
              "/schools/:schoolId/domains/edit/:domainId",
              DomainDetails,
              "/schools/:schoolId/domains/details/:domainId",
              DomainDelete,
              "/schools/:schoolId/domains/delete/:domainId",
              null,
              null,
              "domains",
              "domain"
            )}
            {/* batch domain creation */}
            <Route
              exact
              path="/schools/:schoolId/domains/batchCreate"
              component={DomainBatchCreate}
              exact
            />
            <Route
              exact
              path="/schools/:schoolId/colleges/:collegeId/domains/batchCreate"
              component={DomainBatchCreate}
              exact
            />

            {/* College */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              CollegeCreate,
              "/schools/:schoolId/colleges/create",
              CollegeEdit,
              "/schools/:schoolId/colleges/edit/:collegeId",
              CollegeDetails,
              "/schools/:schoolId/colleges/details/:collegeId/tab/:tabName",
              CollegeDelete,
              "/schools/:schoolId/colleges/delete/:collegeId/tab/:tabName",
              null,
              null,
              "colleges",
              "college"
            )}
            {/* batch school creation */}
            <Route
              exact
              path="/schools/:schoolId/colleges/batchCreate"
              component={CollegeBatchCreate}
              exact
            />

            {/* Schools */}
            {simpleCRUDrouteGenerator(
              Schools,
              null,
              SchoolCreate,
              null,
              SchoolEdit,
              null,
              SchoolDetails,
              "/schools/details/:schoolId/tab/:tabName",
              SchoolDelete,
              "/schools/delete/:schoolId/tab/:tabName",
              null,
              null,
              "schools",
              "school"
            )}
            {/* batch school creation */}
            <Route
              exact
              path="/schools/batchCreate"
              component={SchoolBatchCreate}
              exact
            />

            {/* degrees */}
            {simpleCRUDrouteGenerator(
              Degrees,
              null,
              DegreeCreate,
              null,
              DegreeEdit,
              null,
              DegreeDetails,
              null,
              DegreeDelete,
              null,
              null,
              null,
              "degrees",
              "degree"
            )}

            {/* Genders */}
            {simpleCRUDrouteGenerator(
              Genders,
              null,
              GenderCreate,
              null,
              GenderEdit,
              null,
              GenderDetails,
              null,
              GenderDelete,
              null,
              null,
              null,
              "genders",
              "gender"
            )}

            {/* Skills */}
            {simpleCRUDrouteGenerator(
              Skills,
              null,
              SkillCreate,
              null,
              SkillEdit,
              null,
              SkillDetails,
              null,
              SkillDelete,
              null,
              null,
              null,
              "skills",
              "skill"
            )}

            {/* JourneyTypes */}
            {simpleCRUDrouteGenerator(
              JourneyTypes,
              null,
              JourneyTypeCreate,
              null,
              JourneyTypeEdit,
              null,
              JourneyTypeDetails,
              null,
              JourneyTypeDelete,
              null,
              null,
              null,
              "journeyTypes",
              "journeyType"
            )}

            {/* Feedback Categories */}
            {simpleCRUDrouteGenerator(
              FeedbackCategories,
              null,
              FeedbackCategoryCreate,
              null,
              FeedbackCategoryEdit,
              null,
              FeedbackCategoryDetails,
              null,
              FeedbackCategoryDelete,
              null,
              null,
              null,
              "feedbackCategories",
              "feedbackCategory"
            )}

            {/* Feedbacks */}
            {simpleCRUDrouteGenerator(
              Feedbacks,
              null,
              null,
              null,
              null,
              null,
              FeedbackDetails,
              null,
              FeedbackDelete,
              null,
              null,
              null,
              "feedbacks",
              "feedback"
            )}

            {/* Post Categories */}
            {simpleCRUDrouteGenerator(
              PostCategories,
              null,
              PostCategoryCreate,
              null,
              PostCategoryEdit,
              null,
              PostCategoryDetails,
              null,
              PostCategoryDelete,
              null,
              null,
              null,
              "postCategories",
              "postCategory"
            )}

            {/* Report Categories */}
            {simpleCRUDrouteGenerator(
              ReportCategories,
              null,
              ReportCategoryCreate,
              null,
              ReportCategoryEdit,
              null,
              ReportCategoryDetails,
              null,
              ReportCategoryDelete,
              null,
              null,
              null,
              "reportCategories",
              "reportCategory"
            )}

            {/* Admins */}
            {simpleCRUDrouteGenerator(
              Admins,
              null,
              AdminCreate,
              null,
              null,
              null,
              AdminDetails,
              null,
              AdminDelete,
              null,
              AdminBlock,
              null,
              "admins",
              "admin"
            )}

            {/* UserReports */}
            {simpleCRUDrouteGenerator(
              UserReports,
              null,
              null,
              null,
              null,
              null,
              UserReportDetails,
              null,
              UserReportDelete,
              null,
              null,
              null,
              "userReports",
              "userReport"
            )}

            {/* PostReports */}
            {simpleCRUDrouteGenerator(
              PostReports,
              null,
              null,
              null,
              null,
              null,
              PostReportDetails,
              null,
              PostReportDelete,
              null,
              null,
              null,
              "postReports",
              "postReport"
            )}

            {/* CommentReports */}
            {simpleCRUDrouteGenerator(
              CommentReports,
              null,
              null,
              null,
              null,
              null,
              CommentReportDetails,
              null,
              CommentReportDelete,
              null,
              null,
              null,
              "commentReports",
              "commentReport"
            )}

            {/* UserPosts */}
            {simpleCRUDrouteGenerator(
              UserPosts,
              null,
              null,
              null,
              null,
              null,
              UserPostDetails,
              "/userPosts/details/:userPostId/tab/:tabName",
              UserPostDelete,
              "/userPosts/delete/:userPostId/tab/:tabName",
              null,
              null,
              "userPosts",
              "userPost"
            )}
            <Route
              exact
              path="/postReports/:postReportsId/userPosts/delete/:userPostId"
              component={UserPostDelete}
              exact
            />
            <Route
              exact
              path="/postReports/:postReportsId/userPosts/profane/:userPostId"
              component={UserPostProfane}
              exact
            />
            <Route
              exact
              path="/userPosts/profane/:userPostId/tab/:tabName"
              component={UserPostProfane}
              exact
            />

            {/* HashTags */}
            {simpleCRUDrouteGenerator(
              HashTags,
              null,
              null,
              null,
              null,
              null,
              HashTagDetails,
              null,
              null,
              null,
              null,
              null,
              "hashTags",
              "hashTag"
            )}

            {/* Comments more explicit */}
            {simpleCRUDrouteGenerator(
              null,
              null,
              null,
              null,
              null,
              null,
              CommentDetails,
              "/userPosts/:userPostId/comments/details/:commentId/tab/:tabName",
              CommentDelete,
              "/userPosts/:userPostId/comments/delete/:commentId/tab/:tabName",
              null,
              null,
              "comments",
              "comment"
            )}
            {/* Comments */}
            {simpleCRUDrouteGenerator(
              Comments,
              null,
              null,
              null,
              null,
              null,
              CommentDetails,
              null,
              CommentDelete,
              null,
              null,
              null,
              "comments",
              "comment"
            )}
            <Route
              exact
              path="/commentReports/:commentReportId/comments/profane/:commentId"
              component={CommentProfane}
              exact
            />
            <Route
              exact
              path="/commentReports/:commentReportId/comments/delete/:commentId"
              component={CommentDelete}
              exact
            />
            <Route
              exact
              path="/userPosts/:userPostId/comments/profane/:commentId/tab/:tabName"
              component={CommentProfane}
              exact
            />
            <Route
              exact
              path="/comments/profane/:commentId"
              component={CommentProfane}
              exact
            />

            {/* pointSystems */}
            {simpleCRUDrouteGenerator(
              PointSystems,
              null,
              null,
              null,
              PointSystemEdit,
              null,
              PointSystemDetails,
              null,
              null,
              null,
              null,
              null,
              "pointSystems",
              "pointSystem"
            )}

            <Route
              exact
              path="/profile/myprofile"
              component={MyProfile}
              exact
            />
            <Route
              exact
              path="/profile/changepassword"
              component={ChangePassword}
              exact
            />
            <Route
              exact
              path="/systeminitialsettings"
              component={SystemInitialSettings}
            />
            <Route
              exact
              path="/systeminitialassets"
              component={SystemInitialAssets}
            />
            <Route
              exact
              path="/systemsettings/:type"
              component={SystemSettings}
            />
            <Route
              exact
              path="/schoolsinitialsettings"
              component={SchoolsInitialSettings}
            />
            <Route exact path="/batchupload" component={BatchUpload} />
            <Route exact path="/" component={Dashboard} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(
  App,
  true,
  [
    // <SignUp />,
    // <ConfirmSignUp />,
    <SignInCustom
      allowedUserGroups={[UserGroups.SuperAdmins, UserGroups.SuperAdmins]}
    />,
    // <SignIn />,
    <ConfirmSignIn />,
    <ForgotPassword />,
    <RequireNewPassword />,
    // <Greetings />,
    <GreetingsCustom />,
    // <AlwaysOn />
  ],
  null,
  AmplifyTruliTheme
);
