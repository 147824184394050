import React from "react";
import { getSchoolCollege } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const CollegeGeneralInfo = (props) => {
  // const getSchoolData = async (collegeId) => {
  //   try {
  //     const result = await API.graphql(
  //       graphqlOperation(getSchoolCollege, { id: collegeId })
  //     );
  //     const { name, createdAt, updatedAt, school } = result.data.getSchoolCollege;

  //     setCollegeData([
  //       <DetailsPresentationItem
  //         key={1}
  //         name="Name"
  //         value={name}
  //         description="College Name"
  //         dataType={DataTypes.String}
  //       />,
  //       <DetailsPresentationItem
  //         key={2}
  //         name="School Name"
  //         value={school.name}
  //         description="School Name"
  //         dataType={DataTypes.String}
  //       />,
  //       <DetailsPresentationItem
  //         key={3}
  //         name="Created At"
  //         value={createdAt}
  //         description="College Created At"
  //         dataType={DataTypes.DateTime}
  //       />,
  //       <DetailsPresentationItem
  //         key={4}
  //         name="Update At"
  //         value={updatedAt}
  //         description="College Update At"
  //         dataType={DataTypes.DateTime}
  //       />,
  //     ]);

  //     setIsLoading(false);
  //   } catch (error) {
  //     toast.error(`Error while fetching college data ${JSON.stringify(error)}`);
  //   }
  // };

  return (
    <GeneralInfo
      modelId={props.collegeId}
      modelName={"SchoolCollege"}
      modelNameTitle={"College"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "name",
          attributeTitle: "Name",
          description: "College Name",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeFetchFunction: (data) => {
            return data.school.name;
          },
          attributeTitle: "School Name",
          description: "School Name",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "College Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 4,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "College Updated At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 5,
          attributeName: "numberOfUsers",
          attributeTitle: "Number Of Users",
          description: "Number Of Users who signed up with college domains",
          dataType: DataTypes.String,
        },
      ]}
      queryFunction={getSchoolCollege}
      queryFunctionName={"getSchoolCollege"}
    />
  );
};

CollegeGeneralInfo.propTypes = {
  collegeId: PropTypes.string.isRequired,
};

export default CollegeGeneralInfo;
