import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col } from "react-bootstrap";

const CardContainer = (props) => {
  return (
    <Card style={props.style}>
      <Card.Header>
        <Row className="d-flex justify-content-between">
          <Col sm={8}>
            <span className="cardcontainer-header">{props.header}</span>
          </Col>
          <Col sm={4} className="text-right">
            {props.backButton} {props.submitButton} {props.extraButtons}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {props.title && <Card.Title>{props.title}</Card.Title>}
        {props.text && <Card.Text>{props.text}</Card.Text>}
        {props.children}
      </Card.Body>
    </Card>
  );
};

CardContainer.propTypes = {
  header: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.object.isRequired,
  submitButton: PropTypes.object,
  extraButtons: PropTypes.array,
  backButton: PropTypes.object,
  style: PropTypes.object,
};

CardContainer.defaultProps = {
  style: { marginBottom: "20px" },
};

export default CardContainer;
