import React, { useState, useEffect } from "react";
import TabsContainer from "../../../../components/Common/TabsContainer";
import UserGeneralInfo from "../GeneralInfo";
import UserProfile from "../UserProfile";
import UserPrivacySettings from "../UserPrivacySettings";
import UserNotificationSettings from "../UserNotificationSettings";
import UserJourneys from "../UserJourneys";
import UserSkills from "../UserSkills";
import UserClasses from "../UserClasses";
import UserPastClasses from "../UserPastClasses";
import SchoolGeneralInfo from "../../Schools/GeneralInfo";
import CollegeGeneralInfo from "../../Colleges/GeneralInfo";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import DataGrid from "../../../Common/DataGrid";
import {
  getUser,
  getSchool,
  getDomain,
  getSchoolCollege,
  searchClazzs,
  searchUsers,
} from "../../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../../graphql/Constants/QueryTypes";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import UserWillingToHelps from "../UserWillingToHelps";

const UserDetails = (props) => {
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userCollegeIdValue, setCollegeIdValue] = useState(null);
  const [userSchoolIdValue, setSchoolIdValue] = useState(null);
  // const [schoolClassesTotalCount, setSchoolClassesTotalCount] = useState(0);
  const [userJourneys, setUserJourneys] = useState(null);
  const [userReferralCodeId, setUserReferralCodeId] = useState(null);
  const [refferedUsersTotalCount, setRefferedUsersTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getUser, {
          id: props.userId ?? props.match.params.userId,
        })
      );
      const {
        id,
        userName,
        numberOfClazzes,
        userCollegeId,
        schoolId,
        journeys,
        numberOfTimesReferralCodeUsed,
        referralCodeId,
        signedUpWithReferralCode,
      } = result.data.getUser;

      // check if we need to fetch the referralCode owner used at signup
      if (signedUpWithReferralCode) {
        const referringUserResult = await API.graphql(
          graphqlOperation(getUser, {
            id: signedUpWithReferralCode.userId,
          })
        );
        // attach the username to the userData model
        result.data.getUser.signedUpWithReferralCode = {
          ...result.data.getUser.signedUpWithReferralCode,
          user: referringUserResult.data.getUser,
        };
      }

      // keep state
      setUserData(result);
      setRefferedUsersTotalCount(numberOfTimesReferralCodeUsed);
      setUserReferralCodeId(referralCodeId);
      // reuse the same function being called by college data grid to assign the header schoolname
      // setSchoolClassesTotalCount(numberOfClazzes);
      setCollegeIdValue(userCollegeId);
      setSchoolIdValue(schoolId);
      setUserJourneys(journeys);
      setUserId(id);
      setUserName(userName);
    } catch (error) {
      toast.error(`Error while fetching user data ${JSON.stringify(error)}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (userName) setIsLoading(false);
  }, [userName]);

  // const getSchoolClassesTotalCount = async () => {
  //   return schoolClassesTotalCount;
  // };

  const handleReturn = () => {
    // check how we got here ( from school or from college )
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Users`
      );
    } else {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/${props.match.params.tabName}`
      );
    }
  };

  const getRefferedUsersTotalCount = async () => {
    return refferedUsersTotalCount;
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <TabsContainer
        header={`User Details : ${userName}`}
        defaultActiveKey={"GeneralInfo"}
        backButton={
          props.match.params.userId && (
            <ButtonInput
              label="Back to List"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          )
        }
        tabs={[
          {
            name: "General Info",
            eventKey: "GeneralInfo",
            content: userData && (
              <UserGeneralInfo
                providedModelData={userData}
                userId={props.userId ?? props.match.params.userId}
              />
            ),
          },
          {
            name: "Profile",
            eventKey: "UserProfile",
            content: userData && (
              <UserProfile
                providedModelData={userData}
                userId={props.userId ?? props.match.params.userId}
              />
            ),
          },
          {
            name: "Privacy Settings",
            eventKey: "UserPrivacySettings",
            content: userData && (
              <UserPrivacySettings
                providedModelData={userData}
                userId={props.userId ?? props.match.params.userId}
              />
            ),
          },
          {
            name: "Notification Settings",
            eventKey: "UserNotificationSettings",
            content: userData && (
              <UserNotificationSettings
                providedModelData={userData}
                userId={props.userId ?? props.match.params.userId}
              />
            ),
          },
          {
            name: "School",
            eventKey: "SchoolInfo",
            content: <SchoolGeneralInfo schoolId={userSchoolIdValue} />,
          },
          {
            name: "College",
            eventKey: "CollegeInfo",
            content: userCollegeIdValue ? (
              <CollegeGeneralInfo collegeId={userCollegeIdValue} />
            ) : (
              <>
                <span>User does not belong to a college</span>
              </>
            ),
          },
          {
            name: "Journeys",
            eventKey: "Journeys",
            content: <UserJourneys userId={userId} />,
          },
          {
            name: "Skills",
            eventKey: "Skills",
            content: <UserSkills userId={userId} />,
          },
          {
            name: "Helps",
            eventKey: "Helps",
            content: <UserWillingToHelps userId={userId} />,
          },
          {
            name: "Classes",
            eventKey: "Classes",
            content: <UserClasses userId={userId} />,
          },
          {
            name: "Past Classes",
            eventKey: "PastClasses",
            content: <UserPastClasses userId={userId} />,
          },
          {
            name: "Referred Users",
            eventKey: "ReferredUsers",
            content: (
              <DataGrid
                header="Referred Users"
                {...props}
                gridObjectDataModel={[
                  {
                    name: "userName",
                    headerText: "UserName",
                    type: DataTypes.String,
                  },
                  {
                    name: "createdAt",
                    headerText: "Created At",
                    type: DataTypes.DateTime,
                  },
                ]}
                customTotalCountFunction={getRefferedUsersTotalCount}
                queryFunction={searchUsers}
                queryFunctionType={QueryTypes.Search}
                queryName={"searchUsers"}
                queryFilters={{
                  schoolId: {
                    eq: props.match.params.schoolId,
                  },
                  userSignedUpWithReferralCodeId: {
                    eq: userReferralCodeId,
                  },
                }}
                hasCreate={false}
                hasDetails={false}
                hasEdit={false}
                hasDelete={false}
                hasBlock={false}
                hasProfane={false}
                baseRoute={``}
                detailsActiveTab={""}
              />
            ),
          },
          //   {
          //     name: "Classes",
          //     eventKey: "Classes",
          //     content: (
          //       <DataGrid
          //         header="Classes"
          //         {...props}
          //         gridObjectDataModel={[
          //           { name: "name", headerText: "Name", type: DataTypes.String },
          //           {
          //             name: "createdAt",
          //             headerText: "Created At",
          //             type: DataTypes.DateTime,
          //           },
          //         ]}
          //         customTotalCountFunction={getSchoolClassesTotalCount}
          //         queryFunction={searchClazzs}
          //         queryFunctionType={QueryTypes.Search}
          //         queryName={"searchClazzs"}
          //         queryFilters={{
          //           schoolId: {
          //             eq: props.match.params.schoolId,
          //           },
          //           clazzCollegeId: {
          //             exists: false,
          //           },
          //         }}
          //         hasCreate={true}
          //         hasDetails={true}
          //         hasEdit={true}
          //         hasDelete={true}
          //         baseRoute={`schools/${props.match.params.schoolId}/classes`}
          //       />
          //     ),
          //   }
        ]}
      />
    </PagePreLoad>
  );
};

export default UserDetails;
