import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createSkill } from "../../../../graphql/mutations";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import * as SkillsConstants from "../../../../graphql/Constants/Skills/Types";
import nameChangeHandler from "../../../Common/Crud/formHandlers/nameChangeHandler";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
import nameOnlyFormsValidationHandler from "../../../Common/Crud/formHandlers/nameOnlyFormsValidationHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const SkillCreate = (props) => {
  // states
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [createButtonDisableStatus, setCreateButtonDisableStatus] = useState(
    false
  );

  const handleCreateSkill = async () => {
    try {
      const input = {
        type: ModelTypes.Skill,
        name: name.trim(),
        order: order,
        deletedAt: null,
      };
      await API.graphql(graphqlOperation(createSkill, { input }));
      return { result: true, message: `Skill created successfully` };
    } catch (error) {
      return { result: false, message: `Error creating skill, ${error}` };
    }
  };

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Create}
      returnHandler={() => props.history.push(`/skills`)}
      modelName="Skill"
      // createOrUpdateDisableMethod={(modelData) => {
      //   if (modelData.id === SkillsConstants.Other.id) return true;
      //   return false;
      // }}
      // createOrUpdateDisabledActions={() => {
      //   setNameInputTextDisable(true);
      // }}
      formSubmissionHandler={handleCreateSkill}
      formValidationHandler={() =>
        nameOnlyFormsValidationHandler(
          name,
          "Skill",
          false, // we need to exclude the OTHER,
          setCreateButtonDisableStatus,
          setErrors
        )
      }
      setErrorsState={() => setErrors}
      actionButtonDisabledState={createButtonDisableStatus}
    >
      <TextInput
        type="name"
        id="name"
        label="Skill Name"
        placeholder="Enter a Skill name"
        value={name}
        onChange={(e) =>
          nameChangeHandler(
            e,
            "Skill",
            false, // we need to exclude the OTHER,
            setName,
            setCreateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.name}
        disabled={inputFieldsDisable}
      />
      <TextInput
        type="number"
        id="order"
        label="Skill Order"
        placeholder="Enter a Skill Order"
        value={order}
        onChange={(e) =>
          orderChangeHandler(
            e,
            "Skill",
            setOrder,
            setCreateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.order}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default SkillCreate;
