import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getPost } from "../../../../graphql/queries";
import { updatePost, updateUser } from "../../../../graphql/mutations";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import UserGeneralInfo from "../GeneralInfo";
import getCurrentUserStarPoints from "../../../Common/PointSystem/handlers/getCurrentUserStarPoints";
import getPointSystemByTypeAsync from "../../../Common/PointSystem/handlers/getPointSystemByTypeAsync";
import updateUserStarPointsByUserId from "../../../Common/PointSystem/handlers/updateUserStarPointsByUserId";

const UserPostProfane = (props) => {
  const [postId, setPostId] = useState(null);
  const [postData, setPostData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [postCurrentProfaneState, setPostCurrentProfaneState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [generalError, setGeneralError] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [
    formSubmitDemeritInProgress,
    setFormSubmitDemeritInProgress,
  ] = useState(false);
  const [demeritPoint, setDemeritPoint] = useState(null);

  useEffect(() => {
    getPointSystemForFlagesContent();
    getPostData();
  }, []);

  // get post data
  // apply the states
  const getPostData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getPost, { id: props.match.params.userPostId })
      );
      const { id, isProfane, userId } = result.data.getPost;

      // keep state
      setPostData(result);
      setUserId(userId);
      setPostCurrentProfaneState(isProfane);
      setPostId(id);
    } catch (error) {
      toast.error(`Error while fetching post data ${JSON.stringify(error)}`);
    }
  };

  // get the profane flagging demerit point from the current data at db
  const getPointSystemForFlagesContent = async () => {
    let pointSystemResult = await getPointSystemByTypeAsync(
      "FLAGGED_REMOVED_CONTENT"
    );

    if (pointSystemResult.result) {
      setDemeritPoint(pointSystemResult.point);
    } else {
      toast.error(`${pointSystemResult.message}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (postId && demeritPoint) {
      setIsLoading(false);
    }
  }, [postId, demeritPoint]);

  // return handler being used at back button after the form submissions
  const handleReturn = () => {
    if (props.match.params.postReportsId) {
      props.history.push(
        `/postReports/details/${props.match.params.postReportsId}`
      );
    } else {
      props.history.push(`/userPosts`);
    }
  };

  // usual form submission being used by mark button
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    await markAsProfaneFunction();
  };

  // reusable function to mark a post as profane
  const markAsProfaneFunction = async () => {
    setFormSubmitInProgress(true);

    let opperationResultMessage = "";

    if (!postCurrentProfaneState) {
      // update user data at dynamo db
      let updateProfaneStatusToTrueResult = await updateUserPostProfaneStatus(
        postId,
        true
      );
      if (!updateProfaneStatusToTrueResult.result) {
        toast.error(updateProfaneStatusToTrueResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = updateProfaneStatusToTrueResult.message;
    } else {
      // update post data at dynamo db
      let updateProfaneStatusToFalseResult = await updateUserPostProfaneStatus(
        postId,
        false
      );
      if (!updateProfaneStatusToFalseResult.result) {
        toast.success(updateProfaneStatusToFalseResult.message);
        setFormSubmitInProgress(false);
        return;
      }

      opperationResultMessage = updateProfaneStatusToFalseResult.message;
    }

    // go back to list
    setTimeout(function () {
      toast.success(opperationResultMessage);
      handleReturn();
    }, GlobalSubmitUIDelay.DefaultDelay);
  };

  // reusable profane status update at db
  const updateUserPostProfaneStatus = async (postId, newStatus) => {
    let formSubmissionResult = {
      result: true,
      message: "",
    };
    try {
      let input = {
        id: postId,
        isProfane: newStatus,
      };
      let userPostUpdateResult = await API.graphql(
        graphqlOperation(updatePost, { input })
      );

      // check result
      if (userPostUpdateResult) {
        formSubmissionResult.message = `Post ${
          newStatus ? "Marked As Profane" : "UnMarked from Profane"
        } successfully`;
      }
    } catch (userUpdateError) {
      formSubmissionResult.result = false;
      formSubmissionResult.message = `Error while updating post ${JSON.stringify(
        userUpdateError,
        null,
        2
      )}`;
    }

    return formSubmissionResult;
  };

  // functionality to apply demerit points to post owner
  const applyDemeritPoints = async (positiveNeggative) => {
    setFormSubmitDemeritInProgress(true);

    // fetch current start points of the post owner
    let userCurrentPointsResult = await getCurrentUserStarPoints(userId);

    if (userCurrentPointsResult.result) {
      let updateUserPointsResult = await updateUserStarPointsByUserId(
        userId,
        userCurrentPointsResult.currentPoints + positiveNeggative * demeritPoint
      );

      // check the result
      if (!updateUserPointsResult.result) {
        toast.error(`${updateUserPointsResult.message}`);
        setFormSubmitInProgress(false);
        return;
      } else {
        // mark the post as profane
        // which will have the return handler inside
        await markAsProfaneFunction();
      }
    } else {
      toast.error(`${userCurrentPointsResult.message}`);
      setFormSubmitInProgress(false);
      return;
    }
  };

  return (
    <>
      <PagePreLoad isLoading={isLoading}>
        <Form onSubmit={handleFormSubmission} noValidate>
          <CardContainer
            header={`${
              postCurrentProfaneState
                ? "UnMark post from Profanity flag "
                : "Mark Post as Profane"
            } `}
            submitButton={
              <ButtonInput
                label={postCurrentProfaneState ? "UnMark" : "Mark"}
                variant={
                  postCurrentProfaneState
                    ? BootstrapVariants.Success
                    : BootstrapVariants.Danger
                }
                formSubmitInProgress={formSubmitInProgress}
                formSubmittingText={"In Progress..."}
                disabled={formSubmitDemeritInProgress}
              />
            }
            extraButtons={
              !postCurrentProfaneState && (
                <ButtonInput
                  label={"Mark and Apply Demerit Points "}
                  type="button"
                  variant={BootstrapVariants.Danger}
                  formSubmitInProgress={formSubmitDemeritInProgress}
                  formSubmittingText={"In Progress..."}
                  onClick={() => applyDemeritPoints(+1)}
                  disabled={formSubmitInProgress}
                />
              )
            }
            backButton={
              <ButtonInput
                label="Back to List"
                type="button"
                variant={BootstrapVariants.OutlineSecondary}
                onClick={() => handleReturn()}
              />
            }
          >
            {generalError && (
              <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
            )}
            <span>
              By clicking on{" "}
              <b>{postCurrentProfaneState ? "UnMark" : "Mark"}</b>, Post will be{" "}
              {postCurrentProfaneState
                ? "unmarked from profane account"
                : "marked as a profane account"}
              , do you want to proceed?
            </span>
          </CardContainer>
        </Form>
        <UserGeneralInfo
          providedModelData={postData}
          userId={props.match.params.userId}
        />
      </PagePreLoad>
    </>
  );
};

export default UserPostProfane;
