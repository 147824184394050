import React from "react";
import { searchReports } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const UserReports = (props) => {
  return (
    <DataGrid
      {...props}
      header="User Reports"
      gridObjectDataModel={[
        {
          name: "reportUserName",
          headerText: "Reported User",
          type: DataTypes.String,
        },
        {
          name: "isAcknowledged",
          headerText: "Is Acknowledged",
          type: DataTypes.Boolean,
        },
        { name: "reportByUserName", headerText: "Reported By", type: DataTypes.String },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.UserReport}
      queryFunction={searchReports}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchReports"}
      queryFilters={{
        reportUserId: {
          exists: true,
        },
      }}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      baseRoute={"userReports"}
    />
  );
};

export default UserReports;
