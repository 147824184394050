import { API, graphqlOperation } from "aws-amplify";
import { listSystemSettingss } from "../../queries";

export const getSystemSettingsByType = async (type) => {
  const filter = {
    settingType: {
      eq: type,
    },
  };
  try {
    return await API.graphql(graphqlOperation(listSystemSettingss, { filter }));
  } catch (error) {
    return error;
  }
};
