import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { getLikesByPostId, searchLikes } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";
import DataGrid from "../../../Common/DataGrid";
import * as QueryTypes from "../../../../graphql/Constants/QueryTypes";

const UserPostLikes = (props) => {
  const { postId, postLikesTotalCount } = props;

  useEffect(() => {}, []);

  const getPostLikesTotalCount = async () => {
    return postLikesTotalCount;
  };

  return (
    <DataGrid
      header="Likes"
      {...props}
      gridObjectDataModel={[
        {
          name: "userName",
          headerText: "Liked By UserName",
          type: DataTypes.String,
        },
        {
          name: "createdAt",
          headerText: "Liked At",
          type: DataTypes.DateTime,
        },
      ]}
      customTotalCountFunction={getPostLikesTotalCount}
      queryFunction={searchLikes}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchLikes"}
      queryFilters={{
        postId: {
          eq: postId,
        },
      }}
      hasCreate={false}
      hasDetails={false}
      hasEdit={false}
      hasDelete={false}
      hasBlock={false}
      hasProfane={false}
      baseRoute={`/`}
      softDeleteAbleModel={false}
    />
  );
};

UserPostLikes.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default UserPostLikes;
