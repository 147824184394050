import React, { useState } from "react";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import ClassGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateClazz } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const ClassDelete = (props) => {
  return (
    <>
      <SoftDelete
        {...props}
        modelName="Class"
        deleteDescription="class will be deleted. Deleting a
        class will have presentational affect on existing relationships."
        returnRoutePropBasedFunction={() => {
          // check how we got here ( from school or from college )
          if (props.match.params.collegeId) {
            props.history.push(
              `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
            );
          } else {
            props.history.push(
              `/schools/details/${props.match.params.schoolId}/tab/Classes`
            );
          }
        }}
        generalInfoComponent={
          <ClassGeneralInfo classId={props.match.params.classId} />
        }
        mutationMethod={updateClazz}
        mutationInput={{
          id: props.match.params.classId,
        }}
      />
    </>
  );
};

export default ClassDelete;
