import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import CardContainer from "../CardContainer";
import DetailsPresentationItem from "./DetailsPresentationItem";

const DetailsPresentation = (props) => {
  return (
    <>
      {props.itemsList && (
        <CardContainer header={props.header}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>{props.itemsList.map((item) => item)}</tbody>
          </Table>
        </CardContainer>
      )}
    </>
  );
};

DetailsPresentation.propTypes = {
  itemsList: PropTypes.array.isRequired,
  header: PropTypes.string,
};

DetailsPresentation.defaultProps = {
  header: "Details",
};

export default DetailsPresentation;
