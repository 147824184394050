import ExecuteAdminQuery from "../ExecuteAdminQuery";
import * as HttpMethodTypes from "../../Constants/HttpMethodTypes";
import { toast } from "react-toastify";

const AdminDeleteUser = async (userName, setGeneralErrorState) => {
  let formSubmissionResult = {
    result: true,
    message: "",
  };

  // in order to delete an admin
  // disable user
  let apiName = "AdminQueries";
  try {
    let result = await ExecuteAdminQuery(
      apiName,
      "/adminDeleteUser",
      {
        body: {
          username: userName,
        },
      },
      HttpMethodTypes.POST
    );
    // user data comes back inside thge Attributes
    if (result && result.success) {
      formSubmissionResult.message = "Admin Deleted Successfully";
    } else {
      let errorObject = JSON.parse(result.errorMessage);
      let errorMessage = `Error while deleting admin, ${errorObject.message}`;
      formSubmissionResult.result = false;
      formSubmissionResult.message = errorMessage;
      setGeneralErrorState(errorMessage);
    }
  } catch (error) {
    let errorMessage = `Error while deleting admin, ${JSON.stringify(
      error,
      null,
      2
    )}`;
    toast.error(errorMessage);
    formSubmissionResult.result = false;
    formSubmissionResult.message = errorMessage;
    setGeneralErrorState(errorMessage);
  }

  return formSubmissionResult;
};

export default AdminDeleteUser;
