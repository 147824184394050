export const Writing = {
  id: "f95a40c4-4455-4886-b732-f4dc815e5fdf",
  name: "Writing",
  order: 10,
};
export const Learning = {
  id: "b382ad67-f54f-44b0-b80d-4d3158dc3431",
  name: "Learning",
  order: 20,
};
export const Teaching = {
  id: "ff429a44-7a5a-4043-96ae-5375caf9a91d",
  name: "Teaching",
  order: 30,
};
export const Mentoring = {
  id: "76efc361-84bd-4bb3-a9cd-012e40faaef5",
  name: "Mentoring",
  order: 40,
};
export const Debating = {
  id: "c3929329-bebd-4495-9859-70ff22d94ffa",
  name: "Debating",
  order: 50,
};
export const Cooking = {
  id: "bbc93510-7e25-461b-bb12-d22dff14d033",
  name: "Cooking",
  order: 60,
};
export const Singing = {
  id: "fe0a2f81-e500-4a8a-93a0-5600303c27af",
  name: "Singing",
  order: 70,
};
export const Dancing = {
  id: "b20678aa-2428-463a-8fb2-f2b14bd3b194",
  name: "Dancing",
  order: 80,
};
export const Performing = {
  id: "07df159e-ff90-4874-879d-44d68f0cd584",
  name: "Performing",
  order: 90,
};
export const Listening = {
  id: "5a490a3f-3f1b-4c9a-80a3-333212b4cf7b",
  name: "Listening",
  order: 100,
};
export const Socializing = {
  id: "a8b789d1-107f-480a-b7fe-a1807e4ad92f",
  name: "Socializing",
  order: 110,
};
export const OrganizingEvents = {
  id: "bde61bb4-30b0-42fa-ac1a-af622b415993",
  name: "Organizing Events",
  order: 120,
};
export const HelpingOthers = {
  id: "06e2a4bf-92b5-4688-86ab-a3dd336ca7eb",
  name: "Helping Others",
  order: 130,
};
export const Inventing = {
  id: "1c92b8f1-0b5e-42c5-a9a6-b5dc6c94da8f",
  name: "Inventing",
  order: 140,
};
export const BeingPatient = {
  id: "c0d4fc33-0c6a-48da-8d88-f998b969cf96",
  name: "Being Patient",
  order: 150,
};
export const BeingPersistent = {
  id: "65073f20-d22a-491a-8ae5-1141d47afdbc",
  name: "Being Persistent",
  order: 160,
};
export const HavingFun = {
  id: "6e71afab-1d9d-48eb-b771-b95ca3b12319",
  name: "Having Fun",
  order: 170,
};
export const Timemanagement = {
  id: "0fb2d490-ba1c-4669-861c-f39478ee0af3",
  name: "Time management",
  order: 180,
};
export const PublicSpeaking = {
  id: "5a1abf2a-435b-4c71-b2dd-2c99c9b46673",
  name: "Public Speaking",
  order: 190,
};
export const BeingPunctual = {
  id: "2c01417b-2bad-4ef8-bef7-f177dd732ba5",
  name: "Being Punctual",
  order: 200,
};
export const Procrastination = {
  id: "efb71a0a-0eed-4eb2-9488-eb6aca679796",
  name: "Procrastination",
  order: 210,
};
export const SayingNo = {
  id: "45e39ebd-a4e2-4949-8a6d-da3542929a40",
  name: 'Saying "No"',
  order: 220,
};
export const ProblemSolving = {
  id: "c9a51f05-ce4f-4cca-b79a-f96abd667775",
  name: "Problem Solving",
  order: 230,
};
// export const Other = {
//   id: "6a00d8dc-dbaf-45bf-a62e-24f0ccbd7e8d",
//   name: "Other",
// };

export const AllTypes = [
  Writing,
  Learning,
  Teaching,
  Mentoring,
  Debating,
  Cooking,
  Singing,
  Dancing,
  Performing,
  Listening,
  Socializing,
  OrganizingEvents,
  HelpingOthers,
  Inventing,
  BeingPatient,
  BeingPersistent,
  HavingFun,
  Timemanagement,
  PublicSpeaking,
  BeingPunctual,
  Procrastination,
  SayingNo,
  ProblemSolving,
  // Other,
];
