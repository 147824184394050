const commandOptionsGenerator = (hasDetails, hasEdit, hasBlock, hasProfane, hasDelete) => {
  const commands = [];

  // if we have details
  if (hasDetails) {
    commands.push({
      type: "Details",
      buttonOption: { cssClass: "e-flat", iconCss: "e-Preview e-icons" },
    });
  }

  // if we have edit
  if (hasEdit) {
    commands.push({
      type: "Edit",
      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
    });
  }

  // if we have block
  if (hasBlock) {
    commands.push({
      type: "Block",
      buttonOption: { cssClass: "e-flat", iconCss: "e-Block e-icons" },
    });
  }

  // if we have delete
  if (hasProfane) {
    commands.push({
      type: "Profane",
      buttonOption: { cssClass: "e-flat", iconCss: "e-Close e-icons" },
    });
  }

  // if we have delete
  if (hasDelete) {
    commands.push({
      type: "Delete",
      buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
    });
  }

  return commands;
};

export default commandOptionsGenerator;
