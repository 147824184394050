import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getDegree } from "../../../../graphql/queries";
import { updateDegree } from "../../../../graphql/mutations";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import * as DegreesConstants from "../../../../graphql/Constants/Degrees/Types";
import nameChangeHandler from "../../../Common/Crud/formHandlers/nameChangeHandler";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
import nameOnlyFormsValidationHandler from "../../../Common/Crud/formHandlers/nameOnlyFormsValidationHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";

const DegreeEdit = (props) => {
  // states
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [updateButtonDisableStatus, setUpdateButtonDisableStatus] = useState(
    false
  );

  //#region update Degree business logic
  const handleUpdateDegree = async () => {
    try {
      // initial required Degree update data
      let input = {
        id: props.match.params.degreeId,
        name: name.trim(),
        order: order
      };

      await API.graphql(graphqlOperation(updateDegree, { input }));
      return { result: true, message: `Degree updated successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error updating degree, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };
  //#endregion

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Edit}
      returnHandler={() => props.history.push(`/degrees`)}
      modelName="Degree"
      getModelMethod={async () => {
        let result = await API.graphql(
          graphqlOperation(getDegree, { id: props.match.params.degreeId })
        );
        if (result) {
          setName(result.data.getDegree.name);
          setOrder(result.data.getDegree.order);
        }
        return result.data.getDegree;
      }}
      createOrUpdateDisableMethod={(modelData) => {
        if (modelData.id === DegreesConstants.Other.id) return true;
        return false;
      }}
      createOrUpdateDisabledActions={() => {
        setInputFieldsDisable(true);
      }}
      formSubmissionHandler={handleUpdateDegree}
      formValidationHandler={() =>
        nameOnlyFormsValidationHandler(
          name,
          "Degree",
          true, // we need to exclude the OTHER
          setUpdateButtonDisableStatus,
          setErrors
        )
      }
      setErrorsState={() => setErrors}
      actionButtonDisabledState={updateButtonDisableStatus}
    >
      <TextInput
        type="text"
        id="name"
        label="Degree Name"
        placeholder="Enter a Degree name"
        value={name}
        onChange={(e) =>
          nameChangeHandler(
            e,
            "Degree",
            true, // we need to exclude the OTHER,
            setName,
            setUpdateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.name}
        disabled={inputFieldsDisable}
      />
      <TextInput
        type="number"
        id="order"
        label="Degree Order"
        placeholder="Enter a Degree Order"
        value={order}
        onChange={(e) =>
          orderChangeHandler(
            e,
            "Degree",
            setOrder,
            setUpdateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.order}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default DegreeEdit;
