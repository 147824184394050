import React from "react";
import { getUser } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const UserNotificationSettings = (props) => {
  return (
    <GeneralInfo
      modelId={props.userId}
      modelName={"User"}
      listOfProperties={[
        {
          key: 11,
          attributeName: "notificationsEmailEnabled",
          attributeTitle: "Email Notification",
          description: "Email Notification Setting Enabled",
          dataType: DataTypes.Boolean,
        },
        {
          key: 11,
          attributeName: "notificationsLikeEnabled",
          attributeTitle: "Like Notification",
          description: "Like Notification Setting Enabled",
          dataType: DataTypes.Boolean,
        },
        {
          key: 12,
          attributeName: "notificationsCommentEnabled",
          attributeTitle: "Comment Notification",
          description: "Comment Notification Setting Enabled",
          dataType: DataTypes.Boolean,
        },
        {
          key: 13,
          attributeName: "notificationsNewFollowerEnabled",
          attributeTitle: "New Follower Notification",
          description: "New Follower Notification Setting Enabled",
          dataType: DataTypes.Boolean,
        },
        {
          key: 14,
          attributeName: "notificationsFriendRequestEnabled",
          attributeTitle: "Friend Request Notification",
          description: "Friend Request Notification Setting Enabled",
          dataType: DataTypes.Boolean,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getUser}
      queryFunctionName={"getUser"}
    />
  );
};

UserNotificationSettings.propTypes = {
  userId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

UserNotificationSettings.defaultProps = {
  providedModelData: null,
};

export default UserNotificationSettings;
