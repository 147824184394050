import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getPointSystem } from "../../../../graphql/queries";
import { updatePointSystem } from "../../../../graphql/mutations";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import * as PointSystemsConstants from "../../../../graphql/Constants/PointSystems/Types";
import nameChangeHandler from "../../../Common/Crud/formHandlers/nameChangeHandler";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
import nameOnlyFormsValidationHandler from "../../../Common/Crud/formHandlers/nameOnlyFormsValidationHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";

const PointSystemEdit = (props) => {
  // states
  const [pointsystemType, setPointSystemType] = useState("");
  const [point, setPoint] = useState(0);
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [updateButtonDisableStatus, setUpdateButtonDisableStatus] = useState(
    false
  );

  //#region update pointSystem business logic
  const handleUpdatePointSystem = async () => {
    try {
      // initial required Skill update data
      let input = {
        id: props.match.params.pointSystemId,
        point: point,
      };

      await API.graphql(graphqlOperation(updatePointSystem, { input }));
      return { result: true, message: `pointSystem updated successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error updating pointSystem, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };
  //#endregion

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Edit}
      returnHandler={() => props.history.push(`/pointSystems`)}
      modelName="Point System"
      getModelMethod={async () => {
        let result = await API.graphql(
          graphqlOperation(getPointSystem, {
            id: props.match.params.pointSystemId,
          })
        );
        if (result) {
          setPointSystemType(result.data.getPointSystem.pointType);
          setPoint(result.data.getPointSystem.point);
        }
        return result.data.getPointSystem;
      }}
      formSubmissionHandler={handleUpdatePointSystem}
      formValidationHandler={() =>
        nameOnlyFormsValidationHandler(
          pointsystemType,
          "PointSystem",
          true, // we need to exclude the OTHER
          setUpdateButtonDisableStatus,
          setErrors
        )
      }
      setErrorsState={() => setErrors}
      actionButtonDisabledState={updateButtonDisableStatus}
    >
      <TextInput
        type="text"
        id="pointsystemType"
        label="Point System type"
        placeholder=""
        value={pointsystemType}
        onChange={(e) =>
          nameChangeHandler(
            e,
            "Point System",
            true, // we need to exclude the OTHER,
            setPointSystemType,
            setUpdateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.pointsystemType}
        disabled={true}
      />
      <TextInput
        type="number"
        id="point"
        label="Points"
        placeholder="Enter a Point System point"
        value={point}
        onChange={(event) => setPoint(event.target.value)}
        isRequired={true}
        error={errors.order}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default PointSystemEdit;
