import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateDomain } from "../../../../graphql/mutations";
import { getDomain } from "../../../../graphql/queries";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import DomainValidation from "../DomainValidation";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
// https://www.npmjs.com/package/is-valid-domain

const DomainEdit = (props) => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [name, setName] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [updateButtonDisableStatus, setCreateButtonDisableStatus] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(true);

  const getDomainData = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getDomain, { id: props.match.params.domainId })
      );
      if (result) {
        setName(result.data.getDomain.name);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(
        `Error while fetching domain data, ${JSON.stringify(error, null, 2)}`
      );
    }
  };

  useEffect(() => {
    getDomainData();
  }, []);

  //#region  handle domain change including validation checks
  const handleDomainNameChange = (event) => {
    const _errors = {};
    let providedName = event.target.value;
    setName(providedName);
    // check the domain validation
    let validationResult = DomainValidation(providedName);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setCreateButtonDisableStatus(true);
    } else {
      setCreateButtonDisableStatus(false);
    }
    setErrors(_errors);
  };
  //#endregion

  //#region checling form is valid
  const isFormValid = () => {
    const _errors = {};

    // domain name is already checked and status button is already disabled
    // double check here
    // check the domain validation
    let validationResult = DomainValidation(name);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setCreateButtonDisableStatus(true);
    } else {
      setCreateButtonDisableStatus(false);
    }
    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };
  //#endregion

  //#region Handle form submit
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    // create school at this point
    let result = await handleCreateDomain();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        if (props.match.params.collegeId) {
          props.history.push(
            `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Domains`
          );
        } else {
          props.history.push(
            `/schools/details/${props.match.params.schoolId}/tab/Domains`
          );
        }
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };
  //#endregion

  //#region  create domain business logic
  const handleCreateDomain = async () => {
    try {
      // initial required domain creation data
      let input = {
        id: props.match.params.domainId,
        name: name,
      };

      await API.graphql(graphqlOperation(updateDomain, { input }));
      return { result: true, message: `Domain updated successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error updating domain, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };
  //#endregion

  //#region handle return
  const handleReturn = () => {
    // check how we got here from schools or from college
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Domains`
      );
    } else {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/Domains`
      );
    }
  };
  //#endregion

  return (
    <PagePreLoad isLoading={isLoading}>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header="Edit Domain"
          submitButton={
            <ButtonInput
              label="Update"
              disabled={updateButtonDisableStatus}
              variant={BootstrapVariants.Primary}
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
          backButton={
            <ButtonInput
              label="back"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}

          <TextInput
            type="name"
            id="name"
            label="Domain Name"
            placeholder="Enter a domain"
            value={name}
            onChange={(e) => handleDomainNameChange(e)}
            isRequired={true}
            error={errors.name}
          />
        </CardContainer>
      </Form>
    </PagePreLoad>
  );
};

export default DomainEdit;
