const nameVerification = (providedName, modelName, excludeOtherType) => {
    // trim the provided value
    providedName = providedName.trim();
  
    // define the base result
    let result = { result: true, message: "" };
  
    // apply the logic
    if (!providedName || providedName.length === 0) {
      result.result = false;
      result.message = `${modelName} Name is required`;
    }
    
    // if we need to exclude OTHER from the names
    if(excludeOtherType && excludeOtherType === true){
        if (providedName.toLowerCase() === "other") {
            result.result = false;
            result.message = "Can not have OTHER as name";
          }
    }
  
    // return the result of validation logic
    return result;
  };
  
  export default nameVerification;
  