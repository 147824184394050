const { API, graphqlOperation } = require("aws-amplify");
const { updateUser } = require("../../../../graphql/mutations");

const updateUserStarPointsByUserId = async (userId, newPoints) => {
  let operationResult = { result: false, message: "" };

  try {
    const result = await API.graphql(
      graphqlOperation(updateUser, {
        input: { id: userId, numberOfStarPoints: newPoints },
      })
    );

    // apply the result
    operationResult.result = true;
  } catch (error) {
    operationResult.message = `Error while updating user points ${JSON.stringify(
      error
    )}`;
  }

  return operationResult;
};

export default updateUserStarPointsByUserId;
