import { Auth, API } from "aws-amplify";
import * as HttpMethodTypes from "../../Constants/HttpMethodTypes";

const ExecuteAdminQuery = async (
  apiName,
  path,
  init,
  httpMethodType = HttpMethodTypes.GET
) => {
  let result = {
    success: true,
    errorMessage: "",
    resultItem: null,
  };

  try {
    // add auth headers
    init = {
      ...init,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let queryResult = null;
    if (httpMethodType === HttpMethodTypes.GET) {
      queryResult = await API.get(apiName, path, init);
    } else if (httpMethodType === HttpMethodTypes.POST) {
      queryResult = await API.post(apiName, path, init);
    }

    // check if we got the result and list back
    if (queryResult) {
      if (queryResult.success == null) {
        result.success = true;
        result.resultItem = queryResult;
      } else {
        result.success = queryResult.success;
        result.errorMessage = queryResult.message;
      }
    }

    return result;
  } catch (error) {
    result.success = false;
    result.errorMessage = JSON.stringify(error, null, 2);
  }
};

export default ExecuteAdminQuery;
