import React, { useState, useRef } from "react";
import { Storage } from "aws-amplify";
import CardContainer from "../../../components/Common/CardContainer";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import { ProgressBar, Table } from "react-bootstrap";
import IsChecked from "../../../components/Common/IsChecked";
import * as XLSX from "xlsx";
import ButtonInput from "../../../components/Common/ButtonInput";

const SchoolsInitialSettings = (props) => {
  // default defined excel file at s3
  const truliSchoolsListName = "TRULI_SCHOOLS_LIST.xlsx";

  // page preload number of actions
  const requiredActionsBeforePageIsLoaded = 0;
  let finishedActionsCount = 0;
  const [finishedActions, setFinishedActions] = useState(0);

  // state indicating the process had been started
  const [procedureStarted, setProcedureStarted] = useState(false);

  // state indicating and showing the over all errors
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // upload input disable state
  const [uploadInputDisable, setUploadInputDisable] = useState(false);
  const inputFileRef = useRef(null);

  // states required to render the Structure check process
  const [structureCheckProgressShow, setStructureCheckProgressShow] = useState(
    false
  );
  const [
    structureCheckProgressState,
    setStructureCheckProgressState,
  ] = useState(0);
  const [
    structureCheckProgressVariant,
    setStructureCheckProgressVariant,
  ] = useState("info");
  const [structureCheckIsCheck, setStructureCheckIsCheck] = useState(null);

  // states required to render the Upload to S3 process
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState(0);
  const [uploadProgressStateVariant, setuploadProgressStateVariant] = useState(
    "info"
  );
  const [uploadProgressIsCheck, setUploadProgressIsCheck] = useState(null);

  // showing the json result
  const [jsonResult, setJsonResult] = useState(null);

  // event to start the process after providing the file on file input
  const onChange = async (e) => {
    // disable the input file
    setUploadInputDisable(true);

    // start
    setProcedureStarted(true);
    const file = e.target.files[0];
    // schema structure check
    schemaCheck(file, (schemaCheckResult) => {
      // upload to s3 if sxhema check passes
      if (schemaCheckResult) {
        uploadExcelToS3(file);
      }
    });
  };

  // defining the default values
  let numberOfRequiredActions = 0;
  let numberOfAccomplishedActions = 0;
  let overallStatus = true;
  let overallErrorMessage = "";

  // defining the column Names for the excel file schema
  const COLUMN_SCHOOL_ID = "A";
  const COLUMN_SCHOOL_NAME = "B";
  const COLUMN_SCHOOL_DOMAIN_ID = "C";
  const COLUMN_SCHOOL_DOMAIN_NAME = "D";
  const COLUMN_SCHOOL_CLASS_ID = "E";
  const COLUMN_SCHOOL_CLASS_NAME = "F";
  const COLUMN_SCHOOL_COLLEGE_ID = "G";
  const COLUMN_SCHOOL_COLLEGE_NAME = "H";
  const COLUMN_SCHOOL_COLLEGE_DOMAIN_ID = "I";
  const COLUMN_SCHOOL_COLLEGE_DOMAIN_NAME = "J";
  const COLUMN_SCHOOL_COLLEGE_CLASS_ID = "K";
  const COLUMN_SCHOOL_COLLEGE_CLASS_NAME = "L";

  // increase the structure progress completion
  const increaseStructureProgress = () => {
    ++numberOfAccomplishedActions;
    setStructureCheckProgressState(
      (numberOfAccomplishedActions / numberOfRequiredActions) * 100
    );
  };

  // reusable logic in order to check if a cell has the values
  const cellValidationCheck = (cellNumber, sheetData) => {
    const cellItem = sheetData[cellNumber];
    if (cellItem && cellItem.v) {
      increaseStructureProgress();
    } else {
      overallStatus = false;
      overallErrorMessage += ` ${cellNumber} doesn't have a value`;
    }
  };

  // getting list of keys at a specific column
  const cellKeysAtColumn = (columnName, arrayOfKeys) => {
    const firstCell = columnName + "1";
    return arrayOfKeys.filter(
      (key) => key.startsWith(columnName) && key !== firstCell
    );
  };

  // define the ranges based on the excel file for schools
  const defineSchoolBasedRanges = (schoolIds) => {
    const schoolRangesResult = [];
    for (let index = 0; index < schoolIds.length; ++index) {
      let startRange = schoolIds[index].substring(1);
      let endRange = (parseInt(schoolIds[index].substring(1)) + 50).toString(); // default value which will be used for the last item
      if (index !== schoolIds.length - 1) {
        endRange = (parseInt(schoolIds[index + 1].substring(1)) - 1).toString();
      }
      let newRange = [startRange, endRange];
      schoolRangesResult.push(newRange);

      increaseStructureProgress();
    }
    return schoolRangesResult;
  };

  // getting all the required list of keys by parsing the excel file
  const parseListOfKeys = (arrayOfKeys) => {
    return {
      schoolIds: cellKeysAtColumn(COLUMN_SCHOOL_ID, arrayOfKeys),
      schoolNames: cellKeysAtColumn(COLUMN_SCHOOL_NAME, arrayOfKeys), // finding school names
      schoolDomainIds: cellKeysAtColumn(COLUMN_SCHOOL_DOMAIN_ID, arrayOfKeys), // finding school domain Ids
      schoolDomainNames: cellKeysAtColumn(
        COLUMN_SCHOOL_DOMAIN_NAME,
        arrayOfKeys
      ), // finding school domain Names
      schoolClassIds: cellKeysAtColumn(COLUMN_SCHOOL_CLASS_ID, arrayOfKeys), // finding school classes Ids
      schoolClassNames: cellKeysAtColumn(COLUMN_SCHOOL_CLASS_NAME, arrayOfKeys), // finding school classes names
      schoolCollegeIds: cellKeysAtColumn(COLUMN_SCHOOL_COLLEGE_ID, arrayOfKeys), // finding school college Ids
      schoolCollegeNames: cellKeysAtColumn(
        COLUMN_SCHOOL_COLLEGE_NAME,
        arrayOfKeys
      ), // finding school collge names
      schoolCollegeDomainIds: cellKeysAtColumn(
        COLUMN_SCHOOL_COLLEGE_DOMAIN_ID,
        arrayOfKeys
      ), // finding college domain Ids
      schoolCollegeDomainNames: cellKeysAtColumn(
        COLUMN_SCHOOL_COLLEGE_DOMAIN_NAME,
        arrayOfKeys
      ), // finding college domain Names
      collegeClassIds: cellKeysAtColumn(
        COLUMN_SCHOOL_COLLEGE_CLASS_ID,
        arrayOfKeys
      ), // finding college class Ids
      collegeClassNames: cellKeysAtColumn(
        COLUMN_SCHOOL_COLLEGE_CLASS_NAME,
        arrayOfKeys
      ), // finding college class Names
    };
  };

  // defining how many actions are required to be done
  const defineRequiredActions = (
    schoolIds,
    schoolNames,
    schoolDomains,
    schoolClassIds,
    schoolClassNames,
    schoolCollegeIds,
    schoolCollegeNames,
    schoolCollegeDomains,
    collegeClassIds,
    collegeClassNames
  ) => {
    numberOfRequiredActions = schoolIds.length; // school Id should have corrosponding school name
    numberOfRequiredActions += schoolNames.length; // school name should have a corrosponding id
    numberOfRequiredActions += schoolIds.length; // getting the ranges
    numberOfRequiredActions += schoolIds.length; // getting the school object ready
    numberOfRequiredActions += schoolDomains.length; // getting the domains if available
    numberOfRequiredActions += schoolClassIds.length; // school class Id must have a corrosponding school class name
    numberOfRequiredActions += schoolClassNames.length; // school class Name must have a corrosponding school class ID
    numberOfRequiredActions += schoolClassNames.length; // getting school classes if available
    numberOfRequiredActions += schoolCollegeIds.length; // getting school college Ids must have a corrosponding college name
    numberOfRequiredActions += schoolCollegeNames.length; // getting school college Names must have a corrosponding college Id
    numberOfRequiredActions += schoolCollegeIds.length; // getting school colleges if Available
    numberOfRequiredActions += schoolCollegeDomains.length; // getting college domains if Available
    numberOfRequiredActions += collegeClassIds.length; // getting college class Ids must have a corrosponding college class name
    numberOfRequiredActions += collegeClassIds.length; // getting college class Ids must have a corrosponding college class name
    numberOfRequiredActions += collegeClassNames.length; // getting college college Names must have a corrosponding college class Id
  };

  // logic to check if cell values at different columns have dependencies to other valus at other columns
  // e.g schoold Id must have a school name on the side
  const corrospondingValidationChecks = (
    schoolIds,
    schoolNames,
    schoolDomains,
    schoolClassIds,
    schoolClassNames,
    schoolCollegeIds,
    schoolCollegeNames,
    schoolCollegeDomains,
    collegeClassIds,
    collegeClassNames,
    sheetData
  ) => {
    // for each school Id there should be a corrosponding school name
    schoolIds.map((school) => {
      // finding corrosponsing school
      cellValidationCheck(COLUMN_SCHOOL_NAME + school.substring(1), sheetData);
    });

    // for each school name there should be a school Id
    schoolNames.map((schoolName) => {
      // finding corrosponsing school Id
      cellValidationCheck(COLUMN_SCHOOL_ID + schoolName.substring(1), sheetData);
    });

    schoolClassIds.map((schoolClassId) => {
      // finding corrosponsing school Class Name
      cellValidationCheck(COLUMN_SCHOOL_CLASS_NAME + schoolClassId.substring(1), sheetData);
    });

    schoolClassNames.map((schoolClassName) => {
      // finding corrosponsing school Class Name
      cellValidationCheck(COLUMN_SCHOOL_CLASS_ID + schoolClassName.substring(1), sheetData);
    });

    schoolCollegeIds.map((schoolCollegeId) => {
      // finding corrosponsing school college Name
      cellValidationCheck(COLUMN_SCHOOL_COLLEGE_NAME + schoolCollegeId.substring(1), sheetData);
    });

    schoolCollegeNames.map((schoolCollegeName) => {
      // finding corrosponsing school college ID
      cellValidationCheck(COLUMN_SCHOOL_COLLEGE_ID + schoolCollegeName.substring(1), sheetData);
    });

    collegeClassIds.map((collegeClassId) => {
      // finding corrosponsing college class Name
      cellValidationCheck(COLUMN_SCHOOL_COLLEGE_CLASS_NAME + collegeClassId.substring(1), sheetData);
    });

    collegeClassNames.map((collegeClassName) => {
      // finding corrosponsing college class ID
      cellValidationCheck(COLUMN_SCHOOL_COLLEGE_CLASS_ID + collegeClassName.substring(1), sheetData);
    });
  };

  // convinient method
  const convertListOfItemsInListOfKeysToListOfValuesInARange = (
    providedList,
    providedRange,
    sheetData,
    valueColumnName
  ) => {
    // getting school domains keys
    let listOfKeys = providedList.filter(
      (listItem) =>
        parseInt(listItem.substring(1)) >= parseInt(providedRange[0]) &&
        parseInt(listItem.substring(1)) <= parseInt(providedRange[1])
    );

    return listOfKeys.map((key) => {
      // getting the domains
      increaseStructureProgress();

      return {
        id: sheetData[key].v,
        value: sheetData[valueColumnName + key.substring(1)].v,
      };
    });
  };

  // getting the list of school domain
  const gettingSchoolDomains = (
    schoolDomainIds,
    schoolObjectRange,
    sheetData
  ) => {
    // getting school domains keys
    return convertListOfItemsInListOfKeysToListOfValuesInARange(
      schoolDomainIds,
      schoolObjectRange,
      sheetData,
      COLUMN_SCHOOL_DOMAIN_NAME
    );

    // let schoolObjectDomainKeys = schoolDomains.filter(
    //   (schoolDomain) =>
    //     parseInt(schoolDomain.substring(1)) >= parseInt(schoolObjectRange[0]) &&
    //     parseInt(schoolDomain.substring(1)) <= parseInt(schoolObjectRange[1])
    // );

    // return schoolObjectDomainKeys.map((key) => {
    //   // getting the domains
    //   increaseStructureProgress();
    //   return sheetData[key].v;
    // });
  };

  const gettingSchoolClasses = (
    schoolClassIds,
    schoolObjectRange,
    sheetData
  ) => {

    return convertListOfItemsInListOfKeysToListOfValuesInARange(
      schoolClassIds,
      schoolObjectRange,
      sheetData,
      COLUMN_SCHOOL_CLASS_NAME
    );


    // let schoolClassesKeys = schoolClassIds.filter(
    //   (schoolClass) =>
    //     parseInt(schoolClass.substring(1)) >= parseInt(schoolObjectRange[0]) &&
    //     parseInt(schoolClass.substring(1)) <= parseInt(schoolObjectRange[1])
    // );
    // schoolClassesKeys.map((key) => {
    //   // getting the school classes
    //   increaseStructureProgress();

    //   return {
    //     id: sheetData[key].v,
    //     name: sheetData["E" + key.substring(1)].v,
    //   };
    // });
  };

  const gettingSchoolColleges = (
    schoolCollegeIds,
    schoolCollegeDomainIds,
    schoolCollegeDomainNames,
    collegeClassIds,
    schoolObjectRange,
    sheetData
  ) => {
    let schoolCollegeKeys = schoolCollegeIds.filter(
      (schoolCollege) =>
        parseInt(schoolCollege.substring(1)) >=
          parseInt(schoolObjectRange[0]) &&
        parseInt(schoolCollege.substring(1)) <= parseInt(schoolObjectRange[1])
    );

    let collegesList = [];
    for (let index = 0; index < schoolCollegeKeys.length; ++index) {
      let newSchoolCollege = {
        id: sheetData[schoolCollegeKeys[index]].v,
        name: sheetData[COLUMN_SCHOOL_COLLEGE_NAME + schoolCollegeKeys[index].substring(1)].v,
      };

      // getting the school college
      increaseStructureProgress();

      // get college domains inside the college range
      let collegeRange = getCollegeRange(
        schoolCollegeKeys,
        index,
        schoolObjectRange
      );

      // getting college domains values
      newSchoolCollege.domains = gettingCollegeDomains(
        schoolCollegeDomainIds,
        collegeRange,
        sheetData
      );

      // get college classes
      newSchoolCollege.classes = getCollegeClasses(
        collegeClassIds,
        collegeRange,
        sheetData
      );

      collegesList.push(newSchoolCollege);
    }

    return collegesList;
  };

  const getCollegeRange = (schoolCollegeKeys, index, schoolObjectRange) => {
    // last Item
    let collegeRange = [
      schoolCollegeKeys[index].substring(1),
      schoolObjectRange[1],
    ];
    if (index !== schoolCollegeKeys.length - 1) {
      collegeRange = [
        schoolCollegeKeys[index].substring(1),
        (parseInt(schoolCollegeKeys[index + 1].substring(1)) - 1).toString(),
      ];
    }
    return collegeRange;
  };

  const gettingCollegeDomains = (
    schoolCollegeDomainIds,
    collegeRange,
    sheetData
  ) => {
    return convertListOfItemsInListOfKeysToListOfValuesInARange(
      schoolCollegeDomainIds,
      collegeRange,
      sheetData,
      COLUMN_SCHOOL_COLLEGE_DOMAIN_NAME
    );
    // getting college domains keys
    // let collgeDomainKeys = schoolCollegeDomains.filter(
    //   (collegeDomain) =>
    //     parseInt(collegeDomain.substring(1)) >= parseInt(collegeRange[0]) &&
    //     parseInt(collegeDomain.substring(1)) <= parseInt(collegeRange[1])
    // );
    // return collgeDomainKeys.map((key) => {
    //   // getting the domains
    //   increaseStructureProgress();
    //   return sheetData[key].v;
    // });
  };

  const getCollegeClasses = (collegeClassIds, collegeRange, sheetData) => {
    return convertListOfItemsInListOfKeysToListOfValuesInARange(
      collegeClassIds,
      collegeRange,
      sheetData,
      COLUMN_SCHOOL_COLLEGE_CLASS_NAME
    );
    // let collgeClassKeys = collegeClassIds.filter(
    //   (collegeClassId) =>
    //     parseInt(collegeClassId.substring(1)) >= parseInt(collegeRange[0]) &&
    //     parseInt(collegeClassId.substring(1)) <= parseInt(collegeRange[1])
    // );
    // return collgeClassKeys.map((key) => {
    //   // getting the collge classes
    //   increaseStructureProgress();

    //   return {
    //     id: sheetData[key].v,
    //     name: sheetData["J" + key.substring(1)].v,
    //   };
    // });
  };

  const gettingSchools = (
    schoolIds,
    schoolRanges,
    schoolDomainIds,
    schoolDomains,
    schoolClassIds,
    schoolCollegeIds,
    schoolCollegeDomainIds,
    schoolCollegeDomainNames,
    collegeClassIds,
    sheetData
  ) => {
    let schoolsList = [];
    for (let index = 0; index < schoolIds.length; ++index) {
      let newschoolObject = {
        id: sheetData["A" + schoolIds[index].substring(1)].v,
        name: sheetData["B" + schoolIds[index].substring(1)].v,
      };


      // getting the school object ready
      increaseStructureProgress();

      // getting school range
      let schoolObjectRange = schoolRanges[index];

      // getting school domains values
      newschoolObject.domains = gettingSchoolDomains(
        schoolDomainIds,
        schoolObjectRange,
        sheetData
      );

      // getting school classes
      newschoolObject.classes = gettingSchoolClasses(
        schoolClassIds,
        schoolObjectRange,
        sheetData
      );

      // getting school colleges
      newschoolObject.colleges = gettingSchoolColleges(
        schoolCollegeIds,
        schoolCollegeDomainIds,
        schoolCollegeDomainNames,
        collegeClassIds,
        schoolObjectRange,
        sheetData
      );

      // add defined school object
      schoolsList.push(newschoolObject);
    }
    return schoolsList;
  };

  // schema structure check
  const schemaCheck = (providedFile, callback) => {
    // read excel file line by line and check if the structure is correct
    setStructureCheckProgressShow(true);

    try {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

        // at this point ws will be an object with keys like A1, A2
        const arrayOfKeys = Object.keys(ws);

        // 1. Rule
        const {
          schoolIds,
          schoolNames,
          schoolDomainIds, // finding school domain Ids
          schoolDomainNames, // finding school domain Names
          schoolClassIds, // finding school classes Ids
          schoolClassNames, // finding school classes names
          schoolCollegeIds, // finding school college Ids
          schoolCollegeNames, // finding school collge names
          schoolCollegeDomainIds, // finding college domain Ids
          schoolCollegeDomainNames, // finding college domain Names
          collegeClassIds, // finding college class Ids
          collegeClassNames, // finding college class Names
        } = parseListOfKeys(arrayOfKeys);

        // defining the required actions
        defineRequiredActions(
          schoolIds,
          schoolNames,
          schoolDomainNames,
          schoolClassIds,
          schoolClassNames,
          schoolCollegeIds,
          schoolCollegeNames,
          schoolCollegeDomainNames,
          collegeClassIds,
          collegeClassNames
        );

        // checking the corrosponsing validation checks
        // e.g school Id must have a school name beside
        corrospondingValidationChecks(
          schoolIds,
          schoolNames,
          schoolDomainNames,
          schoolClassIds,
          schoolClassNames,
          schoolCollegeIds,
          schoolCollegeNames,
          schoolCollegeDomainNames,
          collegeClassIds,
          collegeClassNames,
          ws
        );

        // school ranges
        const schoolRanges = defineSchoolBasedRanges(schoolIds);

        // getting schools
        let schoolObjectList = gettingSchools(
          schoolIds,
          schoolRanges,
          schoolDomainIds,
          schoolDomainNames,
          schoolClassIds,
          schoolCollegeIds,
          schoolCollegeDomainIds,
          schoolCollegeDomainNames,
          collegeClassIds,
          ws
        );

        if (overallStatus) {
          setStructureCheckIsCheck(true);
          setStructureCheckProgressVariant("success");
          setJsonResult(JSON.stringify(schoolObjectList, null, 2));
        } else {
          setStructureCheckIsCheck(false);
          setStructureCheckProgressVariant("danger");
          setIsError(true);
          setErrorMessage(overallErrorMessage);
        }

        callback(overallStatus);
      };
      reader.readAsBinaryString(providedFile);
    } catch (error) {
      setIsError(true);
      setErrorMessage(error);
    }
  };

  // upload excel file to s3
  const uploadExcelToS3 = (providedFile) => {
    setUploadProgressShow(true);
    Storage.put(`${truliSchoolsListName}`, providedFile, {
      contentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        setUploadProgressState((progress.loaded / progress.total) * 100);
      },
    })
      .then((result) => {
        console.log(result);
        setuploadProgressStateVariant("success");
        setUploadProgressIsCheck(true);
        // setIsUploaded(true);
        // getObjectFromS3(result.key);
      })
      .catch((err) => {
        console.log(err);
        setuploadProgressStateVariant("danger");
        setUploadProgressIsCheck(false);
      });
  };

  //   const getObjectFromS3 = (objectKey) => {
  //     Storage.get(objectKey)
  //       .then((result) => {
  //         console.log(result);
  //         setUploadedUrl(result);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  const handleRestart = () => {
    setUploadInputDisable(false);
    setProcedureStarted(false);

    // empty input file
    inputFileRef.current.value = "";

    // clean the json result
    setJsonResult(null);

    // start the process
    // clean up
    setIsError(false);
    setErrorMessage("");
    setStructureCheckProgressShow(false);
    setStructureCheckProgressState(0);
    setStructureCheckProgressVariant("info");
    setStructureCheckIsCheck(null);
    //
    setUploadProgressShow(false);
    setUploadProgressState(0);
    setuploadProgressStateVariant("info");
    setUploadProgressIsCheck(null);
  };

  return (
    <PagePreLoad
      isLoading={finishedActions < requiredActionsBeforePageIsLoaded}
    >
      <CardContainer
        header="Schools/Colleges/Classes Initial"
        title="Populate Schools/Colleges/Classes"
        text="Provide the Excel file"
        submitButton={
          <ButtonInput label="Restart" onClick={handleRestart}></ButtonInput>
        }
      >
        <>
          <CardContainer header="Upload file" style={{ marginBottom: "20px" }}>
            <input
              type="file"
              accept=".xlsx"
              onChange={(evt) => onChange(evt)}
              disabled={uploadInputDisable}
              ref={inputFileRef}
            />
          </CardContainer>

          {isError && (
            <CardContainer
              header="Error!"
              title="Some errors have occured!"
              text={errorMessage}
              style={{ marginBottom: "20px", backgroundColor: "red" }}
            ></CardContainer>
          )}

          {procedureStarted && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Process</th>
                  <th style={{ width: "70%" }}>Progress</th>
                  <th style={{ width: "10%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Structure Check</td>
                  <td>
                    {structureCheckProgressShow && (
                      <ProgressBar
                        now={structureCheckProgressState}
                        label={`${structureCheckProgressState}%`}
                        variant={structureCheckProgressVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {structureCheckIsCheck && (
                      <IsChecked status={structureCheckIsCheck} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Upload</td>
                  <td>
                    {uploadProgressShow && (
                      <ProgressBar
                        now={uploadProgressState}
                        label={`${uploadProgressState}%`}
                        variant={uploadProgressStateVariant}
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {uploadProgressIsCheck && (
                      <IsChecked status={uploadProgressIsCheck} />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}

          {jsonResult && (
            <pre>
              <code>{jsonResult}</code>
            </pre>
          )}
        </>
      </CardContainer>
    </PagePreLoad>
  );
};

export default SchoolsInitialSettings;
