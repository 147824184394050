import React from "react";
import { searchReports } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const CommentReports = (props) => {
  return (
    <DataGrid
      {...props}
      header="reportCommentUserName"
      gridObjectDataModel={[
        {
          name: "reportCommentUserName",
          headerText: "Reported Comment Owner",
          type: DataTypes.String,
        },
        {
          name: "isAcknowledged",
          headerText: "Is Acknowledged",
          type: DataTypes.Boolean,
        },
        { name: "reportByUserName", headerText: "Reported By", type: DataTypes.String },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.CommentReport}
      queryFunction={searchReports}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchReports"}
      queryFilters={{
        reportCommentId: {
          exists: true,
        },
      }}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      baseRoute={"commentReports"}
    />
  );
};

export default CommentReports;
