import { Storage } from "aws-amplify";

const getObjectFromS3 = async (objectKey) => {
  let getObjectResult = { result: false, url: "", errorMessage: "" };
  let storageResult = await Storage.get(objectKey);

  if (storageResult) {
    getObjectResult.result = true;
    getObjectResult.url = storageResult;
    return getObjectResult;
  } else {
    getObjectResult.errorMessage = JSON.stringify(storageResult);
    return getObjectResult;
  }
};

export default getObjectFromS3;
