import React from "react";
import BatchCreation from "../../../Common/Crud/BatchCreation";
import { getSchool, listSchoolColleges } from "../../../../graphql/queries";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const CollegeBatchCreate = (props) => {
  return (
    <BatchCreation
      listQuery={listSchoolColleges}
      listQueryName="listSchoolColleges"
      //   listQueryAttributes={}
      listQueryFilters={{
        deletedAt: {
          eq: null,
        },
        schoolId: {
          eq: props.match.params.schoolId,
        },
      }}
      modelName={"College"}
      schemaModelName={ModelTypes.College}
      parentModelName={ModelTypes.School}
      parentModelId={props.match.params.schoolId}
      //   ancestorModelId={}
      parentModelQueryFunction={getSchool}
      parentModelQueryFunctionName="getSchool"
      returnMethod={() => {
        props.history.push(
          `/schools/details/${props.match.params.schoolId}/tab/Colleges`
        );
      }}
    />
  );
};

export default CollegeBatchCreate;
