import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import CardContainer from "../../../../components/Common/CardContainer";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import dateTimeConvertProvidedData from "../handlers/dateTimeConvertProvidedData";
import commandClickHandler from "../handlers/commandClickHandler";
import toolbarOptionsGenerator from "../handlers/toolbarOptionsGenerator";
import commandOptionsGenerator from "../handlers/commandOptionsGenerator";
import columnsGenerator from "../handlers/columnsGenerator";
import uriDecodeProvidedData from "../handlers/uriDecodeProvidedData";

const DataGridWithProvidedData = (props) => {
  //#region  props
  // destructuring props
  const {
    data,
    header,
    gridObjectDataModel,
    hasCreate,
    hasDetails,
    detailsActiveTab,
    hasEdit,
    hasDelete,
    hasBlock,
    hasProfane,
    baseRoute,
  } = props;
  //#endregion

  //#region states
  const [__Truli__Grid__PageSize, SET__Truli__Grid__PageSize] = useState(10);
  //#endregion

  // datetime convert the provided data
  let dateTimeConvertedResult = dateTimeConvertProvidedData(
    data,
    gridObjectDataModel
  );

  
  let uriConvertedResult = uriDecodeProvidedData(
    dateTimeConvertedResult,
    gridObjectDataModel
  );

  //#region refrence to the grids ( being used by both grid components )
  const grid = useRef();
  //#endregion

  //#region default grid page settings
  let pageSettings = {
    pageSize: __Truli__Grid__PageSize,
    pageSizes: true,
  };
  //#endregion

  //#region sort settings
  let sortingOptions = {
    columns: [{ field: "createdAt", direction: "Descending" }],
  };
  //#endregion

  //#region tool bar options

  // toolbaroptions
  const toolBarOptions = toolbarOptionsGenerator(hasCreate, false);

  //#endregion

  //#region command column setting
  const commands = commandOptionsGenerator(
    hasDetails,
    hasEdit,
    hasBlock,
    hasProfane,
    hasDelete
  );
  //#endregion

  //#region Tool bar actions
  const toolbarClickHandler = (args) => {
    if (grid) {
      switch (args.item.id) {
        case "create":
          props.history.push(`/${baseRoute}/create`);
          break;
        default:
          break;
      }
    }
  };
  //#endregion

  //#region command clicks handlers
  const gridCommandClickHandler = (args) => {
    commandClickHandler(args, props, grid, baseRoute, detailsActiveTab);
  };
  //#endregion

  //#region Defining the columns
  const columns = columnsGenerator(gridObjectDataModel);
  //#endregion

  return (
    <CardContainer header={header}>
      <GridComponent
        dataSource={uriConvertedResult}
        allowPaging={true}
        pageSettings={pageSettings}
        allowSorting={true}
        sortSettings={sortingOptions}
        ref={grid}
        toolbar={toolBarOptions}
        toolbarClick={toolbarClickHandler}
        commandClick={gridCommandClickHandler}
      >
        <ColumnsDirective>
          {columns}
          <ColumnDirective
            headerText="Commands"
            width="120"
            commands={commands}
          />
        </ColumnsDirective>
        <Inject
          services={[Page, Sort, Filter, Group, Toolbar, CommandColumn]}
        />
      </GridComponent>
    </CardContainer>
  );
};

DataGridWithProvidedData.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  gridObjectDataModel: PropTypes.array.isRequired,
  hasCreate: PropTypes.bool.isRequired,
  hasDetails: PropTypes.bool.isRequired,
  detailsActiveTab: PropTypes.string,
  hasEdit: PropTypes.bool.isRequired,
  hasDelete: PropTypes.bool.isRequired,
  hasBlock: PropTypes.bool.isRequired,
  hasProfane: PropTypes.bool.isRequired,
  baseRoute: PropTypes.string.isRequired,
};

DataGridWithProvidedData.defaultProps = {
  data: [],
  header: "Models",
  hasCreate: false,
  hasDetails: false,
  detailsActiveTab: null,
  hasEdit: false,
  hasDelete: false,
  hasBlock: false,
  hasProfane: false,
};

export default DataGridWithProvidedData;
