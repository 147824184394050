import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import { toast } from "react-toastify";

const GeneralInfo = (props) => {
  const {
    modelId,
    modelName,
    modelNameTitle,
    listOfProperties,
    queryFunction,
    queryFunctionName,
    providedModelData,
  } = props;

  const [modelData, setModelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getModelData = async (modelId) => {
    try {
      const result = await API.graphql(
        graphqlOperation(queryFunction, { id: modelId })
      );
      const { name, createdAt, updatedAt } = result.data[queryFunctionName];

      // create the items based on provided list of properties object array
      const modelData = createPresentationalModelList(listOfProperties, result);

      setModelData(modelData);

      setIsLoading(false);
    } catch (error) {
      toast.error(
        `Error while fetching ${modelName} data ${JSON.stringify(error)}`
      );
    }
  };

  const createPresentationalModelList = (
    listOfProperties,
    providedModelData
  ) => {
    return listOfProperties.map((property) => {
      return (
        <DetailsPresentationItem
          key={property.key}
          name={property.attributeTitle}
          value={
            property.attributeFetchFunction
              ? decodeURIComponent(
                  property.attributeFetchFunction(
                    providedModelData.data[queryFunctionName]
                  )
                )
              : decodeURIComponent(
                  providedModelData.data[queryFunctionName][
                    property.attributeName
                  ]
                )
          }
          description={property.description}
          dataType={property.dataType}
        />
      );
    });
  };

  useEffect(() => {
    if (providedModelData) {
      // if the data is passed to genertal infor, create the presentational model list based off provided data
      const modelData = createPresentationalModelList(
        listOfProperties,
        providedModelData
      );

      setModelData(modelData);
      setIsLoading(false);
    } else getModelData(modelId);
  }, []);

  return (
    <PagePreLoad isLoading={isLoading}>
      {modelData && (
        <DetailsPresentation
          itemsList={modelData}
          header={`${modelNameTitle ? modelNameTitle : modelName} General Info`}
        />
      )}
    </PagePreLoad>
  );
};

GeneralInfo.propTypes = {
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  modelNameTitle: PropTypes.string,
  listOfProperties: PropTypes.array.isRequired,
  queryFunction: PropTypes.string.isRequired,
  queryFunctionName: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

GeneralInfo.defaultProps = {
  providedModelData: null,
};

export default GeneralInfo;
