import React from "react";
import PageAccordion from "../../../components/Common/PageAccordion";
import AnalyticsCard from "../../../components/Common/AnalyticsCard";
import {
  FaCalendarAlt,
  FaUsers,
  FaClone,
  FaSchool,
  FaUsersCog,
  FaUniversity,
  FaRegistered,
  FaChair,
  FaStarHalfAlt,
  FaSuperpowers,
  FaVenusMars,
  FaComment,
} from "react-icons/fa";
import * as BootstrapVariants from "../../../constants/Bootstrap/Variants";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import { getModelAggregationsByType } from "../../../graphql/CustomQueries/ModelAggregations/getModelAggregationsByType";
import { toast } from "react-toastify";

function Dashboard() {
  const totalAggegationCountLoadFunction = async (
    aggregationType,
    AggregationTitle
  ) => {
    const result = await getModelAggregationsByType(aggregationType);
    if (result.errors) {
      toast.error(`Error Fetching total ${AggregationTitle} count`);
      return "0";
    }
    if (result.data && result.data.getModelAggregations) {
      return result.data.getModelAggregations.totalActiveCount.toString();
    }
  };

  const analyticsCardSize = "2em";
  return (
    <>
      <PageAccordion title="General Limited Analytics">
        <AnalyticsCard
          title="Today"
          text={new Date().toLocaleDateString()}
          icon={<FaCalendarAlt size={analyticsCardSize} />}
          variant={BootstrapVariants.Dark}
        />
        <AnalyticsCard
          title="Total Schools"
          icon={<FaSchool size={analyticsCardSize} />}
          variant={BootstrapVariants.Warning}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.School, "schools")
          }
        />
        <AnalyticsCard
          title="Total Colleges"
          icon={<FaUniversity size={analyticsCardSize} />}
          variant={BootstrapVariants.Success}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.College, "colleges")
          }
        />

        <AnalyticsCard
          title="Total Domains"
          icon={<FaRegistered size={analyticsCardSize} />}
          variant={BootstrapVariants.Info}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Domain, "domains")
          }
        />
        <AnalyticsCard
          title="Total Classes"
          icon={<FaChair size={analyticsCardSize} />}
          variant={BootstrapVariants.Primary}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Clazz, "classes")
          }
        />
        <AnalyticsCard
          title="Total Defined Degrees"
          icon={<FaStarHalfAlt size={analyticsCardSize} />}
          variant={BootstrapVariants.Danger}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Degree, "degrees")
          }
        />
        <AnalyticsCard
          title="Total Defined Skills"
          icon={<FaSuperpowers size={analyticsCardSize} />}
          variant={BootstrapVariants.Dark}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Skill, "skills")
          }
        />
        <AnalyticsCard
          title="Total Defined Genders"
          icon={<FaVenusMars size={analyticsCardSize} />}
          variant={BootstrapVariants.Warning}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Gender, "genders")
          }
        />
      </PageAccordion>
      <PageAccordion title="User Limited Analytics">
        <AnalyticsCard
          title="Total Users"
          icon={<FaUsers size={analyticsCardSize} />}
          variant={BootstrapVariants.Info}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.User, "users")
          }
        />
        {/* <AnalyticsCard
          title="Total Content Admin"
          text={"0"}
          icon={<FaUsersCog size={analyticsCardSize} />}
          variant={BootstrapVariants.Primary}
        /> */}
      </PageAccordion>
      <PageAccordion title="Post Limited Analytics">
        <AnalyticsCard
          title="Total Posts"
          icon={<FaClone size={analyticsCardSize} />}
          variant={BootstrapVariants.Success}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Post, "post")
          }
        />
        <AnalyticsCard
          title="Total Tags"
          icon={<FaClone size={analyticsCardSize} />}
          variant={BootstrapVariants.Danger}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.HashTag, "hashtags")
          }
        />
        <AnalyticsCard
          title="Total Comments"
          icon={<FaComment size={analyticsCardSize} />}
          variant={BootstrapVariants.Warning}
          text={"0"}
          withPreload={true}
          loadFunction={() =>
            totalAggegationCountLoadFunction(ModelTypes.Comment, "comments")
          }
        />
      </PageAccordion>
    </>
  );
}

export default Dashboard;
