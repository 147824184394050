import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const PagePreLoad = (props) => {
  return props.isLoading ? (
    <div
      style={{
        marginTop: "150px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="xl"
          role="status"
          aria-hidden="true"
          variant="danger"
        />
      </div>
    </div>
  ) : (
    props.children
  );
};

PagePreLoad.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

export default PagePreLoad;
