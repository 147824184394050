import React from "react";
import { searchHashTags } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const HashTags = (props) => {
  return (
    <DataGrid
      {...props}
      header="HashTags"
      gridObjectDataModel={[
        { name: "title", headerText: "Title", type: DataTypes.String },
        { name: "schoolName", headerText: "School Name", type: DataTypes.String },
        {
          name: "numberOfPosts",
          headerText: "Number Of Posts",
          type: DataTypes.Number,
        },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.HashTag}
      queryFunction={searchHashTags}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchHashTags"}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={false}
      baseRoute={"hashTags"}
    />
  );
};

export default HashTags;
