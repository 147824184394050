import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateSchool } from "../../../../graphql/mutations";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import { getSchool } from "../../../../graphql/queries";
import PagePreLoad from "../../../../components/Common/PagePreLoad";

const SchoolEdit = (props) => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [name, setName] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // get school data
  const getSchoolData = async (schoolId) => {
    try {
      const result = await API.graphql(
        graphqlOperation(getSchool, { id: schoolId })
      );
      setName(result.data.getSchool.name);
      setIsLoading(false);
    } catch (error) {
      toast.error(`Error while fetching school data ${JSON.stringify(error)}`);
    }
  };

  // mount hook
  useEffect(() => {
    getSchoolData(props.match.params.schoolId);
  }, []);

  // form validation
  const isFormValid = () => {
    const _errors = {};

    if (!name) _errors.name = "School name is required";

    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    // update school
    let result = await handleUpdateSchool();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        props.history.push("/schools");
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };

  const handleUpdateSchool = async () => {
    try {
      const input = {
        id: props.match.params.schoolId,
        name: name,
      };
      await API.graphql(graphqlOperation(updateSchool, { input }));
      return { result: true, message: `School update successfully` };
    } catch (error) {
      return { result: false, message: `Error updating school, ${error}` };
    }
  };

  // return back button
  const handleReturn = () => {
    props.history.push("/schools");
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header="Edit School"
          submitButton={
            <ButtonInput
              label="Update"
              variant={BootstrapVariants.Primary}
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
          backButton={
            <ButtonInput
              label="back"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}

          <TextInput
            type="name"
            id="name"
            label="School Name"
            placeholder="Enter a name for school"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isRequired={true}
            error={errors.name}
            value={name}
          />
        </CardContainer>
      </Form>
    </PagePreLoad>
  );
};

export default SchoolEdit;
