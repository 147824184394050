import React from "react";
import Sidebar from "../../components/Sidebar";

const Layout = () => {

  return (
    <>
      <Sidebar />
    </>
  );
};

export default Layout;
