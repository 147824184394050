import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../../../../graphql/queries";
import { updateUser } from "../../../../graphql/mutations";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import UserGeneralInfo from "../GeneralInfo";
import AdminDisableUser from "../../../../graphql/CustomAdminQueries/AdminDisableUser";
import AdminUserGlobalSignOut from "../../../../graphql/CustomAdminQueries/AdminUserGlobalSignOut";

const UserDelete = (props) => {
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [generalError, setGeneralError] = useState("");
  const [deletekButtonDisabledStatus, setDeleteButtonDisabledStatus] = useState(
    true
  );
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getUser, { id: props.match.params.userId })
      );
      const { id, userName, signedUpWithReferralCode } = result.data.getUser;

      // check if we need to fetch the referralCode owner used at signup
      if (signedUpWithReferralCode) {
        const referringUserResult = await API.graphql(
          graphqlOperation(getUser, {
            id: signedUpWithReferralCode.userId,
          })
        );
        // attach the username to the userData model
        result.data.getUser.signedUpWithReferralCode = {
          ...result.data.getUser.signedUpWithReferralCode,
          user: referringUserResult.data.getUser,
        };
      }

      // keep state
      setUserData(result);
      setUserId(id);
      setUserName(userName);
    } catch (error) {
      toast.error(`Error while fetching user data ${JSON.stringify(error)}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (userName) {
      setIsLoading(false);
      setDeleteButtonDisabledStatus(false);
    }
  }, [userName]);

  const handleReturn = () => {
    // check how we got here ( from school or from college )
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Users`
      );
    } else if (props.match.params.schoolId) {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/${props.match.params.tabName}`
      );
    } else {
      props.history.push(
        `/userReports/details/${props.match.params.userReportId}`
      );
    }
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setFormSubmitInProgress(true);

    let opperationResultMessage = "";

    // delete user
    // sign user out
    let signUserOutResult = await AdminUserGlobalSignOut(
      userId,
      setGeneralError
    );

    // check success
    if (!signUserOutResult.result) {
      toast.error(signUserOutResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    // disable user
    let disableUserResult = await AdminDisableUser(userId, setGeneralError);

    // check success
    if (!disableUserResult.result) {
      toast.error(disableUserResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    // update user data at dynamo db
    let updateUserDeletedAtResult = await updateUserDeletedAtValue(userId);
    if (!updateUserDeletedAtResult.result) {
      toast.error(updateUserDeletedAtResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    opperationResultMessage = updateUserDeletedAtResult.message;

    // go back to list
    setTimeout(function () {
      toast.success(opperationResultMessage);
      handleReturn();
    }, GlobalSubmitUIDelay.DefaultDelay);
  };

  const updateUserDeletedAtValue = async (userId) => {
    let formSubmissionResult = {
      result: true,
      message: "",
    };
    try {
      let input = {
        id: userId,
        deletedAt: new Date().toISOString(),
      };
      let userUpdateResult = await API.graphql(
        graphqlOperation(updateUser, { input })
      );

      // check result
      if (userUpdateResult) {
        formSubmissionResult.message = `User Deleted successfully`;
      }
    } catch (userUpdateError) {
      formSubmissionResult.result = false;
      formSubmissionResult.message = `Error while updating user ${JSON.stringify(
        userUpdateError,
        null,
        2
      )}`;
    }

    return formSubmissionResult;
  };

  return (
    <>
      <PagePreLoad isLoading={isLoading}>
        <Form onSubmit={handleFormSubmission} noValidate>
          <CardContainer
            header="Delete User"
            submitButton={
              <ButtonInput
                label="Delete"
                disabled={deletekButtonDisabledStatus}
                variant={BootstrapVariants.Danger}
                formSubmitInProgress={formSubmitInProgress}
                formSubmittingText={"In Progress..."}
              />
            }
            backButton={
              <ButtonInput
                label="Back to List"
                type="button"
                variant={BootstrapVariants.OutlineSecondary}
                onClick={() => handleReturn()}
              />
            }
          >
            {generalError && (
              <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
            )}
            <span>
              By clicking on <b>"Delete"</b>, User will be Deleted, do you want
              to proceed?
            </span>
          </CardContainer>
        </Form>
        <UserGeneralInfo
          providedModelData={userData}
          userId={props.match.params.userId}
        />
      </PagePreLoad>
    </>
  );
};

export default UserDelete;
