/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a75af511-d34a-45cb-8f48-184d842fc031",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_is4PD01Bx",
    "aws_user_pools_web_client_id": "162l6qpbthmpa95gf781i00t1q",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nmlms4vasfflhca4znpaug6pce.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ta6trbc2zf.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "trulibucket141144-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "02f85410c54947b48c39b2a6358c8bb2",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
