import * as DataTypes from "../../../../constants/Data/DataTypes";

const uriDecodeProvidedData = (providedDataList, gridObjectDataModel) => {
  return providedDataList.map((resultItem) => {
    // define what entries are dateTime type twhich need to be converted
    let objectModelEntries = gridObjectDataModel.filter(
      (item) => item.type === DataTypes.String
    );

    // converting string values to DateTime in order to have formatting and local convert enabled
    for (let stringTypeItem of objectModelEntries) {
      resultItem[stringTypeItem.name] = decodeURIComponent(
        resultItem[stringTypeItem.name]
      );
    }
    return resultItem;
  });
};

export default uriDecodeProvidedData;
