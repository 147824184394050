import React from "react";
import SkillGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateSkill } from "../../../../graphql/mutations";
import { getSkill} from "../../../../graphql/queries";
import * as SkillsConstants from "../../../../graphql/Constants/Skills/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const SkillDelete = (props) => {
  const deleteOperationalValidation = async (skillId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired skill
      let tobeDeleted = await API.graphql(
        graphqlOperation(getSkill, { id: skillId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching skill data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Skill"
        deleteDescription="skill will be deleted. Deleting a skill will have presentational affect on existing relationships."
        returnRoute="/skills"
        generalInfoComponent={
          <SkillGeneralInfo skillId={props.match.params.skillId} />
        }
        mutationMethod={updateSkill}
        mutationInput={{
          id: props.match.params.skillId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.skillId)
        }
      />
    </>
  );
};

export default SkillDelete;
