import React from "react";
import { getUser } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const UserGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.userId}
      modelName={"User"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "id",
          attributeTitle: "Id",
          description: "User ID",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "userName",
          attributeTitle: "UserName",
          description: "UserName",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "isBlocked",
          attributeTitle: "Is Blocked",
          description: "Is User Blocked",
          dataType: DataTypes.Boolean,
        },
        {
          key: 4,
          attributeName: "isProfane",
          attributeTitle: "Is Profane",
          description: "Is User Marked as Profane",
          dataType: DataTypes.Boolean,
        },
        {
          key: 20,
          attributeTitle: "School Name",
          description: "School Name",
          attributeFetchFunction: (data) => {
            return data.school.name;
          },
          dataType: DataTypes.String,
        },
        {
          key: 21,
          attributeTitle: "College Name",
          description: "College Name",
          attributeFetchFunction: (data) => {
            if (data.college) {
              return data.college.name;
            } else {
              return "---";
            }
          },
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "numberOfFollowings",
          attributeTitle: "Number Of Followings",
          description: "Number of Users this user follows",
          dataType: DataTypes.String,
        },
        {
          key: 6,
          attributeName: "numberOfFollowers",
          attributeTitle: "Number Of Followers",
          description: "Number of Users who are following this user",
          dataType: DataTypes.String,
        },
        {
          key: 7,
          attributeName: "numberOfFriends",
          attributeTitle: "Number Of Friends",
          description: "Number of Users who are Friends with this user",
          dataType: DataTypes.String,
        },
        {
          key: 8,
          attributeName: "numberOfPosts",
          attributeTitle: "Number Of Posts",
          description: "Number of Posts",
          dataType: DataTypes.String,
        },
        {
          key: 9,
          attributeName: "numberOfStarPoints",
          attributeTitle: "Number Of Points",
          description: "Number of Points",
          dataType: DataTypes.String,
        },
        {
          key: 11,
          attributeTitle: "Referral Code",
          description: "Referral Code",
          attributeFetchFunction: (data) => {
            if (data.referralCode) {
              return data.referralCode.code;
            } else {
              return "---";
            }
          },
          dataType: DataTypes.String,
        },
        {
          key: 10,
          attributeName: "numberOfTimesReferralCodeUsed",
          attributeTitle: "Referral Code Used",
          description: "Number of users signed up with user's referral code",
          dataType: DataTypes.String,
        },
        {
          key: 12,
          attributeTitle: "Used Referral Code for SignUp",
          description: "Used Referral Code for SignUp",
          attributeFetchFunction: (data) => {
            if (data.signedUpWithReferralCode) {
              return `${data.signedUpWithReferralCode.code}`;
            } else {
              return "---";
            }
          },
          dataType: DataTypes.String,
        },
        {
          key: 13,
          attributeTitle: "Owner of Used Referral Code for SignUp",
          description: "Owner of Used Referral Code for SignUp",
          attributeFetchFunction: (data) => {
            if (data.signedUpWithReferralCode) {
              return `${data.signedUpWithReferralCode.user.userName}`;
            } else {
              return "---";
            }
          },
          dataType: DataTypes.String,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getUser}
      queryFunctionName={"getUser"}
    />
  );
};

UserGeneralInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

UserGeneralInfo.defaultProps = {
  providedModelData: null,
};

export default UserGeneralInfo;
