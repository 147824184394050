import { API, graphqlOperation } from "aws-amplify";
import { createSystemSettings } from "../../mutations";

export const createSystemSettingsByType = async (type, content) => {
  const input = {
    settingType: type,
    content,
  };
  try {
    return await API.graphql(graphqlOperation(createSystemSettings, { input }));
  } catch (error) {
    return error;
  }
};
