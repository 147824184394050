import React from "react";
import PropTypes from "prop-types";
import { Accordion, Container } from "react-bootstrap";

const SideBarAccordion = (props) => {
  return (
    <>
      <Accordion>
        <Accordion.Toggle
          as={Container}
          eventKey="0"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          {props.toggleItem}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">{props.children}</Accordion.Collapse>
      </Accordion>
    </>
  );
};

SideBarAccordion.propTypes = {
  toggleItem: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default SideBarAccordion;
