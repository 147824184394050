export const BugReport = {
  id: "f8a2e9fe-ec1a-4286-ae04-5db2826b3057",
  name: "Bug Report",
  order: 10,
};
export const BrokenLink = {
  id: "c741d891-083e-4288-85ce-66bf426f4771",
  name: "Broken Link",
  order: 20,
};
export const GeneralFeedback = {
  id: "8afc736e-3288-436a-8dac-175d14713ee8",
  name: "General Feedback",
  order: 30,
};

export const AllTypes = [BugReport, BrokenLink, GeneralFeedback];
