import React from "react";
import CommentReportGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateReport } from "../../../../graphql/mutations";
import { getReport } from "../../../../graphql/queries";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const CommentReportDelete = (props) => {
  const deleteOperationalValidation = async (commentReportId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired CommentReport
      // let tobeDeleted = await API.graphql(
      //   graphqlOperation(getReport, { id: commentReportId })
      // );

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching Report data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Comment Report"
        deleteDescription="user report will be deleted. do you want to proceed?"
        returnRoute="/commentReports"
        generalInfoComponent={
          <CommentReportGeneralInfo
            commentReportId={props.match.params.commentReportId}
          />
        }
        mutationMethod={updateReport}
        mutationInput={{
          id: props.match.params.commentReportId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.commentReportId)
        }
      />
    </>
  );
};

export default CommentReportDelete;
