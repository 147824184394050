import React from "react";
import FeedbackCategoryGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateFeedbackCategory } from "../../../../graphql/mutations";
import { getFeedbackCategory } from "../../../../graphql/queries";
import * as FeedbackCategoriesConstants from "../../../../graphql/Constants/FeedbackCategories/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const FeedbackCategoryDelete = (props) => {
  const deleteOperationalValidation = async (feedbackCategoriesId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired feedbackCategory
      let tobeDeleted = await API.graphql(
        graphqlOperation(getFeedbackCategory, { id: feedbackCategoriesId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching feedbackCategory data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="FeedbackCategory"
        deleteDescription="feedbackCategory will be deleted. Deleting a feedbackCategory will have presentational affect on existing relationships."
        returnRoute="/feedbackCategories"
        generalInfoComponent={
          <FeedbackCategoryGeneralInfo
            feedbackCategoryId={props.match.params.feedbackCategoryId}
          />
        }
        mutationMethod={updateFeedbackCategory}
        mutationInput={{
          id: props.match.params.feedbackCategoryId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(
            props.match.params.feedbackCategoryId
          )
        }
      />
    </>
  );
};

export default FeedbackCategoryDelete;
