import React from "react";
import UserReportGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateReport } from "../../../../graphql/mutations";
import { getReport } from "../../../../graphql/queries";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const UserReportDelete = (props) => {
  const deleteOperationalValidation = async (userReportId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired UserReport
      let tobeDeleted = await API.graphql(
        graphqlOperation(getReport, { id: userReportId })
      );

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching Report data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="User Report"
        deleteDescription="user report will be deleted. do you want to proceed?"
        returnRoute="/userReports"
        generalInfoComponent={
          <UserReportGeneralInfo userReportId={props.match.params.userReportId} />
        }
        mutationMethod={updateReport}
        mutationInput={{
          id: props.match.params.userReportId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.userReportId)
        }
      />
    </>
  );
};

export default UserReportDelete;
