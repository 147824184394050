import { API, graphqlOperation, JS } from "aws-amplify";

const GetFullListResult = async (listQuery, listQueryName, variables) => {
  let result = {
    success: true,
    errorMessage: "",
    nextToken: null,
    resultList: null,
  };
  try {
    do {
      let queryResult = await API.graphql(
        graphqlOperation(listQuery, {
          ...variables,
          nextToken: result.nextToken,
        })
      );

      // check if we got the result and list back
      if (queryResult && queryResult.data[listQueryName].items) {
        result.nextToken = queryResult.data[listQueryName].nextToken;
        if (result.resultList === null) {
          result.resultList = queryResult.data[listQueryName].items;
        } else {
          result.resultList = result.resultList.concat(
            queryResult.data[listQueryName].items
          );
        }
      } else {
        // if we had errors
        result.success = false;
        result.errorMessage = JSON.stringify(queryResult, null, 2);
        break;
      }
    } while (result.nextToken !== null);

    return result;
  } catch (error) {
    result.success = false;
    result.errorMessage = JSON.stringify(error, null, 2);
  }
};

export default GetFullListResult;
