import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getSystemSettingsByType } from "../../../graphql/CustomQueries/SystemSettings/getSystemSettingsByType";
import { getModelAggregationsByType } from "../../../graphql/CustomQueries/ModelAggregations/getModelAggregationsByType";
import { createSystemSettingsByType } from "../../../graphql/CustomMutations/SystemSettings/createSystemSettingsByType";
import { createModelAggregationsByType } from "../../../graphql/CustomMutations/ModelAggregations/createModelAggregationsByType";
import {
  listDegrees,
  listGenders,
  listSkills,
  listJourneyTypes,
  listFeedbackCategorys,
  listPostCategorys,
  listReportCategorys,
  getSchool,
  getSchoolCollege,
  listPointSystems,
} from "../../../graphql/queries";
import {
  createDegree,
  deleteDegree,
  updateDegree,
  createGender,
  deleteGender,
  updateGender,
  createSkill,
  deleteSkill,
  updateSkill,
  createJourneyType,
  deleteJourneyType,
  updateJourneyType,
  createFeedbackCategory,
  deleteFeedbackCategory,
  updateFeedbackCategory,
  createPostCategory,
  deletePostCategory,
  updatePostCategory,
  createReportCategory,
  deleteReportCategory,
  updateReportCategory,
  createSchool,
  createSchoolCollege,
  createPointSystem,
  deletePointSystem,
  updatePointSystem,
} from "../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import CardContainer from "../../../components/Common/CardContainer";
import ButtonInput from "../../../components/Common/ButtonInput";
import IsChecked from "../../../components/Common/IsChecked";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import { toast } from "react-toastify";
import * as SystemSettingsTypes from "../../../graphql/Constants/SystemSettings/Types";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import * as DegreesConstants from "../../../graphql/Constants/Degrees/Types";
import * as GendersConstants from "../../../graphql/Constants/Genders/Types";
import * as SkillsConstants from "../../../graphql/Constants/Skills/Types";
import * as JourneyTypesConstants from "../../../graphql/Constants/JourneyTypes/Types";
import * as FeedbackCategoriesConstants from "../../../graphql/Constants/FeedbackCategories/Types";
import * as PostCategoriesConstants from "../../../graphql/Constants/PostCategories/Types";
import * as ReportCategoriesConstants from "../../../graphql/Constants/ReportCategories/Types";
import * as PointSystemsConstants from "../../../graphql/Constants/PointSystems/Types";
import SchoolNONE from "../../../graphql/Constants/Schools/None";
import CollegeNONE from "../../../graphql/Constants/Colleges/None";

const SystemInitialSettings = () => {
  const [isTermsOfServiceDefined, setIsTermsOfServiceDefined] = useState(false);
  const [termsOfServiceInProgress, setTermsOfServiceInProgress] = useState(
    false
  );
  const [isPrivacyPolicyDefined, setIsPrivacyPolicyDefined] = useState(false);
  const [privacyPolicyInProgress, setPrivacyPolicyInProgress] = useState(false);
  const [isAboutAppDefined, setIsAboutAppDefined] = useState(false);
  const [aboutAppInProgress, setAboutAppInProgress] = useState(false);

  // aggregations
  // school
  const [
    isSchoolModelAggregationDefined,
    setIsSchoolModelAggregationDefined,
  ] = useState(false);
  const [
    isSchoolAggregationInProgress,
    setIsSchoolAggregationInProgress,
  ] = useState(false);
  // NONE SCHOOL
  const [isNoneSchoolModelDefined, setIsNoneSchoolModelDefined] = useState(
    false
  );
  const [isNoneSchoolModelInProgress, setNoneSchoolModelInProgress] = useState(
    false
  );

  // college
  const [
    isCollegeModelAggregationDefined,
    setIsCollegeModelAggregationDefined,
  ] = useState(false);
  const [
    isCollegeAggregationInProgress,
    setIsCollegeAggregationInProgress,
  ] = useState(false);
  // NONE COLLEGE
  const [isNoneCollegeModelDefined, setIsNoneCollegeModelDefined] = useState(
    false
  );
  const [
    isNoneCollegeModelInProgress,
    setNoneCollegeModelInProgress,
  ] = useState(false);

  // domain
  const [
    isDomainModelAggregationDefined,
    setIsDomainModelAggregationDefined,
  ] = useState(false);
  const [
    isDomainAggregationInProgress,
    setIsDomainAggregationInProgress,
  ] = useState(false);
  // user
  const [
    isUserModelAggregationDefined,
    setIsUserModelAggregationDefined,
  ] = useState(false);
  const [
    isUserAggregationInProgress,
    setIsUserAggregationInProgress,
  ] = useState(false);
  // class
  const [
    isClassModelAggregationDefined,
    setIsClassModelAggregationDefined,
  ] = useState(false);
  const [
    isClassAggregationInProgress,
    setIsClassAggregationInProgress,
  ] = useState(false);
  // degree
  const [
    isDegreeModelAggregationDefined,
    setIsDegreeModelAggregationDefined,
  ] = useState(false);
  const [
    isDegreeAggregationInProgress,
    setIsDegreeAggregationInProgress,
  ] = useState(false);
  // degree predefined list
  const [
    isDegreePreDefinedListDefined,
    setIsDegreePreDefinedListDefined,
  ] = useState(false);
  const [
    isDegreePreDefinedListInProgress,
    setIsDegreePreDefinedListInProgress,
  ] = useState(false);
  // gender
  const [
    isGenderModelAggregationDefined,
    setIsGenderModelAggregationDefined,
  ] = useState(false);
  const [
    isGenderAggregationInProgress,
    setIsGenderAggregationInProgress,
  ] = useState(false);
  // Gender predefined list
  const [
    isGenderPreDefinedListDefined,
    setIsGenderPreDefinedListDefined,
  ] = useState(false);
  const [
    isGenderPreDefinedListInProgress,
    setIsGenderPreDefinedListInProgress,
  ] = useState(false);
  // skill
  const [
    isSkillModelAggregationDefined,
    setIsSkillModelAggregationDefined,
  ] = useState(false);
  const [
    isSkillAggregationInProgress,
    setIsSkillAggregationInProgress,
  ] = useState(false);
  // Skill predefined list
  const [
    isSkillPreDefinedListDefined,
    setIsSkillPreDefinedListDefined,
  ] = useState(false);
  const [
    isSkillPreDefinedListInProgress,
    setIsSkillPreDefinedListInProgress,
  ] = useState(false);
  // journeyType
  const [
    isJourneyTypeModelAggregationDefined,
    setIsJourneyTypeModelAggregationDefined,
  ] = useState(false);
  const [
    isJourneyTypeAggregationInProgress,
    setIsJourneyTypeAggregationInProgress,
  ] = useState(false);
  // JourneyType predefined list
  const [
    isJourneyTypePreDefinedListDefined,
    setIsJourneyTypePreDefinedListDefined,
  ] = useState(false);
  const [
    isJourneyTypePreDefinedListInProgress,
    setIsJourneyTypePreDefinedListInProgress,
  ] = useState(false);
  // feedbackCategory
  const [
    isFeedbackCategoryModelAggregationDefined,
    setIsFeedbackCategoryModelAggregationDefined,
  ] = useState(false);
  const [
    isFeedbackCategoryAggregationInProgress,
    setIsFeedbackCategoryAggregationInProgress,
  ] = useState(false);
  // feedbackCategory predefined list
  const [
    isFeedbackCategoryPreDefinedListDefined,
    setIsFeedbackCategoryPreDefinedListDefined,
  ] = useState(false);
  const [
    isFeedbackCategoryPreDefinedListInProgress,
    setIsFeedbackCategoryPreDefinedListInProgress,
  ] = useState(false);
  // feedback
  const [
    isFeedbackModelAggregationDefined,
    setIsFeedbackModelAggregationDefined,
  ] = useState(false);
  const [
    isFeedbackAggregationInProgress,
    setIsFeedbackAggregationInProgress,
  ] = useState(false);
  // postCategory
  const [
    isPostCategoryModelAggregationDefined,
    setIsPostCategoryModelAggregationDefined,
  ] = useState(false);
  const [
    isPostCategoryAggregationInProgress,
    setIsPostCategoryAggregationInProgress,
  ] = useState(false);
  // PostCategory predefined list
  const [
    isPostCategoryPreDefinedListDefined,
    setIsPostCategoryPreDefinedListDefined,
  ] = useState(false);
  const [
    isPostCategoryPreDefinedListInProgress,
    setIsPostCategoryPreDefinedListInProgress,
  ] = useState(false);
  // reportCategory
  const [
    isReportCategoryModelAggregationDefined,
    setIsReportCategoryModelAggregationDefined,
  ] = useState(false);
  const [
    isReportCategoryAggregationInProgress,
    setIsReportCategoryAggregationInProgress,
  ] = useState(false);
  // ReportCategory predefined list
  const [
    isReportCategoryPreDefinedListDefined,
    setIsReportCategoryPreDefinedListDefined,
  ] = useState(false);
  const [
    isReportCategoryPreDefinedListInProgress,
    setIsReportCategoryPreDefinedListInProgress,
  ] = useState(false);
  // userReport
  const [
    isUserReportModelAggregationDefined,
    setIsUserReportModelAggregationDefined,
  ] = useState(false);
  const [
    isUserReportAggregationInProgress,
    setIsUserReportAggregationInProgress,
  ] = useState(false);
  // postReport
  const [
    isPostReportModelAggregationDefined,
    setIsPostReportModelAggregationDefined,
  ] = useState(false);
  const [
    isPostReportAggregationInProgress,
    setIsPostReportAggregationInProgress,
  ] = useState(false);
  // commentReport
  const [
    isCommentReportModelAggregationDefined,
    setIsCommentReportModelAggregationDefined,
  ] = useState(false);
  const [
    isCommentReportAggregationInProgress,
    setIsCommentReportAggregationInProgress,
  ] = useState(false);
  // Post
  const [
    isPostModelAggregationDefined,
    setIsPostModelAggregationDefined,
  ] = useState(false);
  const [
    isPostAggregationInProgress,
    setIsPostAggregationInProgress,
  ] = useState(false);
  // HashTag
  const [
    isHashTagModelAggregationDefined,
    setIsHashTagModelAggregationDefined,
  ] = useState(false);
  const [
    isHashTagAggregationInProgress,
    setIsHashTagAggregationInProgress,
  ] = useState(false);
  // Comment
  const [
    isCommentModelAggregationDefined,
    setIsCommentModelAggregationDefined,
  ] = useState(false);
  const [
    isCommentAggregationInProgress,
    setIsCommentAggregationInProgress,
  ] = useState(false);
  // point system
  const [
    isPointSystemModelAggregationDefined,
    setIsPointSystemModelAggregationDefined,
  ] = useState(false);
  const [
    isPointSystemAggregationInProgress,
    setIsPointSystemAggregationInProgress,
  ] = useState(false);
  // PointSystem predefined list
  const [
    isPointSystemPreDefinedListDefined,
    setIsPointSystemPreDefinedListDefined,
  ] = useState(false);
  const [
    isPointSystemPreDefinedListInProgress,
    setIsPointSystemPreDefinedListInProgress,
  ] = useState(false);

  // page preload number of actions
  const requiredActionsBeforePageIsLoaded = 31;
  let finishedActionsCount = 0;
  const [finishedActions, setFinishedActions] = useState(0);

  // defining the current state of settings
  useEffect(() => {
    isSystemSettingDefined(SystemSettingsTypes.TermsOfService);
    isSystemSettingDefined(SystemSettingsTypes.PrivacyPolicy);
    isSystemSettingDefined(SystemSettingsTypes.AboutApp);

    // model aggregations
    isModelAggregationsDefined(ModelTypes.School);
    isModelAggregationsDefined(ModelTypes.College);
    isModelAggregationsDefined(ModelTypes.Domain);
    isModelAggregationsDefined(ModelTypes.User);
    isModelAggregationsDefined(ModelTypes.Clazz);
    isModelAggregationsDefined(ModelTypes.Degree);
    isModelAggregationsDefined(ModelTypes.Gender);
    isModelAggregationsDefined(ModelTypes.Skill);
    isModelAggregationsDefined(ModelTypes.JourneyType);
    isModelAggregationsDefined(ModelTypes.FeedbackCategory);
    isModelAggregationsDefined(ModelTypes.Feedback);
    isModelAggregationsDefined(ModelTypes.PostCategory);
    isModelAggregationsDefined(ModelTypes.ReportCategory);
    isModelAggregationsDefined(ModelTypes.UserReport);
    isModelAggregationsDefined(ModelTypes.PostReport);
    isModelAggregationsDefined(ModelTypes.CommentReport);
    isModelAggregationsDefined(ModelTypes.Post);
    isModelAggregationsDefined(ModelTypes.HashTag);
    isModelAggregationsDefined(ModelTypes.Degree);
    isModelAggregationsDefined(ModelTypes.Degree);
    isModelAggregationsDefined(ModelTypes.Comment);
    isModelAggregationsDefined(ModelTypes.PointSystem);

    // predefined data
    // degrees list
    checktIfPreDefinedListDefined(
      listDegrees,
      "listDegrees",
      DegreesConstants.AllTypes,
      setIsDegreePreDefinedListDefined
    );
    // genders list
    checktIfPreDefinedListDefined(
      listGenders,
      "listGenders",
      GendersConstants.AllTypes,
      setIsGenderPreDefinedListDefined
    );
    // skills list
    checktIfPreDefinedListDefined(
      listSkills,
      "listSkills",
      SkillsConstants.AllTypes,
      setIsSkillPreDefinedListDefined
    );
    // journeyTypes list
    checktIfPreDefinedListDefined(
      listJourneyTypes,
      "listJourneyTypes",
      JourneyTypesConstants.AllTypes,
      setIsJourneyTypePreDefinedListDefined
    );
    // feedbackCategory list
    checktIfPreDefinedListDefined(
      listFeedbackCategorys,
      "listFeedbackCategorys",
      FeedbackCategoriesConstants.AllTypes,
      setIsFeedbackCategoryPreDefinedListDefined
    );
    // postCategory list
    checktIfPreDefinedListDefined(
      listPostCategorys,
      "listPostCategorys",
      PostCategoriesConstants.AllTypes,
      setIsPostCategoryPreDefinedListDefined
    );
    // reportCategory list
    checktIfPreDefinedListDefined(
      listReportCategorys,
      "listReportCategorys",
      ReportCategoriesConstants.AllTypes,
      setIsReportCategoryPreDefinedListDefined
    );
    // point systems list
    checktIfPreDefinedListDefined(
      listPointSystems,
      "listPointSystems",
      PointSystemsConstants.AllTypes,
      setIsPointSystemPreDefinedListDefined
    );

    // check none school
    checkIfNoneSchoolIsDefined();

    // check none college
    checkIfNoneCollegeIsDefined();
  }, []);

  // check the existance of system settings
  const isSystemSettingDefined = async (type) => {
    const result = await getSystemSettingsByType(type);
    if (result.errors) {
      toast.error(`Error Fetching ${type}`);
    }
    if (result.data && result.data.listSystemSettingss.items.length > 0) {
      actionTypesStateDefiner(type);
    }
    ++finishedActionsCount;
    setFinishedActions(finishedActionsCount);
  };

  // defining the state of each system settings
  const actionTypesStateDefiner = (type) => {
    switch (type) {
      case SystemSettingsTypes.TermsOfService:
        setIsTermsOfServiceDefined(true);
        break;
      case SystemSettingsTypes.PrivacyPolicy:
        setIsPrivacyPolicyDefined(true);
        break;
      case SystemSettingsTypes.AboutApp:
        setIsAboutAppDefined(true);
        break;
      case ModelTypes.School:
        setIsSchoolModelAggregationDefined(true);
        break;
      case ModelTypes.College:
        setIsCollegeModelAggregationDefined(true);
        break;
      case ModelTypes.Domain:
        setIsDomainModelAggregationDefined(true);
        break;
      case ModelTypes.User:
        setIsUserModelAggregationDefined(true);
        break;
      case ModelTypes.Clazz:
        setIsClassModelAggregationDefined(true);
        break;
      case ModelTypes.Degree:
        setIsDegreeModelAggregationDefined(true);
        break;
      case ModelTypes.Gender:
        setIsGenderModelAggregationDefined(true);
        break;
      case ModelTypes.Skill:
        setIsSkillModelAggregationDefined(true);
        break;
      case ModelTypes.JourneyType:
        setIsJourneyTypeModelAggregationDefined(true);
        break;
      case ModelTypes.FeedbackCategory:
        setIsFeedbackCategoryModelAggregationDefined(true);
        break;
      case ModelTypes.Feedback:
        setIsFeedbackModelAggregationDefined(true);
        break;
      case ModelTypes.PostCategory:
        setIsPostCategoryModelAggregationDefined(true);
        break;
      case ModelTypes.ReportCategory:
        setIsReportCategoryModelAggregationDefined(true);
        break;
      case ModelTypes.UserReport:
        setIsUserReportModelAggregationDefined(true);
        break;
      case ModelTypes.PostReport:
        setIsPostReportModelAggregationDefined(true);
        break;
      case ModelTypes.CommentReport:
        setIsCommentReportModelAggregationDefined(true);
        break;
      case ModelTypes.Post:
        setIsPostModelAggregationDefined(true);
        break;
      case ModelTypes.HashTag:
        setIsHashTagModelAggregationDefined(true);
        break;
      case ModelTypes.Comment:
        setIsCommentModelAggregationDefined(true);
        break;
      case ModelTypes.PointSystem:
        setIsPointSystemModelAggregationDefined(true);
        break;
      default:
        break;
    }
  };

  // defining the inprogress state by system setting type
  const actionTypesProgressDefiner = (type, isInProgress) => {
    switch (type) {
      case SystemSettingsTypes.TermsOfService:
        setTermsOfServiceInProgress(isInProgress);
        break;
      case SystemSettingsTypes.PrivacyPolicy:
        setPrivacyPolicyInProgress(isInProgress);
        break;
      case SystemSettingsTypes.AboutApp:
        setAboutAppInProgress(isInProgress);
        break;
      case ModelTypes.School:
        setIsSchoolAggregationInProgress(isInProgress);
        break;
      case ModelTypes.College:
        setIsCollegeAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Domain:
        setIsDomainAggregationInProgress(isInProgress);
        break;
      case ModelTypes.User:
        setIsUserAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Clazz:
        setIsClassAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Degree:
        setIsDegreeAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Gender:
        setIsGenderAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Skill:
        setIsSkillAggregationInProgress(isInProgress);
        break;
      case ModelTypes.JourneyType:
        setIsJourneyTypeAggregationInProgress(isInProgress);
        break;
      case ModelTypes.FeedbackCategory:
        setIsFeedbackCategoryAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Feedback:
        setIsFeedbackAggregationInProgress(isInProgress);
        break;
      case ModelTypes.PostCategory:
        setIsPostCategoryAggregationInProgress(isInProgress);
        break;
      case ModelTypes.ReportCategory:
        setIsReportCategoryAggregationInProgress(isInProgress);
        break;
      case ModelTypes.UserReport:
        setIsUserReportAggregationInProgress(isInProgress);
        break;
      case ModelTypes.PostReport:
        setIsPostReportAggregationInProgress(isInProgress);
        break;
      case ModelTypes.CommentReport:
        setIsCommentReportAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Post:
        setIsPostAggregationInProgress(isInProgress);
        break;
      case ModelTypes.HashTag:
        setIsHashTagAggregationInProgress(isInProgress);
        break;
      case ModelTypes.Comment:
        setIsCommentAggregationInProgress(isInProgress);
        break;
      case ModelTypes.PointSystem:
        setIsPointSystemAggregationInProgress(isInProgress);
        break;
      default:
        break;
    }
  };

  const defineSystemSetting = async (type) => {
    // set to inprofgress state
    actionTypesProgressDefiner(type, true);

    // define the system settings with default value
    const initialContent = "<p>Initial Content<p>";
    const result = await createSystemSettingsByType(type, initialContent);
    if (result.errors) {
      toast.error(`Error Creating initial ${type}`);
    }
    if (result.data && result.data.createSystemSettings) {
      actionTypesStateDefiner(type);
      toast.success(`${type} created`);
    }

    // disable in progress status
    actionTypesProgressDefiner(type, false);
  };

  const isModelAggregationsDefined = async (aggregationType) => {
    const result = await getModelAggregationsByType(aggregationType);
    if (result.errors) {
      toast.error(`Error Fetching ${aggregationType}`);
    }
    if (result.data && result.data.getModelAggregations) {
      actionTypesStateDefiner(aggregationType);
    }
    ++finishedActionsCount;
    setFinishedActions(finishedActionsCount);
  };

  const defineModelAggregations = async (aggregationType) => {
    // set to inprofgress state
    actionTypesProgressDefiner(aggregationType, true);

    // define the model aggregations with default value
    const initialCount = 0;
    const result = await createModelAggregationsByType(
      aggregationType,
      initialCount
    );
    if (result.errors) {
      toast.error(`Error Creating initial ${aggregationType}`);
    }
    if (result.data && result.data.createModelAggregations) {
      actionTypesStateDefiner(aggregationType);
      toast.success(`${aggregationType} created`);
    }

    // disable in progress status
    actionTypesProgressDefiner(aggregationType, false);
  };

  // check if the list is defined properly
  const checktIfPreDefinedListDefined = async (
    queryMethod,
    queryMethodName,
    preDefinedList,
    stateHandlerMethod
  ) => {
    let result = await isPreDefinedListDefined(
      queryMethod,
      queryMethodName,
      preDefinedList
    );
    stateHandlerMethod(result);
    ++finishedActionsCount;
    setFinishedActions(finishedActionsCount);
  };

  const isPreDefinedListDefined = async (
    queryMethod,
    queryMethodName,
    preDefinedList
  ) => {
    // calls the query method and and checks the result
    // if existing list is exactly equal to the provided list return true
    try {
      let predefinedListResult = await API.graphql(
        graphqlOperation(queryMethod)
      );

      // we need to check if the 2 lists are exactly
      let listResult = predefinedListResult.data[queryMethodName].items;

      // check the length
      if (listResult.length !== preDefinedList.length) return false;

      // point system had a different model
      if (queryMethodName === "ListPointSystems") {
        // check item by item
        for (let item of preDefinedList) {
          let foudnResult = listResult.find(
            (searchItem) =>
              searchItem.id === item.id &&
              searchItem.pointType === item.pointType &&
              searchItem.order === item.order &&
              searchItem.point === item.point
          );
          if (!foudnResult) return false;
        }
      } else {
        // check item by item
        for (let item of preDefinedList) {
          let foudnResult = listResult.find(
            (searchItem) =>
              searchItem.id === item.id &&
              searchItem.name === item.name &&
              searchItem.order === item.order
          );
          if (!foudnResult) return false;
        }
      }

      return true;
    } catch (error) {
      toast.error(
        `Error while fetching ${queryMethodName}, error: ${JSON.stringify(
          error,
          null,
          2
        )}`
      );
    }
  };

  // define predefined lists based off the mutation method and input
  const definePreDefinedlLists = async (
    modelType,
    createMutationMethod,
    deletedMutationMethod,
    modifyMutationMethod,
    queryMethod,
    queryMethodName,
    preDefinedList,
    stateHandlerMethod,
    inProgressStateHandler
  ) => {
    inProgressStateHandler(true);
    // get the available list at db
    try {
      let predefinedListResult = await API.graphql(
        graphqlOperation(queryMethod)
      );

      // get the available lists at DB
      let listResult = predefinedListResult.data[queryMethodName].items;

      // find the existing items
      let existingItems = listResult.filter((item) => {
        return preDefinedList.find(
          (element) => element.id === item.id && element.name === item.name
        );
      });

      // find added items
      let addedItems = preDefinedList.filter((item) => {
        return !listResult.find((element) => element.id === item.id);
      });

      // find deleted items
      let deletedItems = listResult.filter((item) => {
        return !preDefinedList.find((element) => element.id === item.id);
      });

      // modifiedItems
      let modifiedItems = listResult.filter((item) => {
        return preDefinedList.find(
          (element) => element.id === item.id && element.name !== item.name
        );
      });

      // create added ones
      for (let addedItem of addedItems) {
        // point system is different
        let input = {
          id: addedItem.id,
          type: modelType,

          order: addedItem.order,
          deletedAt: null,
        };
        if (queryMethodName === "listPointSystems") {
          input = {
            ...input,
            pointType: addedItem.pointType,
            point: addedItem.point,
          };
        } else {
          input = {
            ...input,
            name: addedItem.name,
          };
        }

        try {
          let addResult = await API.graphql(
            graphqlOperation(createMutationMethod, { input })
          );
        } catch (addError) {
          toast.error(
            `Error while adding ${addedItem.name}, error: ${JSON.stringify(
              addError,
              null,
              2
            )}`
          );
        }
      }

      // delete deleted ones
      for (let deletedItem of deletedItems) {
        let input = {
          id: deletedItem.id,
        };
        try {
          let deletedItem = await API.graphql(
            graphqlOperation(deletedMutationMethod, { input })
          );
        } catch (deleteError) {
          toast.error(
            `Error while deleting ${deletedItem.name}, error: ${JSON.stringify(
              deleteError,
              null,
              2
            )}`
          );
        }
      }

      // update modified ones
      for (let modifiedItem of modifiedItems) {
        let input = {
          id: modifiedItem.id,
          order: preDefinedList.find(
            (element) => element.id === modifiedItem.id
          ).order,
        };
        if (queryMethodName === "listPointSystems") {
          input = {
            ...input,
            pointType: preDefinedList.find(
              (element) => element.id === modifiedItem.id
            ).pointType,
            point: preDefinedList.find(
              (element) => element.id === modifiedItem.id
            ).point,
          };
        } else {
          input = {
            ...input,
            name: preDefinedList.find(
              (element) => element.id === modifiedItem.id
            ).name,
          };
        }

        try {
          let updatedItem = await API.graphql(
            graphqlOperation(modifyMutationMethod, { input })
          );
        } catch (updateError) {
          toast.error(
            `Error while updating ${modifiedItem.name}, error: ${JSON.stringify(
              updateError,
              null,
              2
            )}`
          );
        }
      }

      // handle UI states
      stateHandlerMethod(true);
      inProgressStateHandler(false);
    } catch (error) {
      inProgressStateHandler(false);
      toast.error(
        `Error while fetching ${queryMethodName}, error: ${JSON.stringify(
          error,
          null,
          2
        )}`
      );
    }
  };

  // check if school none is defined
  const checkIfNoneSchoolIsDefined = async () => {
    try {
      let schoolNoneResult = await API.graphql(
        graphqlOperation(getSchool, { id: SchoolNONE.id })
      );

      // check the result
      if (!schoolNoneResult || !schoolNoneResult.data) {
        toast.error(
          `error while fetching none school, ${JSON.stringify(
            schoolNoneResult
          )}`
        );
      } else {
        if (schoolNoneResult.data.getSchool != null)
          setIsNoneSchoolModelDefined(true);
      }
    } catch (error) {
      toast.error(`error while fetching none school, ${JSON.stringify(error)}`);
    }
  };

  const defineNoneSchoolModel = async () => {
    setNoneSchoolModelInProgress(true);
    try {
      const input = {
        id: SchoolNONE.id,
        name: SchoolNONE.name,
        numberOfColleges: 0,
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        totalSchoolUsersCount: 0,
        deletedAt: null,
      };
      let createNoneSchoolModel = await API.graphql(
        graphqlOperation(createSchool, { input })
      );

      // check result
      if (!createNoneSchoolModel || !createNoneSchoolModel.data) {
        toast.error(
          `error while creating none school, ${JSON.stringify(
            createNoneSchoolModel
          )}`
        );
      } else {
        setIsNoneSchoolModelDefined(true);
      }
    } catch (error) {
      toast.error(`error while creating none school, ${JSON.stringify(error)}`);
    }
    setNoneSchoolModelInProgress(false);
  };

  // check if college none is defined
  const checkIfNoneCollegeIsDefined = async () => {
    try {
      let collegeNoneResult = await API.graphql(
        graphqlOperation(getSchoolCollege, { id: CollegeNONE.id })
      );

      // check the result
      if (!collegeNoneResult || !collegeNoneResult.data) {
        toast.error(
          `error while fetching none college, ${JSON.stringify(
            collegeNoneResult
          )}`
        );
      } else {
        if (collegeNoneResult.data.getSchoolCollege != null)
          setIsNoneCollegeModelDefined(true);
      }
    } catch (error) {
      toast.error(
        `error while fetching none college, ${JSON.stringify(error)}`
      );
    }
  };

  const defineNoneCollegeModel = async () => {
    setNoneCollegeModelInProgress(true);
    try {
      const input = {
        id: CollegeNONE.id, // we will use this ID at college trigger to filter out this school
        name: CollegeNONE.name,
        schoolId: CollegeNONE.schoolId, // this is school NONE
        numberOfDomains: 0,
        numberOfClazzes: 0,
        numberOfUsers: 0,
        deletedAt: null,
      };
      let createNoneSchoolModel = await API.graphql(
        graphqlOperation(createSchoolCollege, { input })
      );

      // check result
      if (!createNoneSchoolModel || !createNoneSchoolModel.data) {
        toast.error(
          `error while creating none college, ${JSON.stringify(
            createNoneSchoolModel
          )}`
        );
      } else {
        setIsNoneCollegeModelDefined(true);
      }
    } catch (error) {
      toast.error(
        `error while creating none college, ${JSON.stringify(error)}`
      );
    }
    setNoneCollegeModelInProgress(false);
  };

  return (
    <PagePreLoad
      isLoading={finishedActions < requiredActionsBeforePageIsLoaded}
    >
      <CardContainer header="System Initial Settings">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Terms Of Service Initial</td>
              <td>Defines initial Terms OF Service at DB</td>
              <td>
                <IsChecked status={isTermsOfServiceDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={termsOfServiceInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineSystemSetting(SystemSettingsTypes.TermsOfService)
                  }
                  disabled={isTermsOfServiceDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Privacy Policy Initial</td>
              <td>Defines initial Privacy Policy at DB</td>
              <td>
                <IsChecked status={isPrivacyPolicyDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={privacyPolicyInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineSystemSetting(SystemSettingsTypes.PrivacyPolicy)
                  }
                  disabled={isPrivacyPolicyDefined}
                />
              </td>
            </tr>
            <tr>
              <td>About App Initial</td>
              <td>Defines initial About App at DB</td>
              <td>
                <IsChecked status={isAboutAppDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={aboutAppInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineSystemSetting(SystemSettingsTypes.AboutApp)
                  }
                  disabled={isAboutAppDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Schools Aggregation</td>
              <td>Defines initial Schools Model aggregation count</td>
              <td>
                <IsChecked status={isSchoolModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isSchoolAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.School)}
                  disabled={isSchoolModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>NONE school</td>
              <td>Defines NONE school model</td>
              <td>
                <IsChecked status={isNoneSchoolModelDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isNoneSchoolModelInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineNoneSchoolModel()}
                  disabled={isNoneSchoolModelDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Colleges Aggregation</td>
              <td>Defines initial Colleges Model aggregation count</td>
              <td>
                <IsChecked status={isCollegeModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isCollegeAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.College)}
                  disabled={isCollegeModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>NONE college</td>
              <td>Defines NONE college model</td>
              <td>
                <IsChecked status={isNoneCollegeModelDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isNoneCollegeModelInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineNoneCollegeModel()}
                  disabled={isNoneCollegeModelDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Domains Aggregation</td>
              <td>Defines initial Domains Model aggregation count</td>
              <td>
                <IsChecked status={isDomainModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isDomainAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Domain)}
                  disabled={isDomainModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Users Aggregation</td>
              <td>Defines initial Users Model aggregation count</td>
              <td>
                <IsChecked status={isUserModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isUserAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.User)}
                  disabled={isUserModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Classes Aggregation</td>
              <td>Defines initial Classes Model aggregation count</td>
              <td>
                <IsChecked status={isClassModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isClassAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Clazz)}
                  disabled={isClassModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Degrees Aggregation</td>
              <td>Defines initial Degrees Model aggregation count</td>
              <td>
                <IsChecked status={isDegreeModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isDegreeAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Degree)}
                  disabled={isDegreeModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Degrees Pre-Defined List</td>
              <td>Defines provided list by client at degrees table</td>
              <td>
                <IsChecked status={isDegreePreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isDegreePreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.Degree,
                      createDegree,
                      deleteDegree,
                      updateDegree,
                      listDegrees,
                      "listDegrees",
                      DegreesConstants.AllTypes,
                      setIsDegreePreDefinedListDefined,
                      setIsDegreePreDefinedListInProgress
                    )
                  }
                  disabled={isDegreePreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Genders Aggregation</td>
              <td>Defines initial Genders Model aggregation count</td>
              <td>
                <IsChecked status={isGenderModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isGenderAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Gender)}
                  disabled={isGenderModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Genders Pre-Defined List</td>
              <td>Defines provided list by client at Genders table</td>
              <td>
                <IsChecked status={isGenderPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isGenderPreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.Gender,
                      createGender,
                      deleteGender,
                      updateGender,
                      listGenders,
                      "listGenders",
                      GendersConstants.AllTypes,
                      setIsGenderPreDefinedListDefined,
                      setIsGenderPreDefinedListInProgress
                    )
                  }
                  disabled={isGenderPreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Skills Aggregation</td>
              <td>Defines initial Skills Model aggregation count</td>
              <td>
                <IsChecked status={isSkillModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isSkillAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Skill)}
                  disabled={isSkillModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Skills Pre-Defined List</td>
              <td>Defines provided list by client at Skills table</td>
              <td>
                <IsChecked status={isSkillPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isSkillPreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.Skill,
                      createSkill,
                      deleteSkill,
                      updateSkill,
                      listSkills,
                      "listSkills",
                      SkillsConstants.AllTypes,
                      setIsSkillPreDefinedListDefined,
                      setIsSkillPreDefinedListInProgress
                    )
                  }
                  disabled={isSkillPreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>JourneyTypes Aggregation</td>
              <td>Defines initial JourneyTypes Model aggregation count</td>
              <td>
                <IsChecked status={isJourneyTypeModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isJourneyTypeAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.JourneyType)
                  }
                  disabled={isJourneyTypeModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>JourneyTypes Pre-Defined List</td>
              <td>Defines provided list by client at JourneyTypes table</td>
              <td>
                <IsChecked status={isJourneyTypePreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isJourneyTypePreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.JourneyType,
                      createJourneyType,
                      deleteJourneyType,
                      updateJourneyType,
                      listJourneyTypes,
                      "listJourneyTypes",
                      JourneyTypesConstants.AllTypes,
                      setIsJourneyTypePreDefinedListDefined,
                      setIsJourneyTypePreDefinedListInProgress
                    )
                  }
                  disabled={isJourneyTypePreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>FeedbackCategory Aggregation</td>
              <td>Defines initial FeedbackCategory Model aggregation count</td>
              <td>
                <IsChecked status={isFeedbackCategoryModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isFeedbackCategoryAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.FeedbackCategory)
                  }
                  disabled={isFeedbackCategoryModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>FeedbackCategories Pre-Defined List</td>
              <td>
                Defines provided list by client at FeedbackCategories table
              </td>
              <td>
                <IsChecked status={isFeedbackCategoryPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={
                    isFeedbackCategoryPreDefinedListInProgress
                  }
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.FeedbackCategory,
                      createFeedbackCategory,
                      deleteFeedbackCategory,
                      updateFeedbackCategory,
                      listFeedbackCategorys,
                      "listFeedbackCategorys",
                      FeedbackCategoriesConstants.AllTypes,
                      setIsFeedbackCategoryPreDefinedListDefined,
                      setIsFeedbackCategoryPreDefinedListInProgress
                    )
                  }
                  disabled={isFeedbackCategoryPreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Feedback Aggregation</td>
              <td>Defines initial Feedback Model aggregation count</td>
              <td>
                <IsChecked status={isFeedbackModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isFeedbackAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Feedback)}
                  disabled={isFeedbackModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>PostCategory Aggregation</td>
              <td>Defines initial PostCategory Model aggregation count</td>
              <td>
                <IsChecked status={isPostCategoryModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPostCategoryAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.PostCategory)
                  }
                  disabled={isPostCategoryModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>PostCategories Pre-Defined List</td>
              <td>Defines provided list by client at PostCategories table</td>
              <td>
                <IsChecked status={isPostCategoryPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPostCategoryPreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.PostCategory,
                      createPostCategory,
                      deletePostCategory,
                      updatePostCategory,
                      listPostCategorys,
                      "listPostCategorys",
                      PostCategoriesConstants.AllTypes,
                      setIsPostCategoryPreDefinedListDefined,
                      setIsPostCategoryPreDefinedListInProgress
                    )
                  }
                  disabled={isPostCategoryPreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>ReportCategory Aggregation</td>
              <td>Defines initial ReportCategory Model aggregation count</td>
              <td>
                <IsChecked status={isReportCategoryModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isReportCategoryAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.ReportCategory)
                  }
                  disabled={isReportCategoryModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>ReportCategories Pre-Defined List</td>
              <td>Defines provided list by client at ReportCategories table</td>
              <td>
                <IsChecked status={isReportCategoryPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={
                    isReportCategoryPreDefinedListInProgress
                  }
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.ReportCategory,
                      createReportCategory,
                      deleteReportCategory,
                      updateReportCategory,
                      listReportCategorys,
                      "listReportCategorys",
                      ReportCategoriesConstants.AllTypes,
                      setIsReportCategoryPreDefinedListDefined,
                      setIsReportCategoryPreDefinedListInProgress
                    )
                  }
                  disabled={isReportCategoryPreDefinedListDefined}
                />
              </td>
            </tr>
            <tr>
              <td>UserReport Aggregation</td>
              <td>Defines initial UserReport Model aggregation count</td>
              <td>
                <IsChecked status={isUserReportModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isUserReportAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.UserReport)}
                  disabled={isUserReportModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>PostReport Aggregation</td>
              <td>Defines initial PostReport Model aggregation count</td>
              <td>
                <IsChecked status={isPostReportModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPostReportAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.PostReport)}
                  disabled={isPostReportModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>CommentReport Aggregation</td>
              <td>Defines initial CommentReport Model aggregation count</td>
              <td>
                <IsChecked status={isCommentReportModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isCommentReportAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.CommentReport)
                  }
                  disabled={isCommentReportModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Post Aggregation</td>
              <td>Defines initial Post Model aggregation count</td>
              <td>
                <IsChecked status={isPostModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPostAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Post)}
                  disabled={isPostModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>HashTag Aggregation</td>
              <td>Defines initial HashTag Model aggregation count</td>
              <td>
                <IsChecked status={isHashTagModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isHashTagAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.HashTag)}
                  disabled={isHashTagModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Comment Aggregation</td>
              <td>Defines initial Comment Model aggregation count</td>
              <td>
                <IsChecked status={isCommentModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isCommentAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() => defineModelAggregations(ModelTypes.Comment)}
                  disabled={isCommentModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Point Systems Aggregation</td>
              <td>Defines initial Point Systems Model aggregation count</td>
              <td>
                <IsChecked status={isPointSystemModelAggregationDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPointSystemAggregationInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    defineModelAggregations(ModelTypes.PointSystem)
                  }
                  disabled={isPointSystemModelAggregationDefined}
                />
              </td>
            </tr>
            <tr>
              <td>Point Systems Pre-Defined List</td>
              <td>Defines provided list by client at Point Systems table</td>
              <td>
                <IsChecked status={isPointSystemPreDefinedListDefined} />
              </td>
              <td>
                <ButtonInput
                  className="btn-block"
                  variant="success"
                  label="Define"
                  formSubmitInProgress={isPointSystemPreDefinedListInProgress}
                  formSubmittingText={"Defining..."}
                  onClick={() =>
                    definePreDefinedlLists(
                      ModelTypes.PointSystem,
                      createPointSystem,
                      deletePointSystem,
                      updatePointSystem,
                      listPointSystems,
                      "listPointSystems",
                      PointSystemsConstants.AllTypes,
                      setIsPointSystemPreDefinedListDefined,
                      setIsPointSystemPreDefinedListInProgress
                    )
                  }
                  disabled={isPointSystemPreDefinedListDefined}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </CardContainer>
    </PagePreLoad>
  );
};

export default SystemInitialSettings;
