import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { getPostHashTagsByPostId } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const UserPostAssets = (props) => {
  const { postId } = props;
  const [postHashTagsList, setPostHashTagsList] = useState(null);

  const getUserPostHashtagsList = async () => {
    try {
      let result = await GetFullListResult(
        getPostHashTagsByPostId,
        "getPostHashTagsByPostId",
        {
          postId: postId,
        }
      );

      // check the result
      if (result.success) {
        setPostHashTagsList(result.resultList);
      } else {
        toast.error(
          `Error while fetching Post Hashtags, ${result.errorMessage}`
        );
      }
    } catch (error) {
      toast.error("Error while fetching Post Hashtags");
    }
  };

  useEffect(() => {
    getUserPostHashtagsList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 1,
      attributeTitle: "HashTag Title",
      attributeFetchFunction: (data) => {
        return data.hashTag.title;
      },
      description: "HashTag Title",
      dataType: DataTypes.String,
    },
    {
      key: 2,
      attributeTitle: "Number of Posts with this HashTag",
      attributeFetchFunction: (data) => {
        return data.hashTag.numberOfPosts;
      },
      description: "Number of Posts with this HashTag",
      dataType: DataTypes.String,
    },
  ];

  // present the list of journeys based of the list of properties we have defined
  return postHashTagsList && postHashTagsList.length !== 0 ? (
    postHashTagsList.map((postAssetItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(postAssetItem)
                  : postAssetItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`HashTag: #${postAssetItem.title}`}
      />
    ))
  ) : (
    <>
      <span>Post does not have any hashtags</span>
    </>
  );
};

UserPostAssets.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default UserPostAssets;
