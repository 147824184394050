import React from "react";
import { Button } from "react-bootstrap";

const template = (pagerData) => {
  let { gridRefrence, searchTerm, nextButtonHiddenStatus } = template.prototype;
  
  // when this template is being hit, that means we have a search, we can grab the search terms from the previous grid components and pass it to
  // grid component that we are rending at the search times
  if (searchTerm) {
    gridRefrence.current.searchSettings.key = searchTerm;
  }

  // go to next page
  const nextPageChange = () => {
    gridRefrence.current.pageSettings.currentPage = pagerData.currentPage + 1;
  };

  // go to previous page
  const previousPageChange = () => {
    gridRefrence.current.pageSettings.currentPage = pagerData.currentPage - 1;
  };

  return (
    <div className="e-pagertemplate">
      <div className="col-lg-12 control-section">
        <div className="content-wrapper" style={{ padding: "25px" }}>
          <Button
            onClick={previousPageChange}
            hidden={pagerData.currentPage === 1}
            variant="outline-secondary"
          >
            Previous Page
          </Button>
          <Button
            onClick={nextPageChange}
            hidden={nextButtonHiddenStatus}
            variant="outline-secondary"
          >
            Next Page
          </Button>
        </div>
      </div>
      <div
        id="totalPages"
        className="e-pagertemplatemessage"
        style={{
          marginTop: 8,
          marginLeft: 30,
          border: "none",
          display: "inline-block",
        }}
      ></div>
    </div>
  );
};

export default template;
