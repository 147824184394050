import ExecuteAdminQuery from "../ExecuteAdminQuery";
import { toast } from "react-toastify";

const GetAdminData = async (adminId, setAdminDataState, setIsLoadingState) => {
  let apiName = "AdminQueries";
  let path = "/getUser";
  let myInit = {
    queryStringParameters: {
      username: adminId,
    },
  };
  try {
    let result = await ExecuteAdminQuery(apiName, path, myInit);
    // user data comes back inside the Attributes
    if (result && result.success) {
      let adminDataResult = {
        data: { getAdmin: result.resultItem },
      };
      setAdminDataState(adminDataResult);
    } else {
      toast.error(`Error while fetching admin, ${result.errorMessage}`);
    }
  } catch (error) {
    toast.error(
      `Error while fetching admin, ${JSON.stringify(error, null, 2)}`
    );
  }
  setIsLoadingState(false);
};

export default GetAdminData;
