import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";

const Authorization = (props) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          var signedInUserGroups =
            user.signInUserSession.accessToken.payload["cognito:groups"];
          var isMemberOfGroups = isAuthenticatedUserInGroup(
            props.allowedUserGroups,
            signedInUserGroups
          );
          setIsAuthorized(isMemberOfGroups);
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.error(e);
    }
  }, []);

  const isAuthenticatedUserInGroup = (
    allowedUserGroups,
    authenticatedUserGroups
  ) => {
    let isGroupMember = false;
    allowedUserGroups.map((group) => {
      if (authenticatedUserGroups.includes(group)) isGroupMember = true;
    });
    return isGroupMember;
  };

  return <>{isAuthorized && props.children}</>;
};

Authorization.propTypes = {
  allowedUserGroups: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
};

// Authorization.defaultProps = {
//   allowedUserGroups: [],
// };

export default Authorization;
