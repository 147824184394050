import React from "react";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { ColumnDirective } from "@syncfusion/ej2-react-grids";

const columnsGenerator = (gridObjectDataModel) => {
  return gridObjectDataModel.map((objectModel) => {
    if (objectModel.type === DataTypes.DateTime)
      return (
        <ColumnDirective
          key={`__Truli__Grid_ObjectModel__${objectModel.name}`}
          field={objectModel.name}
          headerText={objectModel.headerText}
          width="100"
          textAlign="Center"
          format={{
            type: "dateTime",
            format: "dd/MM/yyyy hh:mm a",
          }}
        />
      );
    else if (objectModel.type === DataTypes.Boolean)
      return (
        <ColumnDirective
          key={`__Truli__Grid_ObjectModel__${objectModel.name}`}
          field={objectModel.name}
          headerText={objectModel.headerText}
          width="100"
          textAlign="Center"
          displayAsCheckBox={true}
        />
      );
    return (
      <ColumnDirective
        key={`__Truli__Grid_ObjectModel__${objectModel.name}`}
        field={objectModel.name}
        headerText={objectModel.headerText}
        width="100"
        textAlign="Center"
      />
    );
  });
};

export default columnsGenerator;
