import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";
import PreLoad from "../PreLoad";

const AnalyticsCard = (props) => {
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    const { loadFunction } = props;
    if (loadFunction) {
      let result = await loadFunction();

      setTextValue(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Col xs={12} sm={6} lg={3}>
      <Card
        bg={props.variant}
        style={{ cursor: "pointer", color: "white", height: "180px" }}
        className="mb-2"
      >
        <Card.Header>{props.icon}</Card.Header>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          {props.withPreload ? (
            <PreLoad isLoading={isLoading}>
              <Card.Text>{textValue}</Card.Text>
            </PreLoad>
          ) : (
            <Card.Text>{props.text}</Card.Text>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

AnalyticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  withPreload: PropTypes.bool,
  loadFunction: PropTypes.func,
};

AnalyticsCard.defaultProps = {
  title: "Title",
  text: "Text",
  icon: <FaCheck />,
  variant: "info",
  withPreload: false,
  loadFunction: null,
};

export default AnalyticsCard;
