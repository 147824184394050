import React from "react";
import { getReport } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const CommentReportGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.commentReportId}
      modelName={"Report"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "reportByUserName",
          attributeTitle: "Reported By",
          description: "Reported By UserName",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeFetchFunction: (data) => {
            return data.reportCategory.name;
          },
          attributeTitle: "Report Category",
          description: "Report Category",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "message",
          attributeTitle: "Report Message",
          description: "Report Message",
          dataType: DataTypes.String,
        },
        {
          key: 4,
          attributeName: "reportCommentUserName",
          attributeTitle: "Reported",
          description: "Reported Comment owner UserName",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "reportCommentId",
          attributeTitle: "Reported Comment",
          description: "Reported Comment Id",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "reportCommentContentAtTimeOfReport",
          attributeTitle: "Comment Content",
          description: "Comment Content At The Time Of Report",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Report Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 6,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Report Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      providedModelData={props.providedModelData}
      queryFunction={getReport}
      queryFunctionName={"getReport"}
    />
  );
};

CommentReportGeneralInfo.propTypes = {
  commentReportId: PropTypes.string.isRequired,
  providedModelData: PropTypes.object,
};

CommentReportGeneralInfo.defaultProps = {
  providedModelData: null,
};

export default CommentReportGeneralInfo;
