import React from "react";
import PropTypes from "prop-types";
import CardContainer from "../../../../components/Common/CardContainer";

import {
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

const EditSystemSettings = (props) => {
  return (
    <>
      <CardContainer
        header={props.title}
        submitButton={props.submitButton}
        backButton={props.backButton}
      >
        <RichTextEditorComponent
          ref={props.richTextBoxRef}
          height={550}
          toolbarSettings={{
            items: [
              "Bold",
              "Italic",
              "Underline",
              "StrikeThrough",
              "FontName",
              "FontSize",
              "FontColor",
              "BackgroundColor",
              "LowerCase",
              "UpperCase",
              "|",
              "Formats",
              "Alignments",
              "OrderedList",
              "UnorderedList",
              "Outdent",
              "Indent",
              "|",
              "CreateLink",
              "|",
              "ClearFormat",
              "Print",
              "SourceCode",
              "|",
              "Undo",
              "Redo",
            ],
            type: "Expand",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: props.content }} />

          <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      </CardContainer>
    </>
  );
};

EditSystemSettings.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  richTextBoxRef: PropTypes.object.isRequired,
  backButton: PropTypes.func.isRequired,
  submitButton: PropTypes.func.isRequired,
};

export default EditSystemSettings;
