import React from "react";
import { searchGenders } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const Genders = (props) => {
    return (
        <DataGrid
          {...props}
          header="Genders"
          gridObjectDataModel={[
            { name: "name", headerText: "Name", type: DataTypes.String },
            { name: "order", headerText: "Order", type: DataTypes.Number },
            {
              name: "createdAt",
              headerText: "Created At",
              type: DataTypes.DateTime,
            },
          ]}
          modelType={ModelTypes.Gender}
          queryFunction={searchGenders}
          queryFunctionType={QueryTypes.Search}
          queryName={"searchGenders"}
          hasCreate={true}
          hasDetails={true}
          hasEdit={true}
          hasDelete={true}
          baseRoute={"genders"}
        />
      );
};

export default Genders;
