/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      userName
      firstName
      lastName
      major
      graduationYear
      values
      interests
      hero
      bio
      notificationsEmailEnabled
      notificationsLikeEnabled
      notificationsCommentEnabled
      notificationsNewFollowerEnabled
      notificationsFriendRequestEnabled
      privacyPublicAccount
      privacyAllowComments
      privacyHideOffensiveContent
      createdAt
      updatedAt
      deletedAt
      isBlocked
      isProfane
      schoolId
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      userCollegeId
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      userDegreeId
      degree {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherDegreeType
      userGenderId
      gender {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherGenderType
      skills {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      userProfileImageId
      profileImage {
        id
        s3ObjectId
        owner
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      journeys {
        items {
          id
          owner
          title
          institution
          location
          isCurrent
          startDate
          endDate
          description
          journeyTypeId
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          owner
          message
          isRead
          feedbackCategoryId
          feedbackCategoryName
          userId
          userName
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      clazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      pastClazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfPosts
      posts {
        items {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      followings {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowings
      followers {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowers
      friendRequestsReceived {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      friendRequestsSent {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFriends
      friends {
        items {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        nextToken
      }
      blokedUsers {
        items {
          id
          owner
          userId
          blockedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      mutedUsers {
        items {
          id
          owner
          userId
          mutedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      willingToHelps {
        items {
          id
          owner
          content
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      subjectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      objectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfStarPoints
      appBadgeCount
      referralCodeId
      numberOfTimesReferralCodeUsed
      userSignedUpWithReferralCodeId
      searchableClazzes
      searchablePastClazzes
      referralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
      signedUpWithReferralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      userName
      firstName
      lastName
      major
      graduationYear
      values
      interests
      hero
      bio
      notificationsEmailEnabled
      notificationsLikeEnabled
      notificationsCommentEnabled
      notificationsNewFollowerEnabled
      notificationsFriendRequestEnabled
      privacyPublicAccount
      privacyAllowComments
      privacyHideOffensiveContent
      createdAt
      updatedAt
      deletedAt
      isBlocked
      isProfane
      schoolId
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      userCollegeId
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      userDegreeId
      degree {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherDegreeType
      userGenderId
      gender {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherGenderType
      skills {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      userProfileImageId
      profileImage {
        id
        s3ObjectId
        owner
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      journeys {
        items {
          id
          owner
          title
          institution
          location
          isCurrent
          startDate
          endDate
          description
          journeyTypeId
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          owner
          message
          isRead
          feedbackCategoryId
          feedbackCategoryName
          userId
          userName
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      clazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      pastClazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfPosts
      posts {
        items {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      followings {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowings
      followers {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowers
      friendRequestsReceived {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      friendRequestsSent {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFriends
      friends {
        items {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        nextToken
      }
      blokedUsers {
        items {
          id
          owner
          userId
          blockedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      mutedUsers {
        items {
          id
          owner
          userId
          mutedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      willingToHelps {
        items {
          id
          owner
          content
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      subjectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      objectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfStarPoints
      appBadgeCount
      referralCodeId
      numberOfTimesReferralCodeUsed
      userSignedUpWithReferralCodeId
      searchableClazzes
      searchablePastClazzes
      referralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
      signedUpWithReferralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      userName
      firstName
      lastName
      major
      graduationYear
      values
      interests
      hero
      bio
      notificationsEmailEnabled
      notificationsLikeEnabled
      notificationsCommentEnabled
      notificationsNewFollowerEnabled
      notificationsFriendRequestEnabled
      privacyPublicAccount
      privacyAllowComments
      privacyHideOffensiveContent
      createdAt
      updatedAt
      deletedAt
      isBlocked
      isProfane
      schoolId
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      userCollegeId
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      userDegreeId
      degree {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherDegreeType
      userGenderId
      gender {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      otherGenderType
      skills {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      userProfileImageId
      profileImage {
        id
        s3ObjectId
        owner
        s3Object {
          id
          owner
          bucket
          key
          region
          fileName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      journeys {
        items {
          id
          owner
          title
          institution
          location
          isCurrent
          startDate
          endDate
          description
          journeyTypeId
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          owner
          message
          isRead
          feedbackCategoryId
          feedbackCategoryName
          userId
          userName
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      clazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      pastClazzes {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfPosts
      posts {
        items {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      followings {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowings
      followers {
        items {
          id
          owner
          followerId
          followingId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFollowers
      friendRequestsReceived {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      friendRequestsSent {
        items {
          id
          userFromId
          userToId
          isAccepted
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfFriends
      friends {
        items {
          id
          owner
          userId
          friendId
          createdAt
          updatedAt
        }
        nextToken
      }
      blokedUsers {
        items {
          id
          owner
          userId
          blockedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      mutedUsers {
        items {
          id
          owner
          userId
          mutedUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      willingToHelps {
        items {
          id
          owner
          content
          userId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      subjectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      objectActivitys {
        items {
          id
          activityType
          isRead
          subjectUserId
          objectUserId
          userActivityLikeId
          userActivityCommentId
          userActivityUserFollowerId
          userActivityUserFriendId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfStarPoints
      appBadgeCount
      referralCodeId
      numberOfTimesReferralCodeUsed
      userSignedUpWithReferralCodeId
      searchableClazzes
      searchablePastClazzes
      referralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
      signedUpWithReferralCode {
        id
        code
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReferralCode = /* GraphQL */ `
  mutation CreateReferralCode(
    $input: CreateReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    createReferralCode(input: $input, condition: $condition) {
      id
      code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateReferralCode = /* GraphQL */ `
  mutation UpdateReferralCode(
    $input: UpdateReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    updateReferralCode(input: $input, condition: $condition) {
      id
      code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferralCode = /* GraphQL */ `
  mutation DeleteReferralCode(
    $input: DeleteReferralCodeInput!
    $condition: ModelReferralCodeConditionInput
  ) {
    deleteReferralCode(input: $input, condition: $condition) {
      id
      code
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createUserFollower = /* GraphQL */ `
  mutation CreateUserFollower(
    $input: CreateUserFollowerInput!
    $condition: ModelUserFollowerConditionInput
  ) {
    createUserFollower(input: $input, condition: $condition) {
      id
      owner
      followerId
      followingId
      follower {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      following {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserFollower = /* GraphQL */ `
  mutation UpdateUserFollower(
    $input: UpdateUserFollowerInput!
    $condition: ModelUserFollowerConditionInput
  ) {
    updateUserFollower(input: $input, condition: $condition) {
      id
      owner
      followerId
      followingId
      follower {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      following {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserFollower = /* GraphQL */ `
  mutation DeleteUserFollower(
    $input: DeleteUserFollowerInput!
    $condition: ModelUserFollowerConditionInput
  ) {
    deleteUserFollower(input: $input, condition: $condition) {
      id
      owner
      followerId
      followingId
      follower {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      following {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFriendRequest = /* GraphQL */ `
  mutation CreateFriendRequest(
    $input: CreateFriendRequestInput!
    $condition: ModelFriendRequestConditionInput
  ) {
    createFriendRequest(input: $input, condition: $condition) {
      id
      userFromId
      userToId
      isAccepted
      userFrom {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userTo {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFriendRequest = /* GraphQL */ `
  mutation UpdateFriendRequest(
    $input: UpdateFriendRequestInput!
    $condition: ModelFriendRequestConditionInput
  ) {
    updateFriendRequest(input: $input, condition: $condition) {
      id
      userFromId
      userToId
      isAccepted
      userFrom {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userTo {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFriendRequest = /* GraphQL */ `
  mutation DeleteFriendRequest(
    $input: DeleteFriendRequestInput!
    $condition: ModelFriendRequestConditionInput
  ) {
    deleteFriendRequest(input: $input, condition: $condition) {
      id
      userFromId
      userToId
      isAccepted
      userFrom {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userTo {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserFriend = /* GraphQL */ `
  mutation CreateUserFriend(
    $input: CreateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    createUserFriend(input: $input, condition: $condition) {
      id
      owner
      userId
      friendId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      friend {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserFriend = /* GraphQL */ `
  mutation UpdateUserFriend(
    $input: UpdateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    updateUserFriend(input: $input, condition: $condition) {
      id
      owner
      userId
      friendId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      friend {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserFriend = /* GraphQL */ `
  mutation DeleteUserFriend(
    $input: DeleteUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    deleteUserFriend(input: $input, condition: $condition) {
      id
      owner
      userId
      friendId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      friend {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserBlock = /* GraphQL */ `
  mutation CreateUserBlock(
    $input: CreateUserBlockInput!
    $condition: ModelUserBlockConditionInput
  ) {
    createUserBlock(input: $input, condition: $condition) {
      id
      owner
      userId
      blockedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      blockedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserBlock = /* GraphQL */ `
  mutation UpdateUserBlock(
    $input: UpdateUserBlockInput!
    $condition: ModelUserBlockConditionInput
  ) {
    updateUserBlock(input: $input, condition: $condition) {
      id
      owner
      userId
      blockedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      blockedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserBlock = /* GraphQL */ `
  mutation DeleteUserBlock(
    $input: DeleteUserBlockInput!
    $condition: ModelUserBlockConditionInput
  ) {
    deleteUserBlock(input: $input, condition: $condition) {
      id
      owner
      userId
      blockedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      blockedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserMute = /* GraphQL */ `
  mutation CreateUserMute(
    $input: CreateUserMuteInput!
    $condition: ModelUserMuteConditionInput
  ) {
    createUserMute(input: $input, condition: $condition) {
      id
      owner
      userId
      mutedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      mutedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMute = /* GraphQL */ `
  mutation UpdateUserMute(
    $input: UpdateUserMuteInput!
    $condition: ModelUserMuteConditionInput
  ) {
    updateUserMute(input: $input, condition: $condition) {
      id
      owner
      userId
      mutedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      mutedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMute = /* GraphQL */ `
  mutation DeleteUserMute(
    $input: DeleteUserMuteInput!
    $condition: ModelUserMuteConditionInput
  ) {
    deleteUserMute(input: $input, condition: $condition) {
      id
      owner
      userId
      mutedUserId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      mutedUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSystemSettings = /* GraphQL */ `
  mutation CreateSystemSettings(
    $input: CreateSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    createSystemSettings(input: $input, condition: $condition) {
      id
      settingType
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemSettings = /* GraphQL */ `
  mutation UpdateSystemSettings(
    $input: UpdateSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    updateSystemSettings(input: $input, condition: $condition) {
      id
      settingType
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemSettings = /* GraphQL */ `
  mutation DeleteSystemSettings(
    $input: DeleteSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    deleteSystemSettings(input: $input, condition: $condition) {
      id
      settingType
      content
      createdAt
      updatedAt
    }
  }
`;
export const createModelAggregations = /* GraphQL */ `
  mutation CreateModelAggregations(
    $input: CreateModelAggregationsInput!
    $condition: ModelModelAggregationsConditionInput
  ) {
    createModelAggregations(input: $input, condition: $condition) {
      modelType
      totalActiveCount
      createdAt
      updatedAt
    }
  }
`;
export const updateModelAggregations = /* GraphQL */ `
  mutation UpdateModelAggregations(
    $input: UpdateModelAggregationsInput!
    $condition: ModelModelAggregationsConditionInput
  ) {
    updateModelAggregations(input: $input, condition: $condition) {
      modelType
      totalActiveCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteModelAggregations = /* GraphQL */ `
  mutation DeleteModelAggregations(
    $input: DeleteModelAggregationsInput!
    $condition: ModelModelAggregationsConditionInput
  ) {
    deleteModelAggregations(input: $input, condition: $condition) {
      modelType
      totalActiveCount
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      createdAt
      deletedAt
      colleges {
        items {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        nextToken
      }
      numberOfColleges
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      totalSchoolUsersCount
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      createdAt
      deletedAt
      colleges {
        items {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        nextToken
      }
      numberOfColleges
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      totalSchoolUsersCount
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      createdAt
      deletedAt
      colleges {
        items {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        nextToken
      }
      numberOfColleges
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      totalSchoolUsersCount
      updatedAt
    }
  }
`;
export const createSchoolCollege = /* GraphQL */ `
  mutation CreateSchoolCollege(
    $input: CreateSchoolCollegeInput!
    $condition: ModelSchoolCollegeConditionInput
  ) {
    createSchoolCollege(input: $input, condition: $condition) {
      id
      schoolId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      updatedAt
    }
  }
`;
export const updateSchoolCollege = /* GraphQL */ `
  mutation UpdateSchoolCollege(
    $input: UpdateSchoolCollegeInput!
    $condition: ModelSchoolCollegeConditionInput
  ) {
    updateSchoolCollege(input: $input, condition: $condition) {
      id
      schoolId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      updatedAt
    }
  }
`;
export const deleteSchoolCollege = /* GraphQL */ `
  mutation DeleteSchoolCollege(
    $input: DeleteSchoolCollegeInput!
    $condition: ModelSchoolCollegeConditionInput
  ) {
    deleteSchoolCollege(input: $input, condition: $condition) {
      id
      schoolId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      domains {
        items {
          id
          schoolId
          domainCollegeId
          name
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfDomains
      clazzes {
        items {
          id
          type
          schoolId
          clazzCollegeId
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        nextToken
      }
      numberOfClazzes
      users {
        items {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        nextToken
      }
      numberOfUsers
      updatedAt
    }
  }
`;
export const createDomain = /* GraphQL */ `
  mutation CreateDomain(
    $input: CreateDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    createDomain(input: $input, condition: $condition) {
      id
      schoolId
      domainCollegeId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateDomain = /* GraphQL */ `
  mutation UpdateDomain(
    $input: UpdateDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    updateDomain(input: $input, condition: $condition) {
      id
      schoolId
      domainCollegeId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteDomain = /* GraphQL */ `
  mutation DeleteDomain(
    $input: DeleteDomainInput!
    $condition: ModelDomainConditionInput
  ) {
    deleteDomain(input: $input, condition: $condition) {
      id
      schoolId
      domainCollegeId
      name
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createClazz = /* GraphQL */ `
  mutation CreateClazz(
    $input: CreateClazzInput!
    $condition: ModelClazzConditionInput
  ) {
    createClazz(input: $input, condition: $condition) {
      id
      type
      schoolId
      clazzCollegeId
      name
      order
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      users {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      usersPastClazz {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateClazz = /* GraphQL */ `
  mutation UpdateClazz(
    $input: UpdateClazzInput!
    $condition: ModelClazzConditionInput
  ) {
    updateClazz(input: $input, condition: $condition) {
      id
      type
      schoolId
      clazzCollegeId
      name
      order
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      users {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      usersPastClazz {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteClazz = /* GraphQL */ `
  mutation DeleteClazz(
    $input: DeleteClazzInput!
    $condition: ModelClazzConditionInput
  ) {
    deleteClazz(input: $input, condition: $condition) {
      id
      type
      schoolId
      clazzCollegeId
      name
      order
      createdAt
      deletedAt
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      college {
        id
        schoolId
        name
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        updatedAt
      }
      users {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      usersPastClazz {
        items {
          id
          owner
          userId
          clazzId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createUserClazz = /* GraphQL */ `
  mutation CreateUserClazz(
    $input: CreateUserClazzInput!
    $condition: ModelUserClazzConditionInput
  ) {
    createUserClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserClazz = /* GraphQL */ `
  mutation UpdateUserClazz(
    $input: UpdateUserClazzInput!
    $condition: ModelUserClazzConditionInput
  ) {
    updateUserClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserClazz = /* GraphQL */ `
  mutation DeleteUserClazz(
    $input: DeleteUserClazzInput!
    $condition: ModelUserClazzConditionInput
  ) {
    deleteUserClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserPastClazz = /* GraphQL */ `
  mutation CreateUserPastClazz(
    $input: CreateUserPastClazzInput!
    $condition: ModelUserPastClazzConditionInput
  ) {
    createUserPastClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPastClazz = /* GraphQL */ `
  mutation UpdateUserPastClazz(
    $input: UpdateUserPastClazzInput!
    $condition: ModelUserPastClazzConditionInput
  ) {
    updateUserPastClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPastClazz = /* GraphQL */ `
  mutation DeleteUserPastClazz(
    $input: DeleteUserPastClazzInput!
    $condition: ModelUserPastClazzConditionInput
  ) {
    deleteUserPastClazz(input: $input, condition: $condition) {
      id
      owner
      userId
      clazzId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      clazz {
        id
        type
        schoolId
        clazzCollegeId
        name
        order
        createdAt
        deletedAt
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        users {
          nextToken
        }
        usersPastClazz {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDegree = /* GraphQL */ `
  mutation CreateDegree(
    $input: CreateDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    createDegree(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updateDegree = /* GraphQL */ `
  mutation UpdateDegree(
    $input: UpdateDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    updateDegree(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deleteDegree = /* GraphQL */ `
  mutation DeleteDegree(
    $input: DeleteDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    deleteDegree(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createGender = /* GraphQL */ `
  mutation CreateGender(
    $input: CreateGenderInput!
    $condition: ModelGenderConditionInput
  ) {
    createGender(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updateGender = /* GraphQL */ `
  mutation UpdateGender(
    $input: UpdateGenderInput!
    $condition: ModelGenderConditionInput
  ) {
    updateGender(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deleteGender = /* GraphQL */ `
  mutation DeleteGender(
    $input: DeleteGenderInput!
    $condition: ModelGenderConditionInput
  ) {
    deleteGender(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      users {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      users {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      users {
        items {
          id
          owner
          userId
          skillId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createUserSkill = /* GraphQL */ `
  mutation CreateUserSkill(
    $input: CreateUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    createUserSkill(input: $input, condition: $condition) {
      id
      owner
      userId
      skillId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      skill {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSkill = /* GraphQL */ `
  mutation UpdateUserSkill(
    $input: UpdateUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    updateUserSkill(input: $input, condition: $condition) {
      id
      owner
      userId
      skillId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      skill {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSkill = /* GraphQL */ `
  mutation DeleteUserSkill(
    $input: DeleteUserSkillInput!
    $condition: ModelUserSkillConditionInput
  ) {
    deleteUserSkill(input: $input, condition: $condition) {
      id
      owner
      userId
      skillId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      skill {
        id
        type
        name
        order
        createdAt
        deletedAt
        users {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProfileImage = /* GraphQL */ `
  mutation CreateProfileImage(
    $input: CreateProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    createProfileImage(input: $input, condition: $condition) {
      id
      s3ObjectId
      owner
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateProfileImage = /* GraphQL */ `
  mutation UpdateProfileImage(
    $input: UpdateProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    updateProfileImage(input: $input, condition: $condition) {
      id
      s3ObjectId
      owner
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteProfileImage = /* GraphQL */ `
  mutation DeleteProfileImage(
    $input: DeleteProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    deleteProfileImage(input: $input, condition: $condition) {
      id
      s3ObjectId
      owner
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createS3Object = /* GraphQL */ `
  mutation CreateS3Object(
    $input: CreateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    createS3Object(input: $input, condition: $condition) {
      id
      owner
      bucket
      key
      region
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const updateS3Object = /* GraphQL */ `
  mutation UpdateS3Object(
    $input: UpdateS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    updateS3Object(input: $input, condition: $condition) {
      id
      owner
      bucket
      key
      region
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3Object = /* GraphQL */ `
  mutation DeleteS3Object(
    $input: DeleteS3ObjectInput!
    $condition: ModelS3ObjectConditionInput
  ) {
    deleteS3Object(input: $input, condition: $condition) {
      id
      owner
      bucket
      key
      region
      fileName
      createdAt
      updatedAt
    }
  }
`;
export const createJourneyType = /* GraphQL */ `
  mutation CreateJourneyType(
    $input: CreateJourneyTypeInput!
    $condition: ModelJourneyTypeConditionInput
  ) {
    createJourneyType(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updateJourneyType = /* GraphQL */ `
  mutation UpdateJourneyType(
    $input: UpdateJourneyTypeInput!
    $condition: ModelJourneyTypeConditionInput
  ) {
    updateJourneyType(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deleteJourneyType = /* GraphQL */ `
  mutation DeleteJourneyType(
    $input: DeleteJourneyTypeInput!
    $condition: ModelJourneyTypeConditionInput
  ) {
    deleteJourneyType(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createJourney = /* GraphQL */ `
  mutation CreateJourney(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
      owner
      title
      institution
      location
      isCurrent
      startDate
      endDate
      description
      journeyTypeId
      journeyType {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateJourney = /* GraphQL */ `
  mutation UpdateJourney(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
      owner
      title
      institution
      location
      isCurrent
      startDate
      endDate
      description
      journeyTypeId
      journeyType {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteJourney = /* GraphQL */ `
  mutation DeleteJourney(
    $input: DeleteJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    deleteJourney(input: $input, condition: $condition) {
      id
      owner
      title
      institution
      location
      isCurrent
      startDate
      endDate
      description
      journeyTypeId
      journeyType {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createFeedbackCategory = /* GraphQL */ `
  mutation CreateFeedbackCategory(
    $input: CreateFeedbackCategoryInput!
    $condition: ModelFeedbackCategoryConditionInput
  ) {
    createFeedbackCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updateFeedbackCategory = /* GraphQL */ `
  mutation UpdateFeedbackCategory(
    $input: UpdateFeedbackCategoryInput!
    $condition: ModelFeedbackCategoryConditionInput
  ) {
    updateFeedbackCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deleteFeedbackCategory = /* GraphQL */ `
  mutation DeleteFeedbackCategory(
    $input: DeleteFeedbackCategoryInput!
    $condition: ModelFeedbackCategoryConditionInput
  ) {
    deleteFeedbackCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      owner
      message
      isRead
      feedbackCategoryId
      feedbackCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      feedbackCategoryName
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      owner
      message
      isRead
      feedbackCategoryId
      feedbackCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      feedbackCategoryName
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      owner
      message
      isRead
      feedbackCategoryId
      feedbackCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      feedbackCategoryName
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createReportCategory = /* GraphQL */ `
  mutation CreateReportCategory(
    $input: CreateReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    createReportCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updateReportCategory = /* GraphQL */ `
  mutation UpdateReportCategory(
    $input: UpdateReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    updateReportCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deleteReportCategory = /* GraphQL */ `
  mutation DeleteReportCategory(
    $input: DeleteReportCategoryInput!
    $condition: ModelReportCategoryConditionInput
  ) {
    deleteReportCategory(input: $input, condition: $condition) {
      id
      type
      name
      order
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      owner
      message
      isAcknowledged
      reportCategoryId
      reportCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      reportByUserId
      reportByUserName
      reportByUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportUserId
      reportUserName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportPostId
      reportPostUserName
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      reportCommentId
      reportCommentUserName
      reportCommentContentAtTimeOfReport
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      owner
      message
      isAcknowledged
      reportCategoryId
      reportCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      reportByUserId
      reportByUserName
      reportByUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportUserId
      reportUserName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportPostId
      reportPostUserName
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      reportCommentId
      reportCommentUserName
      reportCommentContentAtTimeOfReport
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      owner
      message
      isAcknowledged
      reportCategoryId
      reportCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      reportByUserId
      reportByUserName
      reportByUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportUserId
      reportUserName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      reportPostId
      reportPostUserName
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      reportCommentId
      reportCommentUserName
      reportCommentContentAtTimeOfReport
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      postType
      content
      owner
      isProfane
      isFriendsOnly
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postPostCategoryId
      postPostCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfLikes
      likes {
        items {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfComments
      comments {
        items {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      postAssets {
        items {
          id
          owner
          order
          postId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      hashTags {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      postType
      content
      owner
      isProfane
      isFriendsOnly
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postPostCategoryId
      postPostCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfLikes
      likes {
        items {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfComments
      comments {
        items {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      postAssets {
        items {
          id
          owner
          order
          postId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      hashTags {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      postType
      content
      owner
      isProfane
      isFriendsOnly
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postPostCategoryId
      postPostCategory {
        id
        type
        name
        order
        createdAt
        deletedAt
        updatedAt
      }
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfLikes
      likes {
        items {
          id
          owner
          userId
          userName
          postId
          createdAt
          updatedAt
        }
        nextToken
      }
      numberOfComments
      comments {
        items {
          id
          owner
          content
          isProfane
          userId
          userName
          postId
          isReply
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      postAssets {
        items {
          id
          owner
          order
          postId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      hashTags {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createPostAsset = /* GraphQL */ `
  mutation CreatePostAsset(
    $input: CreatePostAssetInput!
    $condition: ModelPostAssetConditionInput
  ) {
    createPostAsset(input: $input, condition: $condition) {
      id
      owner
      order
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updatePostAsset = /* GraphQL */ `
  mutation UpdatePostAsset(
    $input: UpdatePostAssetInput!
    $condition: ModelPostAssetConditionInput
  ) {
    updatePostAsset(input: $input, condition: $condition) {
      id
      owner
      order
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deletePostAsset = /* GraphQL */ `
  mutation DeletePostAsset(
    $input: DeletePostAssetInput!
    $condition: ModelPostAssetConditionInput
  ) {
    deletePostAsset(input: $input, condition: $condition) {
      id
      owner
      order
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createPostHashTag = /* GraphQL */ `
  mutation CreatePostHashTag(
    $input: CreatePostHashTagInput!
    $condition: ModelPostHashTagConditionInput
  ) {
    createPostHashTag(input: $input, condition: $condition) {
      id
      postId
      hashTagId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      hashTag {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePostHashTag = /* GraphQL */ `
  mutation UpdatePostHashTag(
    $input: UpdatePostHashTagInput!
    $condition: ModelPostHashTagConditionInput
  ) {
    updatePostHashTag(input: $input, condition: $condition) {
      id
      postId
      hashTagId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      hashTag {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePostHashTag = /* GraphQL */ `
  mutation DeletePostHashTag(
    $input: DeletePostHashTagInput!
    $condition: ModelPostHashTagConditionInput
  ) {
    deletePostHashTag(input: $input, condition: $condition) {
      id
      postId
      hashTagId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      hashTag {
        id
        title
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfPosts
        posts {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHashTag = /* GraphQL */ `
  mutation CreateHashTag(
    $input: CreateHashTagInput!
    $condition: ModelHashTagConditionInput
  ) {
    createHashTag(input: $input, condition: $condition) {
      id
      title
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfPosts
      posts {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateHashTag = /* GraphQL */ `
  mutation UpdateHashTag(
    $input: UpdateHashTagInput!
    $condition: ModelHashTagConditionInput
  ) {
    updateHashTag(input: $input, condition: $condition) {
      id
      title
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfPosts
      posts {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteHashTag = /* GraphQL */ `
  mutation DeleteHashTag(
    $input: DeleteHashTagInput!
    $condition: ModelHashTagConditionInput
  ) {
    deleteHashTag(input: $input, condition: $condition) {
      id
      title
      schoolId
      schoolName
      school {
        id
        name
        createdAt
        deletedAt
        colleges {
          nextToken
        }
        numberOfColleges
        domains {
          nextToken
        }
        numberOfDomains
        clazzes {
          nextToken
        }
        numberOfClazzes
        users {
          nextToken
        }
        numberOfUsers
        totalSchoolUsersCount
        updatedAt
      }
      numberOfPosts
      posts {
        items {
          id
          postId
          hashTagId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      owner
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      owner
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      owner
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      owner
      content
      isProfane
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      isReply
      replies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      parentReplies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      owner
      content
      isProfane
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      isReply
      replies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      parentReplies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      owner
      content
      isProfane
      userId
      userName
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      postId
      post {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      isReply
      replies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      parentReplies {
        items {
          id
          commentId
          repliedToId
          parrentRepliedToId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createCommentReply = /* GraphQL */ `
  mutation CreateCommentReply(
    $input: CreateCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    createCommentReply(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      repliedToId
      repliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      parrentRepliedToId
      parrentRepliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCommentReply = /* GraphQL */ `
  mutation UpdateCommentReply(
    $input: UpdateCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    updateCommentReply(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      repliedToId
      repliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      parrentRepliedToId
      parrentRepliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommentReply = /* GraphQL */ `
  mutation DeleteCommentReply(
    $input: DeleteCommentReplyInput!
    $condition: ModelCommentReplyConditionInput
  ) {
    deleteCommentReply(input: $input, condition: $condition) {
      id
      commentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      repliedToId
      repliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      parrentRepliedToId
      parrentRepliedTo {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWillingToHelp = /* GraphQL */ `
  mutation CreateWillingToHelp(
    $input: CreateWillingToHelpInput!
    $condition: ModelWillingToHelpConditionInput
  ) {
    createWillingToHelp(input: $input, condition: $condition) {
      id
      owner
      content
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateWillingToHelp = /* GraphQL */ `
  mutation UpdateWillingToHelp(
    $input: UpdateWillingToHelpInput!
    $condition: ModelWillingToHelpConditionInput
  ) {
    updateWillingToHelp(input: $input, condition: $condition) {
      id
      owner
      content
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteWillingToHelp = /* GraphQL */ `
  mutation DeleteWillingToHelp(
    $input: DeleteWillingToHelpInput!
    $condition: ModelWillingToHelpConditionInput
  ) {
    deleteWillingToHelp(input: $input, condition: $condition) {
      id
      owner
      content
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createUserActivity = /* GraphQL */ `
  mutation CreateUserActivity(
    $input: CreateUserActivityInput!
    $condition: ModelUserActivityConditionInput
  ) {
    createUserActivity(input: $input, condition: $condition) {
      id
      activityType
      isRead
      subjectUserId
      objectUserId
      subjectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      objectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userActivityLikeId
      like {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      userActivityCommentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      userActivityUserFollowerId
      userFollower {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      userActivityUserFriendId
      userFriend {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserActivity = /* GraphQL */ `
  mutation UpdateUserActivity(
    $input: UpdateUserActivityInput!
    $condition: ModelUserActivityConditionInput
  ) {
    updateUserActivity(input: $input, condition: $condition) {
      id
      activityType
      isRead
      subjectUserId
      objectUserId
      subjectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      objectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userActivityLikeId
      like {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      userActivityCommentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      userActivityUserFollowerId
      userFollower {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      userActivityUserFriendId
      userFriend {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserActivity = /* GraphQL */ `
  mutation DeleteUserActivity(
    $input: DeleteUserActivityInput!
    $condition: ModelUserActivityConditionInput
  ) {
    deleteUserActivity(input: $input, condition: $condition) {
      id
      activityType
      isRead
      subjectUserId
      objectUserId
      subjectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      objectUser {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      userActivityLikeId
      like {
        id
        owner
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      userActivityCommentId
      comment {
        id
        owner
        content
        isProfane
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postId
        post {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        isReply
        replies {
          nextToken
        }
        parentReplies {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      userActivityUserFollowerId
      userFollower {
        id
        owner
        followerId
        followingId
        follower {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        following {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      userActivityUserFriendId
      userFriend {
        id
        owner
        userId
        friendId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        friend {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPointSystem = /* GraphQL */ `
  mutation CreatePointSystem(
    $input: CreatePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    createPointSystem(input: $input, condition: $condition) {
      id
      type
      pointType
      order
      point
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const updatePointSystem = /* GraphQL */ `
  mutation UpdatePointSystem(
    $input: UpdatePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    updatePointSystem(input: $input, condition: $condition) {
      id
      type
      pointType
      order
      point
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const deletePointSystem = /* GraphQL */ `
  mutation DeletePointSystem(
    $input: DeletePointSystemInput!
    $condition: ModelPointSystemConditionInput
  ) {
    deletePointSystem(input: $input, condition: $condition) {
      id
      type
      pointType
      order
      point
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      members
      chatRoomParticipants {
        items {
          id
          pinnedStatus
          userId
          chatRoomId
          chatRoomParticipantLastReadMessageId
          numberOfUnReadMessages
          createdAt
          updatedAt
          lastChatRoomMessageCreatedAt
        }
        nextToken
      }
      chatRoomMessages {
        items {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomLastMessageId
      lastMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      members
      chatRoomParticipants {
        items {
          id
          pinnedStatus
          userId
          chatRoomId
          chatRoomParticipantLastReadMessageId
          numberOfUnReadMessages
          createdAt
          updatedAt
          lastChatRoomMessageCreatedAt
        }
        nextToken
      }
      chatRoomMessages {
        items {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomLastMessageId
      lastMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      members
      chatRoomParticipants {
        items {
          id
          pinnedStatus
          userId
          chatRoomId
          chatRoomParticipantLastReadMessageId
          numberOfUnReadMessages
          createdAt
          updatedAt
          lastChatRoomMessageCreatedAt
        }
        nextToken
      }
      chatRoomMessages {
        items {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomLastMessageId
      lastMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoomParticipant = /* GraphQL */ `
  mutation CreateChatRoomParticipant(
    $input: CreateChatRoomParticipantInput!
    $condition: ModelChatRoomParticipantConditionInput
  ) {
    createChatRoomParticipant(input: $input, condition: $condition) {
      id
      pinnedStatus
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoom {
        id
        members
        chatRoomParticipants {
          nextToken
        }
        chatRoomMessages {
          nextToken
        }
        chatRoomLastMessageId
        lastMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      chatRoomParticipantLastReadMessageId
      lastReadMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      numberOfUnReadMessages
      createdAt
      updatedAt
      lastChatRoomMessageCreatedAt
    }
  }
`;
export const updateChatRoomParticipant = /* GraphQL */ `
  mutation UpdateChatRoomParticipant(
    $input: UpdateChatRoomParticipantInput!
    $condition: ModelChatRoomParticipantConditionInput
  ) {
    updateChatRoomParticipant(input: $input, condition: $condition) {
      id
      pinnedStatus
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoom {
        id
        members
        chatRoomParticipants {
          nextToken
        }
        chatRoomMessages {
          nextToken
        }
        chatRoomLastMessageId
        lastMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      chatRoomParticipantLastReadMessageId
      lastReadMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      numberOfUnReadMessages
      createdAt
      updatedAt
      lastChatRoomMessageCreatedAt
    }
  }
`;
export const deleteChatRoomParticipant = /* GraphQL */ `
  mutation DeleteChatRoomParticipant(
    $input: DeleteChatRoomParticipantInput!
    $condition: ModelChatRoomParticipantConditionInput
  ) {
    deleteChatRoomParticipant(input: $input, condition: $condition) {
      id
      pinnedStatus
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoom {
        id
        members
        chatRoomParticipants {
          nextToken
        }
        chatRoomMessages {
          nextToken
        }
        chatRoomLastMessageId
        lastMessage {
          id
          owner
          chatMessageType
          body
          userId
          chatRoomId
          chatRoomMessageSharedPostId
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      chatRoomParticipantLastReadMessageId
      lastReadMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      numberOfUnReadMessages
      createdAt
      updatedAt
      lastChatRoomMessageCreatedAt
    }
  }
`;
export const createChatRoomMessage = /* GraphQL */ `
  mutation CreateChatRoomMessage(
    $input: CreateChatRoomMessageInput!
    $condition: ModelChatRoomMessageConditionInput
  ) {
    createChatRoomMessage(input: $input, condition: $condition) {
      id
      owner
      chatMessageType
      body
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoomMessageAssets {
        items {
          id
          owner
          order
          chatRoomMessageId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomMessageSharedPostId
      sharedPost {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateChatRoomMessage = /* GraphQL */ `
  mutation UpdateChatRoomMessage(
    $input: UpdateChatRoomMessageInput!
    $condition: ModelChatRoomMessageConditionInput
  ) {
    updateChatRoomMessage(input: $input, condition: $condition) {
      id
      owner
      chatMessageType
      body
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoomMessageAssets {
        items {
          id
          owner
          order
          chatRoomMessageId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomMessageSharedPostId
      sharedPost {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteChatRoomMessage = /* GraphQL */ `
  mutation DeleteChatRoomMessage(
    $input: DeleteChatRoomMessageInput!
    $condition: ModelChatRoomMessageConditionInput
  ) {
    deleteChatRoomMessage(input: $input, condition: $condition) {
      id
      owner
      chatMessageType
      body
      userId
      user {
        id
        owner
        userName
        firstName
        lastName
        major
        graduationYear
        values
        interests
        hero
        bio
        notificationsEmailEnabled
        notificationsLikeEnabled
        notificationsCommentEnabled
        notificationsNewFollowerEnabled
        notificationsFriendRequestEnabled
        privacyPublicAccount
        privacyAllowComments
        privacyHideOffensiveContent
        createdAt
        updatedAt
        deletedAt
        isBlocked
        isProfane
        schoolId
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        userCollegeId
        college {
          id
          schoolId
          name
          createdAt
          deletedAt
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          updatedAt
        }
        userDegreeId
        degree {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherDegreeType
        userGenderId
        gender {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        otherGenderType
        skills {
          nextToken
        }
        userProfileImageId
        profileImage {
          id
          s3ObjectId
          owner
          createdAt
          updatedAt
          deletedAt
        }
        journeys {
          nextToken
        }
        feedbacks {
          nextToken
        }
        clazzes {
          nextToken
        }
        pastClazzes {
          nextToken
        }
        numberOfPosts
        posts {
          nextToken
        }
        followings {
          nextToken
        }
        numberOfFollowings
        followers {
          nextToken
        }
        numberOfFollowers
        friendRequestsReceived {
          nextToken
        }
        friendRequestsSent {
          nextToken
        }
        numberOfFriends
        friends {
          nextToken
        }
        blokedUsers {
          nextToken
        }
        mutedUsers {
          nextToken
        }
        willingToHelps {
          nextToken
        }
        subjectActivitys {
          nextToken
        }
        objectActivitys {
          nextToken
        }
        numberOfStarPoints
        appBadgeCount
        referralCodeId
        numberOfTimesReferralCodeUsed
        userSignedUpWithReferralCodeId
        searchableClazzes
        searchablePastClazzes
        referralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
        signedUpWithReferralCode {
          id
          code
          userId
          createdAt
          updatedAt
        }
      }
      chatRoomId
      chatRoomMessageAssets {
        items {
          id
          owner
          order
          chatRoomMessageId
          s3ObjectId
          createdAt
          updatedAt
          deletedAt
        }
        nextToken
      }
      chatRoomMessageSharedPostId
      sharedPost {
        id
        postType
        content
        owner
        isProfane
        isFriendsOnly
        userId
        userName
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        postPostCategoryId
        postPostCategory {
          id
          type
          name
          order
          createdAt
          deletedAt
          updatedAt
        }
        schoolId
        schoolName
        school {
          id
          name
          createdAt
          deletedAt
          numberOfColleges
          numberOfDomains
          numberOfClazzes
          numberOfUsers
          totalSchoolUsersCount
          updatedAt
        }
        numberOfLikes
        likes {
          nextToken
        }
        numberOfComments
        comments {
          nextToken
        }
        postAssets {
          nextToken
        }
        hashTags {
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createChatRoomMessageAsset = /* GraphQL */ `
  mutation CreateChatRoomMessageAsset(
    $input: CreateChatRoomMessageAssetInput!
    $condition: ModelChatRoomMessageAssetConditionInput
  ) {
    createChatRoomMessageAsset(input: $input, condition: $condition) {
      id
      owner
      order
      chatRoomMessageId
      chatRoomMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateChatRoomMessageAsset = /* GraphQL */ `
  mutation UpdateChatRoomMessageAsset(
    $input: UpdateChatRoomMessageAssetInput!
    $condition: ModelChatRoomMessageAssetConditionInput
  ) {
    updateChatRoomMessageAsset(input: $input, condition: $condition) {
      id
      owner
      order
      chatRoomMessageId
      chatRoomMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteChatRoomMessageAsset = /* GraphQL */ `
  mutation DeleteChatRoomMessageAsset(
    $input: DeleteChatRoomMessageAssetInput!
    $condition: ModelChatRoomMessageAssetConditionInput
  ) {
    deleteChatRoomMessageAsset(input: $input, condition: $condition) {
      id
      owner
      order
      chatRoomMessageId
      chatRoomMessage {
        id
        owner
        chatMessageType
        body
        userId
        user {
          id
          owner
          userName
          firstName
          lastName
          major
          graduationYear
          values
          interests
          hero
          bio
          notificationsEmailEnabled
          notificationsLikeEnabled
          notificationsCommentEnabled
          notificationsNewFollowerEnabled
          notificationsFriendRequestEnabled
          privacyPublicAccount
          privacyAllowComments
          privacyHideOffensiveContent
          createdAt
          updatedAt
          deletedAt
          isBlocked
          isProfane
          schoolId
          userCollegeId
          userDegreeId
          otherDegreeType
          userGenderId
          otherGenderType
          userProfileImageId
          numberOfPosts
          numberOfFollowings
          numberOfFollowers
          numberOfFriends
          numberOfStarPoints
          appBadgeCount
          referralCodeId
          numberOfTimesReferralCodeUsed
          userSignedUpWithReferralCodeId
          searchableClazzes
          searchablePastClazzes
        }
        chatRoomId
        chatRoomMessageAssets {
          nextToken
        }
        chatRoomMessageSharedPostId
        sharedPost {
          id
          postType
          content
          owner
          isProfane
          isFriendsOnly
          userId
          userName
          postPostCategoryId
          schoolId
          schoolName
          numberOfLikes
          numberOfComments
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
        deletedAt
      }
      s3ObjectId
      s3Object {
        id
        owner
        bucket
        key
        region
        fileName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
