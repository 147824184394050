import { Auth, API } from "aws-amplify";

const GetFullListAdminQueries = async (apiName, path, init, resultListName) => {
  let result = {
    success: true,
    errorMessage: "",
    nextToken: null,
    resultList: null,
  };

  try {
    do {
      let limit = 60;
      let nextToken = null;

      // inject limit and next token
      init = {
        queryStringParameters: {
          ...init.queryStringParameters,
          limit: limit,
          token: nextToken,
        },
      };

      // add auth headers
      init = {
        ...init,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };

      let queryResult = await API.get(apiName, path, init);

      // check if we got the result and list back
      if (queryResult && queryResult[resultListName]) {
        result.nextToken = null;
        result.resultList = queryResult[resultListName];
      } else {
        // if we had errors
        result.success = false;
        result.errorMessage = JSON.stringify(queryResult, null, 2);
        break;
      }
    } while (result.nextToken !== null);

    return result;
  } catch (error) {
    result.success = false;
    result.errorMessage = JSON.stringify(error, null, 2);
  }
};

export default GetFullListAdminQueries;
