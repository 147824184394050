import aws_exports from "../../../../aws-exports";

export const ProfileImage = {
  id: "c8d03ca2-b2fb-4fb3-af30-4f41ff355d70", // this ID will be used for generated users at post confirmation // at userTrigger will be user to determine if the user updated the default profile image for profile image update points
  s3ObjectId: "1eba5071-5791-4146-a33a-c4cf756768e3",
  deletedAt: null,
};

export const S3Object = {
  id: "1eba5071-5791-4146-a33a-c4cf756768e3",
  bucket: aws_exports["aws_user_files_s3_bucket"],
  key: "",
  region: aws_exports["aws_user_files_s3_bucket_region"],
  fileName: "profile.png",
};
