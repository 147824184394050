import React from "react";
import { getFeedback } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const FeedbackGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.feedbackId}
      modelName={"Feedback"}
      listOfProperties={[
        {
          key: 1,
          attributeTitle: "Submitted by",
          attributeFetchFunction: (data) => {
            return data.user.userName;
          },
          description: "Submitted by Username",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeTitle: "Submitted by, School Name",
          attributeFetchFunction: (data) => {
            return data.user.school.name;
          },
          description: "User's school",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeTitle: "Submitted by, College Name",
          attributeFetchFunction: (data) => {
            if (data.user.college) return data.user.college.name;
            return "---";
          },
          description: "User's College",
          dataType: DataTypes.String,
        },
        {
          key: 4,
          attributeTitle: "Feedback Category",
          attributeFetchFunction: (data) => {
            return data.feedbackCategory.name;
          },
          description: "Feedback Category Name",
          dataType: DataTypes.String,
        },
        {
          key: 5,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Feedback Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 6,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Feedback Updated At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 7,
          attributeName: "isRead",
          attributeTitle: "is Read",
          description: "Is Feedback Read?",
          dataType: DataTypes.Boolean,
        },
        {
          key: 9,
          attributeName: "message",
          attributeTitle: "Feedback Message",
          description: "Feedback Message",
          dataType: DataTypes.String,
        },
      ]}
      queryFunction={getFeedback}
      queryFunctionName={"getFeedback"}
    />
  );
};

FeedbackGeneralInfo.propTypes = {
  feedbackId: PropTypes.string.isRequired,
};

export default FeedbackGeneralInfo;
