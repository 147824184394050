import React from "react";
import DegreeGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const DegreeDetails = (props) => {
  const handleReturn = () => {
    props.history.push(`/degrees`);
  };
  return (
    <CardContainer
      header={"Degree Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <DegreeGeneralInfo degreeId={props.match.params.degreeId} />
    </CardContainer>
  );
};

export default DegreeDetails;
