import * as DataTypes from "../../../../constants/Data/DataTypes";

const dateTimeConvertProvidedData = (providedDataList, gridObjectDataModel) => {
  return providedDataList.map((resultItem) => {
    // define what entries are dateTime type twhich need to be converted
    let objectModelEntries = gridObjectDataModel.filter(
      (item) => item.type === DataTypes.DateTime
    );

    // converting string values to DateTime in order to have formatting and local convert enabled
    for (let dateTimeTypeItem of objectModelEntries) {
      resultItem[dateTimeTypeItem.name] = resultItem[dateTimeTypeItem.name]
        ? new Date(resultItem[dateTimeTypeItem.name])
        : resultItem[dateTimeTypeItem.name];
    }
    return resultItem;
  });
};

export default dateTimeConvertProvidedData;
