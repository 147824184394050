import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DetailsPresentation from "../../../../components/Common/DetailsPresentation";
import DetailsPresentationItem from "../../../../components/Common/DetailsPresentation/DetailsPresentationItem";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import { API, graphqlOperation } from "aws-amplify";
import { getJourneysByUserId } from "../../../../graphql/queries";
import { toast } from "react-toastify";
import GetFullListResult from "../../../../graphql/CustomQueries/GetFullListResult";

const UserJourneys = (props) => {
  const { userId } = props;
  const [userJourneysList, setUserJourneysList] = useState(null);

  const getUserGourneysList = async () => {
    try {
      let result = await GetFullListResult(
        getJourneysByUserId,
        "getJourneysByUserId",
        {
          userId: userId,
          filter: {
            deletedAt: {
              eq: null,
            },
          },
        }
      );
      // let result = await API.graphql(
      //   graphqlOperation(getJourneysByUserId, {
      //     userId: userId,
      //     filter: {
      //       deletedAt: {
      //         eq: null,
      //       },
      //     },
      //   })
      // );

      // check the result
      if (result.success) {
        setUserJourneysList(result.resultList);
      } else {
        toast.error(
          `Error while fetching user Journeys, ${result.errorMessage}`
        );
      }
    } catch (error) {
      toast.error("Error while fetching user Journeys");
    }
  };

  useEffect(() => {
    getUserGourneysList();
  }, []);

  // list of properties we'd like to present
  const listOfProperties = [
    {
      key: 1,
      attributeName: "title",
      attributeTitle: "Title",
      description: "Journey Title",
      dataType: DataTypes.String,
    },
    {
      key: 2,
      attributeName: "institution",
      attributeTitle: "Institution",
      description: "Journey Institution",
      dataType: DataTypes.String,
    },
    {
      key: 3,
      attributeName: "location",
      attributeTitle: "Location",
      description: "Journey Location",
      dataType: DataTypes.String,
    },
    {
      key: 4,
      attributeName: "isCurrent",
      attributeTitle: "IsCurrent",
      description: "Journey IsCurrent",
      dataType: DataTypes.Boolean,
    },
    {
      key: 5,
      attributeName: "startDate",
      attributeTitle: "Start Date",
      description: "Journey Start Date",
      dataType: DataTypes.Date,
    },
    {
      key: 6,
      attributeName: "endDate",
      attributeTitle: "End Date",
      description: "Journey End Date",
      dataType: DataTypes.Date,
    },
    {
      key: 7,
      attributeName: "description",
      attributeTitle: "Description",
      description: "Journey Description",
      dataType: DataTypes.String,
    },
    {
      key: 8,
      attributeTitle: "Journey Type",
      attributeFetchFunction: (data) => {
        return data.journeyType.name;
      },
      description: "Journey Journey Type",
      dataType: DataTypes.String,
    },
    {
      key: 9,
      attributeName: "createdAt",
      attributeTitle: "Created At",
      description: "Journey Created At",
      dataType: DataTypes.DateTime,
    },
    {
      key: 10,
      attributeName: "updatedAt",
      attributeTitle: "Updated At",
      description: "Journey Updated At",
      dataType: DataTypes.DateTime,
    },
  ];

  // present the list of journeys based of the list of properties we have defined
  return userJourneysList &&
    userJourneysList.length !== 0 ? (
    userJourneysList.map((journeyItem) => (
      <DetailsPresentation
        itemsList={listOfProperties.map((property) => {
          return (
            <DetailsPresentationItem
              key={property.key}
              name={property.attributeTitle}
              value={
                property.attributeFetchFunction
                  ? property.attributeFetchFunction(journeyItem)
                  : journeyItem[property.attributeName]
              }
              description={property.description}
              dataType={property.dataType}
            />
          );
        })}
        header={`Journey Id : ${journeyItem.id}`}
      />
    ))
  ) : (
    <>
      <span> User does not have a journey</span>
    </>
  );
};

UserJourneys.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserJourneys;
