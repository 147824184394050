import passwordValidator from "password-validator";

const PasswordValidation = (providedPassword) => {
  let result = { result: true, message: "" };
  if (!providedPassword || providedPassword.length === 0) {
    result.result = false;
    result.message = "Password is required";
  } else {
    // Create a schema
    var schema = new passwordValidator();

    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits(1); // Must have at least 1 digit

    if (!schema.validate(providedPassword)) {
      result.result = false;
      result.message =
        "Password is not in a valid format, Minimum 8 charachters is required, Must have uppercase letters, Must have lowercase letters, Must have at least 1 digit";
    }
  }
  return result;
};

export default PasswordValidation;
