import React, { useState, useEffect } from "react";
import AdminGeneralInfo from "../GeneralInfo";
import GetAdminData from "../../../../graphql/CustomAdminQueries/GetAdminData";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import { Form, Alert } from "react-bootstrap";
import { Auth } from "aws-amplify";
import AdminDisableUser from "../../../../graphql/CustomAdminQueries/AdminDisableUser";
import AdminUserGlobalSignOut from "../../../../graphql/CustomAdminQueries/AdminUserGlobalSignOut";
import AdminDeleteUser from "../../../../graphql/CustomAdminQueries/AdminDeleteUser";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import { toast } from "react-toastify";

const AdminDelete = (props) => {
  const [adminData, setAdminData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [deleteButtonDisabledStatus, setDeleteButtonDisabledStatus] = useState(
    true
  );

  useEffect(() => {
    GetAdminData(props.match.params.adminId, setAdminData, setIsLoading);
  }, []);

  const checkIfAdminTryingToDeleteHimself = async (toBeDeletedAdminId) => {
    await Auth.currentUserInfo().then((user) => {
      if (user.attributes.sub !== toBeDeletedAdminId)
        setDeleteButtonDisabledStatus(false);
    });
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (adminData) {
      setIsLoading(false);

      // check if admin is trying to delete himself
      const userId = adminData.data.getAdmin.UserAttributes.find(
        (attr) => attr.Name === "sub"
      ).Value;

      // check from amplify
      checkIfAdminTryingToDeleteHimself(userId);
    }
  }, [adminData]);

  const handleReturn = () => {
    props.history.push(`/admins`);
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setFormSubmitInProgress(true);
    // sign user out
    let signUserOutResult = await AdminUserGlobalSignOut(
      adminData.data.getAdmin.Username,
      setGeneralError
    );

    // check success
    if (!signUserOutResult.result) {
      toast.error(signUserOutResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    // disable user
    let disableUserResult = await AdminDisableUser(
      adminData.data.getAdmin.Username,
      setGeneralError
    );

    // check success
    if (!disableUserResult.result) {
      toast.error(disableUserResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    // delete user
    let deleteUserResult = await AdminDeleteUser(
      adminData.data.getAdmin.Username,
      setGeneralError
    );

    // check success
    if (!deleteUserResult.result) {
      toast.error(deleteUserResult.message);
      setFormSubmitInProgress(false);
      return;
    }

    // go back to list
    setTimeout(function () {
      toast.success(deleteUserResult.message);
      handleReturn();
    }, GlobalSubmitUIDelay.DefaultDelay);
  };

  return (
    <>
      <PagePreLoad isLoading={isLoading}>
        <Form onSubmit={handleFormSubmission} noValidate>
          <CardContainer
            header={`Delete Admin`}
            submitButton={
              <ButtonInput
                label="Delete"
                disabled={deleteButtonDisabledStatus}
                variant={BootstrapVariants.Danger}
                formSubmitInProgress={formSubmitInProgress}
                formSubmittingText={"In Progress..."}
              />
            }
            backButton={
              <ButtonInput
                label="Back to List"
                type="button"
                variant={BootstrapVariants.OutlineSecondary}
                onClick={() => handleReturn()}
              />
            }
          >
            {generalError && (
              <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
            )}
            <span>
              By clicking on <b>Delete</b>, Admin will be deleted, do you want
              to proceed?
            </span>
          </CardContainer>
        </Form>
        <CardContainer header={"Admin Details"}>
          <AdminGeneralInfo
            providedModelData={adminData}
            adminId={props.match.params.adminId}
          />
        </CardContainer>
      </PagePreLoad>
    </>
  );
};

export default AdminDelete;
