import React, { useState, useEffect } from "react";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import ButtonInput from "../../../../components/Common/ButtonInput";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import PropTypes from "prop-types";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
// https://www.npmjs.com/package/is-valid-domain

const SimpleCreateEdit = (props) => {
  const {
    crudActionType,
    returnHandler,
    modelName,
    getModelMethod,
    createOrUpdateDisableMethod,
    createOrUpdateDisabledActions,
    children,
    formSubmissionHandler,
    formValidationHandler,
    setErrorsState,
    actionButtonDisabledState,
  } = props;

  const [generalError, setGeneralError] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    createOrUpdateButtonDisableStatus,
    setCreateOrUpdateButtonDisableStatus,
  ] = useState(false);

  const getModelData = async () => {
    try {
      let modelData = await getModelMethod();
      setIsLoading(false);

      // extra logic on disabling the update
      // disabling the update button and provided list of required action
      if (createOrUpdateDisableMethod) {
        if (createOrUpdateDisableMethod(modelData)) {
          if (createOrUpdateDisabledActions) createOrUpdateDisabledActions();

          // disable the create or update button
          setCreateOrUpdateButtonDisableStatus(true);
        }
      }
    } catch (error) {
      toast.error(
        `Error while fetching ${modelName} data, ${JSON.stringify(
          error,
          null,
          2
        )}`
      );
    }
  };

  useEffect(() => {
    if (crudActionType === CrudActionTypes.Edit) getModelData();
    else setIsLoading(false); // for create we do not need loading
  }, []);

  //#region handle return
  const handleReturn = () => {
    returnHandler();
  };
  //#endregion

  //#region Handle form submit
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrorsState({});
    setFormSubmitInProgress(true);
    if (!formValidationHandler()) {
      setFormSubmitInProgress(false);
      return;
    }

    // update class at this point
    let result = await formSubmissionHandler();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        returnHandler();
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };
  //#endregion

  return (
    <PagePreLoad isLoading={isLoading}>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header={
            crudActionType === CrudActionTypes.Edit
              ? `Edit ${modelName}`
              : `Create ${modelName}`
          }
          submitButton={
            <ButtonInput
              label={
                crudActionType === CrudActionTypes.Edit ? "Update" : "Create"
              }
              disabled={
                createOrUpdateButtonDisableStatus || actionButtonDisabledState
              }
              variant={
                crudActionType === CrudActionTypes.Edit
                  ? BootstrapVariants.Primary
                  : BootstrapVariants.Success
              }
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
          backButton={
            <ButtonInput
              label="back"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}

          {/* listing the provided form inputs */}
          {children}
        </CardContainer>
      </Form>
    </PagePreLoad>
  );
};

SimpleCreateEdit.propTypes = {
  crudActionType: PropTypes.string.isRequired,
  returnHandler: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
  getModelMethod: PropTypes.func,
  createOrUpdateDisableMethod: PropTypes.func,
  createOrUpdateDisabledActions: PropTypes.func,
  children: PropTypes.object.isRequired,
  formSubmissionHandler: PropTypes.func.isRequired,
  formValidationHandler: PropTypes.func.isRequired,
  setErrorsState: PropTypes.func.isRequired,
  actionButtonDisabledState: PropTypes.bool.isRequired,
};

export default SimpleCreateEdit;
