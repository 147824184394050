import React from "react";
import ClassGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const ClassDetails = (props) => {
  const handleReturn = () => {
    // check how we got here ( from school or from college )
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
      );
    } else {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/Classes`
      );
    }
  };
  return (
    <CardContainer
      header={"Class Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <ClassGeneralInfo classId={props.match.params.classId} />
    </CardContainer>
  );
};

export default ClassDetails;
