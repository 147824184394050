import React from "react";
import PostCategoryGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const PostCategoryDetails = (props) => {
  const handleReturn = () => {
    props.history.push(`/postCategories`);
  };
  return (
    <CardContainer
      header={"Post Category Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <PostCategoryGeneralInfo
        postCategoryId={props.match.params.postCategoryId}
      />
    </CardContainer>
  );
};

export default PostCategoryDetails;
