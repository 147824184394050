const toolbarOptionsGenerator = (hasCreate, hasRefresh) => {
  const toolBarOptions = [];

  // add create to tool bar if requested
  if (hasCreate) {
    toolBarOptions.push({
      text: "Create",
      tooltipText: "Create",
      prefixIcon: "e-add",
      id: "create",
    });
  }

  // add the default toolbar items
  toolBarOptions.push("Print");
  toolBarOptions.push("Search");

  // refresh the data
  if (hasRefresh) {
    toolBarOptions.push({
      text: "Refresh",
      tooltipText: "Refresh",
      prefixIcon: "e-reload",
      id: "refresh",
    });
  }

  return toolBarOptions;
};

export default toolbarOptionsGenerator;
