export const Education = {
  id: "3d6c5424-f481-48e6-8aaf-0b0564b347ab",
  name: "Education",
  order: 10,
};
export const Job = {
  id: "f30db18d-2ef4-4d12-8052-8c07568387a9",
  name: "Job",
  order: 20,
};
export const Internship = {
  id: "793d1a8e-6246-45cb-824b-49ff7c883c55",
  name: "Internship",
  order: 30,
};
export const Entrepreneurship = {
  id: "6242f7b8-a883-4219-87bc-657d3a702ac5",
  name: "Entrepreneurship",
  order: 40,
};
export const ProjectResearch = {
  id: "ac955315-6082-4933-abb0-34e1ba7fd1ea",
  name: "Project/Research",
  order: 50,
};
export const Volunteering = {
  id: "5aa26660-6515-4150-8340-665bb9f2f59e",
  name: "Volunteering",
  order: 60,
};
export const Athletics = {
  id: "ee6d2038-6fbb-463d-8b2c-b035bd06a6cb",
  name: "Athletics",
  order: 70,
};
export const HonorAward = {
  id: "134f926f-8417-4a8b-9110-731e027810c2",
  name: "Honor/Award",
  order: 80,
};
export const Publication = {
  id: "5054821f-e013-4b60-9ee0-3a724c3db53b",
  name: "Publication",
  order: 90,
};
export const Patent = {
  id: "cf44f2ab-4c82-48f1-a8ae-7425798a5483",
  name: "Patent",
  order: 100,
};
export const OrganizationClub = {
  id: "9242e4f7-07d6-49cf-a890-f5d31d20cf23",
  name: "Organization/Club",
  order: 110,
};
export const QualificationCertification = {
  id: "79fe749b-9d1f-4249-862b-0af2ba67f73d",
  name: "Qualification/Certification",
  order: 120,
};
export const TestScore = {
  id: "39b39165-b090-44ba-9039-20f4fe50d4ee",
  name: "Test Score",
  order: 130,
};
export const KnowledgeSkills = {
  id: "21c9a2e4-1176-4d41-bd1e-5da6e2b30f81",
  name: "Knowledge & Skills",
  order: 140,
};
export const RecreationalActivity = {
  id: "094d7da4-f581-43c6-b53a-ce1a7d6c1bcf",
  name: "Recreational Activity",
  order: 150,
};

export const AllTypes = [
  Education,
  Job,
  Internship,
  Entrepreneurship,
  ProjectResearch,
  Volunteering,
  Athletics,
  HonorAward,
  Publication,
  Patent,
  OrganizationClub,
  QualificationCertification,
  TestScore,
  KnowledgeSkills,
  RecreationalActivity,
];
