import React from "react";
import { updateComment } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";
import CommentGeneralInfo from "../GeneralInfo";

const CommentDelete = (props) => {
  const deleteOperationalValidation = async (commentId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired UserReport
      //   let tobeDeleted = await API.graphql(
      //     graphqlOperation(getReport, { id: commentId })
      //   );

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching comment, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  const returnHandler = () => {
    if (props.match.params.userPostId) {
      return `/userPosts/details/${props.match.params.userPostId}/tab/Comments`;
    } else if (props.match.params.commentReportId) {
      return `/commentReports/details/${props.match.params.commentReportId}`;
    } else {
      return `/comments`;
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Comment"
        deleteDescription="comment will be deleted. do you want to proceed?"
        returnRoute={returnHandler()}
        generalInfoComponent={
          <CommentGeneralInfo commentId={props.match.params.commentId} />
        }
        mutationMethod={updateComment}
        mutationInput={{
          id: props.match.params.commentId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.commentId)
        }
      />
    </>
  );
};

export default CommentDelete;
