import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createGender } from "../../../../graphql/mutations";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import * as GendersConstants from "../../../../graphql/Constants/Genders/Types";
import nameChangeHandler from "../../../Common/Crud/formHandlers/nameChangeHandler";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
import nameOnlyFormsValidationHandler from "../../../Common/Crud/formHandlers/nameOnlyFormsValidationHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";

const GenderCreate = (props) => {
  // states
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [createButtonDisableStatus, setCreateButtonDisableStatus] = useState(
    false
  );

  const handleCreateGender = async () => {
    try {
      const input = {
        type: ModelTypes.Gender,
        name: name.trim(),
        order: order,
        deletedAt: null,
      };
      await API.graphql(graphqlOperation(createGender, { input }));
      return { result: true, message: `Gender created successfully` };
    } catch (error) {
      return { result: false, message: `Error creating gender, ${error}` };
    }
  };

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Create}
      returnHandler={() => props.history.push(`/genders`)}
      modelName="Gender"
      updateDisableMethod={(modelData) => {
        if (modelData.id === GendersConstants.Other.id) return true;
        return false;
      }}
      updateDisabledActions={() => {
        setInputFieldsDisable(true);
      }}
      formSubmissionHandler={handleCreateGender}
      formValidationHandler={() =>
        nameOnlyFormsValidationHandler(
          name,
          "Gender",
          true, // we need to exclude the OTHER,
          setCreateButtonDisableStatus,
          setErrors
        )
      }
      setErrorsState={() => setErrors}
      actionButtonDisabledState={createButtonDisableStatus}
    >
      <TextInput
        type="name"
        id="name"
        label="Gender Name"
        placeholder="Enter a Gender name"
        value={name}
        onChange={(e) =>
          nameChangeHandler(
            e,
            "Gender",
            true, // we need to exclude the OTHER,
            setName,
            setCreateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.name}
        disabled={inputFieldsDisable}
      />
      <TextInput
        type="number"
        id="order"
        label="Gender Order"
        placeholder="Enter a Gender Order"
        value={order}
        onChange={(e) =>
          orderChangeHandler(
            e,
            "Gender",
            setOrder,
            setCreateButtonDisableStatus,
            setErrors
          )
        }
        isRequired={true}
        error={errors.order}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default GenderCreate;
