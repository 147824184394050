import React, { useState, useEffect, useRef } from "react";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import CardContainer from "../../../components/Common/CardContainer";
import ButtonInput from "../../../components/Common/ButtonInput";
import * as SystemSettingsTypes from "../../../graphql/Constants/SystemSettings/Types";
import { getSystemSettingsByType } from "../../../graphql/CustomQueries/SystemSettings/getSystemSettingsByType";
import { updateSystemSettingsById } from "../../../graphql/CustomMutations/SystemSettings/updateSystemSettingsById";
import { toast } from "react-toastify";
import EditSystemSettings from "./Edit";

const SystemSettings = (props) => {
  // rich text box refrence
  const richTextBoxRef = useRef(null);

  const [systemSettings, setSystemSettings] = useState("");
  const [systemSettingsId, setSystemSettingsId] = useState("");
  const [updateIsInProgress, setUpdateIsInProgress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // page preload number of actions
  const requiredActionsBeforePageIsLoaded = 1;
  let finishedActionsCount = 0;
  const [finishedActions, setFinishedActions] = useState(0);

  const getSystemSettingsAsync = async () => {
    const systemSettingsType = defineSystemSettingsType(
      props.match.params.type
    );
    const result = await getSystemSettingsByType(systemSettingsType);
    if (result.errors) {
      toast.error(`Error Fetching ${systemSettingsType}`);
    }
    if (result.data && result.data.listSystemSettingss.items.length > 0) {
      setSystemSettings(result.data.listSystemSettingss.items[0].content);
      setSystemSettingsId(result.data.listSystemSettingss.items[0].id);
    }
    ++finishedActionsCount;
    setFinishedActions(finishedActionsCount);
  };

  // get the system settings
  useEffect(() => {
    getSystemSettingsAsync();
  }, [props.match.params.type]);

  const defineSystemSettingsType = (type) => {
    switch (type) {
      case "termsofservice":
        return SystemSettingsTypes.TermsOfService;
      case "privacypolicy":
        return SystemSettingsTypes.PrivacyPolicy;
      case "aboutapp":
        return SystemSettingsTypes.AboutApp;
      default:
        break;
    }
  };

  const defineSystemSettingsLabel = (type) => {
    switch (type) {
      case "termsofservice":
        return "Terms Of Service";
      case "privacypolicy":
        return "Privacy Policy";
      case "aboutapp":
        return "About App";
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    setUpdateIsInProgress(true);

    var type = defineSystemSettingsType(props.match.params.type);
    var newContent = richTextBoxRef.current.value;

    const updateResult = await updateSystemSettingsById(
      systemSettingsId,
      newContent
    );
    if (updateResult.errors) {
      toast.error(`Error updating ${type}`);
    }
    if (updateResult.data && updateResult.data.updateSystemSettings) {
      toast.success(`${type} updated`);
      setIsEditing(false);
      // update the content
      setSystemSettings(updateResult.data.updateSystemSettings.content);
    }

    setUpdateIsInProgress(false);
  };

  return (
    <PagePreLoad
      isLoading={finishedActions < requiredActionsBeforePageIsLoaded}
    >
      {!isEditing ? (
        <CardContainer
          header={defineSystemSettingsLabel(props.match.params.type)}
          submitButton={
            <ButtonInput
              label="Edit"
              variant="primary"
              onClick={() => setIsEditing(true)}
            />
          }
        >
          <div dangerouslySetInnerHTML={{ __html: systemSettings }} />
        </CardContainer>
      ) : (
        <EditSystemSettings
          title={defineSystemSettingsLabel(props.match.params.type)}
          type={defineSystemSettingsType(props.match.params.type)}
          content={systemSettings}
          richTextBoxRef={richTextBoxRef}
          submitButton={
            <ButtonInput
              label="Save Changes"
              variant="success"
              onClick={handleUpdate}
              formSubmitInProgress={updateIsInProgress}
              formSubmittingText={"Saving..."}
            />
          }
          backButton={
            <ButtonInput
              label="back"
              type="button"
              variant="outline-secondary"
              onClick={() => setIsEditing(false)}
            />
          }
        />
      )}
    </PagePreLoad>
  );
};

export default SystemSettings;
