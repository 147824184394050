import React from "react";
import { searchPosts } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";

const UserPosts = (props) => {
  return (
    <DataGrid
      {...props}
      header="Posts"
      gridObjectDataModel={[
        {
          name: "userName",
          headerText: "Posted By",
          type: DataTypes.String,
        },
        {
          name: "schoolName",
          headerText: "School Name",
          type: DataTypes.String,
        },
        {
          name: "isProfane",
          headerText: "Is Profane Content",
          type: DataTypes.Boolean,
        },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.Post}
      queryFunction={searchPosts}
      queryFunctionType={QueryTypes.Search}
      queryName={"searchPosts"}
      hasCreate={false}
      hasDetails={true}
      hasEdit={false}
      hasDelete={true}
      hasProfane={true}
      baseRoute={"userPosts"}
      detailsActiveTab="GeneralInfo"
    />
  );
};

export default UserPosts;
