import React from "react";
import PostGeneralInfo from "../GeneralInfo";
import { updatePost } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const SchoolDelete = (props) => {
  return (
    <>
      <SoftDelete
        {...props}
        modelName="Post"
        deleteDescription="post will be deleted.Are you
        willing to proceed?"
        returnRoute={
          props.match.params.postReportsId
            ? `/postReports/details/${props.match.params.postReportsId}`
            : `/userPosts`
        }
        generalInfoComponent={
          <PostGeneralInfo userPostId={props.match.params.userPostId} />
        }
        mutationMethod={updatePost}
        mutationInput={{
          id: props.match.params.userPostId,
        }}
      />
    </>
  );
};

export default SchoolDelete;
