import React from "react";
import { getPointSystem } from "../../../../graphql/queries";
import PropTypes from "prop-types";
import * as DataTypes from "../../../../constants/Data/DataTypes";
import GeneralInfo from "../../../Common/GeneralInfo";

const PointSystemGeneralInfo = (props) => {
  return (
    <GeneralInfo
      modelId={props.pointSystemId}
      modelName={"PointSystem"}
      listOfProperties={[
        {
          key: 1,
          attributeName: "pointType",
          attributeTitle: "Type",
          description: "Type",
          dataType: DataTypes.String,
        },
        {
          key: 2,
          attributeName: "point",
          attributeTitle: "point",
          description: "Appearance Order",
          dataType: DataTypes.String,
        },
        {
          key: 3,
          attributeName: "order",
          attributeTitle: "Order",
          description: "Point System Appearance Order",
          dataType: DataTypes.String,
        },
        {
          key: 4,
          attributeName: "createdAt",
          attributeTitle: "Created At",
          description: "Point System Created At",
          dataType: DataTypes.DateTime,
        },
        {
          key: 5,
          attributeName: "updatedAt",
          attributeTitle: "Updated At",
          description: "Point System Updated At",
          dataType: DataTypes.DateTime,
        },
      ]}
      queryFunction={getPointSystem}
      queryFunctionName={"getPointSystem"}
    />
  );
};

PointSystemGeneralInfo.propTypes = {
  pointSystemId: PropTypes.string.isRequired,
};

export default PointSystemGeneralInfo;
