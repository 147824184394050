import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createClazz } from "../../../../graphql/mutations";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import * as ModelTypes from "../../../../graphql/Constants/ModelTypes";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";
// https://www.npmjs.com/package/is-valid-domain

const ClassCreate = (props) => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [createButtonDisableStatus, setCreateButtonDisableStatus] = useState(
    true
  );

  const validationLogic = (providedName) => {
    let result = { result: true, message: "" };
    if (!providedName || providedName.length === 0) {
      result.result = false;
      result.message = "Class name is required";
    }
    return result;
  };

  //#region  handle domain change including validation checks
  const handleClassNameChange = (event) => {
    const _errors = {};
    let providedName = event.target.value;
    setName(providedName);
    // check the domain validation
    let validationResult = validationLogic(providedName);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setCreateButtonDisableStatus(true);
    } else {
      setCreateButtonDisableStatus(false);
    }
    setErrors(_errors);
  };
  //#endregion

  //#region checling form is valid
  const isFormValid = () => {
    const _errors = {};

    // domain name is already checked and status button is already disabled
    // double check here
    // check the domain validation
    let validationResult = validationLogic(name);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setCreateButtonDisableStatus(true);
    } else {
      setCreateButtonDisableStatus(false);
    }
    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };
  //#endregion

  //#region Handle form submit
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    // create school at this point
    let result = await handleCreateClass();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        if (props.match.params.collegeId) {
          props.history.push(
            `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
          );
        } else {
          props.history.push(
            `/schools/details/${props.match.params.schoolId}/tab/Classes`
          );
        }
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };
  //#endregion

  //#region  create class business logic
  const handleCreateClass = async () => {
    try {
      // initial required class creation data
      let input = {
        name: name,
        type: ModelTypes.Clazz,
        order: order,
        deletedAt: null,
      };

      // check if we are building the class for college or for school
      if (props.match.params.collegeId) {
        // this if for school college
        input = {
          ...input,
          schoolId: props.match.params.schoolId,
          clazzCollegeId: props.match.params.collegeId,
        };
      } else {
        // this if for school
        input = { ...input, schoolId: props.match.params.schoolId };
      }

      await API.graphql(graphqlOperation(createClazz, { input }));
      return { result: true, message: `Class created successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error creating class, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };
  //#endregion

  //#region handle return
  const handleReturn = () => {
    // since we can create a domain for college as well we need to check how we got here and go back there
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
      );
    } else {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/Classes`
      );
    }
  };
  //#endregion

  return (
    <Form onSubmit={handleFormSubmission} noValidate>
      <CardContainer
        header="Create a Class"
        submitButton={
          <ButtonInput
            label="Create"
            disabled={createButtonDisableStatus}
            variant={BootstrapVariants.Success}
            formSubmitInProgress={formSubmitInProgress}
            formSubmittingText={"In Progress..."}
          />
        }
        backButton={
          <ButtonInput
            label="back"
            type="button"
            variant={BootstrapVariants.OutlineSecondary}
            onClick={() => handleReturn()}
          />
        }
      >
        {generalError && (
          <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
        )}

        <TextInput
          type="name"
          id="name"
          label="Class Name"
          placeholder="Enter a class name"
          value={name}
          onChange={(e) => handleClassNameChange(e)}
          isRequired={true}
          error={errors.name}
        />
        <TextInput
          type="number"
          id="order"
          label="Class Order"
          placeholder="Enter a Class Appearance Order"
          value={order}
          onChange={(e) =>
            orderChangeHandler(
              e,
              "Class",
              setOrder,
              setCreateButtonDisableStatus,
              setErrors
            )
          }
          isRequired={true}
          error={errors.order}
        />
      </CardContainer>
    </Form>
  );
};

export default ClassCreate;
