import nameVerification from "../../../Common/Crud/formHandlers/nameVerification";

const nameOnlyFormsValidationHandler = (
  name,
  modelName,
  excludeOtherType,
  setActionButtonDisableStatus,
  setErrorsState
) => {
  const _errors = {};

  // Skill name is already checked and status button is already disabled
  // double check here
  // check the Skill validation
  let validationResult = nameVerification(name, modelName, excludeOtherType);
  // check the result
  if (!validationResult.result) {
    _errors.name = validationResult.message;
    setActionButtonDisableStatus(true);
  } else {
    setActionButtonDisableStatus(false);
  }
  setErrorsState(_errors);

  // Forms will be valid if the _errors object does not have any properties
  return Object.keys(_errors).length === 0;
};

export default nameOnlyFormsValidationHandler;
