import React, { useState, useEffect } from "react";
import FeedbackGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import { updateFeedback } from "../../../../graphql/mutations";
import { getFeedback } from "../../../../graphql/queries";

const FeedbackDetails = (props) => {
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [formSubmitDisabledstatus, setFormSubmitDisabledstatus] = useState(
    true
  );

  // get feed back data
  const getFeedBackData = async (feedbackId) => {
    try {
      let result = await API.graphql(
        graphqlOperation(getFeedback, { id: feedbackId })
      );
      if (result.data) {
        if (!result.data.getFeedback.isRead) setFormSubmitDisabledstatus(false);
      } else {
        toast.error(
          `Error while fetching feedback data, ${JSON.stringify(
            result,
            null,
            2
          )}`
        );
      }
    } catch (error) {
      toast.error(
        `Error while fetching feedback data, ${JSON.stringify(error, null, 2)}`
      );
    }
  };

  // check if we have read the feedback
  useEffect(() => {
    getFeedBackData(props.match.params.feedbackId);
  }, []);

  const handleReturn = () => {
    props.history.push(`/feedbacks`);
  };

  const handlerMardAsRead = async () => {
    setFormSubmitInProgress(true);
    try {
      let input = {
        id: props.match.params.feedbackId,
        isRead: true,
      };
      let result = await API.graphql(
        graphqlOperation(updateFeedback, { input })
      );
      if (result.data) {
        // go back to list
        setTimeout(function () {
          toast.success("Feedback marked as read");
          handleReturn();
        }, GlobalSubmitUIDelay.DefaultDelay);
      } else {
        toast.error(
          `Error occured while marking feedback as read, ${JSON.stringify(
            result,
            null,
            2
          )}`
        );
      }
    } catch (error) {
      setFormSubmitInProgress(false);
      toast.error(
        `Error occured while marking feedback as read, ${JSON.stringify(
          error,
          null,
          2
        )}`
      );
    }
  };
  return (
    <CardContainer
      header={"Feedback Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
      submitButton={
        <ButtonInput
          label="Mark as Read"
          variant={BootstrapVariants.Success}
          onClick={() => handlerMardAsRead()}
          disabled={formSubmitDisabledstatus}
          formSubmitInProgress={formSubmitInProgress}
          formSubmittingText={"In Progress..."}
        />
      }
    >
      <FeedbackGeneralInfo feedbackId={props.match.params.feedbackId} />
    </CardContainer>
  );
};

export default FeedbackDetails;
