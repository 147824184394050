import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as UserGroups from "../../../graphql/Constants/UserGroups";
import DataGridWithProvidedData from "../../Common/DataGrid/DataGridWithProvidedData";
import * as DataTypes from "../../../constants/Data/DataTypes";
import GetFullListAdminQueries from "../../../graphql/CustomAdminQueries/GetFullListAdminQueries";
import PagePreLoad from "../../../components/Common/PagePreLoad";

const Admins = (props) => {
  const [admins, setAdmins] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname: UserGroups.SuperAdmins,
      },
    };
    try {
      let result = await GetFullListAdminQueries(
        apiName,
        path,
        myInit,
        "Users"
      );
      // user data comes back inside thge Attributes
      if (result && result.success) {
        // filter our system admin account
        result.resultList = result.resultList.filter(
          (user) =>
            user.Attributes.find((attr) => attr.Name === "email").Value !==
            "dev@vogcalgaryappdeveloper.com"
        );

        // create the datagrid amin list
        let adminsList = result.resultList.map((user) => {
          return {
            id: user.Attributes.find((attr) => attr.Name === "sub").Value,
            createdAt: user.UserCreateDate,
            username: user.Attributes.find((attr) => attr.Name === "email")
              .Value,
            enabled: user.Enabled,
            userStatus: user.UserStatus,
          };
        });

        // set admin list data
        setAdmins(adminsList);
      } else {
        toast.error(`Error while fetching admin, ${result.errorMessage}`);
        setAdmins([]);
      }
    } catch (error) {
      toast.error(
        `Error while fetching admin, ${JSON.stringify(error, null, 2)}`
      );
      setAdmins([]);
    }
    setIsLoading(false);
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      {admins && (
        <DataGridWithProvidedData
          {...props}
          data={admins}
          header={"Admin"}
          gridObjectDataModel={[
            {
              name: `username`,
              headerText: "Username",
              type: DataTypes.String,
            },
            {
              name: `userStatus`,
              headerText: "Status",
              type: DataTypes.Boolean,
            },
            {
              name: `enabled`,
              headerText: "Is Enable",
              type: DataTypes.Boolean,
            },
            {
              name: "createdAt",
              headerText: "Created At",
              type: DataTypes.DateTime,
            },
          ]}
          hasCreate={true}
          hasDetails={true}
          hasEdit={false}
          hasDelete={true}
          hasBlock={true}
          baseRoute={"admins"}
        />
      )}
    </PagePreLoad>
  );
};

export default Admins;
