import React, { useState } from "react";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { Form, Alert } from "react-bootstrap";
import CardContainer from "../../../../components/Common/CardContainer";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";

const SoftDelete = (props) => {
  const {
    modelName,
    deleteDescription,
    returnRoute,
    returnRoutePropBasedFunction,
    generalInfoComponent,
    mutationMethod,
    mutationInput,
    deleteOperationValidation,
  } = props;

  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);

    // soft delete degree by update degree deleted at value
    let result = await handleSoftDeleteModel();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        // return
        handleReturn();
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };

  const handleSoftDeleteModel = async () => {
    try {
      // extra condition check
      // delete oprtational validation check,
      // mostly used to prevent unwanted deletions
      if (deleteOperationValidation) {
        let deleteOperationValidationCheckResult = await deleteOperationValidation();
        if (!deleteOperationValidationCheckResult) {
          return {
            result: false,
            message: `You are not allowed to delete`,
          };
        }
      }

      // soft delete mutation input
      const input = {
        ...mutationInput,
        deletedAt: new Date().toISOString(),
      };
      await API.graphql(graphqlOperation(mutationMethod, { input }));
      return { result: true, message: `${modelName} deleted successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error deleting ${modelName}, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  const handleReturn = () => {
    if (returnRoutePropBasedFunction) {
      returnRoutePropBasedFunction();
    } else {
      props.history.push(`${returnRoute}`);
    }
  };

  return (
    <>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header={`Delete ${modelName}`}
          submitButton={
            <ButtonInput
              label="Delete"
              variant={BootstrapVariants.Danger}
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
          backButton={
            <ButtonInput
              label="Back to List"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}
          <span>
            By clicking on <b>Delete</b>, {deleteDescription}
          </span>
        </CardContainer>
      </Form>
      {generalInfoComponent}
    </>
  );
};

SoftDelete.propTypes = {
  modelName: PropTypes.string.isRequired,
  deleteDescription: PropTypes.string.isRequired,
  returnRoute: PropTypes.string,
  returnRoutePropBasedFunction: PropTypes.func,
  generalInfoComponent: PropTypes.object,
  mutationMethod: PropTypes.string.isRequired,
  mutationInput: PropTypes.object.isRequired,
  deleteOperationValidation: PropTypes.func,
};

export default SoftDelete;
