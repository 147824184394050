import React, { useState } from "react";
import SimpleCreateEdit from "../../../Common/Crud/SimpleCreateEdit";
import TextInput from "../../../../components/Common/TextInput";
import userNameChangeHandler from "../../../Common/Crud/formHandlers/userNameChangeHandler";
import * as CrudActionTypes from "../../../../constants/Crud/ActionTypes";
import * as HttpMethodTypes from "../../../../graphql/Constants/HttpMethodTypes";
import passwordChangeHandler from "../../../Common/Crud/formHandlers/passwordChangeHandler";
import passwordConfirmationChangeHandler from "../../../Common/Crud/formHandlers/passwordConfirmationChangeHandler";
import PasswordConfoirmationValidation from "../../../Common/Crud/formHandlers/passwordConfirmationValidation";
import PasswordValidation from "../../../Common/Crud/formHandlers/PasswordValidation";
import userNameVerification from "../../../Common/Crud/formHandlers/userNameVerification";
import ExecuteAdminQuery from "../../../../graphql/CustomAdminQueries/ExecuteAdminQuery";
import * as UserGroups from "../../../../graphql/Constants/UserGroups";
import { Alert } from "react-bootstrap";

const AdminCreate = (props) => {
  // states
  const [adminUserName, setAdminUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [inputFieldsDisable, setInputFieldsDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [createButtonDisableStatus, setCreateButtonDisableStatus] = useState(
    false
  );

  const handleCreateAdmin = async () => {
    let formSubmissionResult = {
      result: true,
      message: "",
    };

    // handle admin creation
    // first a user must be created
    let apiName = "AdminQueries";
    try {
      let result = await ExecuteAdminQuery(
        apiName,
        "/adminCreateUser",
        {
          body: {
            username: adminUserName,
            password: password,
          },
        },
        HttpMethodTypes.POST
      );
      // user data comes back inside thge Attributes
      if (result && result.success) {
        formSubmissionResult.message = "User Created Successfully";
      } else {
        let errorObject = JSON.parse(result.errorMessage);
        let errorMessage = `Error while creating admin User, ${errorObject.message}`;
        formSubmissionResult.result = false;
        formSubmissionResult.message = errorMessage;
        setGeneralError(errorMessage);
      }
    } catch (error) {
      let errorMessage = `Error while creating admin, ${JSON.stringify(
        error,
        null,
        2
      )}`;
      formSubmissionResult.result = false;
      formSubmissionResult.message = errorMessage;
      setGeneralError(errorMessage);
    }

    // confirmUserSignUp
    // this will throw error because at this point the user state is at FORCE_CHANGE_PASSWORD and it is not CONFIRMED
    // if (formSubmissionResult.result) {
    //   try {
    //     let result = await ExecuteAdminQuery(
    //       apiName,
    //       "/confirmUserSignUp",
    //       {
    //         body: {
    //           username: adminUserName,
    //         },
    //       },
    //       HttpMethodTypes.POST
    //     );
    //     // user data comes back inside thge Attributes
    //     if (result && result.success) {
    //       formSubmissionResult.message = "User Created Successfully";
    //     } else {
    //       let errorObject = JSON.parse(result.errorMessage);
    //       let errorMessage = `Error while Confirming admin User, ${errorObject.message}`;
    //       formSubmissionResult.result = false;
    //       formSubmissionResult.message = errorMessage;
    //       setGeneralError(errorMessage);
    //     }
    //   } catch (error) {
    //     let errorMessage = `Error while Confirming admin, ${JSON.stringify(
    //       error,
    //       null,
    //       2
    //     )}`;
    //     formSubmissionResult.result = false;
    //     formSubmissionResult.message = errorMessage;
    //     setGeneralError(errorMessage);
    //   }
    // }

    // then it must be added to superadmin group
    if (formSubmissionResult.result) {
      try {
        let result = await ExecuteAdminQuery(
          apiName,
          "/addUserToGroup",
          {
            body: {
              username: adminUserName,
              groupname: UserGroups.SuperAdmins,
            },
          },
          HttpMethodTypes.POST
        );
        // user data comes back inside thge Attributes
        if (result && result.success) {
          formSubmissionResult.message = "User Created Successfully";
        } else {
          let errorObject = JSON.parse(result.errorMessage);
          let errorMessage = `Error while Adding admin User to admin group, ${errorObject.message}`;
          formSubmissionResult.result = false;
          formSubmissionResult.message = errorMessage;
          setGeneralError(errorMessage);
        }
      } catch (error) {
        let errorMessage = `Error while Adding admin User to admin group,, ${JSON.stringify(
          error,
          null,
          2
        )}`;
        formSubmissionResult.result = false;
        formSubmissionResult.message = errorMessage;
        setGeneralError(errorMessage);
      }
    }

    return formSubmissionResult;

    // try {
    //   const input = {
    //     type: ModelTypes.Degree,
    //     name: adminUserName.trim(),
    //     order: order,
    //     deletedAt: null,
    //   };
    //   await API.graphql(graphqlOperation(createDegree, { input }));
    //   return { result: true, message: `Degree created successfully` };
    // } catch (error) {
    //   return { result: false, message: `Error creating degree, ${error}` };
    // }
  };

  const createAdminFormValidationHandler = () => {
    const _errors = {};
    // check username validation
    let usernameValidation = userNameVerification(adminUserName);

    if (!usernameValidation.result) {
      _errors.userName = usernameValidation.message;
    }

    // check password validation
    let passwordValidationResult = PasswordValidation(password);

    if (!passwordValidationResult.result) {
      _errors.password = passwordValidationResult.message;
    }

    // check password confirmation validation
    let passwordConfirmationValidation = PasswordConfoirmationValidation(
      passwordConfirmation,
      password
    );

    if (!passwordConfirmationValidation.result) {
      _errors.passwordConfirmation = passwordConfirmationValidation.message;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  return (
    <SimpleCreateEdit
      {...props}
      crudActionType={CrudActionTypes.Create}
      returnHandler={() => props.history.push(`/admins`)}
      modelName="Admin"
      formSubmissionHandler={handleCreateAdmin}
      formValidationHandler={() => createAdminFormValidationHandler()}
      setErrorsState={() => setErrors}
      actionButtonDisabledState={createButtonDisableStatus}
    >
      {generalError && <Alert variant={"danger"}>{generalError}</Alert>}

      <TextInput
        type="text"
        id="userName"
        label="Admin UserName"
        placeholder="Enter a Admin UserName"
        value={adminUserName}
        onChange={(e) =>
          userNameChangeHandler(
            e,
            "Admin",
            setAdminUserName,
            null,
            setErrors,
            errors
          )
        }
        isRequired={true}
        error={errors.userName}
        disabled={inputFieldsDisable}
      />

      <TextInput
        type="password"
        id="password"
        label="Password"
        placeholder="Enter a Password"
        value={password}
        onChange={(e) =>
          passwordChangeHandler(e, setPassword, null, setErrors, errors)
        }
        isRequired={true}
        error={errors.password}
        disabled={inputFieldsDisable}
      />

      <TextInput
        type="password"
        id="passwordConfirmation"
        label="Password Confirmation"
        placeholder="Password Confirmation"
        value={passwordConfirmation}
        onChange={(e) =>
          passwordConfirmationChangeHandler(
            e,
            password,
            setPasswordConfirmation,
            null,
            setErrors,
            errors
          )
        }
        isRequired={true}
        error={errors.passwordConfirmation}
        disabled={inputFieldsDisable}
      />
    </SimpleCreateEdit>
  );
};

export default AdminCreate;
