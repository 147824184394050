import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const SideBarItem = (props) => {
  const activeStyle = { color: "rgb(240, 0, 108)", backgroundColor: "#a2a2a2" };
  return (
    <LinkContainer to={props.to} activeStyle={activeStyle} style={props.style} exact={props.exact}>
      <li className="sidebar-item">
        <Row>
          <Col xs={2}>{props.icon}</Col>
          <Col xs={10} style={{ paddingTop: "2px" }}>
            <span className="e-text" title={props.label}>
              {props.label}
            </span>
          </Col>
        </Row>
      </li>
    </LinkContainer>
  );
};

SideBarItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  exact: PropTypes.bool
};

SideBarItem.defaultProps = {
  style: {},
  exact: true
}

export default SideBarItem;
