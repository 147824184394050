import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import CommentGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import {
  getComment,
  getCommentReply,
  getRepliedToCommentByCommentId,
  getUser,
} from "../../../../graphql/queries";
import { toast } from "react-toastify";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import TabsContainer from "../../../../components/Common/TabsContainer";
import UserPostGeneralInfo from "../../UserPosts/GeneralInfo";
import CommentReplies from "../Replies";
import UserGeneralInfo from "../../Users/GeneralInfo";

const CommentDetails = (props) => {
  const [commentData, setCommentData] = useState(null);
  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [repliedToCommentData, setRepliedToCommentData] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCommentData();
  }, []);

  const getCommentData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getComment, {
          id: props.commentId ?? props.match.params.commentId,
        })
      );
      const { id, post, isReply, user } = result.data.getComment;

      // check if we need to fetch the referralCode owner used at signup
      if (user.signedUpWithReferralCode) {
        const referringUserResult = await API.graphql(
          graphqlOperation(getUser, {
            id: user.signedUpWithReferralCode.userId,
          })
        );
        // attach the username to the userData model
        user.signedUpWithReferralCode = {
          ...user.signedUpWithReferralCode,
          user: referringUserResult.data.getUser,
        };
      }

      // keep state
      setCommentData(result);
      setPostData({ data: { getPost: post } });
      setUserData({ data: { getUser: user } });

      if (isReply) {
        // get reply connection
        const replyResult = await API.graphql(
          graphqlOperation(getRepliedToCommentByCommentId, {
            commentId: props.commentId ?? props.match.params.commentId,
          })
        );

        if (
          replyResult.data.getRepliedToCommentByCommentId &&
          replyResult.data.getRepliedToCommentByCommentId.items
        ) {
          setRepliedToCommentData({
            data: {
              getComment:
                replyResult.data.getRepliedToCommentByCommentId.items[0]
                  .repliedTo,
            },
          });
        }
      }

      setCommentId(id);
    } catch (error) {
      toast.error(`Error while comment data ${JSON.stringify(error)}`);
    }
  };

  // make sure we load the page after the state for counts are updated
  useEffect(() => {
    if (commentId) setIsLoading(false);
  }, [commentId]);

  const handleReturn = () => {
    // check how we got here ( from user post or comments )
    if (props.match.params.userPostId) {
      props.history.push(
        `/userPosts/details/${props.match.params.userPostId}/tab/Comments`
      );
    } else {
      props.history.push(`/comments`);
    }
  };
  return (
    <PagePreLoad isLoading={isLoading}>
      <TabsContainer
        header={`Comment Details : ${commentId}`}
        defaultActiveKey={"GeneralInfo"}
        backButton={
          props.match.params.commentId && (
            <ButtonInput
              label="Back to List"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          )
        }
        tabs={[
          {
            name: "General Info",
            eventKey: "GeneralInfo",
            content: commentData && (
              <CommentGeneralInfo
                providedModelData={commentData}
                commentId={props.match.params.commentId}
              />
            ),
          },
          {
            name: "User",
            eventKey: "UserGeneralInfo",
            content: userData && (
              <UserGeneralInfo
                providedModelData={userData}
                userId={userData.id}
              />
            ),
          },
          {
            name: "Post",
            eventKey: "PostGeneralInfo",
            content: postData && (
              <UserPostGeneralInfo
                providedModelData={postData}
                postId={postData.id}
              />
            ),
          },
          {
            name: "Replied To",
            eventKey: "RepliedToCommentGeneralInfo",
            content: repliedToCommentData ? (
              <CommentGeneralInfo
                providedModelData={repliedToCommentData}
                commentId={repliedToCommentData.Id}
              />
            ) : (
              <span>Comment is not a reply to another comment.</span>
            ),
          },
          {
            name: "Replies",
            eventKey: "Replies",
            content: (
              <CommentReplies
                commentId={props.commentId ?? props.match.params.commentId}
              />
            ),
          },
        ]}
      />
    </PagePreLoad>
  );
};

export default CommentDetails;
