import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateClazz } from "../../../../graphql/mutations";
import { getClazz } from "../../../../graphql/queries";
import CardContainer from "../../../../components/Common/CardContainer";
import { Form, Alert } from "react-bootstrap";
import TextInput from "../../../../components/Common/TextInput";
import ButtonInput from "../../../../components/Common/ButtonInput";
import { toast } from "react-toastify";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";
import * as GlobalSubmitUIDelay from "../../../../constants/AppBehaviour/globalSubmitUIDelay";
import PagePreLoad from "../../../../components/Common/PagePreLoad";
import orderChangeHandler from "../../../Common/Crud/formHandlers/orderChangeHandler";

const ClassEdit = (props) => {
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = useState(0);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [updateButtonDisableStatus, setUpdateButtonDisableStatus] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(true);

  const getClassData = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getClazz, { id: props.match.params.classId })
      );
      if (result) {
        setName(result.data.getClazz.name);
        setOrder(result.data.getClazz.order);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(
        `Error while fetching class data, ${JSON.stringify(error, null, 2)}`
      );
    }
  };

  useEffect(() => {
    getClassData();
  }, []);

  const validationLogic = (providedName) => {
    let result = { result: true, message: "" };
    if (!providedName || providedName.length === 0) {
      result.result = false;
      result.message = "Class name is required";
    }
    return result;
  };

  //#region  handle class change including validation checks
  const handleClassNameChange = (event) => {
    const _errors = {};
    let providedName = event.target.value;
    setName(providedName);
    // check the class validation
    let validationResult = validationLogic(providedName);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setUpdateButtonDisableStatus(true);
    } else {
      setUpdateButtonDisableStatus(false);
    }
    setErrors(_errors);
  };
  //#endregion

  //#region checling form is valid
  const isFormValid = () => {
    const _errors = {};

    // class name is already checked and status button is already disabled
    // double check here
    // check the class validation
    let validationResult = validationLogic(name);
    // check the result
    if (!validationResult.result) {
      _errors.name = validationResult.message;
      setUpdateButtonDisableStatus(true);
    } else {
      setUpdateButtonDisableStatus(false);
    }
    setErrors(_errors);

    // Forms will be valid if the _errors object does not have any properties
    return Object.keys(_errors).length === 0;
  };
  //#endregion

  //#region Handle form submit
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    setErrors({});
    setFormSubmitInProgress(true);
    if (!isFormValid()) {
      setFormSubmitInProgress(false);
      return;
    }

    // update class at this point
    let result = await handleUpdateClass();
    if (result.result) {
      // go back to list
      setTimeout(function () {
        toast.success(result.message);
        if (props.match.params.collegeId) {
          props.history.push(
            `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
          );
        } else {
          props.history.push(
            `/schools/details/${props.match.params.schoolId}/tab/Classes`
          );
        }
      }, GlobalSubmitUIDelay.DefaultDelay);
    } else {
      toast.error(result.message);
      setFormSubmitInProgress(false);
    }
  };
  //#endregion

  //#region update class business logic
  const handleUpdateClass = async () => {
    try {
      // initial required class update data
      let input = {
        id: props.match.params.classId,
        name: name,
        order: order,
      };

      await API.graphql(graphqlOperation(updateClazz, { input }));
      return { result: true, message: `Class updated successfully` };
    } catch (error) {
      return {
        result: false,
        message: `Error updating class, ${JSON.stringify(error, null, 2)}`,
      };
    }
  };
  //#endregion

  //#region handle return
  const handleReturn = () => {
    // check how we got here from schools or from college
    if (props.match.params.collegeId) {
      props.history.push(
        `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Classes`
      );
    } else {
      props.history.push(
        `/schools/details/${props.match.params.schoolId}/tab/Classes`
      );
    }
  };
  //#endregion

  return (
    <PagePreLoad isLoading={isLoading}>
      <Form onSubmit={handleFormSubmission} noValidate>
        <CardContainer
          header="Edit Class"
          submitButton={
            <ButtonInput
              label="Update"
              disabled={updateButtonDisableStatus}
              variant={BootstrapVariants.Primary}
              formSubmitInProgress={formSubmitInProgress}
              formSubmittingText={"In Progress..."}
            />
          }
          backButton={
            <ButtonInput
              label="back"
              type="button"
              variant={BootstrapVariants.OutlineSecondary}
              onClick={() => handleReturn()}
            />
          }
        >
          {generalError && (
            <Alert variant={BootstrapVariants.Danger}>{generalError}</Alert>
          )}

          <TextInput
            type="name"
            id="name"
            label="Class Name"
            placeholder="Enter a class name"
            value={name}
            onChange={(e) => handleClassNameChange(e)}
            isRequired={true}
            error={errors.name}
          />
          <TextInput
            type="number"
            id="order"
            label="Class Order"
            placeholder="Enter a Class Appearance Order"
            value={order}
            onChange={(e) =>
              orderChangeHandler(
                e,
                "Degree",
                setOrder,
                setUpdateButtonDisableStatus,
                setErrors
              )
            }
            isRequired={true}
            error={errors.order}
          />
        </CardContainer>
      </Form>
    </PagePreLoad>
  );
};

export default ClassEdit;
