import React from "react";
import JourneyTypeGeneralInfo from "../GeneralInfo";
import CardContainer from "../../../../components/Common/CardContainer";
import ButtonInput from "../../../../components/Common/ButtonInput";
import * as BootstrapVariants from "../../../../constants/Bootstrap/Variants";

const JournetTypeDetails = (props) => {
  const handleReturn = () => {
    props.history.push(`/journeyTypes`);
  };
  return (
    <CardContainer
      header={"Journey Type Details"}
      backButton={
        <ButtonInput
          label="Back to List"
          type="button"
          variant={BootstrapVariants.OutlineSecondary}
          onClick={() => handleReturn()}
        />
      }
    >
      <JourneyTypeGeneralInfo
        journeyTypeId={props.match.params.journeyTypeId}
      />
    </CardContainer>
  );
};

export default JournetTypeDetails;
