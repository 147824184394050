import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const PreLoad = (props) => {
  return props.isLoading ? (
    <>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </>
  ) : (
    props.children
  );
};

PreLoad.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
};

export default PreLoad;
