import React from "react";
import DomainGeneralInfo from "../GeneralInfo";
import { updateDomain } from "../../../../graphql/mutations";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const DomainDelete = (props) => {
  return (
    <>
      <SoftDelete
        {...props}
        modelName="Domain"
        deleteDescription="domain will be deleted. Deleting a
        domain won't have any impact on pre-verified users."
        returnRoutePropBasedFunction={() => {
          // check how we got here ( from school or from college )
          if (props.match.params.collegeId) {
            props.history.push(
              `/schools/${props.match.params.schoolId}/colleges/details/${props.match.params.collegeId}/tab/Domains`
            );
          } else {
            props.history.push(
              `/schools/details/${props.match.params.schoolId}/tab/Domains`
            );
          }
        }}
        generalInfoComponent={
          <DomainGeneralInfo domainId={props.match.params.domainId} />
        }
        mutationMethod={updateDomain}
        mutationInput={{
          id: props.match.params.domainId,
        }}
      />
    </>
  );
};

export default DomainDelete;
