import React from "react";
import FeedbackGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateFeedback } from "../../../../graphql/mutations";
import { getFeedback} from "../../../../graphql/queries";
// import * as FeedbacksConstants from "../../../../graphql/Constants/Feedbacks/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const FeedbackDelete = (props) => {
  const deleteOperationalValidation = async (feedbackId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired feedback
      let tobeDeleted = await API.graphql(
        graphqlOperation(getFeedback, { id: feedbackId })
      );

      if (tobeDeleted.data) {
        // if (tobeDeleted.data.getSkill.id === SkillsConstants.Other.id) {
        //   // we need to prevent deletion
        //   return false;
        // }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching feedback data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Feedback"
        deleteDescription="feedback will be deleted. Delete this item?"
        returnRoute="/feedbacks"
        generalInfoComponent={
          <FeedbackGeneralInfo feedbackId={props.match.params.feedbackId} />
        }
        mutationMethod={updateFeedback}
        mutationInput={{
          id: props.match.params.feedbackId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.feedbackId)
        }
      />
    </>
  );
};

export default FeedbackDelete;
