import React from "react";
import { searchPostCategorys } from "../../../graphql/queries";
import * as ModelTypes from "../../../graphql/Constants/ModelTypes";
import DataGrid from "../../Common/DataGrid";
import * as DataTypes from "../../../constants/Data/DataTypes";
import * as QueryTypes from "../../../graphql/Constants/QueryTypes";
import * as PostCategoryTypes from "../../../graphql/Constants/PostCategories/Types";

const PostCategories = (props) => {
  return (
    <DataGrid
      {...props}
      header="Post Categories"
      gridObjectDataModel={[
        { name: "name", headerText: "Name", type: DataTypes.String },
        { name: "order", headerText: "Order", type: DataTypes.Number },
        {
          name: "createdAt",
          headerText: "Created At",
          type: DataTypes.DateTime,
        },
      ]}
      modelType={ModelTypes.PostCategory}
      queryFunction={searchPostCategorys}
      queryFunctionType={QueryTypes.Search}
      queryFilters={{
        id: {
          ne: PostCategoryTypes.NONE.id,
        },
      }}
      queryName={"searchPostCategorys"}
      hasCreate={true}
      hasDetails={true}
      hasEdit={true}
      hasDelete={true}
      baseRoute={"postCategories"}
    />
  );
};

export default PostCategories;
