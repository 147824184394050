import React from "react";
import DegreeGeneralInfo from "../GeneralInfo";
import { API, graphqlOperation } from "aws-amplify";
import { updateDegree } from "../../../../graphql/mutations";
import { getDegree } from "../../../../graphql/queries";
import * as DegreesConstants from "../../../../graphql/Constants/Degrees/Types";
import SoftDelete from "../../../Common/Crud/SoftDelete";

const DegreeDelete = (props) => {
  const deleteOperationalValidation = async (degreeId) => {
    try {
      // extra condition check
      // we should not let admin to delete the OTHER option
      // get the desired degree
      let tobeDeleted = await API.graphql(
        graphqlOperation(getDegree, { id: degreeId })
      );

      if (tobeDeleted.data) {
        if (tobeDeleted.data.getDegree.id === DegreesConstants.Other.id) {
          // we need to prevent deletion
          return false;
        }
      }

      // deleting is permitted
      return true;
    } catch (error) {
      return {
        result: false,
        message: `Error fetching degree data, validation check error, ${JSON.stringify(
          error,
          null,
          2
        )}`,
      };
    }
  };

  return (
    <>
      <SoftDelete
        {...props}
        modelName="Degree"
        deleteDescription="degree will be deleted. Deleting a degree will have presentational affect on existing relationships."
        returnRoute="/degrees"
        generalInfoComponent={
          <DegreeGeneralInfo degreeId={props.match.params.degreeId} />
        }
        mutationMethod={updateDegree}
        mutationInput={{
          id: props.match.params.degreeId,
        }}
        deleteOperationValidation={async () =>
          await deleteOperationalValidation(props.match.params.degreeId)
        }
      />
    </>
  );
};

export default DegreeDelete;
