import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import { appendInfiniteContent } from "@syncfusion/ej2-react-grids";
import { getProfileImage } from "../../../graphql/queries";
import {
  createProfileImage,
  createS3Object,
  updateProfileImage,
  updateS3Object,
} from "../../../graphql/mutations";
import * as DefaultProfileImage from "../../../graphql/Constants/ProfileImages/DefaultImage";
import PagePreLoad from "../../../components/Common/PagePreLoad";
import { Row, Table } from "react-bootstrap";
import IsChecked from "../../../components/Common/IsChecked";
import CardContainer from "../../../components/Common/CardContainer";
import ImagePreview from "../../../components/Common/ImagePreview";
import getObjectFromS3 from "../../Common/S3/Handlers/getObjectFromS3";
import uuid from "react-uuid";
import { Storage } from "aws-amplify";

const SystemInitialAssets = () => {
  const [
    isDefaultUserProfileDefined,
    setIsDefaultUserProfileDefined,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultProfileImageUrl, setDefaultProfileImageUrl] = useState("");

  useEffect(() => {
    checkIfDefaultUserProfileAssetIsDefined();
  }, []);

  const checkIfDefaultUserProfileAssetIsDefined = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getProfileImage, {
          id: DefaultProfileImage.ProfileImage.id,
        })
      );
      if (result.data.getProfileImage) {
        let getProfileImageS3ObjectResult = await getObjectFromS3(
          result.data.getProfileImage.s3Object.key
        );
        if (getProfileImageS3ObjectResult.result) {
          setIsDefaultUserProfileDefined(true);
          setDefaultProfileImageUrl(getProfileImageS3ObjectResult.url);
        } else {
          toast.error(
            `Error while fetching profile Image S3 object from S3, ${getProfileImageS3ObjectResult.errorMessage}`
          );
        }
      }
    } catch (error) {
      toast.error(
        `Error while fetching default profile Image, ${JSON.stringify(error)}`
      );
    }
    setIsLoading(false);
  };

  const onChange = async (e) => {
    const file = e.target.files[0];
    Storage.put(DefaultProfileImage.S3Object.fileName, file, {
      acl: "public-read", // this is required to set the public read access
      contentType: "image/png",
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    })
      .then(async (uploadResult) => {
        // if new create
        if (!isDefaultUserProfileDefined) {
          let createS3ObjectResult = await API.graphql(
            graphqlOperation(createS3Object, {
              input: {
                id: DefaultProfileImage.S3Object.id,
                bucket: DefaultProfileImage.S3Object.bucket,
                region: DefaultProfileImage.S3Object.region,
                key: uploadResult.key,
                fileName: DefaultProfileImage.S3Object.fileName,
              },
            })
          );

          // check the result
          if (createS3ObjectResult.data.createS3Object) {
            // create the [rofile Image object at db
            let createProfileImageAtDbResult = await API.graphql(
              graphqlOperation(createProfileImage, {
                input: {
                  id: DefaultProfileImage.ProfileImage.id,
                  s3ObjectId: DefaultProfileImage.S3Object.id,
                  deletedAt: null,
                },
              })
            );

            // check result
            if (createProfileImageAtDbResult.data.createProfileImage) {
              checkIfDefaultUserProfileAssetIsDefined();
            } else {
              toast.error(
                `Error while creating profile Image at DB , ${JSON.stringify(
                  createProfileImageAtDbResult
                )}`
              );
            }
          } else {
            toast.error(
              `Error while creating S3 object at DB , ${JSON.stringify(
                createS3ObjectResult
              )}`
            );
          }
        } else {
          // if exists update
          let s3ObjectAtDbUpodateResult = await API.graphql(
            graphqlOperation(updateS3Object, {
              input: {
                id: DefaultProfileImage.S3Object.id,
                bucket: DefaultProfileImage.S3Object.bucket,
                region: DefaultProfileImage.S3Object.region,
                key: uploadResult.key,
                fileName: DefaultProfileImage.S3Object.fileName,
              },
            })
          );

          // check the result
          if (s3ObjectAtDbUpodateResult.data.updateS3Object) {
            // do not need to update profile Image at db since Ids are the same
            checkIfDefaultUserProfileAssetIsDefined();
          } else {
            toast.error(
              `Error while u[pdating] S3 object at DB , ${JSON.stringify(
                s3ObjectAtDbUpodateResult
              )}`
            );
          }
        }
      })
      .catch((err) =>
        toast.error(
          `Error while uploading default profile Image, ${JSON.stringify(err)}`
        )
      );
  };

  return (
    <PagePreLoad isLoading={isLoading}>
      <CardContainer header="System Initial Assets">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Preview</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Users Default Profile Image</td>
              <td>
                <IsChecked status={isDefaultUserProfileDefined} />
              </td>
              <td>
                {isDefaultUserProfileDefined && (
                  <ImagePreview url={defaultProfileImageUrl} />
                )}
              </td>
              <td>
                <input
                  type="file"
                  accept="image/png"
                  onChange={(evt) => onChange(evt)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </CardContainer>
    </PagePreLoad>
  );
};

export default SystemInitialAssets;
