const PasswordConfoirmationValidation = (passwordConfirmation, Password) => {
  let result = { result: true, message: "" };
  if (!passwordConfirmation || passwordConfirmation.length === 0) {
    result.result = false;
    result.message = "Password Confirmation is required";
  } else {
    if (passwordConfirmation !== Password) {
      result.result = false;
      result.message = "Password Confirmation does not match with Password.";
    }
  }
  return result;
};

export default PasswordConfoirmationValidation;
