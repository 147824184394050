import EmailValidation from "./EmailValidation";

const userNameVerification = (providedName, modelName) => {
  // trim the provided value
  providedName = providedName.trim();

  // define the base result
  let result = { result: true, message: "" };

  // apply the logic
  if (!providedName || providedName.length === 0) {
    result.result = false;
    result.message = `${modelName} UserName is required`;
  }

  // check email validation
  let emailValidationResul = EmailValidation(providedName);

  if (!emailValidationResul.result) {
    result.result = false;
    result.message = `${modelName} UserName is not in a valid format, acceptable format is: test@example.com`;
  }
  // return the result of validation logic
  return result;
};

export default userNameVerification;
